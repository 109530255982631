/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { makeStyles, Theme, createStyles, withStyles } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { KeyboardDatePicker } from '@material-ui/pickers/DatePicker/DatePicker';
// import MergeTypeIcon from '@material-ui/icons/MergeType';
import MomentUtils from '@date-io/moment';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import moment from 'moment';
import { PrioritySelector } from './selectors/PrioritySelector';
import { IAutoCompleteItem } from '../typings/autoComplete';
import { TaskStatusSelector } from './selectors/TaskStatusSelector';
import { UserAutocompleteSelector } from '../components/UserAutocompleteSelector';
import { DueSelector } from './selectors/DueSelector';
import { CreatedSelector } from './selectors/CreatedSelector';
import { FirmTeamSelector } from '../lookupConfiguration/selectors/FirmTeamSelector';
import { UserFeature, UserFeatureEnum } from '../types/UserFeature';
import { TaskTypeSelector } from '../lookupConfiguration/selectors/TaskTypeSelector';
import { TaskSummaryAdvancedFilter } from './TasksSummaryPage';
import { Button, IconButton, Tooltip } from '@material-ui/core';
// import ClearAllIcon from '@material-ui/icons/ClearAll';
import CloseIcon from '@material-ui/icons/Close';
import { MatterAutoCompleteSelector } from '../components/MatterAutoCompleteSelector';
import { ClientAutoCompleteSelector } from '../components/ClientAutoCompleteSelector';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
        display: 'flex',
        flex: 1,
        alignItems: 'center',
        margin: '0 -10px',
    },
    col1: {
        flex: 1,
        display: 'flex',
        justifyContent: 'flex-start',
        margin: '0 -10px',
        alignItems: 'center',
        flexFlow: 'row wrap',
        padding: '0 10px',
        '& > div': {
            padding: '10px'
        }
    },
    col2: {
        padding: '0 10px',
    },
    formControl: {
        minWidth: '100px',
    }
  })
);

interface TasksSummaryAdvanceFilterProps {
    advancedFilter: TaskSummaryAdvancedFilter;
    onSearch?: (
        due: IAutoCompleteItem | undefined,
        created: IAutoCompleteItem | undefined,
        priority: IAutoCompleteItem[] | undefined,
        status: IAutoCompleteItem[] | undefined,
        allocatedTo: IAutoCompleteItem[] | undefined,
        allocatedBy: IAutoCompleteItem[] | undefined,
        updatedFrom: Date | undefined,
        updatedTo: Date | undefined,
        completedFrom: Date | undefined,
        completedTo: Date | undefined,
        team: IAutoCompleteItem[] | undefined,
        taskTypes: IAutoCompleteItem[] | undefined,
        matterList: IAutoCompleteItem[] | undefined,
        clientList: IAutoCompleteItem[] | undefined,
    ) => void;
    onClose?: () => void;
    onHasFilter?: (value: boolean) => void;
}

interface TasksSummaryAdvanceFilterState {
    due: IAutoCompleteItem | undefined;
    created: IAutoCompleteItem | undefined;
    priority: IAutoCompleteItem[] | undefined;
    status: IAutoCompleteItem[] | undefined;
    allocatedTo: IAutoCompleteItem[] | undefined;
    allocatedBy: IAutoCompleteItem[] | undefined;
    updatedFrom: Date  | undefined;
    updatedTo: Date  | undefined;
    completedFrom: Date  | undefined;
    completedTo: Date  | undefined;
    team: IAutoCompleteItem[] | undefined;
    taskTypes: IAutoCompleteItem[] | undefined;
    matterList: IAutoCompleteItem[] | undefined;
    clientList: IAutoCompleteItem[] | undefined;
}

export const TasksSummaryAdvanceFilter: React.FC<TasksSummaryAdvanceFilterProps> = props => {

    const classes = useStyles();

    const [state, setState] = useState<TasksSummaryAdvanceFilterState>({
        due: props.advancedFilter.due,
        created: props.advancedFilter.created,
        priority: props.advancedFilter.priority,
        status: props.advancedFilter.status,
        allocatedTo: props.advancedFilter.allocatedTo,
        allocatedBy: props.advancedFilter.allocatedBy,
        updatedFrom: props.advancedFilter.updatedFrom,
        updatedTo: props.advancedFilter.updatedTo,
        completedFrom: props.advancedFilter.completedFrom,
        completedTo: props.advancedFilter.completedTo,
        team: props.advancedFilter.team,
        taskTypes: props.advancedFilter.taskTypes,
        matterList: props.advancedFilter.matterList,
        clientList: props.advancedFilter.clientList,
    });

    useEffect(() => {
                
        // tslint:disable-next-line: no-console
        // console.log('filter', state);

        if (props.onSearch) {
            props.onSearch(
                state.due,
                state.created,
                state.priority,
                state.status,
                state.allocatedTo,
                state.allocatedBy,
                state.updatedFrom,
                state.updatedTo,
                state.completedFrom,
                state.completedTo,
                state.team,
                state.taskTypes,
                state.matterList,
                state.clientList
            );
        }

        if (props.onHasFilter) {

            let hasFilter = false;

            if (
                (state.due !== undefined && state.due !== null) ||
                (state.created !== undefined && state.created !== null) ||
                (state.priority && state.priority.length > 0) ||
                (state.status && state.status.length > 0) ||
                (state.allocatedTo && state.allocatedTo.length > 0) ||
                (state.allocatedBy && state.allocatedBy.length > 0) ||
                state.updatedFrom ||
                state.updatedTo ||
                state.completedFrom ||
                state.completedTo ||
                (state.team && state.team.length > 0) ||
                (state.taskTypes && state.taskTypes.length > 0) ||
                (state.matterList && state.matterList.length > 0) ||
                (state.clientList && state.clientList.length > 0)
            ) {
                hasFilter = true;
            }

            props.onHasFilter(hasFilter);
        }
    // tslint:disable-next-line: align
    }, [state]);

    const handleDateChange = (field: string) => (date: MaterialUiPickersDate, value?: string | null | undefined) => {
        setState((prevState) => {
            return {
                ...prevState,
                [field]: date ? moment(date).format('YYYY-MM-DD') : null
            };
        });
    };

    const onSelection = (_value: IAutoCompleteItem[], name: string) => {
        setState((prevState) => {
            return {
                ...prevState,
                [name]: _value
            };
        });
    };

    const onSingleSelection = (value: IAutoCompleteItem, name: string) => {

        const _value = value.label.length > 0 ? value : undefined;

        setState((prevState) => {
            return {
                ...prevState,
                [name]: _value
            };
        });
    };

    const onClear = () => {
        setState((prevState) => {
            return {
                ...prevState,
                due: undefined,
                created: undefined,
                priority: undefined,
                status: undefined,
                allocatedTo: undefined,
                allocatedBy: undefined,
                updatedFrom: undefined,
                updatedTo: undefined,
                completedFrom: undefined,
                completedTo: undefined,
                team: undefined,
                taskTypes: undefined,
                matter: undefined,
                client: undefined
            };
        });
    };

    const LightTooltip = withStyles((theme: Theme) => ({
        tooltip: {
          backgroundColor: theme.palette.common.white,
          color: 'rgba(0, 0, 0, 0.87)',
          boxShadow: theme.shadows[1],
          fontSize: 11,
        },
    }))(Tooltip);

    return (
        <MuiPickersUtilsProvider utils={MomentUtils}>
            <div className={classes.root}>
                <div className={classes.col1}>
                    <div>
                        <DueSelector 
                            name="due"
                            label="Due"
                            value={state.due ? state.due : null}
                            onSelection={onSingleSelection}
                        />
                    </div>
                    <div>
                        <CreatedSelector 
                            name="created"
                            label="Created"
                            value={state.created ? state.created : null}
                            onSelection={onSingleSelection}
                        />
                    </div>
                    <div>
                        <PrioritySelector 
                            name="priority"
                            multiple={true}
                            value={state.priority ? state.priority : []}
                            onSelection={onSelection}
                        />
                    </div>
                    <div>
                        <TaskStatusSelector 
                            multiple={true}
                            value={state.status ? state.status : []}
                            onSelection={onSelection}
                            label="Status"
                            name="status"
                            isRefresh={true}
                            isIncludeCount={false}
                        />
                    </div>
                    <div>
                        <UserAutocompleteSelector 
                            name="allocatedTo"
                            label="Assigned To"
                            multiple={true}
                            value={state.allocatedTo ? state.allocatedTo : []}
                            onSelection={onSelection}
                            id="allocatedTo"
                            visible={{
                                role: true
                            }}
                            isExternal={false}
                            isAuthorized={true}
                        />
                    </div>
                    <div>
                        <UserAutocompleteSelector 
                            name="allocatedBy"
                            label="Assigned By"
                            multiple={true}
                            value={state.allocatedBy ? state.allocatedBy : []}
                            onSelection={onSelection}
                            id="allocatedBy"
                            isExternal={false}
                            isAuthorized={true}
                        />
                    </div>
                    <div>
                        <MatterAutoCompleteSelector 
                            name="matterList"
                            label="Matter"
                            multiple={true}
                            value={state.matterList ? state.matterList : []}
                            onSelection={onSelection}
                            id="matterList"
                            textLimit={{
                                description: 20,
                                title: 20
                            }}
                        />
                    </div>
                    <div>
                        <ClientAutoCompleteSelector 
                            name="clientList"
                            label="Client"
                            multiple={true}
                            value={state.clientList ? state.clientList : []}
                            onSelection={onSelection}
                            id="clientList"
                            textLimit={{
                                email: 20,
                                label: 20
                            }}
                        />
                    </div>
                    <div>
                        <KeyboardDatePicker
                            // variant="inline"
                            label="Updated From"
                            value={state.updatedFrom ? state.updatedFrom : null}
                            onChange={handleDateChange('updatedFrom')}
                            // tslint:disable-next-line: no-console
                            onError={console.log}
                            format="MMM D, YYYY"
                            clearable={true}
                            variant={'inline'}
                            autoOk={true}
                            allowKeyboardControl={true}
                            animateYearScrolling={true}
                        />
                    </div>
                    <div>
                        <KeyboardDatePicker
                            minDate={state.updatedFrom}
                            // variant="inline"
                            label="Updated To"
                            value={state.updatedTo ? state.updatedTo : null}
                            onChange={handleDateChange('updatedTo')}
                            // tslint:disable-next-line: no-console
                            onError={console.log}
                            format="MMM D, YYYY"
                            clearable={true}
                            variant={'inline'}
                            autoOk={true}
                            allowKeyboardControl={true}
                            animateYearScrolling={true}
                        />
                    </div>
                    <div>
                        <KeyboardDatePicker
                            // variant="inline"
                            label="Completed From"
                            value={state.completedFrom ? state.completedFrom : null}
                            onChange={handleDateChange('completedFrom')}
                            // tslint:disable-next-line: no-console
                            onError={console.log}
                            format="MMM D, YYYY"
                            clearable={true}
                            variant={'inline'}
                            autoOk={true}
                            allowKeyboardControl={true}
                            animateYearScrolling={true}
                        />
                    </div>
                    <div>
                        <KeyboardDatePicker
                            minDate={state.completedFrom}
                            // variant="inline"
                            label="Completed To"
                            value={state.completedTo ? state.completedTo : null}
                            onChange={handleDateChange('completedTo')}
                            // tslint:disable-next-line: no-console
                            onError={console.log}
                            format="MMM D, YYYY"
                            clearable={true}
                            variant={'inline'}
                            autoOk={true}
                            allowKeyboardControl={true}
                            animateYearScrolling={true}
                        />
                    </div>
                    <div>
                        <FirmTeamSelector 
                            name="team"
                            label="Team"
                            multiple={true}
                            onSelection={onSelection}
                            value={state.team ? state.team : []}
                            isMyTeamOnly={ 
                                UserFeature.HasAccess(UserFeatureEnum.hasTaskViewAll) 
                                             ? null
                                             : UserFeature.HasAccess(UserFeatureEnum.hasTaskViewMyTeamOnly)
                            }
                            taskOnly={true}
                            enquiryOnly={false}
                            financialOnly={false}
                        />
                    </div>
                    <div>
                        <TaskTypeSelector 
                            name="taskTypes"
                            label="Task Type"
                            multiple={true}
                            value={state.taskTypes ? state.taskTypes : []}
                            onSelection={onSelection}
                        />
                    </div>
                </div>
                <div className={classes.col2}>
                    <LightTooltip
                        arrow={true}
                        title="clear filter"
                    >
                        <Button variant="outlined" color="primary" onClick={onClear}>
                            Clear
                        </Button>
                    </LightTooltip>
                    <LightTooltip
                        arrow={true}
                        title="close filter"
                    >
                        <IconButton
                            aria-label="Advanced Filter"
                            // onClick={onClickAdvanceFilter}
                            onClick={props.onClose}
                        >
                            <CloseIcon 
                                fontSize="default"
                            />
                        </IconButton>
                    </LightTooltip>
                </div>
            </div>
        </MuiPickersUtilsProvider>

    );
};
