import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import {
    Button,
    Dialog,
    DialogTitle,
    DialogActions,
    DialogContent,
    Grid,
    Divider,
} from '@material-ui/core';
import moment from 'moment';
import { IAutoCompleteItem } from '../../typings/autoComplete';
import { mainTheme } from '../../Theme';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {},
        header: {
            textTransform: 'capitalize',
        },
        fieldContainer: {
            display: 'flex',
            justifyContent: 'space-between',
            margin: '0 -10px',
            flex: 1,
            height: '100%',
            alignItems: 'center',
            '& .col': {
                padding: '0 10px',
            },
            '& .value': {
                // fontWeight: 600,
                color: 'grey',
            },
            '& .text': {
                flex: 1,
                color: 'grey',
            },
            '& .status': {
                // fontWeight: 600,
                color: mainTheme.BrandColors.TemplatePrimary
            }
        },
        divider: {
            width: '100%',
        }
    })
);

interface GeneralViewDialogProps {
    open: boolean;
    title?: string;
    onClose?: () => void;
    loggedInUser: IAutoCompleteItem | null;
    defaultPriority: IAutoCompleteItem | null;
    
    // Saved values
    taskGuid?: string;
    description?: string | null;
    notes?: string | null;
    assignedTo?: IAutoCompleteItem | null;
    priority?: IAutoCompleteItem | null;
    taskCategory?: IAutoCompleteItem | null;
    dueDate?: Date | null;
    estimatedEffort?: string | null;
    startDate?: Date | null;
    completionDate?: Date | null;
    duration?: string | null;
    assignedBy?: IAutoCompleteItem | null;
    status?: string | null;
    completionNotes?: string | null;
    owner?: IAutoCompleteItem | null;
}

export const GeneralViewDialog: React.FC<GeneralViewDialogProps> = (props) => {
    const classes = useStyles();

    const onClose = () => {
        if (props.onClose) {
            props.onClose();
        }
    };

    return (
        <>
            <Dialog
                open={props.open}
                onClose={onClose}
                maxWidth="sm"
                fullWidth={true}
                className={classes.root}
                scroll={'paper'}
            >
                <DialogTitle className={classes.header}>
                    {props.title}
                </DialogTitle>
                <DialogContent dividers={true}>
                    <Grid container={true} spacing={3}>
                        {!!props.description && (
                            <Grid item={true} xs={12} md={12}>
                                <div className={classes.fieldContainer}>
                                    <div className="col label">
                                        Description:
                                    </div>
                                    <div className="col value" dangerouslySetInnerHTML={{ __html: props.description }} />
                                </div>
                            </Grid>
                        )}
                        {!!props.notes && (
                            <Grid item={true} xs={12} md={12}>
                                <div className={classes.fieldContainer}>
                                    <div className="col label">
                                        Notes:
                                    </div>
                                    <div className="col value" dangerouslySetInnerHTML={{ __html: props.notes }} />
                                </div>
                            </Grid>
                        )}
                        <Divider className={classes.divider} />
                        <Grid item={true} xs={12} md={12}>
                            <Grid container={true} spacing={3}>
                                {!!props.assignedTo && (
                                    <Grid item={true} xs={12} md={6}>
                                        <div className={classes.fieldContainer}>
                                            <div className="col label">
                                                Assigned To:
                                            </div>
                                            <div className="col value">
                                                {props.assignedTo.label}
                                            </div>
                                        </div>
                                    </Grid>
                                )}
                                {props.owner && (
                                    <Grid item={true} xs={12} md={6}>
                                        <div className={classes.fieldContainer}>
                                            <div className="col label">
                                                Owner:
                                            </div>
                                            <div className="col value">
                                                {props.owner.label}
                                            </div>
                                        </div>
                                    </Grid>
                                )}
                            </Grid>
                        </Grid>
                        {!!(props.priority || props.defaultPriority) && (
                            <Grid item={true} xs={12} md={6}>
                                <div className={classes.fieldContainer}>
                                    <div className="col label">
                                        Priority:
                                    </div>
                                    <div className="col value">
                                        {props.priority ? props.priority.label : props.defaultPriority!.label}
                                    </div>
                                </div>
                            </Grid>
                        )}
                        {!!props.taskCategory && (
                            <Grid item={true} xs={12} md={6}>
                                <div className={classes.fieldContainer}>
                                    <div className="col label">
                                        Category:
                                    </div>
                                    <div className="col value">
                                        {props.taskCategory.label}
                                    </div>
                                </div>
                            </Grid>
                        )}
                        {!!props.dueDate && (
                            <Grid item={true} xs={12} md={6}>
                                <div className={classes.fieldContainer}>
                                    <div className="col label">
                                        Due:
                                    </div>
                                    <div className="col value">
                                        {moment(props.dueDate).format('MMM D, YYYY hh:mm a')}
                                    </div>
                                </div>
                            </Grid>
                        )}
                        {!!props.estimatedEffort && (
                            <Grid item={true} xs={12} md={6}>
                                <div className={classes.fieldContainer}>
                                    <div className="col label">
                                        Estimated Effort:
                                    </div>
                                    <div className="col value">
                                        {props.estimatedEffort}
                                    </div>
                                </div>
                            </Grid>
                        )}
                        {!!props.startDate && (
                            <Grid item={true} xs={12} md={6}>
                                <div className={classes.fieldContainer}>
                                    <div className="col label">
                                        Start:
                                    </div>
                                    <div className="col value">
                                        {moment(props.startDate).format('MMM D, YYYY hh:mm a')}
                                    </div>
                                </div>
                            </Grid>
                        )}
                        {!!props.completionDate && (
                            <Grid item={true} xs={12} md={6}>
                                <div className={classes.fieldContainer}>
                                    <div className="col label">
                                        Completion:
                                    </div>
                                    <div className="col value">
                                        {moment(props.completionDate).format('MMM D, YYYY hh:mm a')}
                                    </div>
                                </div>
                            </Grid>
                        )}
                        {!!props.duration && (
                            <Grid item={true} xs={12} md={6}>
                                <div className={classes.fieldContainer}>
                                    <div className="col label">
                                        Actual Effort:
                                    </div>
                                    <div className="col value">
                                        {props.duration}
                                    </div>
                                </div>
                            </Grid>
                        )}
                        {!!(props.assignedBy || props.loggedInUser) && (
                            <Grid item={true} xs={12} md={6}>
                                <div className={classes.fieldContainer}>
                                    <div className="col label">
                                        Assigned By:
                                    </div>
                                    <div className="col value">
                                        {props.assignedBy ? props.assignedBy!.label : props.loggedInUser!.label}
                                    </div>
                                </div>
                            </Grid>
                        )}
                        {!!props.status && (
                            <Grid item={true} xs={12} md={6}>
                                <div className={classes.fieldContainer}>
                                    <div className="col label">
                                        Status:
                                    </div>
                                    <div className="col status">
                                        {props.status}
                                    </div>
                                </div>
                            </Grid>
                        )}
                        {!!props.completionNotes && (
                            <Grid item={true} xs={12} md={12}>
                                <div className={classes.fieldContainer}>
                                    <div className="col label">
                                        Completion Notes:
                                    </div>
                                    <div className="col value">
                                        {props.completionNotes}
                                    </div>
                                </div>
                            </Grid>
                        )}
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button
                        type="button"
                        onClick={onClose}
                        color="primary"
                    >
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};
