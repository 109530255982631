/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import {
    Button,
    Dialog,
    DialogTitle,
    DialogActions,
    DialogContent,
    Grid,
} from '@material-ui/core';
import { AnyObject, Field, FieldInputProps, Form, FormSpy } from 'react-final-form';
import { FormApi } from 'final-form';
import { showNotification } from '../../App';
import { RvLoader } from '../../components/Loader';
import { client } from '../..';
import { IAutoCompleteItem } from '../../typings/autoComplete';
import { AppUserData, ChangeTaskAllocation, fetchAppUserData, retrieveTaskDetailsData, TaskDetailsData, TaskDetailsParam } from '../TasksSummaryRepository';
import DialogBox from '../../Dashboard/Component/DialogBox';
import { TaskAssignedBySelector } from '../selectors/TaskAssignedBySelector';
import { AllocatedToEntityType } from '../AllocatedToEntityType';
import { TaskAssignedByUserTeamSelector } from '../selectors/TaskAssignedByUserTeamSelector';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        dialogRoot: {
            '& .downshift-wrapper': {
                flex: 1,
                padding: 0,
            }
        },
        header: {
            textTransform: 'capitalize',
        },
        textField: {
            width: '100%',
        },
        content: {
            flex: 1
        },
        buttonContainer: {
            display: 'flex',
            justifyContent: 'space-between',
            // paddingBottom: '20px'
        },
        button: {
            margin: theme.spacing(1),
            minWidth: '83px',
        },
        hidden: {
            display: 'none',
        },
        loaderWrapper: {
            display: 'flex',
            justifyContent: 'center',
            padding: '20px 0',
        },
        clientSelector: {
            flex: 1
        },
    })
);

interface AllocateFormDialogProps {
    open: boolean;
    onClose?: () => void;
    title?: string;
    guid: string;
    loggedInUser: IAutoCompleteItem | null;
}

interface AllocateFormDialogState {
    open: boolean;
    isLoading: boolean;
    isUserLoading: boolean;
    onSubmitLoading: boolean;
    showDiscard: boolean;
    isChanged: boolean;
}

interface AllocateForm {
    assignTo: IAutoCompleteItem | null;
    assignedBy: IAutoCompleteItem | null;
    allocatedToEntityType: number | null;
}

export const AllocateFormDialog: React.FC<AllocateFormDialogProps> = (props) => {
    const classes = useStyles();

    const [state, setState] = useState<AllocateFormDialogState>({
        open: false,
        isLoading: false,
        isUserLoading: false,
        onSubmitLoading: false,
        showDiscard: false,
        isChanged: false
    });

    const [allocateForm, setAllocateForm] = useState<AllocateForm>({
        assignTo: null,
        assignedBy: null,
        allocatedToEntityType: null
    });

    useEffect(() => {

        if (props.guid) {
            fetchTaskDetails(props.guid);
            fetchUser();
        }
        setState((prevState) => {
            return {
                ...prevState,
                open: props.open
            };
        });
    // tslint:disable-next-line: align
    }, [props.open, props.guid]);

    const fetchTaskDetails = (guid: string) => {

        setState((prevState) => {
            return {
                ...prevState,
                isLoading: true
            };
        });

        const taskDetailsParams: TaskDetailsParam = {
            guid: guid
        };

        retrieveTaskDetailsData(
            taskDetailsParams,
            true,
            // tslint:disable-next-line: no-console
            (data: TaskDetailsData) => onTaskDetailsRetrieved(data),
            // tslint:disable-next-line: no-any
            function (reason: any): void {
                showNotification(null, reason, 'error');
            }
        );
    };

    const onTaskDetailsRetrieved = (data: TaskDetailsData) => {
        // tslint:disable-next-line: no-console

        if (data && data.task && data.task.detail) {
            
            const taskDetails = data.task.detail;

            setAllocateForm((prevAllocateForm) => {
                return {
                    ...prevAllocateForm,
                    // tslint:disable-next-line: max-line-length
                    // assignTo will be empty. User has to select the value
                    // assignTo: taskDetails.assignedTo && taskDetails.assignedToName ? {label: taskDetails.assignedToName, value: taskDetails.assignedTo} : null,
                    // tslint:disable-next-line: max-line-length
                    assignedBy: taskDetails.assignedBy && taskDetails.assignedByName ? {label: taskDetails.assignedByName, value: taskDetails.assignedBy} : props.loggedInUser,
                    allocatedToEntityType: taskDetails.allocatedToEntityType, 
                };
            });
        }

        setState((prevState) => {
            return {
                ...prevState,
                isLoading: false
            };
        });
    };

    const fetchUser = () => {
        setState((prevState) => {
            return {
                ...prevState,
                isUserLoading: true
            };
        });

        fetchAppUserData(
            false,
            // tslint:disable-next-line: no-console
            (data: AppUserData) => onUserRetrieved(data),
            // tslint:disable-next-line: no-any
            function (reason: any): void {
                showNotification(null, reason, 'error');
            }
        );
    };

    const onUserRetrieved = (data: AppUserData) => {
        setAllocateForm({
            ...allocateForm,
            assignedBy: {
                label: data.appUser.name,
                value: data.appUser.userId
            }
        });

        setState((prevState) => {
            return {
                ...prevState,
                isUserLoading: false
            };
        });
    };

    const onSubmit = (form: FormApi<AllocateForm>, values: AnyObject) => {
        setState((prevState) => {
            return {
                ...prevState,
                onSubmitLoading: true
            };
        });

        client
        .mutate({
            mutation: ChangeTaskAllocation,
            variables: {
                guidId: props.guid, 
                assignedTo: values.assignTo ? values.assignTo.value : null,
                assignedBy: values.assignedBy ? values.assignedBy.value : null,
                allocatedToEntityType: values.allocatedToEntityType,
            },
        })
        // tslint:disable-next-line: no-any
        .then((results: { data: any }) => {
            if (results.data) {
                if (results.data.error === null || results.data.error === undefined) {
                    showNotification(null, 'Successfully submitted', 'info');     
                    closePanel();                   
                } else {
                    showNotification('Failed to update allocation', results.data.error, 'error');
                }

                setState((prevState) => {
                    return {
                        ...prevState,
                        onSubmitLoading: false
                    };
                });
            }
        })
        // tslint:disable-next-line:no-any
        .catch((reason: any) => {
            showNotification('Failed to update allocation', reason, 'error');
            setState((prevState) => {
                return {
                    ...prevState,
                    onSubmitLoading: false
                };
            });
        });
    };

    const closePanel = () => {
        // codepanel
        if (props.onClose) {
            return props.onClose();
        }
    };

    const onReset = () => {
        // reset
    };

    // tslint:disable-next-line: no-any
    const onFormValueChanged = (form: FormApi<AllocateForm>, changeProps: any) => {
        if (!changeProps.pristine) {
            setState((prevState) => {
                return {
                    ...prevState,
                    isChanged: true
                };
            });
        }
    };

    const onClose = () => {
        if (state.isChanged) {
            setState((prevState) => {
                return {
                    ...prevState,
                    showDiscard: true
                };
            });
        } else {
            if (props.onClose) {
                props.onClose();
            }
        }
    };

    const onDiscardChanges = (agree: boolean) => {
        if (agree) {
            setState((prevState) => {
                return {
                    ...prevState,
                    showDiscard: false,
                };
            });

            if (props.onClose) {
                props.onClose();
            }

        } else {
           setState((prevState) => {
               return {
                   ...prevState,
                   showDiscard: false
               };
           });
        }
    };

    // tslint:disable-next-line: no-any
    const onDropDownSelect = (form: FormApi<AllocateForm>, values: AnyObject, input: FieldInputProps<any, HTMLElement>, 
                              // tslint:disable-next-line: no-any
                              selection: IAutoCompleteItem | any, name: string, isUser: boolean) => {
        if (name === 'assignTo') {
            let assignedTo = selection && selection.value && selection.label 
                                ? { value : selection.value, label: selection.label }
                                : null;
            form.batch(() => {
                form.change('assignTo', assignedTo);
                form.change('allocatedToEntityType', isUser 
                    ? AllocatedToEntityType.user 
                    : AllocatedToEntityType.team
                );                
            });
        }
    };

    // tslint:disable-next-line: no-any
    const required = (value: any) => {
        return value ? undefined : 'Required';
    };

    const actionButton = (
        // tslint:disable-next-line: no-any
        form: FormApi<AllocateForm>,
        submitting: boolean,
        pristine: boolean,
        // tslint:disable-next-line: no-any
        values: AnyObject
    ) => {
        return (
            <div className={classes.buttonContainer}>
                {/* <RenderCount /> */}
                <div>
                    <Button
                        color="primary"
                        type="submit"
                        className={classes.button}
                        onClick={() => {
                            // code here..
                        }}
                        disabled={submitting || pristine || state.onSubmitLoading}
                    >
                        Update
                    </Button>
                </div>
                <div>
                    <Button
                        id="resetButton"
                        type="button"
                        onClick={onReset}
                        disabled={submitting || pristine}
                        className={classes.hidden}
                    >
                        Reset
                    </Button>
                    <Button
                        color="primary"
                        type="button"
                        className={classes.button}
                        onClick={closePanel}
                    >
                        Cancel
                    </Button>
                </div>
            </div>
        );
    };

    return (
        <>
            <DialogBox
                title="Assign"
                // tslint:disable-next-line:max-line-length
                content={`Are you sure you want to close the form?`}
                show={state.showDiscard}
                isAgree={onDiscardChanges}
                disAgreeLabel={'No'}
                agreeLabel={'Yes'}
            />
            
            <Dialog
                open={state.open}
                onClose={onClose}
                maxWidth="sm"
                fullWidth={true}
                className={classes.dialogRoot}
                scroll={'paper'}
            >
                <DialogTitle className={classes.header}>{props.title}</DialogTitle>
                {(state.isLoading || state.isUserLoading) ? (
                    <DialogContent dividers={true}>
                        <div className={classes.loaderWrapper}>
                            <RvLoader size="small" label="Loading..."/>
                        </div>
                    </DialogContent>
                ) : (
                    <Form
                        onSubmit={(values, form: FormApi<AllocateForm>) => onSubmit(form, values)}                
                        initialValues={allocateForm}
                        subscription={{ submitting: true, pristine: true }}
                        render={({handleSubmit, form, submitting, pristine, values}) => (
                            <form onSubmit={handleSubmit} id="taskForm">
                                <FormSpy 
                                    subscription={{ pristine: true, values: true }}
                                    // tslint:disable-next-line: no-shadowed-variable
                                    onChange={props => {
                                        onFormValueChanged(form, props);
                                    }}
                                />
                                <DialogContent dividers={true}>
                                    <Grid container={true} spacing={3}>
                                        <Grid item={true} xs={12} md={6}>
                                            <Field
                                                name="assignTo"
                                                validate={form.getFieldState('assignTo')?.value !== null ? required : undefined}
                                            >
                                                {({ input, meta }) => (
                                                    <TaskAssignedByUserTeamSelector
                                                        {...input}
                                                        name="assignTo"
                                                        label="Assigned To"
                                                        value={input.value ? input.value : null}
                                                        disablePortal={false}
                                                        // required={true}
                                                        className={`${classes.textField} ${classes.clientSelector}`}
                                                        error={meta.error && meta.touched}
                                                        // tslint:disable-next-line: jsx-alignment
                                                        onSelection={(selection: IAutoCompleteItem, name: string, isUser: boolean) => 
                                                            onDropDownSelect(form, values, input, selection, name, isUser)
                                                        }
                                                        required={true}
                                                        helperText={
                                                            meta.error &&
                                                            meta.touched
                                                                ? 'Assigned to is required'
                                                                : ''
                                                        }
                                                        visible={{
                                                            role: true
                                                        }}
                                                    />
                                                )}
                                            </Field>
                                        </Grid>
                                        <Grid item={true} xs={12} md={6}>
                                            <Field
                                                name="assignedBy"
                                                validate={form.getFieldState('assignedBy')?.value !== null ? required : undefined}
                                            >
                                                {({ input, meta }) => (
                                                    <TaskAssignedBySelector
                                                        {...input}
                                                        name="assignedBy"
                                                        label="Assigned By"
                                                        value={input.value ? input.value : undefined}
                                                        disablePortal={false}
                                                        required={true}
                                                        className={`${classes.textField} ${classes.clientSelector}`}
                                                        error={meta.error && meta.touched}
                                                        // tslint:disable-next-line: jsx-alignment
                                                        onSelection={(selection: IAutoCompleteItem, name: string) => 
                                                            input.onChange(selection)
                                                        }
                                                        helperText={
                                                            meta.error &&
                                                            meta.touched
                                                                ? 'Assigned by is required'
                                                                : ''
                                                        }
                                                        visible={{
                                                            role: true
                                                        }}
                                                    />
                                                )}
                                            </Field>
                                        </Grid>
                                    </Grid>
                                </DialogContent>
                                <DialogActions>
                                    {actionButton(form, submitting, pristine, values)}
                                </DialogActions>
                            </form>
                        )} 
                    />
                )}
            </Dialog>
        </>
    );
};
