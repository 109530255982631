/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { Autocomplete, RenderInputParams } from '@material-ui/lab';
import { TextField, CircularProgress } from '@material-ui/core';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import { showNotification } from '../../App';
import { IAutoCompleteItem } from '../../typings/autoComplete';
import { FetchPolicy } from 'apollo-client';
import { client } from '../..';
import gql from 'graphql-tag';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
        minWidth: '200px',
    },
  })
);

interface PhoneNumberSelectorProps {
    disabled?: boolean;
    required?: boolean;
    multiple?: boolean;
    // tslint:disable-next-line: no-any
    value?: IAutoCompleteItem | any; // value only available in single select
    error?: boolean;
    onSelection?: (value: IAutoCompleteItem | IAutoCompleteItem[], name: string) => void;
    className?: string;
    helperText?: string;
    name?: string;
    label?: string;
    disablePortal?: boolean;
    nameId: number;
    isFromDataSource: boolean;
    onBlur?: React.FocusEventHandler<HTMLDivElement>;
}

interface PhoneNumberSelectorState {
    isLoading: boolean;
    options: IAutoCompleteItem[];
}

export const PhoneNumberSelector: React.FC<PhoneNumberSelectorProps> = props => {

    const classes = useStyles();

    const [state, setState] = useState<PhoneNumberSelectorState>({
        isLoading: false,
        options: [],
    });

    useEffect(() => {   
        if (props.nameId) {
            fetchData(props.nameId, props.isFromDataSource);
        }
    // tslint:disable-next-line: align
    }, [props.nameId, props.isFromDataSource]);

    const fetchData = (nameId: number, isFromDataSource: boolean) => {

        setState({
            ...state,
            isLoading: true
        });

        const phoneNumberDataParams: PhoneNumberDataParams = {
            nameId: nameId,
            isFromDataSource: isFromDataSource
        };

        retrievePhoneNumberData(
            phoneNumberDataParams,
            true, // this.state.isRefresh, -- Since we are updating and creating, we do not fetch cached data
            // tslint:disable-next-line: no-console
            (data: PhoneNumberData) => onRetrievePhoneNumber(data),
            // tslint:disable-next-line:no-any
            function (reason: any): void {
                showNotification(null, reason, 'error');
            }
        );
    };

    const onRetrievePhoneNumber = (data: PhoneNumberData) => {
        setState({
            ...state,
            options: data && data.partydetails ? data.partydetails.phoneNumbers.map( (source: PhoneNumber) => ({ ...source, value: source.number, label: source.number })) : [],
            isLoading: false
        });
    };

    // tslint:disable-next-line: no-any
    const handleChange = (event: React.ChangeEvent<{}>, value: any) => {
        if (props.onSelection) {
            props.onSelection(value, props.name ? props.name : 'phoneNumber');
        }
    };

    const handleOnClose = (event: React.ChangeEvent<{}>) => {

        // tslint:disable-next-line: no-string-literal
        if (event.currentTarget && event.currentTarget['value']) {
            // tslint:disable-next-line: no-string-literal
            let newValue = event.currentTarget['value'];

            if (props.onSelection) {
                // tslint:disable-next-line: no-string-literal
                let tempValue = { value: newValue, label: newValue };
                props.onSelection(tempValue, props.name ? props.name : 'phoneNumber');
            }
        }
    };

    const getInputProps = (params: RenderInputParams, isLoading?: boolean) => {
        return  ({   
            ...params.InputProps,
            endAdornment: (
                <React.Fragment>
                  {isLoading ? <CircularProgress color="primary" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),     
         });
    };

    return (
        <div className={`${classes.root} ${props.className}`}>
            { props.multiple ? 
                (
                    <Autocomplete
                        value={props.value}
                        multiple={true}
                        loading={state.isLoading}
                        disabled={props.disabled}
                        onChange={handleChange}
                        options={state.options}
                        disablePortal={props.disablePortal} // to Stop the dropdown select from closing the form
                        // tslint:disable-next-line: jsx-no-lambda
                        getOptionLabel={(option: IAutoCompleteItem) => option.label}  
                        getOptionSelected={(option, value) => value.value === option.value}         
                        onBlur={props.onBlur}            
                        // tslint:disable-next-line: jsx-no-lambda
                        renderInput={params => (
                            <TextField 
                                {...params} 
                                label={props.label ? props.label : 'options'}
                                variant="standard" 
                                fullWidth={true} 
                                margin="none" 
                                required={props.required}
                                InputProps={getInputProps(params, state.isLoading)}
                                error={props.error}      
                                helperText={props.helperText}                      
                            />
                        )}
                        // tslint:disable-next-line: jsx-no-lambda
                        renderOption={(option, { inputValue }) => {
                            const matches = match(option.label, inputValue);
                            const parts = parse(option.label, matches);
                    
                            return (
                                <div>
                                    {parts.map((part, index) => (
                                        <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                                            {part.text}
                                        </span>
                                    ))}
                                </div>
                            );
                        }}
                    />
                )
                :
                (
                    <Autocomplete
                        value={props.value}
                        loading={state.isLoading}
                        disabled={props.disabled}
                        onChange={handleChange}
                        onClose={handleOnClose}
                        options={state.options}
                        disablePortal={props.disablePortal} // to Stop the dropdown select from closing the form
                        // tslint:disable-next-line: jsx-no-lambda
                        getOptionLabel={(option: IAutoCompleteItem) => option.label}     
                        getOptionSelected={(option, value) => value.value === option.value}    
                        onBlur={props.onBlur}   
                        // tslint:disable-next-line: jsx-no-lambda
                        renderInput={params => (
                            <TextField 
                                {...params} 
                                label={props.label ? props.label : 'options'} 
                                variant="standard" 
                                fullWidth={true} 
                                margin="none" 
                                required={props.required}
                                InputProps={getInputProps(params, state.isLoading)}
                                error={props.error}       
                                helperText={props.helperText}                          
                            />
                        )}
                        // tslint:disable-next-line: jsx-no-lambda
                        renderOption={(option, { inputValue }) => {
                            const matches = match(option.label, inputValue);
                            const parts = parse(option.label, matches);
                    
                            return (
                                <div>
                                {parts.map((part, index) => (
                                    <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                                    {part.text}
                                    </span>
                                ))}
                                </div>
                            );
                        }}
                    />
                )
            }
        </div>
    );
};

export interface PhoneNumberDataParams {
    nameId: number;
    isFromDataSource: boolean;
}

export interface PhoneNumberData {
    loading?: boolean;
    networkStatus?: number;
    partydetails: Partydetails;
}

export interface Partydetails {
    phoneNumbers: PhoneNumber[];
}

export interface PhoneNumber {
    extension:     string;
    number:        string;
    partyId:       number;
    phoneNumberId: number;
    sequence:      number;
}

export function retrievePhoneNumberData(
    query: PhoneNumberDataParams,
    refreshData: boolean,
    onSuccess: (data: PhoneNumberData) => void,
    // tslint:disable-next-line:no-any
    onError: (reason: any) => void
): void {
    var fetchPolicy: FetchPolicy = refreshData === true ? 'network-only' : 'cache-first';
    client
        .query({
            query: PHONE_NUMBER_QUERY,
            variables: {
                nameId: query.nameId,
                isFromDataSource: query.isFromDataSource
            },
            fetchPolicy: fetchPolicy,
        })
        // tslint:disable-next-line:no-any
        .then((results: { data: any }) => {
            onSuccess(results.data);
        })
        // tslint:disable-next-line:no-any
        .catch((reason: any) => {
            onError(reason);
        });
}

const PHONE_NUMBER_QUERY = gql`
    query PhoneNumbers($nameId: Int!, $isFromDataSource: Boolean) {
        partydetails(nameId: $nameId, isFromDataSource: $isFromDataSource) {
            ... on Individual {
                phoneNumbers {
                    extension
                    number
                    partyId
                    phoneNumberId
                    sequence
                }
            }
            ... on Organisation {
                phoneNumbers {
                    extension
                    number
                    partyId
                    phoneNumberId
                    sequence
                }
            }
        }
    }
`;