import React from 'react';
import { makeStyles, Theme, createStyles, Paper } from '@material-ui/core';
// import { AgGridReact } from '@ag-grid-community/react';
// import { AgGridStyle, AgGridTableWrapper } from '../../components/AgGridStyle';
// import { AllModules } from '@ag-grid-enterprise/all-modules';
import { formatNumber } from '../../helpers/ChartHelper';
import moment from 'moment';
import { MatterDetails, MatterSummary } from '../TasksSummaryRepository';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: 'normal',
    },
    gridWrapper: {
        display: 'flex',
        flex: 1,
        flexFlow: 'column wrap',
        minHeight: '220px'
    },
    otherInfo: {
        padding: '10px',
    },
    paper: {
        padding: '15px 10px 10px 10px'
    },
    tableContainer: {
        display: 'flex',
        flexFlow: 'column',
        flex: 1,
        padding: '0 10px'
    },
    tableHeader: {
        display: 'flex',
        alignItems: 'center',
        margin: '0 -10px',
        backgroundColor: '#F5F6FF'
    },
    tableHeaderColumn: {
        flex: 1,
        padding: '10px'
    },
    tableBody: {
        flex: 1,
        display: 'flex',
        flexFlow: 'column',
    },
    tableBodyRow: {
        display: 'flex',
        flex: 1,
        margin: '0 -10px',
        '&:nth-child(odd)': {

        },
        '&:nth-child(even)': {
            backgroundColor: '#F5F6FF'
        }
    },
    tableBodyColumn: {
        flex: 1,
        padding: '10px'
    }
  }),
);

interface PhoneMatterDetailsPanelProps {
    data: MatterDetails;
}

export const PhoneMatterDetailsPanel: React.FC<PhoneMatterDetailsPanelProps> = (props) => {

    const classes = useStyles();

    const otherCurrentMatter = props.data.totalMatters - props.data.matterSummary.length;

    // const columnDefs = [
    //     { headerName: 'Matter Type', field: 'matterType' },
    //     { headerName: 'Matter No', field: 'fileNumber' },
    //     { headerName: 'Matter Title', field: 'matterTitle' },
    //     { headerName: 'Person Acting', field: 'personActing' }
    // ];

    // const gridOptions = {
    //     defaultColDef: {
    //         // suppressResize: true,
    //         menuTabs: ['filterMenuTab', 'generalMenuTab', 'columnsMenuTab'],
    //         resizable: true,
    //         sortable: true,
    //         filter: true
    //     },
    //     rowBuffer: 0,
    //     cacheOverflowSize: 5,
    //     maxConcurrentDatasourceRequests: 1,           
    //     maxBlocksInCache: 100, // How many blocks to cache in the client.
    //     suppressPropertyNamesCheck: true,
    // };

    // if (!props.data) {
    //     return <div />;
    // }

    // if (props.data.closedMatters === 0 && props.data.totalMatters === 0) {
    //     return <div />;
    // }
    
    return (
        <Paper 
            elevation={3}
            className={classes.paper}
        >
            {props.data.totalMatters > 0 ? (
                <>
                    <div className={classes.tableContainer}>
                        <div className={classes.tableHeader}>
                            <div className={classes.tableHeaderColumn}>
                                Matter Type
                            </div>
                            <div className={classes.tableHeaderColumn}>
                                Matter No.
                            </div>
                            <div className={classes.tableHeaderColumn}>
                                Matter Title
                            </div>
                            <div className={classes.tableHeaderColumn}>
                                Person Acting
                            </div>
                        </div>
                        <div className={classes.tableBody}>
                            {props.data.matterSummary.map((matter: MatterSummary) => (
                                <div className={classes.tableBodyRow}>
                                    <div className={classes.tableBodyColumn}>
                                        {matter.matterType}
                                    </div>
                                    <div className={classes.tableBodyColumn}>
                                        {matter.fileNumber}
                                    </div>
                                    <div className={classes.tableBodyColumn}>
                                        {matter.matterTitle}
                                    </div>
                                    <div className={classes.tableBodyColumn}>
                                        {matter.personActing}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    {(otherCurrentMatter > 0 || props.data.closedMatters > 0) && (
                        <div className={classes.otherInfo}>{`Plus ${formatNumber(otherCurrentMatter)} other current matters, ${formatNumber(props.data.closedMatters)} closed matters`}</div>
                    )}
                </>
            ) : (
                <div className={classes.otherInfo}>
                    {`${formatNumber(props.data.closedMatters)} closed matters, last active ${moment(props.data.lastUpdate).format('DD MMMM, YYYY')}`}
                </div>
            )}
            {/* {props.data.totalMatters > 0 ? (
                <div className={classes.gridWrapper}>
                    <AgGridStyle
                        id="SummaryGrid"
                        className="ag-theme-material"
                    >
                        <AgGridTableWrapper
                            className={'grid-container'}
                        >
                            <AgGridReact
                                key={'clientGridMatter'}
                                modules={AllModules}
                                gridOptions={gridOptions}
                                // onGridReady={this.onGridReady}
                                // tslint:disable-next-line:max-line-length
                                rowData={tempData}
                                // rowGroupPanelShow={this.state.isInMemory ? 'always' : 'none'}
                                // rowGroupPanelShow={'always'}
                                // cacheQuickFilter={true}
                                // rowSelection="multiple"
                                // rowMultiSelectWithClick={true}
                                // suppressDragLeaveHidesColumns={true}
                                // onRowSelected={this.onRowSelected}
                                // onRowClicked={this.onRowSelected}
                                animateRows={false}
                                floatingFilter={false}
                                rowHeight={40}
                                headerHeight={40}
                                columnDefs={columnDefs}
                                // getMainMenuItems={this.getMainMenuItems}

                                // onModelUpdated={this.onModelUpdated}
                                // suppressNoRowsOverlay={true}
                                // overlayLoadingTemplate={overlayLoadingTemplate} // non InMemory mode. Custom overlay
                                // onDisplayedColumnsChanged={this.handleDisplayedColumnsChanged}
                                // onColumnResized={this.handleColumnResized}
                            />
                        </AgGridTableWrapper>
                    </AgGridStyle>
                    <div className={classes.otherInfo}>{`Plus ${formatNumber(props.data.totalMatters - props.data.matterSummary.length)}
                     other current matters, ${formatNumber(props.data.closedMatters)} closed matters`}</div>
                </div>
            ) : (
                <div className={classes.otherInfo}>
                    {`${formatNumber(props.data.closedMatters)} closed matters, last active ${moment(props.data.lastUpdate).format('DD MMMM, YYYY')}`}
                </div>
            )} */}
        </Paper>
    );
};

export default PhoneMatterDetailsPanel;