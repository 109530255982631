/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import {
    FormControl,
    RadioGroup,
    FormControlLabel,
    Radio,
    RadioProps,
    Grid,
    Button,
    // FormLabel,
    Dialog,
    DialogTitle,
    DialogActions,
    DialogContent,
    FormLabel,
    TextField,
    Checkbox,
    Tooltip,
    Typography,
    IconButton,
    Tabs,
    Tab,
    Chip,
    Divider,
} from '@material-ui/core';
import clsx from 'clsx';
import { Form, Field, FormSpy, FieldInputProps } from 'react-final-form';
import { FormApi, AnyObject } from 'final-form';
import { Editor, SyntheticEvent } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { 
    ContentState, 
    convertToRaw, 
    EditorState 
} from 'draft-js';
import { PrioritySelector } from '../selectors/PrioritySelector';
import { IAutoCompleteItem } from '../../typings/autoComplete';
import { showNotification } from '../../App';
import { 
    AppUserData,
    CreatePhoneTaskMutation, 
    Detail, 
    ExtendPhoneTaskDetailsData, 
    ExtendPhoneTaskDetailsParams, 
    EXTEND_PHONE_TASK_DETAILS, 
    fetchAppUserData, 
    retrieveTaskDetailsData, 
    TaskAndPhoneMessageConfig, 
    TaskDetailsData, 
    TaskDetailsParam,
    UpdatePhoneTaskMutation,
    CcUserList
} from '../TasksSummaryRepository';
import { TaskCategorySelector } from '../selectors/TaskCategorySelector';
import { TaskAssignedBySelector } from '../selectors/TaskAssignedBySelector';
import DialogBox from '../../Dashboard/Component/DialogBox';
import { client } from '../..';
import moment from 'moment';
import draftToHtml from 'draftjs-to-html';
import { ClientAutoCompleteSelector } from '../../components/ClientAutoCompleteSelector';
import htmlToDraft from 'html-to-draftjs';
import { MatterAutoCompleteSelector } from '../../components/MatterAutoCompleteSelector';
import { RvLoader } from '../../components/Loader';
import { mainTheme } from '../../Theme';
import { KeyboardDateTimePicker } from '@material-ui/pickers/DateTimePicker/DateTimePicker';
import { OrganisationSelector } from '../../enquirySummary/selectors/OrganisationSelector';
import { IndividualNameSummary, IndividualSelector } from '../../enquirySummary/selectors/IndividualSelector';
import SyncAltIcon from '@material-ui/icons/SyncAlt';
import { PhoneNumberSelector } from '../selectors/PhoneNumberSelector';
import { DropzoneArea } from 'material-ui-dropzone';
import { DocumentFile } from '../../types/DocumentFile';
import { TaskHistoryActivity, TaskHistoryActivityWidth } from '../common/TaskHistoryActivity';
import { AllocatedToEntityType } from '../AllocatedToEntityType';
import { fetchBasicMatterDetailsData } from '../../matterSummary/MatterSummaryRepository';
import { MatterData, MatterDetails } from '../../matterSummary/models/Matter';
import { useLazyQuery } from 'react-apollo';
import PhoneMessageDetailsPanel from '../panels/PhoneMessageDetailsPanel';
import PhoneMatterDetailsPanel from '../panels/PhoneMatterDetailsPanel';
import PhoneContactDetailsPanel from '../panels/PhoneContactDetailsPanel';
import { ReminderSelector } from '../../components/ReminderSelector';
import { TaskAssignedByUserTeamSelector } from '../selectors/TaskAssignedByUserTeamSelector';
import { UserFeature, UserFeatureEnum } from '../../types/UserFeature';
import { AreaOfLawSelector, IAreaOfLawAutoCompleteItem } from '../../enquirySummary/selectors/AreaOfLawSelector';
import { SourceSelector } from '../../enquirySummary/selectors/SourceSelector';
import { OfficeSelector } from '../../MailingList/selectors/OfficeSelector';
import { ClientAndContactSelector } from '../../components/ClientAndContactSelector';
import { useCreateEnquiryMutation } from '../../enquirySummary/hooks/useCreateEnquiryMutation';
import { addBusinessDays, finalFormValidateEmail, removeTagsAndStyles, separateName } from '../../helpers/commonHelper';
import { EnquiryDefaultConfig } from '../../enquirySummary/hooks/useEnquirySettingsQuery';
import { UserAutocompleteSelector } from '../../components/UserAutocompleteSelector';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        dialogRoot: {
            '& .downshift-wrapper': {
                flex: 1,
                padding: 0,
            }
        },
        header: {
            textTransform: 'capitalize',
        },
        formControlWrapper: {
            height: '100%',
            display: 'flex',
            alignItems: 'flex-end',
        },
        textField: {
            width: '100%',
        },
        radioRoot: {
            '&:hover': {
                backgroundColor: 'transparent',
            },
        },
        icon: {
            borderRadius: '50%',
            width: 16,
            height: 16,
            boxShadow:
                'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
            backgroundColor: '#f5f8fa',
            backgroundImage:
                'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
            '$root.Mui-focusVisible &': {
                outline: '2px auto rgba(19,124,189,.6)',
                outlineOffset: 2,
            },
            'input:hover ~ &': {
                backgroundColor: '#ebf1f5',
            },
            'input:disabled ~ &': {
                boxShadow: 'none',
                background: 'rgba(206,217,224,.5)',
            },
        },
        checkedIcon: {
            backgroundColor: '#137cbd',
            backgroundImage:
                'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
            '&:before': {
                display: 'block',
                width: 16,
                height: 16,
                backgroundImage:
                    'radial-gradient(#fff,#fff 28%,transparent 32%)',
                content: '""',
            },
            'input:hover ~ &': {
                backgroundColor: '#106ba3',
            },
        },
        radioGroup: {
            flexFlow: 'row'
        },
        clientContainer: {
            display: 'flex',
            alignItems: 'center',
            '& .downshift-wrapper': {
                flex: 1,
            }
        },
        clientRowRadio: {

        },
        clientSelector: {
            flex: 1
        },
        content: {
            flex: 1
        },
        matterForm: {},
        clientForm: {},
        formControl: {
            '& .MuiFormGroup-root': {
                padding: '6px 7px'
            }
        },
        buttonContainer: {
            display: 'flex',
            justifyContent: 'space-between',
            // paddingBottom: '20px'
        },
        button: {
            margin: theme.spacing(1),
        },
        heading: {
            textTransform: 'capitalize'
        },
        headerDetails: {
            paddingTop: '20px',
            fontSize: '16px'
        },
        hidden: {
            display: 'none',
        },
        editorClassName: {
            padding: '0 10px',
            minHeight: '100px',
        },
        wrapperClassName: {
            border: '1px solid #949494',
            display: 'flex',
            flexFlow: 'column-reverse',
        },
        toolbarClassName: {
            border: 0,
            // borderBottom: '1px solid #F1F1F1',
            margin: 0,
            padding: 0,
        },
        formLabel: {
            paddingBottom: '10px',
        },
        loaderWrapper: {
            display: 'flex',
            justifyContent: 'center',
            padding: '20px 0',
        },
        status: {
            flex: 1,
            display: 'flex',
            alignItems: 'flex-end',
            height: '100%',
            justifyContent: 'flex-start',
            color: 'grey',
            '& > span': {
                color: mainTheme.BrandColors.TemplatePrimary,
                marginLeft: '10px',
            }
        },
        commentField: {
            width: '100%',
        },
        margin: {
            margin: theme.spacing(1),
        },
        switcherWrapper: {
            display: 'flex',
            alignItems: 'center',
            '& > div:first-child': {
                flex: 1
            }
        },
        selector: {
            flex: 1,
        },
        paperWidthMd: {
            maxWidth: '750px',
        },
        tabs: {
            marginBottom: 10,
            padding: '10px 0',
        },
        tabWrapper: {
            flex: 1,
            display: 'flex',
            flexFlow: 'column',
        },
        chips: {
            display: 'flex',
            flexWrap: 'wrap',
        },
        chip: {
            marginLeft: '8px'
        },
        dropZone: {
            minHeight: 'fit-content !important' ,
            '& .MuiDropzoneArea-root' : {
                minHeight: 'fit-content !important'
            }
        },
        dropZoneFont: {            
            '& .MuiDropzoneArea-text': {
                fontSize: '1em',
            }
        },
        taskHistorykContentWrapper: {
            display: 'flex',
            flex: 1,
            flexFlow: 'column',
        },
        historyActivityContainer: {
            flex: 1,
            position: 'relative',
            flexFlow: 'column',
            minHeight: 267,
        },
        historyActivityWrapper: {
            position: 'absolute',
            top: 0,
            right: 0,
            left: 0,
            bottom: 0,
        },
        divider: {
            // marginTop: 10,
            width: '100%',
        },
        marginBottom: {
            marginBottom: '16px',
        },
        secondaryDescription: {
            flex: 1,
            maxWidth: '100%',
            overflow: 'hidden',
        },
        subTitle: {
            fontSize: 14,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            maxWidth: '97%',
            color: '#000000DE'
        },
        description: {
            fontSize: 12,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            maxWidth: '97%',
            color: '#000000DE'
        },
        optionWrapper: {
            display: 'flex',
            alignItems: 'center',
        },
        optionField: {
            flex: 1
        },
        reminderInput: {
            padding: '6px 0 6px !important'
        }
    })
);

export enum PhoneFormTab {
    details = 0,
    documents = 1,
    activity = 2
}

export enum TaskOptionEnum {
    none = 0,
    matter,
    client,
    phone,
    enquiry
}

export interface TaskInput {
    guid: string | null;
    description: string | null;
    notes: string | null;
    // tslint:disable-next-line: no-any
    files: any;
    assignedTo: string | null;
    allocatedToEntityType: number | null;
    assignedBy: string | null;
    priority: string | null;
    category: string | null;
    dueDate: Date | null;
    estimatedEffort: string | null;
    startDate: Date | null;
    completionDate: Date | null;
    completionNotes: string | null;
    duration: string | null;
    activityText: string | null;
    reminderOffset: string | null;
    ccUserIds: string | null;
}

interface PhoneFormDialogProps {
    isOpen: boolean;
    onClose?: () => void;
    isCreate: boolean;
    guid?: string | null;
    matterFileNumber?: string;
    matterGuid?: string;
    matterTitle?: string;
    defaultPriority?: IAutoCompleteItem | null;
    reminderDefaultConfig?: TaskAndPhoneMessageConfig;
    enquiryDefaultConfig?: EnquiryDefaultConfig;
    defaultMatter?: IAutoCompleteItem;
}

interface EnquiryForm {
    isEmailNotification: boolean;
    isSentAcknowledgementEmail: boolean;
    isShowReferrer: boolean;
    areaOfLaw: IAreaOfLawAutoCompleteItem | null;
    email: string | null;
    source: IAutoCompleteItem | null;
    office: IAutoCompleteItem | null;
    referrer: IAutoCompleteItem | null;
    sourceNotes: string | null;
    followUpDueDate: Date | null;
    assignmentFollowUpDueReminder: IAutoCompleteItem | null;
}
interface PhoneForm extends EnquiryForm {
    option: TaskOptionEnum;
    handleCreateAndClose: boolean | undefined;
    handleCreateAndNew: boolean | undefined;
    handleUpdate: boolean | undefined;
    dueDate: Date | null;
    matter: IAutoCompleteItem | null;
    client: IAutoCompleteItem | null;
    category: IAutoCompleteItem | null;
    assignedBy: IAutoCompleteItem | null;
    // taskOwner: string | null;
    notes: EditorState;
    priority?: IAutoCompleteItem | null;
    // tslint:disable-next-line: no-any
    from: IndividualNameSummary | string | null | any;
    to: IAutoCompleteItem | null;
    phoneNumber: IAutoCompleteItem | null;
    isFromSwitched: boolean;
    isOrganisationSwitched: boolean;
    isPhoneCall: boolean;
    isReturnedCall: boolean;
    isWillCallAgain: boolean;
    // tslint:disable-next-line: no-any
    organisation: IAutoCompleteItem | string | null | any;
    files: DocumentFile[];

    allocatedToEntityType: number | null;
    activityText: string | null;
    reminderOffset: IAutoCompleteItem | null;
    cc: IAutoCompleteItem[] | null;
    messageTitle: string | null;
}

interface PhoneFormState {
    hideToolbar: boolean;
    isOpen: boolean;
    showDiscard?: boolean;
    isCreate: boolean;
    isChanged: boolean;
    isUserLoading: boolean;
    isLoading: boolean;
    onSubmitLoading: boolean;
    tab: PhoneFormTab;
    taskDetails?: Detail;
}

function StyledRadio(props: RadioProps) {
    const classes = useStyles();

    return (
        <Radio
            className={classes.radioRoot}
            disableRipple={true}
            color="default"
            checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
            icon={<span className={classes.icon} />}
            {...props}
        />
    );
}
  
export const PhoneFormDialog: React.FC<PhoneFormDialogProps> = (props) => {
    const classes = useStyles();
        
    const [filesKey, setKey] = useState('');

    const createEnquiry = useCreateEnquiryMutation();

    const [phoneForm, setPhoneForm] = useState<PhoneForm>({
        option: props.matterGuid || props.defaultMatter ? TaskOptionEnum.matter : TaskOptionEnum.none,
        handleCreateAndClose: false,
        handleCreateAndNew: false,
        handleUpdate: false,
        
        dueDate: moment().set('hours', 17).set('minutes', 0).set('seconds', 0).set('milliseconds', 0).toDate(),
        matter: props.matterGuid && props.matterTitle ? {label: props.matterTitle, value: props.matterGuid} : props.defaultMatter ? props.defaultMatter : null,
        client: null,
        category: null,
        assignedBy: null,
        priority: props.defaultPriority,
        // taskOwner: null,
        notes: EditorState.createEmpty(),
        isFromSwitched: true,
        from: null,
        to: null,
        phoneNumber: null,
        isOrganisationSwitched: false,
        isPhoneCall: false,
        isReturnedCall: false,
        isWillCallAgain: false,
        organisation: null,
        files: [],

        allocatedToEntityType: null,
        activityText: null,
        reminderOffset: props.reminderDefaultConfig
            ? { label: props.reminderDefaultConfig.reminder, value: props.reminderDefaultConfig.reminder }
            : { label: 'None', value: 'None' },
        messageTitle: null,

        // enquiry
        isEmailNotification: true,
        isSentAcknowledgementEmail: false,
        isShowReferrer: false,
        areaOfLaw: null,
        email: null,
        source: null,
        office: null,
        referrer: null,
        sourceNotes: null,
        followUpDueDate: null,
        assignmentFollowUpDueReminder: props.enquiryDefaultConfig ? { label: props.enquiryDefaultConfig.reminder, value: props.enquiryDefaultConfig.reminder } : null,
        cc: [],
    });

    const [state, setState] = useState<PhoneFormState>({
        hideToolbar: true,
        isOpen: props.isOpen,
        showDiscard: false,
        isCreate: props.isCreate,
        isChanged: false,
        isUserLoading: false,
        isLoading: false,
        onSubmitLoading: false,
        tab: PhoneFormTab.details
    });
    
    const [getExtendPhoneTaskDetails, extendPhoneTaskDetailsQuery] = 
                useLazyQuery<ExtendPhoneTaskDetailsData, ExtendPhoneTaskDetailsParams>(EXTEND_PHONE_TASK_DETAILS);

    const [matterData, setMatterData] = useState<MatterDetails>();

    useEffect(() => {
        if (createEnquiry.data && !createEnquiry.loading && !createEnquiry.error) {
            if (createEnquiry.data.createEnquiry.status) {
                showNotification(null, 'Successfully submitted', 'info');
            } else {
                showNotification('Failed to Save Enquiry', createEnquiry.data.createEnquiry.error, 'error');
            }
        }
        if (createEnquiry.error) {
            showNotification('Failed to Save Enquiry', createEnquiry.error.message, 'error');
        }
    },        [createEnquiry.data, createEnquiry.loading, createEnquiry.error]);

    // useEffect(() => {
    //     if (props.matterGuid) {
    //         setState((prevState) => {
    //             return {
    //                 ...prevState,
    //                 option: TaskOptionEnum.matter
    //             };
    //         });
    //     }
    // // tslint:disable-next-line: align
    // }, [props.matterGuid]);

    useEffect(() => {
        if (!state.isCreate && props.guid) {
            fetchTaskDetails(props.guid);
        } else {
            fetchUser();
        }
    // tslint:disable-next-line: align
    }, [props.guid, props.isOpen]);

    const fetchTaskDetails = (guid: string) => {

        setState((prevState) => {
            return {
                ...prevState,
                isLoading: true
            };
        });

        const taskDetailsParams: TaskDetailsParam = {
            guid: guid
        };

        retrieveTaskDetailsData(
            taskDetailsParams,
            true,
            // tslint:disable-next-line: no-console
            (data: TaskDetailsData) => onTaskDetailsRetrieved(data),
            // tslint:disable-next-line: no-any
            function (reason: any): void {
                showNotification(null, reason, 'error');
            }
        );

    };

    const onTaskDetailsRetrieved = (data: TaskDetailsData) => {
        // tslint:disable-next-line: no-console
        
        if (data && data.task && data.task.detail) {

            const taskDetails = data.task.detail;
            const appUser = data.appUser;

            if (!props.isCreate && taskDetails.matterId) {
                fetchMatterDetails(taskDetails.matterId);
            }

            let tempOption = TaskOptionEnum.none;

            switch (taskDetails.__typename) {
                case 'ClientTask': {
                    tempOption = TaskOptionEnum.client;
                    break;
                }
                case 'MatterTask': {
                    tempOption = TaskOptionEnum.matter;
                    break;
                }
                case 'PhoneTask': {
                    tempOption = TaskOptionEnum.phone;
                    break;
                }
                default: {
                    tempOption = TaskOptionEnum.none;
                    break;
                }
            }

            setState((prevState) => {
                return {
                    ...prevState,
                    // option: tempOption,
                    taskDetails: taskDetails
                };
            });
            
            let editorState = EditorState.createEmpty();

            if (taskDetails.notes && taskDetails.notes !== null) {
                const contentBlock = htmlToDraft(taskDetails.notes);
                if (contentBlock) {
                    const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
                    editorState = EditorState.createWithContent(contentState);
                }
            }

            if (taskDetails.fromNameGuid) {
                // get extend phone task details
                getExtendPhoneTaskDetails({
                    variables: {
                        nameGuid: taskDetails.fromNameGuid,
                        // to cater for old records. If there is no data for IsFromDataSource, always pass true
                        isFromDataSource: taskDetails.isFromNameFromDataSource ? taskDetails.isFromNameFromDataSource : true 
                    }
                });
            }

            setKey('' + taskDetails.guid);

            let reminderOffset: IAutoCompleteItem = { label: 'None', value: 'None'};
            
            if (taskDetails.reminderOffset) {
                reminderOffset = { label: taskDetails.reminderOffset, value: taskDetails.reminderOffset };
            } 

            setPhoneForm((prevPhoneForm) => {
                return {
                    ...prevPhoneForm,
                    option: tempOption,
                    dueDate: taskDetails.dueDate ? moment(taskDetails.dueDate).toDate() : null,
                    // duration: taskDetails.duration ? taskDetails.duration : '00:00', // moment(taskDetails.duration, 'HH:mm').toString()
                    // tslint:disable-next-line: max-line-length
                    matter: taskDetails.matterGuid && taskDetails.matterDescription ? {label: taskDetails.matterDescription, value: taskDetails.matterGuid} : null,
                    // tslint:disable-next-line: max-line-length
                    client: taskDetails.clientGuid && taskDetails.clientDescription ? {label: taskDetails.clientDescription, value: taskDetails.clientGuid} : null,
                    // tslint:disable-next-line: max-line-length
                    category: taskDetails.category && taskDetails.categoryDescription ? {label: taskDetails.categoryDescription, value: taskDetails.category} : null,
                    // tslint:disable-next-line: max-line-length
                    assignedBy: taskDetails.assignedBy && taskDetails.assignedByName ? {label: taskDetails.assignedByName, value: taskDetails.assignedBy} : {label: appUser.name, value: appUser.userId},
                    // tslint:disable-next-line: max-line-length
                    priority: taskDetails.priority && taskDetails.priorityDescription ? {label: taskDetails.priorityDescription, value: taskDetails.priority} : null,
                    
                    // tslint:disable-next-line: max-line-length
                    from: taskDetails.fromName && taskDetails.fromNameGuid 
                        ? {
                            label: taskDetails.fromName, 
                            value: taskDetails.fromNameGuid, 
                            nameId: taskDetails.fromNameId, 
                            guidID: taskDetails.fromNameGuid,
                            isFromDataSource: taskDetails.isFromNameFromDataSource
                        } 
                        : taskDetails.fromName ? taskDetails.fromName : null,
                    // tslint:disable-next-line: max-line-length
                    to: taskDetails.assignedTo && taskDetails.assignedToName ? {label: taskDetails.assignedToName, value: taskDetails.assignedTo} : null,
                    phoneNumber: taskDetails.phoneNumber ? { label: taskDetails.phoneNumber, value: taskDetails.phoneNumber } : null,
            
                    isPhoneCall: taskDetails.isPhoneCall,
                    isReturnedCall: taskDetails.isReturnedCall,
                    isWillCallAgain: taskDetails.isWillCallAgain,
                    // tslint:disable-next-line: max-line-length
                    organisation: taskDetails.organisationGuid && taskDetails.organisation 
                        ? {
                            label: taskDetails.organisation, 
                            value: taskDetails.organisationGuid,
                            isFromDataSource: taskDetails.isOrganisationFromDataSource
                        } 
                        : taskDetails.organisation ? taskDetails.organisation : null,
                    
                    isOrganisationSwitched: taskDetails.organisationGuid && taskDetails.organisation ? true : false,
                    isFromSwitched: (taskDetails.fromName && taskDetails.fromNameGuid) ? true : false,
                    
                    notes: editorState,

                    files: taskDetails.files ? taskDetails.files : [],

                    allocatedToEntityType: taskDetails.allocatedToEntityType, 

                    reminderOffset: reminderOffset,

                    cc: taskDetails.ccUserList ? taskDetails.ccUserList.map((ccUser: CcUserList) => {
                        return {
                            value: ccUser.userID, 
                            label: ccUser.isStaff ? ccUser.staffName : ccUser.friendlyName,
                            email: ccUser.email,
                            roleName: ccUser.roleName
                        // tslint:disable-next-line: no-any
                        } as any;
                    }) : [],

                    messageTitle: taskDetails.messageTitle
                };
            });
        }

        setState((prevState) => {
            return {
                ...prevState,
                isLoading: false,
            };
        });
    };

    const fetchMatterDetails = (matterId: number) => {
        setState((prevState) => {
            return {
                ...prevState,
                isMatterLoading: true
            };
        });

        fetchBasicMatterDetailsData(
            true, // This should always be a new request.
            matterId,
            (data: MatterData) => handleMatterDataFetch(data), 
            (reason) => {
                showNotification('Failed to basic matter details', reason, 'error'); 
            }
        );
        
    };

    const handleMatterDataFetch = (data: MatterData) => {
        if (data && !data.loading && data.matter) {
            if (data.matter) {
                setMatterData(data.matter);
                setState((prevState) => {
                    return {
                        ...prevState,
                        isMatterLoading: false
                    };
                });
            }
        }

    };

    const fetchUser = () => {

        setState((prevState) => {
            return {
                ...prevState,
                isUserLoading: true
            };
        });

        fetchAppUserData(
            false,
            // tslint:disable-next-line: no-console
            (data: AppUserData) => onUserRetrieved(data),
            // tslint:disable-next-line: no-any
            function (reason: any): void {
                showNotification(null, reason, 'error');
            }
        );
    };

    const onUserRetrieved = (data: AppUserData) => {
        setPhoneForm({
            ...phoneForm,
            assignedBy: {
                label: data.appUser.name,
                value: data.appUser.userId
            }
        });

        setState((prevState) => {
            return {
                ...prevState,
                isUserLoading: false
            };
        });
    };

    const handleOption = (input: FieldInputProps<PhoneForm>, form: FormApi<PhoneForm>, values: AnyObject) => (event: React.ChangeEvent<HTMLInputElement>) => {
        
        // tslint:disable-next-line: radix
        const optionValue = parseInt((event.target as HTMLInputElement).value);

        input.onChange(optionValue);
        
        if (optionValue === TaskOptionEnum.enquiry && values.to && values.to.isUser === false) {
            // clear to fields when options is changed
            form.change('to', null);
            form.change('followUpDueDate', null);
            form.change('allocatedToEntityType', null);
        }

        if (optionValue === TaskOptionEnum.enquiry) {
            setState((prevState) => {
                return {
                    ...prevState,
                    tab: PhoneFormTab.details
                };
            });
        }
    };

    // tslint:disable-next-line: no-any
    const required = (value: any) => {
        return value ? undefined : 'Required';
    };

    // tslint:disable-next-line: no-any
    const onSubmit = async (form: FormApi<PhoneForm>, values: PhoneForm) => {

        setState((prevState) => {
            return {
                ...prevState,
                onSubmitLoading: true
            };
        });
        
        const html = draftToHtml(convertToRaw(values.notes.getCurrentContent()));

        if (values.option === TaskOptionEnum.enquiry) {
            // create enquiry

            try {
                await createEnquiry.create({
                    variables: {
                        input: {
                            nameGuid: values.from ? values.from.value : undefined,
                            firstName: values.isFromSwitched ? values.from.firstName : separateName(values.from).firstName,
                            lastName: values.isFromSwitched ? values.from.lastName : separateName(values.from).lastName,
                            phone: values.phoneNumber ? values.phoneNumber.value : undefined,
                            organisation: values.isOrganisationSwitched ? values.organisation ? values.organisation.label : undefined : values.organisation ?? undefined,
                            organisationGuid: values.isOrganisationSwitched ? values.organisation.value : undefined,
                            natureOfEnquiry: removeTagsAndStyles(html),
                            areaOfLaw: values.areaOfLaw?.label,
                            // practiceAreaGuidID: values.areaOfLaw?.value,
                            email: values.email ?? undefined,
                            source: values.source?.label,
                            office: values.office?.label, // location
                            referrerGuid: values.referrer?.value, // contact
                            sourceNotes: values.sourceNotes ?? undefined,
                            assignedTo: values.to?.value,
                            followUpDueDate: values.to ? values.followUpDueDate ?? undefined : undefined,
                            reminderOffset: values.to && values.assignmentFollowUpDueReminder ? values.assignmentFollowUpDueReminder.value : undefined,
                            assignedBy: values.assignedBy?.value,
                            sendNotification: values.isEmailNotification,
                            isSendEnquiryResponse: values.isSentAcknowledgementEmail,
                            initialEnquiryDate: moment().toDate(),
                            ...(values.isFromSwitched && values.from && typeof values.from !== 'string' && {
                                isNameFromDataSource: values.from.isFromDataSource,
                            }),
                            ...(values.isOrganisationSwitched && values.organisation && typeof values.organisation !== 'string' && {
                                isOrganisationFromDataSource: values.organisation.isFromDataSource,
                            })
                        }
                    }
                });
    
                if (values.handleCreateAndClose) {
                    if (props.onClose) {
                        props.onClose();
                    }
                } 
                
                if (values.handleCreateAndNew) {
                    setTimeout(() => formReset(form), 1000);
                    setState((prevState) => {
                        return {
                            ...prevState,
                            onSubmitLoading: false
                        };
                    });
                }
            } catch (error) {
                showNotification('Failed to Save Enquiry', error, 'error');
            }

        } else {
            const taskInput: TaskInput = {
                guid: props.guid ? props.guid : null,
                notes: html,
                files: GetFiles(values.files),
                assignedBy: values.assignedBy ? values.assignedBy.value : null,
                priority: values.priority ? values.priority.label : null,
                category: values.category ? values.category.label : null,
                dueDate: values.dueDate ? values.dueDate : null,
    
                // adhoc parametter
                assignedTo: values.to ? values.to.value : null,
                completionDate: null,
                completionNotes: null,
                description: '',
                duration: null,
                estimatedEffort: null,
                startDate: null,
                allocatedToEntityType: values.allocatedToEntityType,
                activityText: values.activityText,
                reminderOffset: values.reminderOffset ? values.reminderOffset.value : null,
                ccUserIds: values.cc ? values.cc.map((cc: IAutoCompleteItem) => cc.value).join(',') : null
            };
    
            // tslint:disable-next-line: no-console
            // console.log('reminder offset', values.reminderOffset ? values.reminderOffset.value : null);
    
            const taskMatter = values.matter ? values.matter : null;
            const taskClient = values.client ? values.client : null;

            let isFromNameFromDataSource = values.isFromSwitched && values.from && typeof values.from !== 'string' ? values.from.isFromDataSource : false;
            let isOrganisationFromDataSource =
                values.isOrganisationSwitched && values.organisation && typeof values.organisation !== 'string' ? values.organisation.isFromDataSource : false;    
    
            setState((prevState) => {
                return {
                    ...prevState,
                    isChanged: false
                };
            });
    
            if (values.handleCreateAndClose) {
                // tslint:disable-next-line: no-console
                console.log('handleCreateAndClose', form, values);
    
                createTask(
                    taskInput, 
                    values.option,
                    taskMatter, 
                    taskClient,
                    !values.isFromSwitched ? values.from : values.from.label,
                    values.isFromSwitched ? values.from ? values.from.guidID : null : null,
                    values.phoneNumber,
                    !values.isOrganisationSwitched ? values.organisation : null,
                    values.isOrganisationSwitched ? values.organisation ? values.organisation.value : null : null,
                    values.isPhoneCall,
                    values.isReturnedCall,
                    values.isWillCallAgain,
                    isFromNameFromDataSource,
                    isOrganisationFromDataSource,
                    true, 
                    form, 
                    false,
                    values.messageTitle
                );
    
            } else if (values.handleCreateAndNew) {
                // tslint:disable-next-line: no-console
                console.log('handleCreateAndNew', form, values);
    
                createTask(
                    taskInput, 
                    values.option,
                    taskMatter, 
                    taskClient, 
                    !values.isFromSwitched ? values.from : values.from.label,
                    values.isFromSwitched ? (values.from ? values.from.guidID : null) : null,
                    values.phoneNumber,
                    !values.isOrganisationSwitched ? values.organisation : null,
                    values.isOrganisationSwitched ? values.organisation ? values.organisation.value : null : null,
                    values.isPhoneCall,
                    values.isReturnedCall,
                    values.isWillCallAgain,
                    isFromNameFromDataSource,
                    isOrganisationFromDataSource,
                    false, 
                    form, 
                    true,
                    values.messageTitle
                );
    
            } else {
                // tslint:disable-next-line: no-console
                console.log('handleUpdate', form, values);
    
                updateTask(
                    taskInput, 
                    values.option,
                    taskMatter, 
                    taskClient, 
                    !values.isFromSwitched ? values.from : null,
                    values.isFromSwitched ? values.from ? values.from.guidID : null : null,
                    values.phoneNumber,
                    !values.isOrganisationSwitched ? values.organisation : null,
                    values.isOrganisationSwitched ? values.organisation ? values.organisation.value : null : null,
                    values.isPhoneCall,
                    values.isReturnedCall,
                    values.isWillCallAgain,
                    isFromNameFromDataSource,
                    isOrganisationFromDataSource,
                    true,
                    values.messageTitle
                );
            }
        }
    };

    const GetFiles = (files: DocumentFile[]) => {
        return files.map((document: DocumentFile) => {
            return {
                file: document.file,
                name: document.name,
                guidID: document.guidID,
                status: document.status
            };
        });
    };

    const formReset = (form: FormApi<PhoneForm>) => {
        form.setConfig('keepDirtyOnReinitialize', false);
        form.reset();
        form.setConfig('keepDirtyOnReinitialize', true);
    };

    const createTask = (
        input: TaskInput, 
        option: TaskOptionEnum,
        taskMatter: IAutoCompleteItem | null, 
        taskClient: IAutoCompleteItem | null, 
        fromName: string | null,
        fromNameGuid: string | null,
        phoneNumber: IAutoCompleteItem | null,
        organisation: string | null,
        organisationGuid: string | null,
        isPhoneCall: boolean | null,
        isReturnedCall: boolean | null,
        isWillCallAgain: boolean | null,
        isFromNameFromDataSource: boolean | null,
        isOrganisationFromDataSource: boolean | null,
        isForceClosed: boolean, 
        form: FormApi<PhoneForm>,
        isCreatAndNew: boolean,
        messageTitle: string | null
    ) => {

        client
        .mutate({
            mutation: CreatePhoneTaskMutation,
            variables: { 
                // tslint:disable-next-line: max-line-length
                clientGuid: (option !== TaskOptionEnum.none && option !== TaskOptionEnum.matter) ? taskClient ? taskClient.value : null : null,
                // tslint:disable-next-line: max-line-length
                matterGuid: (option !== TaskOptionEnum.none && option !== TaskOptionEnum.client) ? taskMatter ? taskMatter.value : null : null,
                fromName,
                fromNameGuid,
                phoneNumber: phoneNumber ? phoneNumber.value : null,
                organisation,
                organisationGuid,
                isPhoneCall,
                isReturnedCall,
                isWillCallAgain,
                isFromNameFromDataSource: isFromNameFromDataSource,
                isOrganisationFromDataSource: isOrganisationFromDataSource,
                input: input,
                messageTitle
            },
        })
        // tslint:disable-next-line: no-any
        .then((results: { data: any }) => {
            if (results.data) {
                if (results.data.error === null || results.data.error === undefined) {
                    showNotification(null, 'Successfully submitted', 'info');          
                    
                    if (isCreatAndNew) {
                        formReset(form);
                    } else {
                        if (isForceClosed && props.onClose) {
                            props.onClose();
                        } 
                    }
                                 
                } else {
                    showNotification('Failed to save phone task', results.data.error, 'error');
                }
            }
            setState((prevState) => {
                return {
                    ...prevState,
                    onSubmitLoading: false
                };
            });
        })
        // tslint:disable-next-line:no-any
        .catch((reason: any) => {
            showNotification('Failed to save phone task', reason, 'error');
            setState((prevState) => {
                return {
                    ...prevState,
                    onSubmitLoading: false
                };
            });
        });
    };

    const updateTask = (
        input: TaskInput, 
        option: TaskOptionEnum,
        taskMatter: IAutoCompleteItem | null, 
        taskClient: IAutoCompleteItem | null, 
        fromName: string | null,
        fromNameGuid: string | null,
        phoneNumber: IAutoCompleteItem | null,
        organisation: string | null,
        organisationGuid: string | null,
        isPhoneCall: boolean | null,
        isReturnedCall: boolean | null,
        isWillCallAgain: boolean | null,
        isFromNameFromDataSource: boolean | null,
        isOrganisationFromDataSource: boolean | null,
        isForceClosed: boolean,
        messageTitle: string | null
    ) => {
        
        client
        .mutate({
            mutation: UpdatePhoneTaskMutation,
            variables: { 
                // tslint:disable-next-line: max-line-length
                clientGuid: (option !== TaskOptionEnum.none && option !== TaskOptionEnum.matter) ? taskClient ? taskClient.value : null : null,
                // tslint:disable-next-line: max-line-length
                matterGuid: (option !== TaskOptionEnum.none && option !== TaskOptionEnum.client) ? taskMatter ? taskMatter.value : null : null,
                fromName,
                fromNameGuid,
                phoneNumber: phoneNumber ? phoneNumber.value : null,
                organisation,
                organisationGuid,
                isPhoneCall,
                isReturnedCall,
                isWillCallAgain,
                isFromNameFromDataSource: isFromNameFromDataSource,
                isOrganisationFromDataSource: isOrganisationFromDataSource,
                input: input,
                messageTitle: messageTitle
            },
        })
        // tslint:disable-next-line: no-any
        .then((results: { data: any }) => {
            if (results.data) {
                if (results.data.error === null || results.data.error === undefined) {
                    showNotification(null, 'Successfully submitted', 'info');          
                    if (isForceClosed && props.onClose) {
                        props.onClose();
                    }              
                } else {
                    showNotification('Failed to update general task', results.data.error, 'error');
                }
            }
            setState((prevState) => {
                return {
                    ...prevState,
                    onSubmitLoading: false
                };
            });
        })
        // tslint:disable-next-line:no-any
        .catch((reason: any) => {
            showNotification('Failed to update general task', reason, 'error');
            setState((prevState) => {
                return {
                    ...prevState,
                    onSubmitLoading: false
                };
            });
        });
    };

    // tslint:disable-next-line: no-any
    const closePanel = (form: FormApi<PhoneForm>, values: any, pristine: boolean) => {        
        if (!pristine) {
            // code here...
            setState((prevState) => {
                return {
                    ...prevState,
                    showDiscard: true
                };
            });
        } else {
            if (props.onClose) {
                props.onClose();
            }
        }
    };

    const onReset = (form: FormApi<PhoneForm>, values: AnyObject) => {
        formReset(form);
    };

    const onFocusEditor = (event: SyntheticEvent) => {
        setState((prevState) => {
            return {
                ...prevState,
                hideToolbar: false
            };
        });
    };

    const onBlurEditor = (event: SyntheticEvent) => {
        setState((prevState) => {
            return {
                ...prevState,
                hideToolbar: true
            };
        });
    };

    const onClose = () => {
        if (state.isChanged) {
            setState((prevState) => {
                return {
                    ...prevState,
                    showDiscard: true
                };
            });
        } else {
            if (props.onClose) {
                props.onClose();
            }
        }
    };

    const onDiscardChanges = (agree: boolean) => {
        if (agree) {
            setState((prevState) => {
                return {
                    ...prevState,
                    showDiscard: false,
                };
            });

            if (props.onClose) {
                props.onClose();
            }

        } else {
           setState((prevState) => {
               return {
                   ...prevState,
                   showDiscard: false
               };
           });
        }
    };

    // tslint:disable-next-line: no-any no-shadowed-variable
    const onFormValueChanged = (form: FormApi<PhoneForm>, changeProps: any) => {
        if (!changeProps.pristine) {
            setState((prevState) => {
                return {
                    ...prevState,
                    isChanged: true
                };
            });
        }
    };

    const getPhoneNameId = (values: AnyObject) => {
        if (values.from && typeof values.from === 'object') {
            return values.from.nameId;
        }
        if (values.from && typeof values.from === 'string') {
            return 0;
        }
        return 0;
    };

    const onSwitch = (form: FormApi<PhoneForm>, values: AnyObject, name: string) => {
        if (name === 'from') {
            form.batch(() => {
                form.change('from', null); // clear the selector value
                
                form.change('isFromSwitched', !values.isFromSwitched);                
                
            });
        } else if (name === 'organisation') {
            form.batch(() => {
                form.change('isOrganisationSwitched', !values.isOrganisationSwitched);
                // form.change('organisationSelector', null); 
                // form.change('organisationGuid', null);
                // form.change('organisation', '');
            });
        }
    };

    const handleTabChange = (event: React.ChangeEvent<{}>, newValue: PhoneFormTab) => {
        setState((prevState) => {
            return {
                ...prevState,
                tab: newValue
            };
        });
    };

    const onFileClicked = (file: DocumentFile) => {
        if (file && file.url) {
            var win = window.open(file.url, '_blank');
            if (win) {
                win.focus();
            }            
        }
    };

    const getFollowUpDueDefaultValue = () => {
        // check outcome date (FollowUpDue)
        let outcomeDate: Date | null = null;

        if (props.enquiryDefaultConfig) {

            let hours = 0;
            let minutes = 0;
            let validTimeOfDay = false;

            if (props.enquiryDefaultConfig.timeOfDay && props.enquiryDefaultConfig.timeOfDay.toLowerCase() !== 'invalid date') {
                var timeOfDaySplit = props.enquiryDefaultConfig.timeOfDay ? props.enquiryDefaultConfig.timeOfDay.split(':') : null;

                if (timeOfDaySplit) {
                    validTimeOfDay = true;
                    hours = Number(timeOfDaySplit[0]);
                    minutes = Number(timeOfDaySplit[1]);
                }
            }

            if (props.enquiryDefaultConfig.isCalculatedDays) {

                let date = addBusinessDays(moment().toDate(), props.enquiryDefaultConfig.addDays ? props.enquiryDefaultConfig.addDays : 0);

                if (validTimeOfDay) {
                    outcomeDate = date
                        .set('hour', hours)
                        .set('minute', minutes)
                        .toDate();
                } else {
                    outcomeDate = date.toDate();
                }
            } else if (props.enquiryDefaultConfig.isCalculatedHours) {
                let addhours = props.enquiryDefaultConfig.addHours ? props.enquiryDefaultConfig.addHours : 0;

                outcomeDate = moment()
                    .add(addhours, 'hour')
                    .toDate();
            }
        }

        return outcomeDate;
    };
    
    const onDropDownTeamAndUserSelect = (
        form: FormApi<PhoneForm>,
        values: AnyObject,
        // tslint:disable-next-line: no-any
        input: FieldInputProps<any, HTMLElement>,
        // tslint:disable-next-line: no-any
        selection: IAutoCompleteItem | any,
        name: string,
        isUser: boolean,
    ) => {
        if (name === 'to') {
            
            let assignedTo = selection && selection.value && selection.label ? { value: selection.value, label: selection.label, isUser: isUser } : null;
            
            form.batch(() => {
                
                form.change('to', assignedTo);
                form.change('allocatedToEntityType', isUser ? AllocatedToEntityType.user : AllocatedToEntityType.team);
            
                if ((values.followUpDueDate === undefined || values.followUpDueDate === null) && values.option === TaskOptionEnum.enquiry) {
                    // only set assignment's follow up due value when we select assigned to value
                    let followUpDueValue = getFollowUpDueDefaultValue();
                    form.change('followUpDueDate', followUpDueValue);
                }

            });
        }
    };

    // tslint:disable-next-line: no-any max-line-length
    const onDropDownSelect = (form: FormApi<PhoneForm>, values: AnyObject, input: FieldInputProps<any, HTMLElement>, selection: IndividualNameSummary | IAutoCompleteItem | any, name: string) => {
        // input.OnChange(selection.value);
        if (name === 'from') {
            form.batch(() => {
                // Update required fields
                if (selection) {

                    // query extend phone details base on selector id using lazyQuery
                    getExtendPhoneTaskDetails({
                        variables: {
                            nameGuid: selection.value,
                            isFromDataSource: selection.isFromDataSource
                        }
                    });

                    if (selection && selection.organisationName && selection.organisationGuid) {
                        // Only change if the selected individual has an organisation.        
                        const organisationSelectorValue: IAutoCompleteItem = {
                            label: selection.organisationName, 
                            value: selection.organisationGuid
                        };
                        if (values && !values.organisation) {
                            // tslint:disable-next-line: max-line-length
                            form.change('organisation', selection && selection.organisationName ? organisationSelectorValue : null);
                            form.change('isOrganisationSwitched', true);
                        }
                    }

                    if (selection && selection.emailAddress) {
                        form.change('email', selection.emailAddress);
                    } else {
                        form.change('email', null);
                    }

                    if (selection && selection.phone !== null && selection.phone.length) {

                        // if (values && !values.phoneNumber) {
                            const tempPhoneNumber: IAutoCompleteItem = {
                                label: selection.phone,
                                value: selection.phone,
                            };
                            form.change('phoneNumber', tempPhoneNumber);
                        // }
                    } else {
                        form.change('phoneNumber', null);
                    }
                }              
            });
        }
    };

    const getClientAndMatter = () => {
        
        if (phoneForm.matter !== null && phoneForm.client === null && matterData) {
            return (
                <Grid item={true} xs={12} md={12}>
                    <div className={classes.secondaryDescription}>
                        <div className={classes.subTitle}>{matterData?.client}</div>
                        <div className={classes.description}>{`${matterData?.fileNumber} - ${matterData?.title}`}</div>
                    </div>
                </Grid>
            );
        } else if (phoneForm.matter === null && phoneForm.client !== null) {
            return (
                <Grid item={true} xs={12} md={12}>
                    <h4>{`${phoneForm.client!.label}`}</h4>
                </Grid>
            );
        }

        return undefined;
    };

    const extendPhoneTaskDetails = (values: AnyObject) => {

        if (!values.isFromSwitched) {
            return <div />;
        } else if (!values.from) {
            return <div />;
        } else if (extendPhoneTaskDetailsQuery.data && extendPhoneTaskDetailsQuery.data.party && 
            !extendPhoneTaskDetailsQuery.loading && !extendPhoneTaskDetailsQuery.error) {

            const { messageDetails, matterDetails, contactDetails } = extendPhoneTaskDetailsQuery.data.party.phoneTaskDetails;

            return (
                <>
                    {messageDetails && (messageDetails.messagesNotResponded > 0 || messageDetails.messagesReceived > 0) && (
                        <Grid item={true} md={12} xs={12} sm={12}>
                            <PhoneMessageDetailsPanel 
                                data={messageDetails}
                            />
                        </Grid>
                    )}
                    {matterDetails && (matterDetails.closedMatters > 0 || matterDetails.totalMatters > 0) && (
                        <Grid item={true} md={12} xs={12} sm={12}>
                            <PhoneMatterDetailsPanel 
                                data={matterDetails}
                            />
                        </Grid>
                    )}
                    {contactDetails && (
                        <Grid item={true} md={12} xs={12} sm={12}>
                            <PhoneContactDetailsPanel 
                                data={contactDetails}
                            />
                        </Grid>
                    )}
                </>
            );
        }

        return <div />;
    };

    const actionButton = (
        // tslint:disable-next-line: no-any
        form: FormApi<any>,
        submitting: boolean,
        pristine: boolean,
        // tslint:disable-next-line: no-any
        values: any
    ) => {
        return (
            <div className={classes.buttonContainer}>
                {/* <RenderCount /> */}
                {!state.isCreate ? (
                    <div>
                        <Button
                            color="primary"
                            type="submit"
                            className={classes.button}
                            onClick={() => {
                                form.change('handleCreateAndClose', false);
                                form.change('handleCreateAndNew', false);
                                form.change('handleUpdate', true);
                            }}
                            disabled={submitting || pristine || state.onSubmitLoading}
                        >
                            Update
                        </Button>
                    </div>
                ) : (
                    <div>
                        <Button
                            color="primary"
                            type="submit"
                            className={classes.button}
                            onClick={() => {
                                form.change('handleCreateAndClose', true);
                                form.change('handleCreateAndNew', false);
                                form.change('handleUpdate', false);
                            }}
                            disabled={submitting || pristine || state.onSubmitLoading}
                        >
                            Save and Close
                        </Button>
                        <Button
                            color="primary"
                            type="submit"
                            className={classes.button}
                            onClick={() => {
                                form.change('handleCreateAndNew', true);
                                form.change('handleCreateAndClose', false);
                                form.change('handleUpdate', false);
                            }}
                            disabled={submitting || pristine || state.onSubmitLoading}
                        >
                            Save and New
                        </Button>
                    </div>
                )}
                <div>
                    <Button
                        color="primary"
                        type="button"
                        className={classes.button}
                        onClick={() => closePanel(form, values, pristine)}
                    >
                        Cancel
                    </Button>
                    <Button
                        id="resetButton"
                        type="button"
                        onClick={() => onReset(form, values)}
                        disabled={submitting || pristine}
                        className={classes.hidden}
                    >
                        Reset
                    </Button>
                </div>
            </div>
        );
    };

    const heading = (option: TaskOptionEnum, isCreate: boolean) => {

        if (option === TaskOptionEnum.none) {
            if (isCreate) {
                return 'Create Phone Message';
            }
            return 'Edit Phone Message';
        } else {
            if (isCreate) {
                return `Create ${TaskOptionEnum[option]} Message`;
            }
            return `Edit ${TaskOptionEnum[option]} Message`;
        }
    };
    
    const filteredField = (option: TaskOptionEnum, form: FormApi<PhoneForm>) => {
        if (option === TaskOptionEnum.matter) {
            return (
                <div className={classes.optionField}>
                    <Field
                        name="matter"
                        validate={required}
                    >
                        {({ input, meta }) => (
                            <MatterAutoCompleteSelector
                                {...input}
                                label="Matter"
                                name="matter"
                                required={true}
                                onSelection={(selection: IAutoCompleteItem, name: string) =>
                                    input.onChange(selection)
                                }
                                value={input.value ? input.value : null}
                                error={meta.error && meta.touched}
                                helperText={
                                    meta.error &&
                                    meta.touched
                                        ? 'Matter is required'
                                        : ''
                                }
                            />
                        )}
                    </Field>
                </div>
            );
        } else if (option === TaskOptionEnum.client) {
            return (
                <div className={classes.optionField}>
                    <Field
                        name="client"
                        validate={required}
                    >
                        {({ input, meta }) => (
                            <ClientAutoCompleteSelector
                                {...input}
                                label="Client"
                                name="client"
                                required={true}
                                onSelection={(selection: IAutoCompleteItem, name: string) =>
                                    input.onChange(selection)
                                }
                                value={input.value ? input.value : null}
                                error={meta.error && meta.touched}
                                helperText={
                                    meta.error &&
                                    meta.touched
                                        ? 'Client is required'
                                        : ''
                                }
                            />
                        )}
                    </Field>
                </div>
            );
        }

        return undefined;
    };

    const phoneFields = (form: FormApi<PhoneForm>, values: AnyObject) => {
        return (
            <Grid container={true} spacing={3} style={{ display: state.tab === PhoneFormTab.details ? 'block' : 'none'}}>
                <Grid item={true} xs={12}>
                    <Field
                        name="notes"
                        // validate={form.getFieldState('message')?.value !== null ? required : undefined}
                    >
                        {({ input, meta }) => (
                            <>
                                <FormLabel component="legend" className={classes.formLabel}>Message</FormLabel>
                                <Editor 
                                    editorState={input.value}
                                    // tslint:disable-next-line: no-console
                                    onFocus={onFocusEditor}
                                    onBlur={onBlurEditor}
                                    toolbarHidden={state.hideToolbar}
                                    toolbarClassName={`${classes.toolbarClassName} toolbarClassName`}
                                    wrapperClassName={`${classes.wrapperClassName} wrapperClassName`}
                                    editorClassName={`${classes.editorClassName} editorClassName`}
                                    onEditorStateChange={input.onChange}
                                />
                            </>
                        )}
                    </Field>
                </Grid>
                <Grid item={true} xs={12}>
                    <Field
                        name="messageTitle"
                        subscription={{ touched: true, error: true, value: true }}
                    >
                        {({ input, meta }) => (
                            <TextField
                                {...input}
                                label="Message Title"
                                type="text"
                                multiline={true}
                                autoComplete="abcd"
                                className={classes.textField}
                            />
                        )}
                    </Field>
                </Grid>
                <Grid item={true} xs={12}>
                    <Grid container={true} spacing={3}>
                        <Grid item={true} xs={12} md={6}>
                            <Field
                                name="priority"
                                validate={required}
                                required={true}
                                helperText={'Priority is required'}
                                // validate={form.getFieldState('priority')?.value !== undefined ? required : undefined}
                            >
                                {({ input, meta }) => (
                                    <PrioritySelector
                                        {...input}
                                        value={input.value ? input.value : null}
                                        required={true}
                                        className={`${classes.textField} ${classes.clientSelector}`}
                                        error={meta.error && meta.touched}
                                            // tslint:disable-next-line: jsx-alignment
                                        onSelection={(selection: IAutoCompleteItem, name: string) => 
                                            input.onChange(selection)
                                        }
                                        helperText={
                                            meta.error &&
                                            meta.touched
                                                ? 'Priority is required'
                                                : ''
                                        }
                                        disablePortal={false}
                                    />
                                )}
                            </Field>
                        </Grid>
                        <Grid item={true} xs={12} md={6}>
                            <Field
                                name="category"
                                // validate={form.getFieldState('category')?.value !== null ? required : undefined}
                            >
                                {({ input, meta }) => (
                                    <TaskCategorySelector
                                        {...input}
                                        value={input.value ? input.value : null}
                                        label="Category"
                                        required={false}
                                        className={`${classes.textField} ${classes.clientSelector}`}
                                        error={meta.error && meta.touched}
                                        // tslint:disable-next-line: jsx-alignment
                                        onSelection={(selection: IAutoCompleteItem, name: string) =>
                                            input.onChange(selection)
                                        }
                                        helperText={
                                            meta.error &&
                                            meta.touched
                                                ? 'Category is required'
                                                : ''
                                        }
                                        disablePortal={false}
                                    />
                                )}
                            </Field>
                        </Grid>
                        <Grid item={true} xs={12} md={6}>
                            <Field
                                name="dueDate"
                                // validate={form.getFieldState('dueDate')?.value !== null ? required : undefined}
                            >
                                {({ input, meta }) => (
                                    <KeyboardDateTimePicker
                                        {...input}
                                        value={input.value ? input.value : null}
                                        className={classes.textField}
                                        label="Due"
                                        format="DD/MM/YYYY hh:mm a"
                                        placeholder="dd/mm/yyyy hh:mm a"
                                        animateYearScrolling={true}
                                        // tslint:disable-next-line: max-line-length
                                        autoOk={true}
                                        allowKeyboardControl={true}
                                        variant={'inline'}
                                        error={meta.error && meta.touched}
                                        inputProps={{autoComplete: 'off'}}
                                        InputLabelProps={{ shrink: true }}
                                        helperText={
                                            meta.error &&
                                            meta.touched
                                                ? 'Due date is required'
                                                : ''
                                        }
                                        // set new date to current with default hours to 5pm
                                        initialFocusedDate={new Date().setHours(17, 0, 0, 0)}
                                    />
                                )}
                            </Field>
                        </Grid>
                        <Grid item={true} xs={12} md={6}>
                            <Field
                                name="reminderOffset"
                                // validate={required}
                                helperText={'Reminder is required'}
                            >
                                {({ input, meta }) => (
                                    <ReminderSelector
                                        {...input}
                                        label="Reminder" 
                                        className={classes.textField}
                                        onSelection={(selection: IAutoCompleteItem) => {
                                            input.onChange(selection);
                                        }}
                                        error={meta.error && meta.touched}
                                        helperText={
                                            meta.error &&
                                            meta.touched
                                                ? meta.error
                                                : ''
                                        }
                                        showIcon={true}
                                        classes={{
                                            input: classes.reminderInput
                                        }}
                                    />
                                )}
                            </Field>
                        </Grid>
                        <Grid item={true} xs={12} md={6}>
                            <Field
                                name="assignedBy"
                                validate={required}
                                // validate={form.getFieldState('assignedBy')?.value !== null ? required : undefined}
                            >
                                {({ input, meta }) => (
                                    <TaskAssignedBySelector
                                        {...input}
                                        name="assignedBy"
                                        label="Assigned By"
                                        value={input.value ? input.value : null}
                                        disablePortal={false}
                                        required={true}
                                        className={`${classes.textField} ${classes.clientSelector}`}
                                        error={meta.error && meta.touched}
                                        // tslint:disable-next-line: jsx-alignment
                                        onSelection={(selection: IAutoCompleteItem, name: string) => 
                                            input.onChange(selection)
                                        }
                                        helperText={
                                            meta.error &&
                                            meta.touched
                                                ? 'Assigned by is required'
                                                : ''
                                        }
                                        visible={{
                                            role: true
                                        }}
                                        isAuthorized={true}
                                    />
                                )}
                            </Field>
                        </Grid>
                    </Grid>
                </Grid>
                <FormSpy subscription={{values: true, validating: true}}>
                    {/* tslint:disable-next-line: no-shadowed-variable */}
                    {({ values }) => extendPhoneTaskDetails(values)}
                </FormSpy>
            </Grid>
        );
    };

    const onSourceChanged = (form: FormApi<PhoneForm>, values: AnyObject, selection: IAutoCompleteItem) => {
        if (selection && selection.secondaryValue === true) {
            form.batch(() => {
                form.change('isShowReferrer', true);
                form.change('referrer', null);
            });
        } else {
            // Check against the previously selected referrer values, if there are any
            form.batch(() => {
                form.change('isShowReferrer', false);
                form.change('referrer', null);
            });
        }
    };

    const getOutcomeDefaultTime = () => {

        let defaultTime = new Date().setHours(17, 0, 0, 0);

        let hours = 0;
        let minutes = 0;

        if (props.enquiryDefaultConfig && props.enquiryDefaultConfig.isManualEntry
            && props.enquiryDefaultConfig.timeOfDay && props.enquiryDefaultConfig.timeOfDay.toLowerCase() !== 'invalid date') {
            var timeOfDaySplit = props.enquiryDefaultConfig.timeOfDay ? props.enquiryDefaultConfig.timeOfDay.split(':') : null;

            if (timeOfDaySplit) {
                hours = Number(timeOfDaySplit[0]);
                minutes = Number(timeOfDaySplit[1]);
            }

            defaultTime = new Date().setHours(hours, minutes, 0, 0);
        }

        return defaultTime;
    };
    
    const enquiryFields = (form: FormApi<PhoneForm>, values: AnyObject) => {
        return (
            <Grid container={true} spacing={3} style={{ display: state.tab === PhoneFormTab.details ? 'block' : 'none'}}>
                <Grid item={true} xs={12}>
                    <Field
                        name="notes"
                        // validate={form.getFieldState('message')?.value !== null ? required : undefined}
                    >
                        {({ input, meta }) => (
                            <>
                                <FormLabel component="legend" className={classes.formLabel}>Message</FormLabel>
                                <Editor 
                                    editorState={input.value}
                                    // tslint:disable-next-line: no-console
                                    onFocus={onFocusEditor}
                                    onBlur={onBlurEditor}
                                    toolbarHidden={state.hideToolbar}
                                    toolbarClassName={`${classes.toolbarClassName} toolbarClassName`}
                                    wrapperClassName={`${classes.wrapperClassName} wrapperClassName`}
                                    editorClassName={`${classes.editorClassName} editorClassName`}
                                    onEditorStateChange={input.onChange}
                                />
                            </>
                        )}
                    </Field>
                </Grid>
                <Grid item={true} xs={12}>
                    <Grid container={true} spacing={3}>
                        <Grid item={true} xs={12} md={6}>
                            <Field
                                name="areaOfLaw"
                                // tslint:disable-next-line: max-line-length
                                validate={required}
                                subscription={{ touched: true, error: true, value: true }}
                            >
                                {({ input, meta }) => (
                                    <AreaOfLawSelector
                                        {...input}
                                        label="Area of Law"
                                        name="areaOfLaw"
                                        required={true}
                                        className={classes.textField}
                                        error={meta.error && meta.touched}
                                        // tslint:disable-next-line: max-line-length
                                        onSelection={(selection: IAreaOfLawAutoCompleteItem, name: string) => input.onChange(selection)}
                                        onBlur={input.onBlur}
                                        helperText={
                                            meta.error &&
                                                meta.touched
                                                ? 'Area of law is required'
                                                : ''
                                        }
                                    />
                                )}
                            </Field>
                        </Grid>
                        <Grid item={true} xs={12} md={6}>
                            <Field
                                name="email"
                                type="email"
                                validate={finalFormValidateEmail}
                                subscription={{ touched: true, error: true, value: true }}
                            >
                                {({ input, meta }) => (
                                    <TextField
                                        {...input}
                                        label="Email"
                                        type="email"
                                        className={classes.textField}
                                        error={
                                            meta.error &&
                                            meta.touched
                                        }
                                        autoComplete="abcd"
                                        helperText={
                                            meta.error &&
                                                meta.touched
                                                ? meta.error
                                                : ''
                                        }
                                    />
                                )}
                            </Field>
                        </Grid>
                        <Grid item={true} xs={12} md={6}>
                            <Field
                                name="source"
                                validate={required}
                                subscription={{ touched: true, error: true, value: true }}
                            >
                                {({ input, meta }) => (
                                    <SourceSelector
                                        label="Source"
                                        name="source"
                                        value={input.value ? input.value : null}
                                        required={true}
                                        className={classes.textField}
                                        error={meta.error && meta.touched}
                                        onSelection={(selection: IAutoCompleteItem, name: string) => {
                                            input.onChange(selection);
                                            onSourceChanged(form, values, selection);
                                        }}
                                        helperText={
                                            meta.error &&
                                                meta.touched
                                                ? 'Source is required'
                                                : ''
                                        }
                                        onBlur={input.onBlur}
                                    />
                                )}
                            </Field>
                        </Grid>

                        <Grid item={true} xs={12} md={6}>
                            <Field
                                name="office"
                                validate={props.enquiryDefaultConfig!.isLocationFieldMandatory ? required : undefined}
                                subscription={{ touched: true, error: true, value: true }}
                            >
                                {({ input, meta }) => (
                                    <OfficeSelector
                                        {...input}
                                        label="Location"
                                        name="office"
                                        required={props.enquiryDefaultConfig!.isLocationFieldMandatory}
                                        value={input.value ? input.value : null}
                                        className={classes.textField}
                                        error={meta.error && meta.touched}
                                        onSelection={(selection: IAutoCompleteItem, name: string) =>
                                            input.onChange(selection)
                                        }
                                        helperText={meta.error && meta.touched
                                            ? 'Location is required'
                                            : ''
                                        }
                                        onBlur={input.onBlur}
                                    />
                                )}
                            </Field>
                        </Grid>

                        {!!values.isShowReferrer && (
                            <Grid item={true} xs={12} md={6}>
                                <Field name="referrer">
                                    {({ input, meta }) => (
                                        <ClientAndContactSelector
                                            {...input}
                                            label="Contact"
                                            name="referrer"
                                            required={false}
                                            onSelection={(selection: IAutoCompleteItem, name: string) => {
                                                input.onChange(selection);
                                                // onReferrerChanged(form, values, selection);
                                            }}
                                            includeNonPmsContacts={false}
                                            includePmsContacts={true}
                                        />
                                    )}
                                </Field>
                            </Grid>
                        )}

                        <Grid item={true} xs={12} md={values.isShowReferrer ? 6 : 12}>
                            <Field
                                name="sourceNotes"
                                subscription={{ touched: true, error: true, value: true }}
                            >
                                {({ input, meta }) => (
                                    <TextField
                                        {...input}
                                        label="Source Notes"
                                        type="text"
                                        multiline={true}
                                        autoComplete="abcd"
                                        className={classes.textField}
                                    />
                                )}
                            </Field>
                        </Grid>

                        <Grid item={true} xs={12} md={6}>
                            <Field
                                name="followUpDueDate"
                                // tslint:disable-next-line: max-line-length
                                validate={values.to ? required : undefined}
                                subscription={{ touched: true, error: true, value: true }}
                            >
                                {({ input, meta }) => (
                                    <KeyboardDateTimePicker
                                        {...input}
                                        required={values.to ? true : false}
                                        className={classes.textField}
                                        label="Due"
                                        format="DD/MM/YYYY hh:mm a"
                                        placeholder="dd/mm/yyyy hh:mm a"
                                        animateYearScrolling={true}
                                        // tslint:disable-next-line: max-line-length
                                        autoOk={true}
                                        allowKeyboardControl={true}
                                        variant={'inline'}
                                        error={meta.error && meta.touched}
                                        helperText={
                                            meta.error && meta.touched
                                                ? 'Due is required' // 'Follow Up Due date is required'
                                                : ''
                                        }
                                        inputProps={{ autocomplete: 'off' }}
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                        // minDate={new Date()}
                                        initialFocusedDate={getOutcomeDefaultTime()}
                                    />
                                )}
                            </Field>
                        </Grid>

                        <Grid item={true} xs={12} md={6}>
                            <Field
                                name="assignmentFollowUpDueReminder"
                            >
                                {({ input, meta }) => (
                                    <ReminderSelector
                                        {...input}
                                        label="Reminder" 
                                        className={classes.textField}
                                        onSelection={(selection: IAutoCompleteItem) => {
                                            input.onChange(selection);
                                        }}
                                        error={meta.error && meta.touched}
                                        helperText={
                                            meta.error &&
                                            meta.touched
                                                ? meta.error
                                                : ''
                                        }
                                        showIcon={true}
                                        classes={{
                                            input: classes.reminderInput
                                        }}
                                    />
                                )}
                            </Field>
                        </Grid>
                        
                        <Grid item={true} xs={12} md={6}>
                            <Field
                                name="assignedBy"
                                validate={required}
                            >
                                {({ input, meta }) => (
                                    <TaskAssignedBySelector
                                        {...input}
                                        name="assignedBy"
                                        label="Assigned By"
                                        value={input.value ? input.value : null}
                                        disablePortal={false}
                                        required={true}
                                        className={`${classes.textField} ${classes.clientSelector}`}
                                        error={meta.error && meta.touched}
                                        // tslint:disable-next-line: jsx-alignment
                                        onSelection={(selection: IAutoCompleteItem, name: string) => 
                                            input.onChange(selection)
                                        }
                                        helperText={
                                            meta.error &&
                                            meta.touched
                                                ? 'Assigned by is required'
                                                : ''
                                        }
                                        visible={{
                                            role: true
                                        }}
                                        isAuthorized={true}
                                    />
                                )}
                            </Field>
                        </Grid>
                        {enquiryCheckboxes(form, values)}
                    </Grid>
                </Grid>
            </Grid>
        );
    };

    const detilsTypeTab = (form: FormApi<PhoneForm>, values: AnyObject) => {
        if (values.option === TaskOptionEnum.enquiry) {
            return enquiryFields(form, values);
        } else {
            return phoneFields(form, values);
        }
    };

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const getFileItems = (files?: DocumentFile[]) => {
        if (files) {
            const filteredFiles = files.filter((file: DocumentFile) => {
                return file.status !== 2 && file.url;
            });

            if (filteredFiles) {
                const chip = (
                    <div>
                        {
                            filteredFiles.length > 0 
                            ? <Typography>{'Click on the following to open the uploaded files'}</Typography> 
                            : null
                        }
                        <div className={classes.chips}>
                            {
                                filteredFiles.map((file: DocumentFile, index: number) => {
                                        return (
                                            <Chip
                                                key={index}
                                                className={classes.chip}
                                                label={file.name} 
                                                onClick={() => onFileClicked(file)}
                                            />
                                        );
                                    }                           
                                )  
                            }
                        </div>
                    </div>                
                );

                return chip;
            } else {
                return (<div/>);
            }
        } else {
            return (<div/>);
        }
    };

    const documentsTab = (form: FormApi<PhoneForm>, values: AnyObject) => {
        return (
            <Grid container={true} spacing={3} style={{ display: state.tab === PhoneFormTab.documents ? 'block' : 'none'}}>
                <Grid
                    item={true}
                    xs={12}
                >      
                    <FormSpy subscription={{ values: true }}>
                        {/* tslint:disable-next-line: no-shadowed-variable */}
                        {({ values }) => (
                            getFileItems(values.files)
                        )}
                    </FormSpy>  
                </Grid>
                <Grid
                    item={true}
                    xs={12}
                    className={classes.dropZone}
                >
                    <Field
                        name="files"
                        // subscription={{touched: true, error: true, value: true}}
                    >
                        {({ input, meta }) => (
                            <DropzoneArea
                                {...input}
                                inputProps={{
                                    className: classes.dropZone
                                }}
                                key={filesKey}
                                maxFileSize={15000000}
                                // acceptedFiles={['.msg', '.jpg', '.jpeg', '.png', '.docx', '.zip']}
                                showFileNames={true}
                                useChipsForPreview={true}
                                filesLimit={10}
                                dropzoneText="To add a file, drag and drop or click in this box.
                                Click the 'X' on a file to delete.
                                Click the filename to replace it.
                                View a file using the links above. 
                                These links will become available once the task is saved."
                                dropzoneClass={classes.dropZoneFont}
                                // tslint:disable-next-line: no-shadowed-variable
                                onChange={(files: File[]) => {
                                    let existingFiles: DocumentFile[] = [...input.value];
                                    const newFiles = files.filter((file: File) => {
                                        return !existingFiles.some((item: DocumentFile) => {
                                            return item.name === file.name && item.status !== 2;
                                        });
                                    });
                                    if (newFiles && newFiles.length > 0) {
                                        newFiles.forEach((file: File) => {
                                            const documentFile: DocumentFile = {
                                                guidID: null,
                                                file: file,
                                                status: 1,
                                                name: file.name,
                                                url: ''
                                            };
                                            existingFiles.push(documentFile);
                                        });  
                                        input.onChange(existingFiles);
                                    }
                                }}
                                onDelete={(file: File) => {
                                    let existingFiles: DocumentFile[] = [...input.value];
                                    // eslint-disable-next-line array-callback-return
                                    existingFiles.map((item: DocumentFile) => {
                                        if (file.name === item.name) {
                                            item.file = file;
                                            item.status = 2; // deleted
                                        }
                                    });  
                                    // tslint:disable-next-line: max-line-length
                                    const filteredFiles = existingFiles.filter((item: DocumentFile) => {
                                        return item.status !== 2 || item.guidID !== null;
                                    });
                                    input.onChange(filteredFiles);
                                }}                                                    
                                // tslint:disable-next-line
                                onDrop={(files: File[], event: any) => {
                                    // let existingFiles: DocumentFile[] = [...input.value];
                                    // tslint:disable-next-line: max-line-length
                                    // const existingFile = existingFiles.find((item: DocumentFile) => item.name === file)
                                }}                                                  
                                initialFiles={input.value.map((file: { url: string; }) => file.url)}
                            />
                        )}
                    </Field>                    
                </Grid>
            </Grid>
        );
    };

    const activityTab = (form: FormApi<PhoneForm>, values: AnyObject) => {
        return (
            <Grid container={true} spacing={3} style={{ display: state.tab === PhoneFormTab.activity ? 'block' : 'none'}}>
                <Grid item={true} xs={12} md={12}>
                    {state.taskDetails && (
                        <div className={classes.taskHistorykContentWrapper}>
                            <div className={classes.historyActivityContainer}>
                                <TaskHistoryActivity
                                    className={`${classes.historyActivityWrapper} historyActivityWrapper`}
                                    taskDetail={state.taskDetails}
                                    width={TaskHistoryActivityWidth.FULL_WIDTH}
                                    autoScroll={true}
                                />
                            </div>
                        </div>
                    )}
                    <Divider className={`${classes.divider} ${classes.marginBottom}`} />
                    <div>
                        <FormSpy 
                            subscription={{values: true, validating: true}}
                        >
                            {/* tslint:disable-next-line: no-shadowed-variable */}
                            {({ values }) => ( 
                                <Field
                                    name="activityText"
                                >
                                    {({ input, meta }) => (
                                        <TextField 
                                            {...input}
                                            label="Log Activity" 
                                            name="activityText"
                                            required={false}
                                            multiline={true}
                                            rows={4}
                                            className={classes.textField}
                                            disabled={values.allocatedToEntityType !== AllocatedToEntityType.user}
                                            error={meta.error && meta.touched}
                                            helperText={
                                                meta.error &&
                                                meta.touched
                                                    ? 'Activity Text is required'
                                                    : ''
                                            }
                                        />
                                    )}
                                </Field>
                            )}
                        </FormSpy> 
                    </div>
                </Grid>
            </Grid>
        );
    };

    const phoneCheckboxes = (form: FormApi<PhoneForm>, values: AnyObject) => {
        return (
            <Grid item={true} xs={12}>
                <Field
                    name="isReturnedCall"
                    subscription={{touched: true, error: true, value: true}}
                >
                    {({ input, meta }) => (
                        <FormControlLabel
                            control={
                            <Checkbox
                                checked={input.value}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    input.onChange(event.target.checked);
                                }}
                                color="primary"
                            />
                            }
                            label="Returned Call"
                        />
                    )}
                </Field>
                <Field
                    name="isPhoneCall"
                    subscription={{touched: true, error: true, value: true}}
                >
                    {({ input, meta }) => (
                        <FormControlLabel
                            control={
                            <Checkbox
                                checked={input.value}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    input.onChange(event.target.checked);
                                }}
                                color="primary"
                            />
                            }
                            label="Please Call"
                        />
                    )}
                </Field>
                <Field
                    name="isWillCallAgain"
                    subscription={{touched: true, error: true, value: true}}
                >
                    {({ input, meta }) => (
                        <FormControlLabel
                            control={
                            <Checkbox
                                checked={input.value}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    input.onChange(event.target.checked);
                                }}
                                color="primary"
                            />
                            }
                            label="Will Call Again"
                        />
                    )}
                </Field>
            </Grid>
        );
    };

    const enquiryCheckboxes = (form: FormApi<PhoneForm>, values: AnyObject) => {
        return (
            <Grid item={true} xs={12} md={12}>
                <Field
                    name="isEmailNotification"
                    subscription={{touched: true, error: true, value: true}}
                >
                    {({ input, meta }) => (
                        <FormControlLabel
                            control={
                            <Checkbox
                                checked={input.value}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    input.onChange(event.target.checked);
                                }}
                                color="primary"
                            />
                            }
                            label="Email Notification"
                        />
                    )}
                </Field>
                <Field
                    name="isSentAcknowledgementEmail"
                    subscription={{touched: true, error: true, value: true}}
                >
                    {({ input, meta }) => (
                        <FormControlLabel
                            control={
                            <Checkbox
                                checked={input.value}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    input.onChange(event.target.checked);
                                }}
                                color="primary"
                            />
                            }
                            label="Send Acknowledgement"
                        />
                    )}
                </Field>
            </Grid>
        );
    };

    return (
        <>
            <DialogBox
                title="Task"
                // tslint:disable-next-line:max-line-length
                content={`Are you sure you want to close the form?`}
                show={state.showDiscard}
                isAgree={onDiscardChanges}
                disAgreeLabel={'No'}
                agreeLabel={'Yes'}
            />
            <Dialog
                open={state.isOpen}
                onClose={onClose}
                maxWidth="md"
                fullWidth={true}
                className={classes.dialogRoot}
                scroll={'paper'}
                classes={{
                    paperWidthMd: classes.paperWidthMd
                }}
            >
                {state.isUserLoading || (state.isLoading && !state.isCreate) ? (
                    <>
                        <DialogTitle className={classes.header}>{heading(TaskOptionEnum.none, state.isCreate)}</DialogTitle>
                        <DialogContent dividers={true}>
                            <div className={classes.loaderWrapper}>
                                <RvLoader size="small" label="Loading..."/>
                            </div>
                        </DialogContent>
                    </>
                ) : (
                    <Form
                        onSubmit={(values, form: FormApi<PhoneForm>) => onSubmit(form, values)}                
                        initialValues={phoneForm}
                        initialValuesEqual={() => true}
                        keepDirtyOnReinitialize={true}
                        subscription={{ submitting: true, pristine: true }}
                        render={({handleSubmit, form, submitting, pristine, values}) => (
                            <form onSubmit={(e) => handleSubmit(e)} id="taskForm">
                                <FormSpy 
                                    subscription={{ pristine: true, values: true }}
                                    // tslint:disable-next-line: no-shadowed-variable
                                    onChange={props => {
                                        onFormValueChanged(form, props);
                                    }}
                                />
                                    <FormSpy subscription={{values: true, validating: true}}>
                                        {/* tslint:disable-next-line: no-shadowed-variable */}
                                        {({ values }) => (
                                            <DialogTitle className={classes.header}>{heading(values.option, state.isCreate)}</DialogTitle>
                                        )}
                                    </FormSpy>
                                    <DialogContent dividers={true}>
                                        <Grid container={true} spacing={3}>
                                            {!state.isCreate && props.matterGuid ? (
                                                <Grid item={true} xs={12}>
                                                    {getClientAndMatter()}
                                                </Grid>
                                            ) : undefined}
                                            <Grid item={true} xs={12}>
                                                <Grid container={true} spacing={3}>
                                                    <Grid item={true} xs={12} md={6}>
                                                        <FormSpy subscription={{values: true, validating: true}}>
                                                            {/* tslint:disable-next-line: no-shadowed-variable */}
                                                            {({ values }) => (
                                                                <div className={classes.switcherWrapper}>
                                                                    {values.isFromSwitched ? (
                                                                        <Field
                                                                            name="from"
                                                                            validate={required}
                                                                            required={true}
                                                                            helperText={'From is required'}
                                                                            subscription={{touched: true, error: true, value: true}}
                                                                        >
                                                                            {({ input, meta }) => (
                                                                                <IndividualSelector 
                                                                                    {...input}
                                                                                    label="From"
                                                                                    value={typeof input.value === 'object' ? input.value : null}
                                                                                    className={classes.selector}
                                                                                    error={meta.error && meta.touched}
                                                                                    helperText={
                                                                                        meta.error &&
                                                                                        meta.touched
                                                                                            ? 'From is required'
                                                                                            : ''
                                                                                    }
                                                                                    onSelection={(selection: IndividualNameSummary, name: string) => {
                                                                                        input.onChange(selection);
                                                                                        onDropDownSelect(form, values, input, selection, name);
                                                                                    }}
                                                                                    enableSecondary={true}
                                                                                    required={true}
                                                                                    // value={input.value}
                                                                                />
                                                                            )}
                                                                        </Field>
                                                                    ) : (
                                                                        <Field
                                                                            validate={required}
                                                                            name="from"
                                                                            subscription={{touched: true, error: true, value: true}}
                                                                        >
                                                                            {({ input, meta }) => (
                                                                                <TextField
                                                                                    {...input}
                                                                                    // tslint:disable-next-line: max-line-length
                                                                                    value={typeof input.value === 'string' ? input.value : input.value.label}
                                                                                    label="From"
                                                                                    type="text"
                                                                                    className={classes.textField}
                                                                                    error={
                                                                                        meta.error &&
                                                                                        meta.touched
                                                                                    }
                                                                                    disabled={values.nameGuid}
                                                                                    autoComplete="abcd"
                                                                                    helperText={
                                                                                        meta.error &&
                                                                                        meta.touched
                                                                                            ? 'From is required'
                                                                                            : ''
                                                                                    }
                                                                                />
                                                                            )}
                                                                        </Field>
                                                                    )}
                                                                    <div className="switcher">
                                                                        <Tooltip 
                                                                            // placement="left-start"
                                                                            title={
                                                                                values.isFromSwitched ? (
                                                                                    <Typography color="inherit">
                                                                                        Switch to enter the last name
                                                                                    </Typography>
                                                                                ) :
                                                                                (
                                                                                    <Typography color="inherit">
                                                                                        Switch to select an existing client & contact
                                                                                    </Typography>
                                                                                )
                                                                            }
                                                                        >
                                                                            <IconButton 
                                                                                // className={classes.margin}
                                                                                // size="small"
                                                                                onClick={() => {
                                                                                    // Another approach.
                                                                                    onSwitch(form, values, 'from');
                                                                                }}
                                                                            >
                                                                                <SyncAltIcon fontSize="small" />
                                                                            </IconButton>
                                                                        </Tooltip>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </FormSpy>
                                                    </Grid>
                                                    <FormSpy subscription={{values: true, validating: true}}>
                                                        {/* tslint:disable-next-line: no-shadowed-variable */}
                                                        {({ values }) => (
                                                            <>
                                                                <Grid item={true} xs={12} md={6}>
                                                                    <Field
                                                                        name="to"
                                                                        validate={required}
                                                                        required={true}
                                                                        helperText={'To is required'}
                                                                        // validate={form.getFieldState('priority')?.value !== undefined ? required : undefined}
                                                                    >
                                                                        {({ input, meta }) => (
                                                                            <TaskAssignedByUserTeamSelector
                                                                                {...input}
                                                                                label="To"
                                                                                value={input.value ? input.value : null}
                                                                                disablePortal={false}
                                                                                required={true}
                                                                                className={`${classes.textField} ${classes.clientSelector}`}
                                                                                error={meta.error && meta.touched}
                                                                                // tslint:disable-next-line: jsx-alignment
                                                                                onSelection={(selection: IAutoCompleteItem, name: string, isUser: boolean) => 
                                                                                    onDropDownTeamAndUserSelect(form, values, input, selection, name, isUser)
                                                                                }
                                                                                helperText={
                                                                                    meta.error &&
                                                                                    meta.touched
                                                                                        ? 'To is required'
                                                                                        : ''
                                                                                }
                                                                                visible={{
                                                                                    role: true
                                                                                }}
                                                                                isAuthorized={true}
                                                                                // exclude teams if it is enquiry
                                                                                excludeTeams={values.option === TaskOptionEnum.enquiry}
                                                                            />
                                                                        )}
                                                                    </Field>
                                                                </Grid>
                                                                <Grid item={true} xs={12} md={6}>
                                                                    <Field
                                                                        validate={required}
                                                                        name="phoneNumber"
                                                                        // validate={form.getFieldState('description')?.value !== null ? required : undefined}
                                                                    >
                                                                        {({ input, meta }) => (
                                                                            <PhoneNumberSelector 
                                                                                {...input}
                                                                                label="Phone Number"
                                                                                value={typeof input.value === 'object' ? input.value : null}
                                                                                error={meta.error && meta.touched}
                                                                                helperText={
                                                                                    meta.error &&
                                                                                    meta.touched
                                                                                        ? 'Phone Number is required'
                                                                                        : ''
                                                                                }
                                                                                onSelection={(selection: IAutoCompleteItem, name: string) => {
                                                                                    input.onChange(selection);
                                                                                }}
                                                                                nameId={getPhoneNameId(values)}
                                                                                isFromDataSource={
                                                                                    values.from && values.from.isFromDataSource 
                                                                                        ? values.from.isFromDataSource 
                                                                                        : true}
                                                                                required={true}
                                                                            />
                                                                        )}
                                                                    </Field>
                                                                </Grid>
                                                                <Grid item={true} xs={12} md={6}>
                                                                    <div className={classes.switcherWrapper}>
                                                                        {values.isOrganisationSwitched ? (
                                                                            <Field
                                                                                name="organisation"
                                                                                subscription={{touched: true, error: true, value: true}}
                                                                            >
                                                                                {({ input, meta }) => (
                                                                                    <OrganisationSelector 
                                                                                        {...input}
                                                                                        label="Organisation"
                                                                                        value={typeof input.value === 'object' ? input.value : null}
                                                                                        error={meta.error && meta.touched}
                                                                                        helperText={
                                                                                            meta.error &&
                                                                                            meta.touched
                                                                                                ? 'Organisation is required'
                                                                                                : ''
                                                                                        }
                                                                                        onSelection={(selection: IAutoCompleteItem, name: string) => {
                                                                                            input.onChange(selection);
                                                                                        }}
                                                                                    />
                                                                                )}
                                                                            </Field>
                                                                        ) : (
                                                                            <Field
                                                                                name="organisation"
                                                                                subscription={{touched: true, error: true, value: true}}
                                                                            >
                                                                                {({ input, meta }) => (
                                                                                    <TextField
                                                                                        {...input}
                                                                                        // tslint:disable-next-line: max-line-length
                                                                                        value={typeof input.value === 'string' ? input.value : input.value.label}
                                                                                        label="Organisation"
                                                                                        type="text"
                                                                                        className={classes.textField}
                                                                                        error={
                                                                                            meta.error &&
                                                                                            meta.touched
                                                                                        }
                                                                                        disabled={values.nameGuid}
                                                                                        autoComplete="abcd"
                                                                                        helperText={
                                                                                            meta.error &&
                                                                                            meta.touched
                                                                                                ? 'Organisation is required'
                                                                                                : ''
                                                                                        }
                                                                                    />
                                                                                )}
                                                                            </Field>
                                                                        )}
                                                                        <div className="switcher">
                                                                            <Tooltip 
                                                                                // placement="left-start"
                                                                                title={
                                                                                    values.isOrganisationSwitched ? (
                                                                                        <Typography color="inherit">
                                                                                            Switch to select an existing organisation
                                                                                        </Typography>
                                                                                    ) :
                                                                                    (
                                                                                        <Typography color="inherit">
                                                                                            Switch to enter a new organisation name
                                                                                        </Typography>
                                                                                    )
                                                                                }
                                                                            >
                                                                                <IconButton 
                                                                                    // size="small"
                                                                                    // className={classes.margin}
                                                                                    onClick={() => {
                                                                                        // Another approach.
                                                                                        onSwitch(form, values, 'organisation');
                                                                                    }}
                                                                                >
                                                                                    <SyncAltIcon fontSize="small" />
                                                                                </IconButton>
                                                                            </Tooltip>
                                                                        </div>
                                                                    </div>
                                                                </Grid>  

                                                                <Grid item={true} xs={12} md={12}>
                                                                    <Field
                                                                        name="cc"
                                                                        // validate={required}
                                                                        // helperText={'CC is required'}
                                                                        // validate={form.getFieldState('priority')?.value !== undefined ? required : undefined}
                                                                    >
                                                                        {({ input, meta }) => (
                                                                            <UserAutocompleteSelector
                                                                                {...input}
                                                                                label="CC"
                                                                                value={input.value ? input.value : null}
                                                                                disablePortal={false}
                                                                                className={`${classes.textField} ${classes.clientSelector}`}
                                                                                error={meta.error && meta.touched}
                                                                                // tslint:disable-next-line: jsx-alignment
                                                                                onSelection={(selection: IAutoCompleteItem[], name: string, ) => {
                                                                                    input.onChange(selection);
                                                                                }}
                                                                                helperText={
                                                                                    meta.error &&
                                                                                    meta.touched
                                                                                        ? 'CC is required'
                                                                                        : ''
                                                                                }
                                                                                visible={{
                                                                                    role: true
                                                                                }}
                                                                                isAuthorized={true}
                                                                                isExternal={false}
                                                                                multiple={true}
                                                                            />
                                                                        )}
                                                                    </Field>
                                                                </Grid>

                                                                <Grid item={true} xs={12}>
                                                                    <Divider />  
                                                                </Grid>                  
                                                                
                                                                {state.isCreate && !props.matterGuid ? (
                                                                    
                                                                    <Grid item={true} xs={12}>
                                                                        <div className={classes.optionWrapper}>
                                                                            <FormSpy subscription={{values: true, validating: true}}>
                                                                                {/* tslint:disable-next-line: no-shadowed-variable */}
                                                                                {({ values }) => (
                                                                                    <>
                                                                                        <Field
                                                                                            name="option"
                                                                                            subscription={{touched: true, error: true, value: true}}
                                                                                        >
                                                                                            {({ input }) => (
                                                                                                <div className={classes.formControlWrapper}>
                                                                                                    <FormControl component="fieldset" className={classes.clientRowRadio}>
                                                                                                        <FormLabel component="legend">In relation to</FormLabel>
                                                                                                        <RadioGroup
                                                                                                            defaultValue={TaskOptionEnum.none}
                                                                                                            name="type"
                                                                                                            className={classes.radioGroup}
                                                                                                            value={input.value}
                                                                                                            onChange={handleOption(input, form, values)}
                                                                                                        >
                                                                                                            <FormControlLabel
                                                                                                                value={TaskOptionEnum.none}
                                                                                                                control={<StyledRadio />}
                                                                                                                label="General Call"
                                                                                                            />
                                                                                                            <FormControlLabel
                                                                                                                value={TaskOptionEnum.matter}
                                                                                                                control={<StyledRadio />}
                                                                                                                label="Matter"
                                                                                                            />
                                                                                                            <FormControlLabel
                                                                                                                value={TaskOptionEnum.client}
                                                                                                                control={<StyledRadio />}
                                                                                                                label="Client"
                                                                                                            />
                                                                                                            {UserFeature.HasAccess(UserFeatureEnum.hasCreateLead) && (
                                                                                                                <FormControlLabel
                                                                                                                    value={TaskOptionEnum.enquiry}
                                                                                                                    control={<StyledRadio />}
                                                                                                                    label="New Enquiry"
                                                                                                                />
                                                                                                            )}
                                                                                                        </RadioGroup>
                                                                                                    </FormControl>
                                                                                                </div>
                                                                                            )}
                                                                                        </Field>
                                                                                        {filteredField(values.option, form)}
                                                                                    </>
                                                                                )}
                                                                            </FormSpy>
                                                                        </div>
                                                                    </Grid>
                                                                ) : undefined}

                                                                {state.isCreate && (
                                                                    <Grid item={true} xs={12}>
                                                                        <Divider />  
                                                                    </Grid>
                                                                )}
                                                            </>
                                                        )}
                                                    </FormSpy> 
                                                </Grid>
                                            </Grid>
                                            <FormSpy subscription={{ values: true, pristine: true }}>
                                                {/* tslint:disable-next-line: no-shadowed-variable */}
                                                {({ values }) => (
                                                   <>
                                                        {values.option !== TaskOptionEnum.enquiry && phoneCheckboxes(form, values)}
                                                        {values.option === TaskOptionEnum.enquiry ? (
                                                            <Tabs
                                                                value={state.tab}
                                                                onChange={handleTabChange}
                                                                indicatorColor="primary"
                                                                textColor="primary"
                                                                // className={classes.tabs}
                                                            >
                                                                <Tab label="Details" value={PhoneFormTab.details} />
                                                            </Tabs>
                                                        ) : (
                                                            <Tabs
                                                                value={state.tab}
                                                                onChange={handleTabChange}
                                                                indicatorColor="primary"
                                                                textColor="primary"
                                                                // className={classes.tabs}
                                                            >
                                                                <Tab label="Details" value={PhoneFormTab.details} />
                                                                <Tab 
                                                                    label="Documents" 
                                                                    value={PhoneFormTab.documents} 
                                                                />
                                                                <Tab 
                                                                    label="Activity" 
                                                                    value={PhoneFormTab.activity} 
                                                                />
                                                            </Tabs>
                                                        )}
                                                        <Grid item={true} md={12} xs={12} sm={12}>
                                                            {detilsTypeTab(form, values)}
                                                            {documentsTab(form, values)}
                                                            {activityTab(form, values)}
                                                        </Grid>
                                                   </>
                                                )}
                                            </FormSpy>
                                        </Grid>
                                        {/* <div>
                                            {printJson(values)}
                                        </div> */}
                                    </DialogContent>
                                    <FormSpy subscription={{ values: true, pristine: true }}>
                                        {/* tslint:disable-next-line: no-shadowed-variable */}
                                        {({ values }) => (
                                            <DialogActions>
                                                {actionButton(form, submitting, pristine, values)}
                                            </DialogActions>
                                        )}
                                    </FormSpy>
                                {/* <DialogActions>
                                    {actionButton(form, submitting, pristine, values)}
                                </DialogActions> */}
                                
                                {/* {printJson(values)} */}
                            </form>
                        )} 
                    />
                )}
            </Dialog>
        </>
    );
};

// // tslint:disable-next-line: no-any
export function printJson(values: PhoneForm) {
    if (values) {
        return (
            <>
                <pre>
                    {JSON.stringify(values, undefined, 2)}
                </pre>
            </>
        );
    } else {
        return (
            <>
                <FormSpy subscription={{ values: true }}>
                    {/* tslint:disable-next-line: no-shadowed-variable */}
                    {({ values }) => (
                        <pre>
                            {JSON.stringify(values, undefined, 2)}
                            {/* <RenderCount /> */}
                        </pre>
                    )}
                </FormSpy>
            </>
        );
    }
}