/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { Button, Dialog, DialogTitle, DialogActions, DialogContent, Grid, TextField, FormControlLabel, Checkbox, Typography } from '@material-ui/core';
import { AnyObject, Field, FieldInputProps, Form, FormSpy } from 'react-final-form';
import { FormApi } from 'final-form';
import DialogBox from '../../Dashboard/Component/DialogBox';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import gql from 'graphql-tag';
import { showNotification } from '../../App';
import { client } from '../..';
import { useCurrentUser } from '../../hooks/useCurrentUser';
import { RvLoader } from '../../components/Loader';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        dialogRoot: {
            '& .downshift-wrapper': {
                flex: 1,
                padding: 0,
            },
        },
        header: {
            '& > h2': {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                margin: '0 -10px',
            },
        },
        headerCol: {
            flex: 1,
            display: 'flex',
            alignItems: 'center',
            padding: '0 10px',
        },
        alignRight: {
            justifyContent: 'flex-end',
        },
        textField: {
            width: '100%',
        },
        content: {
            flex: 1,
        },
        buttonContainer: {
            display: 'flex',
            justifyContent: 'space-between',
            // paddingBottom: '20px'
        },
        button: {
            margin: theme.spacing(1),
            minWidth: '83px',
        },
        hidden: {
            display: 'none',
        },
        loaderWrapper: {
            display: 'flex',
            justifyContent: 'center',
            padding: '20px 0',
        },
        clientSelector: {
            flex: 1,
        },
    }),
);

interface MessageFormDialogProps {
    open: boolean;
    onClose?: () => void;
    guid: string;
    // tslint:disable-next-line: no-any
    data?: any;
}

interface MessageFormDialogState {
    onSubmitLoading: boolean;
    showDiscard: boolean;
    isChanged: boolean;
}

interface MessageForm {
    message: string | null;
    isOwnerAssignor: boolean;
    isAssignee: boolean;
}

export const MessageFormDialog: React.FC<MessageFormDialogProps> = (props) => {
    const classes = useStyles();

    const currentUser = useCurrentUser();
    
    const [state, setState] = useState<MessageFormDialogState>({
        onSubmitLoading: false,
        showDiscard: false,
        isChanged: false,
    });

    // const [messageForm] = useState<MessageForm>({
    //     message: null,
    //     isOwnerAssignor: true,
    //     isAssignee: false,
    // });

    const messageForm: MessageForm = {
        message: null,
        isOwnerAssignor: false,
        isAssignee: false,
    };

    if (!currentUser.loading && currentUser.data) {
        if (
            (props.data.ownerGuidID !== currentUser.data.appUser.userId && props.data.assignedByGuidID !== currentUser.data.appUser.userId) &&
            props.data.assignedToGuidID !== currentUser.data.appUser.userId
        ) {
            messageForm.isAssignee = true;
            messageForm.isOwnerAssignor = true;
        } else if (props.data.ownerGuidID === currentUser.data.appUser.userId || props.data.assignedByGuidID === currentUser.data.appUser.userId) {
            messageForm.isAssignee = true;
            messageForm.isOwnerAssignor = false;
        } else if (props.data.assignedToGuidID === currentUser.data.appUser.userId) {
            messageForm.isOwnerAssignor = true;
            messageForm.isAssignee = false;
        }
    }

    const onSubmit = (form: FormApi<MessageForm>, values: MessageForm | AnyObject) => {
        setState((prevState) => {
            return {
                ...prevState,
                onSubmitLoading: true,
            };
        });

        client
            .mutate({
                mutation: MESSAGE_TASK_MUTATION,
                variables: {
                    guidId: props.guid,
                    includeOwnerOrAssignor: values.isOwnerAssignor,
                    includeAssignee: values.isAssignee,
                    message: values.message,
                },
            })
            // tslint:disable-next-line: no-any
            .then((results: { data: any }) => {
                if (results.data) {
                    if (results.data.error === null || results.data.error === undefined) {
                        showNotification(null, 'Successfully submitted', 'info');
                        if (props.onClose) {
                            props.onClose();
                        }
                    } else {
                        showNotification('Failed to send message', results.data.error, 'error');
                    }

                    setState((prevState) => {
                        return {
                            ...prevState,
                            onSubmitLoading: false,
                        };
                    });
                }
            })
            // tslint:disable-next-line:no-any
            .catch((reason: any) => {
                showNotification('Failed to send message', reason, 'error');
                setState((prevState) => {
                    return {
                        ...prevState,
                        onSubmitLoading: false,
                    };
                });
            });
    };

    const closePanel = () => {
        if (state.isChanged) {
            setState((prevState) => {
                return {
                    ...prevState,
                    showDiscard: true,
                };
            });
        } else {
            if (props.onClose) {
                props.onClose();
            }
        }
    };

    // tslint:disable-next-line: no-any
    const onFormValueChanged = (form: FormApi<MessageForm>, changeProps: any) => {
        if (!changeProps.pristine) {
            setState((prevState) => {
                return {
                    ...prevState,
                    isChanged: true,
                };
            });
        }
    };

    const onClose = () => {
        if (state.isChanged) {
            setState((prevState) => {
                return {
                    ...prevState,
                    showDiscard: true,
                };
            });
        } else {
            if (props.onClose) {
                props.onClose();
            }
        }
    };

    const onDiscardChanges = (agree: boolean) => {
        if (agree) {
            setState((prevState) => {
                return {
                    ...prevState,
                    showDiscard: false,
                };
            });

            if (props.onClose) {
                props.onClose();
            }
        } else {
            setState((prevState) => {
                return {
                    ...prevState,
                    showDiscard: false,
                };
            });
        }
    };

    const onAssignedTypeChangedCheckbox =
            // tslint:disable-next-line: no-any max-line-length
            (name: string, formApi: FormApi<MessageForm>, input: FieldInputProps<any, HTMLElement>, values: MessageForm | AnyObject) =>
            (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
                input.onChange(checked);
                formApi.setConfig('keepDirtyOnReinitialize', false);

                formApi.batch(() => {
                    if (name === 'isOwnerAssignor') {
                        if (!values.isAssignee && !checked) {
                            formApi.change('isAssignee', true);
                        }
                    } else {
                        if (!values.isOwnerAssignor && !checked) {
                            formApi.change('isOwnerAssignor', true);
                        }
                    }
                });

                formApi.setConfig('keepDirtyOnReinitialize', false);
            };

    // tslint:disable-next-line: no-any
    const required = (value: any) => {
        return value ? undefined : 'Required';
    };

    const actionButton = (
        // tslint:disable-next-line: no-any
        form: FormApi<MessageForm>,
        submitting: boolean,
        pristine: boolean,
        // tslint:disable-next-line: no-any
        values: AnyObject,
    ) => {
        return (
            <div className={classes.buttonContainer}>
                {/* <RenderCount /> */}
                <div>
                    <Button
                        color="primary"
                        type="submit"
                        className={classes.button}
                        onClick={() => {
                            // code here..
                        }}
                        disabled={submitting || pristine || state.onSubmitLoading}
                    >
                        Send
                    </Button>
                </div>
                <div>
                    <Button color="primary" type="button" className={classes.button} onClick={closePanel}>
                        Cancel
                    </Button>
                </div>
            </div>
        );
    };

    const formComponent = () => {
        if (currentUser.loading) {
            return (
                <div className={classes.loaderWrapper}>
                    <RvLoader />
                </div>
            );
        }

        return (
            <Form
                onSubmit={(values, form: FormApi<MessageForm>) => onSubmit(form, values)}
                initialValues={messageForm}
                subscription={{ submitting: true, pristine: true }}
                validate={(values) => {
                    let errors = {
                        isOwnerAssignor: '',
                        isAssignee: '',
                        message: '',
                    };

                    if (!values.message) {
                        errors.message = 'Required';
                    }

                    if (!values.isAssignee && !values.isOwnerAssignor) {
                        errors.isAssignee = 'Atleast one is required';
                        errors.isOwnerAssignor = 'Atleast one is required';
                    }

                    if (errors.isAssignee === '' && errors.isOwnerAssignor === '' && errors.message === '') {
                        return undefined;
                    }

                    return errors;
                }}
                render={({ handleSubmit, form, submitting, pristine, values }) => (
                    <form onSubmit={handleSubmit} id="taskForm">
                        <FormSpy
                            subscription={{ pristine: true, values: true }}
                            // tslint:disable-next-line: no-shadowed-variable
                            onChange={(props) => {
                                onFormValueChanged(form, props);
                            }}
                        />
                        <DialogTitle className={classes.header}>
                            <div className={classes.headerCol}>
                                <div>Message</div>
                                <FormSpy subscription={{ values: true, validating: true }}>
                                    {/* tslint:disable-next-line: no-shadowed-variable */}
                                    {({ values }: { values: MessageForm }) => (
                                        <>
                                            <Field name="isOwnerAssignor" subscription={{ touched: true, error: true, value: true }}>
                                                {({ input, meta }) => (
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                {...input}
                                                                checked={input.value}
                                                                onChange={onAssignedTypeChangedCheckbox('isOwnerAssignor', form, input, values)}
                                                                checkedIcon={<CheckBoxIcon color="primary" />}
                                                            />
                                                        }
                                                        label="Owner/Assignor"
                                                        labelPlacement="start"
                                                    />
                                                )}
                                            </Field>
                                            <Field name="isAssignee" subscription={{ touched: true, error: true, value: true }}>
                                                {({ input, meta }) => (
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                {...input}
                                                                checked={input.value}
                                                                onChange={onAssignedTypeChangedCheckbox('isAssignee', form, input, values)}
                                                                checkedIcon={<CheckBoxIcon color="primary" />}
                                                            />
                                                        }
                                                        label="Assignee"
                                                        labelPlacement="start"
                                                    />
                                                )}
                                            </Field>
                                        </>
                                    )}
                                </FormSpy>
                            </div>
                            {props.data.assignedToName && (
                                <div className={`${classes.headerCol} ${classes.alignRight}`}>
                                    <Typography>Assigned to {props.data.assignedToName}</Typography>
                                </div>
                            )}
                        </DialogTitle>
                        <DialogContent dividers={true}>
                            <Grid container={true} spacing={3}>
                                <Grid item={true} xs={12} md={12}>
                                    <Field name="message" validate={required} subscription={{ touched: true, error: true, value: true }}>
                                        {({ input, meta }) => (
                                            <TextField
                                                {...input}
                                                label="Message"
                                                required={true}
                                                multiline={true}
                                                className={classes.textField}
                                                error={meta.error && meta.touched}
                                                autoComplete="abcd"
                                                helperText={meta.error && meta.touched ? meta.error : ''}
                                            />
                                        )}
                                    </Field>
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions>{actionButton(form, submitting, pristine, values)}</DialogActions>
                    </form>
                )}
            />
        );
    };
    return (
        <>
            <DialogBox
                title="Message"
                // tslint:disable-next-line:max-line-length
                content={`Are you sure you want to close the form?`}
                show={state.showDiscard}
                isAgree={onDiscardChanges}
                disAgreeLabel={'No'}
                agreeLabel={'Yes'}
            />
            <Dialog open={props.open} onClose={onClose} maxWidth="sm" fullWidth={true} className={classes.dialogRoot} scroll={'paper'}>
                {formComponent()}
            </Dialog>
        </>
    );
};

const MESSAGE_TASK_MUTATION = gql`
    mutation messageTask($guidId: String, $includeOwnerOrAssignor: Boolean, $includeAssignee: Boolean, $message: String) {
        messageTask(guidId: $guidId, includeOwnerOrAssignor: $includeOwnerOrAssignor, includeAssignee: $includeAssignee, message: $message)
    }
`;
