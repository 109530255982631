/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import {
    Button,
    Dialog,
    DialogTitle,
    DialogActions,
    DialogContent,
    Grid,
} from '@material-ui/core';
import { AnyObject, Field, Form, FormSpy } from 'react-final-form';
import { FormApi } from 'final-form';
import { RvLoader } from '../../components/Loader';
import { IAutoCompleteItem } from '../../typings/autoComplete';
import DialogBox from '../../Dashboard/Component/DialogBox';
import { TaskAssignedBySelector } from '../selectors/TaskAssignedBySelector';
import { ChangeTaskOwner, retrieveTaskDetailsData, TaskDetailsData, TaskDetailsParam } from '../TasksSummaryRepository';
import { showNotification } from '../../App';
import { client } from '../..';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        dialogRoot: {
            '& .downshift-wrapper': {
                flex: 1,
                padding: 0,
            }
        },
        header: {
            textTransform: 'capitalize',
        },
        textField: {
            width: '100%',
        },
        content: {
            flex: 1
        },
        buttonContainer: {
            display: 'flex',
            justifyContent: 'space-between',
            // paddingBottom: '20px'
        },
        button: {
            margin: theme.spacing(1),
            minWidth: '83px',
        },
        hidden: {
            display: 'none',
        },
        loaderWrapper: {
            display: 'flex',
            justifyContent: 'center',
            padding: '20px 0',
        },
        clientSelector: {
            flex: 1
        },
    })
);

interface OwnerFormDialogProps {
    open: boolean;
    onClose?: () => void;
    title?: string;
    guid: string;
}

interface OwnerFormDialogState {
    open: boolean;
    isLoading: boolean;
    onSubmitLoading: boolean;
    showDiscard: boolean;
    isChanged: boolean;
}

interface OwnerForm {
    owner: IAutoCompleteItem | null;
}

export const OwnerFormDialog: React.FC<OwnerFormDialogProps> = (props) => {
    const classes = useStyles();

    const [state, setState] = useState<OwnerFormDialogState>({
        open: false,
        isLoading: false,
        onSubmitLoading: false,
        showDiscard: false,
        isChanged: false
    });

    const [ownerForm, setOwnerForm] = useState<OwnerForm>({
        owner: null,
    });

    useEffect(() => {

        if (props.guid) {
            fetchTaskDetails(props.guid);
        }
        setState((prevState) => {
            return {
                ...prevState,
                open: props.open
            };
        });
    // tslint:disable-next-line: align
    }, [props.open, props.guid]);

    const fetchTaskDetails = (guid: string) => {

        setState((prevState) => {
            return {
                ...prevState,
                isLoading: true
            };
        });

        const taskDetailsParams: TaskDetailsParam = {
            guid: guid
        };

        retrieveTaskDetailsData(
            taskDetailsParams,
            true,
            // tslint:disable-next-line: no-console
            (data: TaskDetailsData) => onTaskDetailsRetrieved(data),
            // tslint:disable-next-line: no-any
            function (reason: any): void {
                showNotification(null, reason, 'error');
            }
        );
    };

    const onTaskDetailsRetrieved = (data: TaskDetailsData) => {
        // tslint:disable-next-line: no-console

        if (data && data.task && data.task.detail) {

            const taskDetails = data.task.detail;
            
            setOwnerForm((prevOwnerForm) => {
                return {
                    ...prevOwnerForm,
                    // tslint:disable-next-line: max-line-length
                    owner: taskDetails.owner && taskDetails.ownerName 
                            ? {label: taskDetails.ownerName, value: taskDetails.owner} 
                            : null
                };
            });
        }

        setState((prevState) => {
            return {
                ...prevState,
                isLoading: false
            };
        });
    };

    const onSubmit = (form: FormApi<OwnerForm>, values: AnyObject) => {
        setState((prevState) => {
            return {
                ...prevState,
                onSubmitLoading: true
            };
        });

        client
        .mutate({
            mutation: ChangeTaskOwner,
            variables: {
                guid: props.guid, 
                owner: values.owner ? values.owner.value : null
            },
        })
        // tslint:disable-next-line: no-any
        .then((results: { data: any }) => {
            if (results.data) {
                if (results.data.error === null || results.data.error === undefined) {
                    showNotification(null, 'Successfully submitted', 'info');     
                    closePanel();                   
                } else {
                    showNotification('Failed to update owner', results.data.error, 'error');
                }

                setState((prevState) => {
                    return {
                        ...prevState,
                        onSubmitLoading: false
                    };
                });
            }
        })
        // tslint:disable-next-line:no-any
        .catch((reason: any) => {
            showNotification('Failed to update owner', reason, 'error');
            setState((prevState) => {
                return {
                    ...prevState,
                    onSubmitLoading: false
                };
            });
        });
    };

    const closePanel = () => {
        // codepanel
        if (props.onClose) {
            return props.onClose();
        }
    };

    const onReset = () => {
        // reset
    };

    // tslint:disable-next-line: no-any
    const onFormValueChanged = (form: FormApi<OwnerForm>, changeProps: any) => {
        if (!changeProps.pristine) {
            setState((prevState) => {
                return {
                    ...prevState,
                    isChanged: true
                };
            });
        }
    };

    const onClose = () => {
        if (state.isChanged) {
            setState((prevState) => {
                return {
                    ...prevState,
                    showDiscard: true
                };
            });
        } else {
            if (props.onClose) {
                props.onClose();
            }
        }
    };

    const onDiscardChanges = (agree: boolean) => {
        if (agree) {
            setState((prevState) => {
                return {
                    ...prevState,
                    showDiscard: false,
                };
            });

            if (props.onClose) {
                props.onClose();
            }

        } else {
           setState((prevState) => {
               return {
                   ...prevState,
                   showDiscard: false
               };
           });
        }
    };

    // tslint:disable-next-line: no-any
    const required = (value: any) => {
        return value ? undefined : 'Required';
    };

    const actionButton = (
        // tslint:disable-next-line: no-any
        form: FormApi<OwnerForm>,
        submitting: boolean,
        pristine: boolean,
        // tslint:disable-next-line: no-any
        values: AnyObject
    ) => {
        return (
            <div className={classes.buttonContainer}>
                {/* <RenderCount /> */}
                <div>
                    <Button
                        color="primary"
                        type="submit"
                        className={classes.button}
                        onClick={() => {
                            // code here..
                        }}
                        disabled={submitting || pristine || state.onSubmitLoading}
                    >
                        Update
                    </Button>
                </div>
                <div>
                    <Button
                        id="resetButton"
                        type="button"
                        onClick={onReset}
                        disabled={submitting || pristine}
                        className={classes.hidden}
                    >
                        Reset
                    </Button>
                    <Button
                        color="primary"
                        type="button"
                        className={classes.button}
                        onClick={closePanel}
                    >
                        Cancel
                    </Button>
                </div>
            </div>
        );
    };

    return (
        <>
            <DialogBox
                title="Owner"
                // tslint:disable-next-line:max-line-length
                content={`Are you sure you want to close the form?`}
                show={state.showDiscard}
                isAgree={onDiscardChanges}
                disAgreeLabel={'No'}
                agreeLabel={'Yes'}
            />
            
            <Dialog
                open={state.open}
                onClose={onClose}
                maxWidth="sm"
                fullWidth={true}
                className={classes.dialogRoot}
                scroll={'paper'}
            >
                <DialogTitle className={classes.header}>{props.title}</DialogTitle>
                {state.isLoading ? (
                    <DialogContent dividers={true}>
                        <div className={classes.loaderWrapper}>
                            <RvLoader size="small" label="Loading..."/>
                        </div>
                    </DialogContent>
                ) : (
                    <Form
                        onSubmit={(values, form: FormApi<OwnerForm>) => onSubmit(form, values)}                
                        initialValues={ownerForm}
                        subscription={{ submitting: true, pristine: true }}
                        render={({handleSubmit, form, submitting, pristine, values}) => (
                            <form onSubmit={handleSubmit} id="taskForm">
                                <FormSpy 
                                    subscription={{ pristine: true, values: true }}
                                    // tslint:disable-next-line: no-shadowed-variable
                                    onChange={props => {
                                        onFormValueChanged(form, props);
                                    }}
                                />
                                <DialogContent dividers={true}>
                                    <Grid container={true} spacing={3}>
                                        <Grid item={true} xs={12} md={6}>
                                            <Field
                                                name="owner"
                                                validate={form.getFieldState('owner')?.value !== null ? required : undefined}
                                            >
                                                {({ input, meta }) => (
                                                    <TaskAssignedBySelector
                                                        {...input}
                                                        name="owner"
                                                        label="Owner"
                                                        value={input.value ? input.value : undefined}
                                                        disablePortal={false}
                                                        required={true}
                                                        className={`${classes.textField} ${classes.clientSelector}`}
                                                        error={meta.error && meta.touched}
                                                            // tslint:disable-next-line: jsx-alignment
                                                        onSelection={(selection: IAutoCompleteItem, name: string) => 
                                                            input.onChange(selection)
                                                        }
                                                        helperText={
                                                            meta.error &&
                                                            meta.touched
                                                                ? 'Assigned to is required'
                                                                : ''
                                                        }
                                                        visible={{
                                                            role: true
                                                        }}
                                                    />
                                                )}
                                            </Field>
                                        </Grid>
                                    </Grid>
                                </DialogContent>
                                <DialogActions>
                                    {actionButton(form, submitting, pristine, values)}
                                </DialogActions>
                            </form>
                        )} 
                    />
                )}
            </Dialog>
        </>
    );
};
