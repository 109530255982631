import React, { useState } from 'react';

export const TasksSummaryContext = React.createContext<Partial<TasksSummaryTypes>>({});

export interface TasksSummaryTypes {
    isFormOpen: boolean;
    isContextRefreshData: boolean;
    isContextRefreshTaskForm?: boolean;
    isContextRefreshDataOnFormClose: boolean;
    // tslint:disable-next-line: no-any
    onClickFormOpen(): void;
    onClickFormClosed(isRefreshData: boolean): void;
    setIsContextRefreshData(isRefreshData: boolean): void;
    setIsContextRefreshTaskForm(isRefreshData: boolean): void;
    setRefreshDataOnFormClose(isRefreshData: boolean): void;
}

interface ContextProps {}

export interface ContextState {
    isContextRefreshData: boolean;
    // isContextRefreshTaskForm?: boolean;
}

export const TasksSummaryContextProvider: React.FC<ContextProps> = (props) => {

    const [state, setState] = useState<ContextState>({
        isContextRefreshData: false,
        // isContextRefreshTaskForm: undefined
    });

    const [isFormOpen, setIsFormOpen] = useState<boolean>(false);
    const [isContextRefreshDataOnFormClose, setIsContextRefreshDataOnFormClose] = useState<boolean>(false);
    const [isContextRefreshTaskForm, setIsContextRefreshTaskForm] = useState<boolean>(false);

    // tslint:disable-next-line: no-any
    const onClickFormOpen = () => {
        // tslint:disable-next-line: no-console
        console.log('onClickFormOpen - true');
        // tslint:disable-next-line: no-console

        setIsFormOpen(true);
    };

    const onClickFormClosed = (isRefreshData: boolean) => {
        // tslint:disable-next-line: no-console
        console.log('onClickFormOpen - fa;se');
        setIsFormOpen(false);

        setState((prevState) => {
            return {
                ...prevState,
                isContextRefreshData: isRefreshData
            };
        });
    };

    const setIsContextRefreshData = (isRefreshData: boolean) => {
        setState((prevState) => {
            return {
                ...prevState,
                isContextRefreshData: isRefreshData
            };
        });
    };

    // const setIsContextRefreshTaskForm = (isRefreshData: boolean) => {
    //     setState((prevState) => {
    //         return {
    //             ...prevState,
    //             isContextRefreshTaskForm: isRefreshData
    //         };
    //     });
    // };

    const setRefreshDataOnFormClose = (isRefreshData: boolean) => {
        setIsContextRefreshDataOnFormClose(isRefreshData);
    };

    return (
        <TasksSummaryContext.Provider
            value={{
                ...state,
                isFormOpen,
                isContextRefreshDataOnFormClose,
                isContextRefreshTaskForm,
                setIsContextRefreshTaskForm: setIsContextRefreshTaskForm,
                onClickFormOpen: onClickFormOpen,
                onClickFormClosed: onClickFormClosed,
                setIsContextRefreshData: setIsContextRefreshData,
                setRefreshDataOnFormClose: setRefreshDataOnFormClose
            }}
        >
            {props.children}
        </TasksSummaryContext.Provider>
    );    
};