/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef } from 'react';
import { makeStyles, Theme, createStyles, Avatar, Tooltip, withStyles, Paper } from '@material-ui/core';
import moment from 'moment';
import { mainTheme } from '../../Theme';
import CheckOutlinedIcon from '@material-ui/icons/CheckOutlined';
import { Colors, hashString } from '../board/BoardCardDraggable';
import HistoryOutlinedIcon from '@material-ui/icons/HistoryOutlined';
import TextsmsOutlinedIcon from '@material-ui/icons/TextsmsOutlined';
import AssignmentIndOutlinedIcon from '@material-ui/icons/AssignmentIndOutlined';
import PeopleOutlineOutlinedIcon from '@material-ui/icons/PeopleOutlineOutlined';
import { ActivityText, AllocationHistory, TaskActivity, Detail } from '../TasksSummaryRepository';
import _ from 'lodash';
import PlayArrowOutlinedIcon from '@material-ui/icons/PlayArrowOutlined';
import Clear from '@material-ui/icons/Clear';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: '20px 0',
            flex: 1,
            display: 'flex',
            flexFlow: 'column',
            overflowY: 'auto',
            marginBottom: '10px',
            // maxWidth: '500px',
            position: 'relative',
        },
        historyContainer: {
            display: 'flex',
            alignItems: 'center',
            margin: '0 -10px',
            width: '100%',
            padding: '10px 0',
            position: 'relative',
            '& > div': {
                padding: '0 10px',
            },
            '& .connector': {
                borderLeft: '1px solid #bdbdbd',
                position: 'absolute',
                top: 0,
                bottom: 0,
                left: '30px',
            }
        },
        historyIcon: {
            
        },
        taskDetails: {
            // flex: 1,
            // maxWidth: '150px',
            minWidth: '130px',
            minHeight: '100%',
            display: 'flex',
            flexFlow: 'column',
            justifyContent: 'center',
            '& .description': {
                fontWeight: 600,
                marginBottom: '3px',
                color: mainTheme.BrandColors.TemplatePrimary
            },
            '& .date': {
                fontStyle: 'italic',
                color: '#a7a7a7'
            },
            '& .due-date': {
                color: '#fcba03'
            },
            '& .overdue': {
                color: 'red',
            },
            '& .status': {
                fontStyle: 'italic',
                color: '#a7a7a7'
            }
        },
        createClient: {
            // backgroundColor: mainTheme.UtilityColors.Positive
            backgroundColor: '#cfa2a1'
        },
        assignedEnquiry: {
            // backgroundColor: mainTheme.UtilityColors.Neutral
            backgroundColor: '#a8dda8'
        },
        followUp: {
            // backgroundColor: mainTheme.UtilityColors.Negative
            backgroundColor: '#bdb2d1'
        },
        enquiryCreated: {
            backgroundColor: 'orange'
        },
        contentDetails: {
            flex: 1,
            '& .title': {
                fontSize: 14,
                fontWeight: 300,
                color: '#000',
                marginBottom: 5,
            },
            '& .description': {
                marginTop: 10,
                fontSize: 14,
                color: '#000'
            },
            '& .author': {
                fontStyle: 'italic',
                fontSize: 12,
                '& span': {
                    fontWeight: 600
                }
            }
        },
        detailsContainer: {
            padding: '10px !important',
            width: '100%',
            position: 'relative',
            // '& span': {
            //     fontWeight: 600,
            //     fontStyle: 'italic'
            // }
        },
        dialogRoot: {
            '& .downshift-wrapper': {
                flex: 1,
                padding: 0,
            }
        },
        header: {
            textTransform: 'capitalize',
            '& .MuiTypography-root': {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
            }
        },
        dialogPaper: {
            height: '100%',
        },
        emailContentButton: {
            textAlign: 'end',
            position: 'absolute',
            right: 10,
            top: 10,
        },
        emailDetails: {
            textAlign: 'start',
            paddingRight: 80,
            '& .emailDescription': {
                color: '#000',
                fontSize: 14
            }
        },
        newLine: {
            'white-space': 'pre-line !important'
        }
    })
);

export enum TaskHistoryActivityWidth {
    FULL_WIDTH = 0,
    NORMAL
}

interface TaskHistoryActivityProps {
    className?: string;
    taskGuid?: string;
    taskDetail: Detail;
    width?: TaskHistoryActivityWidth;
    autoScroll?: boolean;
}

interface TaskActivityHistory {
    // Activity Text
    activityDate?: Date | null;
    activityTextCreatedByGuid?: string | null;
    activityTextCreatedByName?: string | null;
    text?: string | null; 
    message?: string | null;
    messagedTo?: string | null;

    // Allocation History
    createdDate: Date;
    allocatedByUserID?: string | null;
    allocatedByName?: string | null;
    allocatedUserID?: string | null;
    allocatedUserName?: string | null;
    allocatedTeamID?: string | null;
    allocatedTeamName?: string | null;

    // Task Activity
    activitytypeId?: string | null;
    activityTypeDescription?: string | null;
    activityByUserId?: string | null;
    activityByUser?: string | null;
}

// tslint:disable-next-line: no-anyW
export const TaskHistoryActivity: React.FC<TaskHistoryActivityProps> = props => {
    
    const classes = useStyles();

    // tslint:disable-next-line: no-any
    const messagesEndRef = useRef<any>(null);

    const scrollToBottom = () => {
        if (messagesEndRef && messagesEndRef.current) {
            messagesEndRef.current.scrollIntoView({ 
                behavior: 'smooth',
            });
        }
    };
    
    const stringToColour = (str: string | null) => {
        return Colors[(str === '' || str === null) ? Colors[Colors.length] : (hashString(str) % Colors.length) - 1];
    };

    const showDateEndOfFromNow = (value: Date | string) => {
        return moment(value).fromNow();
    };

    const formatDate = (date: Date | string, format: string) => {
        return moment(date).format(format);
    };

    const getWidth = () => {
        if (props.width === TaskHistoryActivityWidth.NORMAL) {
            return 'initial';
        } else if (props.width === TaskHistoryActivityWidth.FULL_WIDTH) {
            return 'auto';
        } else {
            return 'initial';
        }
    };

    const LightTooltip = withStyles((theme: Theme) => ({
        arrow: {
            color: theme.palette.common.white,
            '&::before': {
                // backgroundColor: 'blue',
                boxShadow: theme.shadows[1],
            }
        },
        tooltip: {
          backgroundColor: theme.palette.common.white,
          color: 'rgba(0, 0, 0, 0.87)',
          boxShadow: theme.shadows[1],
          fontSize: 11,
        },
    }))(Tooltip);

    const convertMentionInCommentToHtml = (comment: string, label?: string) => {
        let regex = /@\[.+?\]\(.+?\)/gm;
        let displayRegex = /@\[.+?\]/g;
        let idRegex = /\(.+?\)/g;
        let matches = comment.match(regex);
        // tslint:disable-next-line: no-any
        let arr: any = [];
        
        // tslint:disable-next-line: no-unused-expression
        matches &&
            // tslint:disable-next-line: no-any
            matches.forEach((m: any) => {
                let id = m.match(idRegex)[0].replace('(', '').replace(')', '');
                let display = m.match(displayRegex)[0].replace('@[', '').replace(']', '');

                arr.push({ id: id, display: display });
            });
        let newComment = comment.split(regex);
        let output = label ? label + ': ' : '';
        for (let i = 0; i < newComment.length; i++) {
            const c = newComment[i];
            if (i === newComment.length - 1) {
                output += c;
            } else {
                output += c + `<span class="mentioned" mentioned_id="${arr[i].id}">${arr[i].display.split('|')[0]}</span>`;
            }
        }

        return output;
    }; 
    
    const getHistoryAvatar = (taskActivityHistory?: TaskActivityHistory, taskDetail?: Detail) => {
        if (taskActivityHistory && taskActivityHistory.text && taskActivityHistory.activityTextCreatedByName) {
            return (
                <Avatar style={{backgroundColor: stringToColour(taskActivityHistory.activityTextCreatedByName)}}>
                    <TextsmsOutlinedIcon/>
                </Avatar> 
            );        
        } else if (taskActivityHistory && taskActivityHistory.activityTextCreatedByName) {
            return (
                <Avatar style={{backgroundColor: stringToColour(taskActivityHistory.activityTextCreatedByName)}}>
                    <HistoryOutlinedIcon />
                </Avatar> 
            );
        } else if (taskActivityHistory && taskActivityHistory.allocatedUserName) {
            return (
                <Avatar style={{backgroundColor: stringToColour(taskActivityHistory.allocatedUserName)}}>
                    <AssignmentIndOutlinedIcon />
                </Avatar> 
            );            
        } else if (taskActivityHistory && taskActivityHistory.allocatedTeamName) {
            return (
                <Avatar style={{backgroundColor: stringToColour(taskActivityHistory.allocatedTeamName)}}>
                    <PeopleOutlineOutlinedIcon />
                </Avatar> 
            );            
        } else if (taskDetail && taskActivityHistory?.activitytypeId === '2') {
            return (
                <Avatar style={{backgroundColor: 'grey'}}>
                    <CheckOutlinedIcon />
                </Avatar> 
            );
        } else if (taskDetail && taskActivityHistory?.activitytypeId === '0') {
            return (
                <Avatar style={{backgroundColor: 'grey'}}>
                    <PlayArrowOutlinedIcon />
                </Avatar> 
            );
        } else if (taskDetail && taskActivityHistory?.activitytypeId === '1') {
            return (
                <Avatar style={{backgroundColor: 'grey'}}>
                    <Clear />
                </Avatar> 
            );
        } else {
            return null;
        }
    };

    const getHistoryDate = (taskActivityHistory?: TaskActivityHistory, taskDetail?: Detail) => {
        if (taskActivityHistory && taskActivityHistory.createdDate) {
            return (
                <LightTooltip 
                    arrow={true}
                    title={formatDate(taskActivityHistory.createdDate, 'Do MMM YYYY hh:mm a')} 
                >
                    <div className="date">
                        {showDateEndOfFromNow(taskActivityHistory.createdDate)}
                    </div>
                </LightTooltip>
            );
        } else {
            return '';
        }
    };

    const getDescription = (taskActivityHistory?: TaskActivityHistory, taskDetail?: Detail) => {
        if (taskActivityHistory && taskActivityHistory.message) {
            return 'Message';
        } else if (taskActivityHistory && taskActivityHistory.text) {
            return 'Notes';
        } else if (taskActivityHistory && taskActivityHistory.allocatedByName && (taskActivityHistory.allocatedUserName || taskActivityHistory.allocatedTeamID)) {
            return 'Assigned to';
        } else if (taskActivityHistory && taskActivityHistory.activitytypeId === '2') {
            return 'Completed';
        } else if (taskActivityHistory && taskActivityHistory.activitytypeId === '0') {
            return 'Started';
        } else if (taskActivityHistory && taskActivityHistory.activitytypeId === '1') {
            return 'Stopped';
        } else {
            return '';
        }
    };

    const activityContent = (taskActivityHistory?: TaskActivityHistory, taskDetail?: Detail) => {        
        return (
            <>                
                <div className={classes.historyContainer}>
                    <div className="connector" />
                    <div className={classes.historyIcon}>
                        {getHistoryAvatar(taskActivityHistory, taskDetail)}
                    </div>
                    <div className={classes.taskDetails}>
                        <div className="description">
                            {getDescription(taskActivityHistory, taskDetail)}
                        </div>
                        {getHistoryDate(taskActivityHistory, taskDetail)}
                    </div>
                    <div className={classes.contentDetails} style={{ flex: getWidth() }}>
                        {getDetailContent(taskActivityHistory, taskDetail)}
                    </div>
                </div>                
            </>
        );
    };

    const getDetailContent = (taskActivityHistory?: TaskActivityHistory, taskDetail?: Detail) => {

        if (taskActivityHistory && taskActivityHistory.message) {
            return (
                <Paper elevation={3} className={classes.detailsContainer}>
                    <h5 className="title">
                        {taskActivityHistory.messagedTo}
                    </h5>                    
                    <div style={{whiteSpace: 'pre-line', marginTop: 0}} className="description">
                        {taskActivityHistory.message}
                    </div>               
                </Paper>
            );
        } else if (taskActivityHistory && taskActivityHistory.text) {
            return (
                <Paper elevation={3} className={classes.detailsContainer}>
                    {/* <h5 className="title">
                        Activity log
                    </h5>                     */}
                    <h5 className="title">
                        {taskActivityHistory.messagedTo}
                    </h5>   
                    <div style={{whiteSpace: 'pre-line', marginTop: 0}} className="description">
                        {taskActivityHistory.text}
                    </div>               
                </Paper>
            );
        } else if (taskActivityHistory && taskActivityHistory.allocatedByName && taskActivityHistory.allocatedUserName) {
            return (
                <Paper elevation={3} className={classes.detailsContainer}>
                    <h5 className="title">
                        Assigned to <span>{taskActivityHistory.allocatedUserName}</span> by <span>{taskActivityHistory.allocatedByName}</span>
                    </h5>             
                </Paper>
            );
        } else if (taskActivityHistory && taskActivityHistory.allocatedByName && taskActivityHistory.allocatedTeamName) {
            return (
                <Paper elevation={3} className={classes.detailsContainer}>
                    <h5 className="title">
                        Assigned to <span>{taskActivityHistory.allocatedTeamName}</span> by <span>{taskActivityHistory.allocatedByName}</span>
                    </h5>             
                </Paper>
            );
        } else if (taskDetail && taskActivityHistory?.activitytypeId === '2') {
            return (
                <>
                    <Paper elevation={3} className={classes.detailsContainer}>
                        <h5 className="title">
                            {taskActivityHistory.activityTypeDescription} by {taskActivityHistory.activityByUser}
                        </h5>
                        <h5 className="title">
                            Completion Notes:
                        </h5>
                        <div style={{whiteSpace: 'pre-line'}} className="description">
                            {taskDetail.completionNotes ?? 'None'}
                        </div>         
                    </Paper>
                </>
            );
        } else if (taskDetail && (taskActivityHistory?.activitytypeId === '0' || taskActivityHistory?.activitytypeId === '1')) {
            return (
                <Paper elevation={3} className={classes.detailsContainer}>
                    <h5 className="title">
                        {taskActivityHistory.activityTypeDescription} by {taskActivityHistory.activityByUser}
                    </h5>              
                </Paper>
            );
        } else {
            return undefined;
        }
    };

    const taskCreated = () => {
        return (
            <div className={classes.historyContainer}>
                <div className="connector" />
                <div className={classes.historyIcon}>
                    <Avatar style={{backgroundColor: '#007d00'}}>
                        <CheckOutlinedIcon />
                    </Avatar> 
                </div>
                <div className={classes.taskDetails}>
                    <div className="description">
                        Created
                    </div>
                    {props.taskDetail && props.taskDetail.createdDate && (
                        <LightTooltip 
                            arrow={true}
                            title={formatDate(props.taskDetail.createdDate, 'Do MMM YYYY hh:mm a')} 
                        >
                            <div className="date">
                                {showDateEndOfFromNow(props.taskDetail.createdDate)}
                            </div>
                        </LightTooltip>                        
                    )}
                </div>
                <div className={classes.contentDetails} style={{ flex: getWidth() }}>
                    <Paper elevation={3} className={classes.detailsContainer}>
                        {props.taskDetail.notes && (
                            <p 
                                className="description" 
                                dangerouslySetInnerHTML={{
                                    __html: convertMentionInCommentToHtml(props.taskDetail.notes)
                                }} 
                            />
                        )}
                        <div className="author">Created by: <span>{props.taskDetail.createdByName}</span></div>
                    </Paper>
                </div>
            </div>
        );
    };

    const activityTexts = JSON.parse(JSON.stringify(props.taskDetail.activityTexts));
    const allocationHistoryList = JSON.parse(JSON.stringify(props.taskDetail.allocationHistoryList));
    const taskActivityList = JSON.parse(JSON.stringify(props.taskDetail.taskActivity));

    // Combine into 1 List
    let taskHistoryData: TaskActivityHistory[] = [];

    activityTexts.forEach((activityText: ActivityText) => {
        taskHistoryData.push({
            activityDate: activityText.activityDate,
            activityTextCreatedByGuid: activityText.createdByGuid,
            activityTextCreatedByName: activityText.createdByName,
            text: activityText.text,
            createdDate: activityText.activityDate,
            message: activityText.message,
            messagedTo: activityText.messagedTo
        });
    });

    allocationHistoryList.forEach((allocationHistory: AllocationHistory) => {
        if (allocationHistory.allocatedUserName !== null || allocationHistory.allocatedTeamName !== null) {
            taskHistoryData.push({
                createdDate: allocationHistory.createdDate,
                allocatedByUserID: allocationHistory.allocatedByUserID,
                allocatedByName: allocationHistory.allocatedByName,
                allocatedUserID: allocationHistory.allocatedUserID,
                allocatedUserName: allocationHistory.allocatedUserName,
                allocatedTeamID: allocationHistory.allocatedTeamID,
                allocatedTeamName: allocationHistory.allocatedTeamName,
                activityDate: allocationHistory.createdDate        
            });
        }
    });

    taskActivityList.forEach((taskActivity: TaskActivity) => {
        if (taskActivity.activityDate && taskActivity.activityByUser && taskActivity.activityTypeID && taskActivity.activityTypeDescription ) {
            taskHistoryData.push({
                createdDate: taskActivity.activityDate,
                activitytypeId: taskActivity.activityTypeID,
                activityTypeDescription: taskActivity.activityTypeDescription,
                activityByUserId: taskActivity.activityByUserId,
                activityByUser: taskActivity.activityByUser,
            });
        }

    });

    const taskHistoryDataList = _.sortBy(taskHistoryData, (history: TaskActivityHistory) => {
        return new Date(history.createdDate);
    });

    // const taskHistoryDataList = taskHistoryData.sort((a: TaskActivityHistory, b: TaskActivityHistory) => {
    //     var dateA = new Date(a.createdDate);
    //     var dateB = new Date(b.createdDate);
    //     return dateA - dateB;
    // });

    if (props.autoScroll !== undefined && props.autoScroll === true) {
        setTimeout(scrollToBottom, 1000);
    }

    return (
        <div className={`${classes.root} ${props.className}`}>
            {taskCreated()}
            
            {
                taskHistoryDataList.map((taskActivityHistory: TaskActivityHistory) => activityContent(taskActivityHistory, props.taskDetail))
            }
            {/* {activityTexts.map((activityText: ActivityText) => activityContent(activityText, props.taskDetail))} */}

            {/* Display Completed details */}
            {activityContent(undefined, props.taskDetail)}
            <div ref={messagesEndRef} />
        </div>
    );
};
