import React, { useState } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
// import { name, random } from 'faker';
import gql from 'graphql-tag';
import { FetchPolicy } from 'apollo-client';
import PersonIcon from '@material-ui/icons/Person';
import PersonOutlinedIcon from '@material-ui/icons/PersonOutlined';
import { TextField, CircularProgress } from '@material-ui/core';
import { IAutoCompleteItem } from '../../typings/autoComplete';
import { showNotification } from '../../App';
import { client } from '../..';
import { InfiniteAutocomplete } from '../../components/InfiniteAutocomplete';
import { RenderInputParams } from '@material-ui/lab';
import { useDebouncedCallback } from 'use-debounce/lib';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
        minWidth: '200px',
    },
    textField: {
        width: '100%',
    },
    iconSelected: {
        width: 17,
        height: 17,
        marginRight: 5,
        marginLeft: -2,
      },
      color: {
        width: 14,
        height: 14,
        flexShrink: 0,
        borderRadius: 3,
        marginRight: 8,
        marginTop: 2,
      },
      text: {
        flexGrow: 1,
      },
      close: {
        opacity: 0.6,
        width: 18,
        height: 18,
      },
      renderWrapper: {
          padding: '15px 0'
      },
      customRenderWrapper: {
        display: 'flex',
        margin: '0 -10px',
        width: '100%',
        '& > div': {
            padding: '0 10px',
            fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
            fontWeight: 400,
        },
        '& .icon-wrapper': {
            display: 'flex',
            alignItems: 'center',
        },
        '& .other': {
            flex: 1,
            display: 'flex',
            flexFlow: 'column',
            '& .primary': {
                fontSize: '1rem',
                lineHeight: 1.5,
                letterSpacing: '0.00938em',
            },
            '& .secondary': {
                color: 'rgba(0, 0, 0, 0.54)',
                fontSize: '0.875rem',
                lineHeight: 1.43,
                letterSpacing: '0.01071em',
            },
            '& .third': {
                color: 'rgba(0, 0, 0, 0.54)',
                fontSize: '0.675rem',
                lineHeight: 1.43,
                letterSpacing: '0.01071em',
                fontWeight: 600
            }
        }
      }
  })
);

interface TaskAssignedBySelectorProps {
    isStaffOnly?: boolean;
    multiple?: boolean;
    isExternal?: boolean;
    label?: string;
    name: string;
    // tslint:disable-next-line: no-any
    value?: IAutoCompleteItem | any; // value only available in single select
    onSelection?: (value: IAutoCompleteItem | IAutoCompleteItem[], name: string) => void;
    id?: string;
    required?: boolean;
    helperText?: string;
    error?: boolean;
    className?: string;
    disablePortal?: boolean;
    visible?: {
        role: boolean;
    };
    isAuthorized?: boolean;
    onBlur?: React.FocusEventHandler<HTMLDivElement>;
}

interface TaskAssignedBySelectorState {
    hasNextPage: boolean;
    isNextPageLoading: boolean;
    offset: number;
    filter: string;
    // tslint:disable-next-line: no-any
    items: any;
}

export const TaskAssignedBySelector: React.FC<TaskAssignedBySelectorProps> = props => {
    const classes = useStyles();

    const loadBlockLimit = 20;
    
    const [state, setState] = useState<TaskAssignedBySelectorState>({
        hasNextPage: true,
        isNextPageLoading: false,
        offset: 0,
        filter: '',
        items: []
    });

    const [delayedonInputChange] = useDebouncedCallback(
        // function
        (event: React.ChangeEvent<{}>, value: string, reason: 'input' | 'reset' | 'clear') => {

            let isClear = false;
            let offset = 0;
            let filter = '';

            if (reason === 'input') {
                filter = value;
                isClear = true;

                fetchData(filter, offset, isClear);
            }
        },
        // delay in ms
        500
    );

    const fetchData = (filter: string, offset: number, isClear: boolean) => {

        setState((prevState) => {
            return {
                ...prevState,
                isNextPageLoading: true,
            };
        });

        const userQueryParams: UserQueryParams = {
            filter: filter,
            first: loadBlockLimit,
            offset: offset,
            isStaff: props.isStaffOnly ? props.isStaffOnly : undefined,
            isExternal: props.isExternal ? props.isExternal : false, // by default this should always return internal users
            isAuthorized: props.isAuthorized
        };

        retrieveUserListData(
            userQueryParams,
            false, // this.state.isRefresh, 
            // tslint:disable-next-line: no-console
            (data) => onRetrieveUserList(data, filter, offset, isClear),
            // tslint:disable-next-line:no-any
            function (reason: any): void {
                showNotification(null, reason, 'error');

                setState((prevState) => {
                    return {
                        ...prevState,
                        isNextPageLoading: false,
                    };
                });
            }
        );
    };

    const onRetrieveUserList = (data: UserListData, filter: string, offset: number, isClear: boolean) => {

        const userData = data.administrator.users.user;
        const recordCount = data.administrator.users.recordCount;

        // tslint:disable-next-line: no-any
        let itemsList: any = [];

        let items = state.items;

        if (!isClear) {
            itemsList = [...state.items];
        } else {
            items = [];
        }

        if (items.length === 0) {

            itemsList = userData.map( (source: User) => ({
                label: source.isStaff ? source.staffName : source.friendlyName,
                value: source.userID,                        
                isStaff: source.isStaff,
                email: source.email,
                roleName: source.roleName
            }));
                
            // items = userData.map( (source: User) => ({value: source.userID, label: source.isStaff ? source.staffName : source.friendlyName }));
        } else {
            for (const user of userData) {
                // tslint:disable-next-line: no-any
                const isExists = items.filter((item: any) => item.value.toLowerCase().includes(user.userID.toLowerCase())).length;

                if (isExists === 0) {
                    itemsList.push({
                        label: user.isStaff ? user.staffName : user.friendlyName,
                        value: user.userID,
                        isStaff: user.isStaff,
                        email: user.email,
                        roleName: user.roleName
                    });
                }
            }
        }

        setState((prevState) => {
            return {
                ...prevState,
                filter: filter,
                // items: itemsList,
                isNextPageLoading: false,
                items: itemsList,
                offset: offset + loadBlockLimit,
                hasNextPage: userData.length !== 0 && (offset + loadBlockLimit) < recordCount,
            };
        });
    };

    // tslint:disable-next-line: no-any
    const loadNextPage = (...args: any) => {
        if (state.hasNextPage && !state.isNextPageLoading) {
            fetchData(state.filter, state.offset, false);
        }
    };

    const onClose = (event: React.ChangeEvent<{}>) => {
        setState((prevState) => {
            return {
                ...prevState,
                isNextPageLoading: false,
                hasNextPage: true,
                filter: '',
                offset: 0,
                items: []
            };
        });
    };

    const onOpen = (event: React.ChangeEvent<{}>) => {        
        fetchData(state.filter, 0, true);
    };

    const onSelection = (selection: IAutoCompleteItem, name: string) => {
        if (props.onSelection) {
            props.onSelection(selection, name);
        }
    };

    const getInputProps = (params: RenderInputParams, isLoading?: boolean) => {
        return  ({   
            ...params.InputProps,
            endAdornment: (
                <React.Fragment>
                  {isLoading ? <CircularProgress color="primary" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),     
         });
    };

    return (
        <div className={`${classes.root}`}>
            <InfiniteAutocomplete
                id={props.id}
                itemSize={70}
                loading={state.isNextPageLoading}
                disablePortal={props.disablePortal}
                name={props.name}
                isMultiple={props.multiple}
                value={props.value}
                hasNextPage={state.hasNextPage}
                isNextPageLoading={state.isNextPageLoading}
                items={state.items}
                loadNextPage={loadNextPage}
                // disableCloseOnSelect={true}
                className={classes.textField}
                disableListWrap={true}
                onSelection={onSelection}
                onClose={onClose}
                onOpen={onOpen}
                getOptionLabel={(option: IAutoCompleteItem) => option.label}
                getOptionSelected={(option, value) => value.value === option.value}
                onInputChange={delayedonInputChange}
                onBlur={props.onBlur}
                renderOption={(option, { selected }) => {
                    return (
                        <div className={classes.customRenderWrapper}>
                            <div className="icon-wrapper">
                                {option.isStaff ? <PersonIcon fontSize="large"/> : <PersonOutlinedIcon fontSize="large"/>}
                            </div>
                            <div className="other">
                                <div className="primary">{option.label}</div>
                                <div className="secondary">{option.email}</div>
                                {!!props.visible && props.visible!.role && (
                                    <div className="third">{option.roleName}</div>
                                )}
                            </div>
                        </div>
                    );
                }}
                renderInput={params => (
                    <TextField
                        {...params}
                        variant="standard"
                        label={props.label}
                        fullWidth={true}
                        margin="none" 
                        required={props.required}
                        InputProps={getInputProps(params, state.isNextPageLoading)}
                        error={props.error}      
                        helperText={props.helperText}  
                    />
                )}
            />
        </div>
    );
};

export function retrieveUserListData(
    query: UserQueryParams,
    refreshData: boolean,
    onSuccess: (data: UserListData) => void,
    // tslint:disable-next-line:no-any
    onError: (reason: any) => void
): void {
    var fetchPolicy: FetchPolicy = refreshData === true ? 'network-only' : 'cache-first';
    client
        .query({
            query: UserListQuery,
            variables: {
                filter: query.filter,
                first: query.first,
                offset: query.offset,
                isStaff: query.isStaff,
                isExternal: query.isExternal,
                isAuthorized: query.isAuthorized
            },
            fetchPolicy: fetchPolicy,
        })
        // tslint:disable-next-line:no-any
        .then((results: { data: any }) => {
            onSuccess(results.data);
        })
        // tslint:disable-next-line:no-any
        .catch((reason: any) => {
            onError(reason);
        });
}

export interface UserQueryParams {
    offset?: number; 
    first?: number;
    filter?: string;
    isStaff?: boolean;
    isExternal?: boolean;
    isAuthorized?: boolean;
}

export interface UserListData {
    loading?: boolean;
    networkStatus?: number;
    administrator: Administrator;
}

export interface Administrator {
    users: UserList;    
}

export interface UserList {
    recordCount: number;
    user: User[];
}

export interface User {
    userID: string;
    email: string;
    friendlyName: string;
    roleName: boolean;
    isStaff: boolean;
    staffName: string;
    isExternal: boolean;
}

const UserListQuery = gql`
    query userQuery($offset: Int, $first: Int, $filter: String, $isStaff: Boolean, $isExternal: Boolean, $isAuthorized: Boolean){
        administrator{
            users(offset:$offset, first:$first, filter:$filter, isStaff: $isStaff, isExternal: $isExternal, isAuthorized: $isAuthorized){
                recordCount,
                user{
                    userID,
                    email,
                    friendlyName,
                    roleName
                    isStaff,
                    staffName,
                    isExternal
                }
            }
        }
    }
`;