/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { CurrencyOptionData, fetchAppUserData, fetchCurrencyData } from '../../matterManagement/MatterManagementRepository';
import { showNotification } from '../../App';
import { RvLoader } from '../../components/Loader';
import { AppUserData } from '../../enquirySummary/EnquirySummaryRepository';
import { IAutoCompleteItem } from '../../typings/autoComplete';
import { Detail, PriorityData, retrievePriorityData, retrieveTaskDetailsData, TaskDetailsData, TaskDetailsParam } from '../../tasksSummary/TasksSummaryRepository';
import { fetchMatterDetailsData } from '../../matterSummary/MatterSummaryRepository';
import { MatterData, MatterDetails } from '../../matterSummary/models/Matter';
import moment from 'moment';
import { BillTheMatterViewDialog } from '../viewDialog/BillTheMatterViewDialog';
import { WriteOffWipViewDialog } from '../viewDialog/WriteOffWipViewDialog';
import { WriteOffDebtorsViewDialog } from '../viewDialog/WriteOffDebtorsViewDialog';
import { TransferTrustToPayInvoiceViewDialog } from '../viewDialog/TransferTrustToPayInvoiceViewDialog';
import { ArchiveMatterViewDialog } from '../viewDialog/ArchiveMatterViewDialog';
import { CloseMatterViewDialog } from '../viewDialog/CloseMatterViewDialog';
import { GeneralViewDialog } from '../viewDialog/GeneralViewDialog';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
        },
        doneIcon: {
            marginRight: '10px',
        },
        loaderWrapper: {
            position: 'absolute',
            top: 0,
            bottom: 0,
            right: 0,
            left: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        }
    })
);

export interface CurrencyDetails {
    currencySymbol: string;
    currency: string;
}

interface MatterTaskViewRenderProps /* extends ICellRendererParams,*/ /*WithStyles<typeof styles>*/ {
    taskGuid: string;
    taskType: string;
    isSystemTask: boolean;
    taskDescription: string;
    onDialogClose?: () => void;
    status?: string | null;
}

interface MatterTaskViewRenderState {
    isBillMatterOpen: boolean;
    isWriteOffUnbilledFeesOpen: boolean;
    isTransferTrustToPayInvoiceOpen: boolean;
    isWriteOffDebtorsOpen: boolean;
    isCloseMatterOpen: boolean;
    isArchiveMatterOpen: boolean;
    isGeneralOpen: boolean;

    loggedInUser: IAutoCompleteItem | null;
    defaultPriority: IAutoCompleteItem | null;
}

// tslint:disable-next-line: no-any
export const MatterTaskViewRender: React.FC<MatterTaskViewRenderProps> = ( props ) => {
    const classes = useStyles();

    const [currencyData, setCurrencyData] = useState<CurrencyDetails>();
    const [taskData, setTaskData] = useState<Detail>();
    const [matterData, setMatterData] = useState<MatterDetails>();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [state, setState] = useState<MatterTaskViewRenderState>({
        isBillMatterOpen: props.taskType === 'BillMatterRequested' ? true : false,
        isWriteOffUnbilledFeesOpen: props.taskType === 'WriteOffMatterWIPRequested' ? true : false,
        isTransferTrustToPayInvoiceOpen: props.taskType === 'TransferMatterTrustToInvoiceRequested' ? true : false,
        isWriteOffDebtorsOpen: props.taskType === 'WriteOffMatterDebtorsRequested' ? true : false,
        isCloseMatterOpen: props.taskType === 'CloseMatterRequested' ? true : false,
        isArchiveMatterOpen: props.taskType === 'ArchiveMatterRequested' ? true : false,
        loggedInUser: null,
        defaultPriority: null,
        isGeneralOpen: (props.taskType === 'General' || props.isSystemTask === false ) ? true : false,
    });

    useEffect(() => {
        setIsLoading(true);
        fetchCurrencyDetails();
        fetchUser();
        fetchPriority();
        fetchTaskDetails(props.taskGuid);
    // tslint:disable-next-line: align
    }, []);

    const fetchCurrencyDetails = () => {
        if (currencyData === undefined) {
            fetchCurrencyData(false,
                              (data: CurrencyOptionData) => {
                                handleCurrencyFetch(data);
                               }, 
                              (reason) => {
                                    showNotification('Failed to Fetch App User Settings', reason, 'error'); 
                               });
        }
    };

    const handleCurrencyFetch = (data: CurrencyOptionData) => {
        if (data && !data.loading && data.options) {
            if (data.options.currencySymbol && data.options.localCurrency) {
                setCurrencyData({
                    currency: data.options.localCurrency.currency,
                    currencySymbol: data.options.currencySymbol.symbol
                });
            }
        }
    };

    const fetchUser = () => {
        fetchAppUserData(
            false,
            (data: AppUserData) => handleUserDataFetch(data),
            // tslint:disable-next-line: no-any
            function (reason: any): void {
                showNotification(null, reason, 'error');
            }
        );
    };

    const handleUserDataFetch = (data: AppUserData) => {
        setState((prevState) => {
            return {
                ...prevState,
                loggedInUser: { label: data.appUser.name, value: data.appUser.userId },
                isLoading: false
            };
        });
    };

    const fetchTaskDetails = (guid: string) => {
        const taskDetailsParams: TaskDetailsParam = {
            guid: guid
        };

        retrieveTaskDetailsData(
            taskDetailsParams,
            true,
            // tslint:disable-next-line: no-console
            (data: TaskDetailsData) => handleTaskDetailsFetch(data),
            // tslint:disable-next-line: no-any
            function (reason: any): void {
                showNotification(null, reason, 'error');
            }
        );
    };

    const handleTaskDetailsFetch = (data: TaskDetailsData) => {
        // tslint:disable-next-line: no-console

        if (data && data.task && data.task.detail) {
            setTaskData(data.task.detail);
            if (data.task.detail.matterId) {
                fetchMatterDetails(data.task.detail.matterId);
            } else {
                setIsLoading(false);
            }
        }
    };

    const fetchMatterDetails = (matterId: number) => {
        fetchMatterDetailsData(true, // This should always be a new request.
                               matterId,
                               (data: MatterData) => {
                                    handleMatterDataFetch(data);
                               }, 
                               (reason) => {
                                    showNotification('Failed to Fetch App User Settings', reason, 'error'); 
                               });
        
    };

    const handleMatterDataFetch = (data: MatterData) => {
        if (data && !data.loading && data.matter) {
            if (data.matter) {
                setMatterData(data.matter);

                setIsLoading(false);
            }
        }
    };

    const fetchPriority = () => {
        retrievePriorityData(
            false,
            // tslint:disable-next-line: no-console
            (data) => handlePriorityDataFetch(data),
            // tslint:disable-next-line:no-any
            function (reason: any): void {
                showNotification(null, reason, 'error');
            }
        );
    };

    const handlePriorityDataFetch = (data: PriorityData) => {
        // set the priority default data
        const priorityData = data.lookup.priority.filter(value => {
            return value.description === 'No Priority';
        });

        if (priorityData && priorityData.length > 0) {
            setState((prevState) => {
                return {
                    ...prevState,
                    defaultPriority: { label: priorityData[0].description, value: priorityData[0].guidId },
                    isLoading: false
                };
            });
        }        
    };

    const onDialogClose = () => {
        if (props.onDialogClose) {
            props.onDialogClose();
        }
    };

    // tslint:disable-next-line: no-shadowed-variable
    const getMatterTaskActions = () => {
        if (taskData && matterData && currencyData && taskData.taskTypeInternalDescription !== 'General' && props.isSystemTask === true) {
            const unBilledFees = matterData.financials ? matterData.financials.unbilledTimeAndFees : 0;
            const unbilledDisbursements =  matterData.financials ? matterData.financials.unbilledDisbursements : 0;
            const outstandingDebtors =  matterData.financials ? matterData.financials.debtors : 0;
            const currentTrustBalance = matterData.financials ? matterData.financials.trustBalance : 0;
            const billedFees = matterData.financials ? matterData.financials.billedTimeAndFees : 0;
            const feeEstimate = matterData.financials ? matterData.financials.feeEstimate : 0;
            const percentageEstimate = matterData.financials ? matterData.financials.percentageEstimate : 0;
            const pendingWriteOffFeesAmountInBillMatterTask = matterData.financials 
                                                                ? matterData.actions.pendingWriteOffFeesAmountInBillMatterTask 
                                                                : 0;
            const pendingWriteOffFeesAmountInWriteOffWipTask = matterData.financials 
                                                                ? matterData.actions.pendingWriteOffFeesAmountInWriteOffWipTask 
                                                                : 0;
            const pendingWriteOffDisbAmountInBillMatterTask = matterData.financials 
                                                                ? matterData.actions.pendingWriteOffFeesAmountInBillMatterTask 
                                                                : 0;
            const pendingWriteOffDisbAmountInWriteOffWipTask = matterData.financials 
                                                                ? matterData.actions.pendingWriteOffFeesAmountInWriteOffWipTask 
                                                                : 0;

            const matterTitle = `${matterData.fileNumber} - ${matterData.description}`;

            const assignedTo = taskData.assignedTo && taskData.assignedToName ? {label: taskData.assignedToName, value: taskData.assignedTo} : null;
            const owner = taskData.owner && taskData.ownerName ? {label: taskData.ownerName, value: taskData.owner} : null;
            const priority = taskData.priority && taskData.priorityDescription 
                                ? {label: taskData.priorityDescription, value: taskData.priority} 
                                : state.defaultPriority;
            const category = taskData.category && taskData.categoryDescription 
                                ? {label: taskData.categoryDescription, value: taskData.category} 
                                : null;
            const dueDate = taskData.dueDate;
            const estimatedEffort = taskData.estimatedEffort && taskData.estimatedEffort !== '00:00:00'
                                ? moment(taskData.estimatedEffort, 'HH:mm').format('HH:mm')
                                : '';
            const startDate = taskData.startDate;
            const completionDate =  taskData.completionDate;
            const duration = taskData.duration && taskData.duration !== '00:00:00'
                                ? moment(taskData.duration, 'HH:mm').format('HH:mm') 
                                : '';
            const assignedBy = taskData.assignedBy && taskData.assignedByName 
                                ? {label: taskData.assignedByName, value: taskData.assignedBy} 
                                : state.loggedInUser;

            return (
                <React.Fragment>
                    {state.isBillMatterOpen && (
                        <BillTheMatterViewDialog 
                            open={state.isBillMatterOpen}
                            currencyDetails={currencyData}
                            matterId={matterData.id}
                            matterGuid={matterData.matterGuid}
                            matterTitle={matterTitle}
                            client={matterData.client}
                            onClose={onDialogClose}
                            unBilledFees={unBilledFees}
                            unbilledDisbursements={unbilledDisbursements}
                            outstandingDebtors={outstandingDebtors}
                            currentTrustBalance={currentTrustBalance}
                            billedFees={billedFees}
                            feeEstimate={feeEstimate}
                            percentageEstimate={percentageEstimate}
                            pendingWriteOffFeesAmount={pendingWriteOffFeesAmountInWriteOffWipTask}
                            pendingWriteOffDisbAmount={pendingWriteOffDisbAmountInWriteOffWipTask}
                            loggedInUser={state.loggedInUser}
                            defaultPriority={state.defaultPriority}
                            formAction={'edit'}

                            // Saved Data
                            taskGuid={props.taskGuid}
                            feesAmount={taskData.billFeesAmount}
                            disbursementAmount={taskData.billDisbursementsAmount}
                            applyTrustAmount={taskData.applyTrustAmount}
                            writeOffFeesAmount={taskData.writeOffFeesAmount}
                            writeOffDisbursementAmount={taskData.writeOffDisbursementsAmount}
                            comments={taskData.comments}
                            assignedTo={assignedTo}
                            priority={priority}
                            taskCategory={category}
                            dueDate={dueDate}
                            estimatedEffort={estimatedEffort}
                            startDate={startDate}
                            completionDate={completionDate}
                            duration={duration}
                            assignedBy={assignedBy}
                            completionNotes={taskData.completionNotes}
                            owner={owner}
                        />
                    )}
                    
                    {state.isWriteOffUnbilledFeesOpen && (
                        <WriteOffWipViewDialog 
                            open={state.isWriteOffUnbilledFeesOpen}
                            currencyDetails={currencyData}
                            matterId={matterData.id}
                            matterGuid={matterData.matterGuid}
                            matterTitle={matterTitle}
                            client={matterData.client}
                            onClose={onDialogClose}
                            unBilledFees={unBilledFees}
                            unbilledDisbursements={unbilledDisbursements}
                            outstandingDebtors={outstandingDebtors}
                            billedFees={billedFees}
                            canClose={matterData.actions.canCloseMatter}
                            pendingWriteOffFeesAmount={pendingWriteOffFeesAmountInBillMatterTask}
                            pendingWriteOffDisbAmount={pendingWriteOffDisbAmountInBillMatterTask}
                            loggedInUser={state.loggedInUser}
                            defaultPriority={state.defaultPriority}
                            formAction={'edit'}

                            // Saved Data
                            taskGuid={props.taskGuid}
                            writeOffFeesAmount={taskData.writeOffFeesAmount}
                            writeOffDisbursementAmount={taskData.writeOffDisbursementsAmount}
                            closeMatterAfterWriteOff={taskData.closeMatterAfterWriteOff}
                            comments={taskData.comments}
                            assignedTo={assignedTo}
                            priority={priority}
                            taskCategory={category}
                            dueDate={dueDate}
                            estimatedEffort={estimatedEffort}
                            startDate={startDate}
                            completionDate={completionDate}
                            duration={duration}
                            assignedBy={assignedBy}  
                            completionNotes={taskData.completionNotes}
                            owner={owner}
                        />
                    )}
                    
                    {state.isWriteOffDebtorsOpen && (
                        <WriteOffDebtorsViewDialog 
                            open={state.isWriteOffDebtorsOpen}
                            currencyDetails={currencyData}
                            matterId={matterData.id}
                            matterGuid={matterData.matterGuid}
                            matterTitle={matterTitle}
                            client={matterData.client}
                            onClose={onDialogClose}
                            unBilledFees={unBilledFees}
                            unbilledDisbursements={unbilledDisbursements}
                            outstandingDebtors={outstandingDebtors}
                            billedFees={billedFees}
                            canClose={matterData.actions.canCloseMatter}
                            loggedInUser={state.loggedInUser}
                            defaultPriority={state.defaultPriority}
                            formAction={'edit'}
                            
                            // Saved Data
                            taskGuid={props.taskGuid}
                            debtorsAmount={taskData.writeOffDebtorsAmount}
                            closeMatterAfterWriteOff={taskData.closeMatterAfterWriteOff}
                            comments={taskData.comments}
                            assignedTo={assignedTo}
                            priority={priority}
                            taskCategory={category}
                            dueDate={dueDate}
                            estimatedEffort={estimatedEffort}
                            startDate={startDate}
                            completionDate={completionDate}
                            duration={duration}
                            assignedBy={assignedBy} 
                            completionNotes={taskData.completionNotes}
                            owner={owner}
                        />
                    )}
                    
                    {state.isTransferTrustToPayInvoiceOpen && (
                        <TransferTrustToPayInvoiceViewDialog 
                            open={state.isTransferTrustToPayInvoiceOpen}
                            currencyDetails={currencyData}                 
                            matterId={matterData.id}
                            matterGuid={matterData.matterGuid}
                            matterTitle={matterTitle}
                            client={matterData.client}
                            onClose={onDialogClose}
                            outstandingDebtors={outstandingDebtors}
                            currentTrustBalance={currentTrustBalance}
                            loggedInUser={state.loggedInUser}
                            defaultPriority={state.defaultPriority}
                            formAction={'edit'}

                            // Saved Data
                            taskGuid={props.taskGuid}
                            trustAmount={taskData.trustTransferAmount}
                            invoiceNumber={taskData.invoiceToApply}
                            comments={taskData.comments}
                            assignedTo={assignedTo}
                            priority={priority}
                            taskCategory={category}
                            dueDate={dueDate}
                            estimatedEffort={estimatedEffort}
                            startDate={startDate}
                            completionDate={completionDate}
                            duration={duration}
                            assignedBy={assignedBy} 
                            completionNotes={taskData.completionNotes}
                            owner={owner}
                        />
                    )}
                    
                    {state.isArchiveMatterOpen && (
                        <ArchiveMatterViewDialog 
                            open={state.isArchiveMatterOpen}
                            currencyDetails={currencyData}            
                            matterId={matterData.id}
                            matterGuid={matterData.matterGuid}
                            matterTitle={matterTitle}
                            client={matterData.client}
                            onClose={onDialogClose}
                            billedFees={billedFees}
                            closedDate={matterData.completionDate}
                            loggedInUser={state.loggedInUser}
                            defaultPriority={state.defaultPriority}
                            formAction={'edit'}

                            // Saved Data
                            taskGuid={props.taskGuid}
                            archiveMatter={taskData.archive}
                            comments={taskData.comments}
                            assignedTo={assignedTo}
                            priority={priority}
                            taskCategory={category}
                            dueDate={dueDate}
                            estimatedEffort={estimatedEffort}
                            startDate={startDate}
                            completionDate={completionDate}
                            duration={duration}
                            assignedBy={assignedBy} 
                            completionNotes={taskData.completionNotes}
                            owner={owner}
                        />
                    )}
                    
                    {state.isCloseMatterOpen && (
                        <CloseMatterViewDialog 
                            open={state.isCloseMatterOpen}
                            currencyDetails={currencyData}
                            matterId={matterData.id}
                            matterGuid={matterData.matterGuid}
                            matterTitle={matterTitle}
                            client={matterData.client}
                            onClose={onDialogClose}
                            billedFees={billedFees}
                            loggedInUser={state.loggedInUser}
                            defaultPriority={state.defaultPriority}
                            formAction={'edit'}

                            // Saved Data
                            taskGuid={props.taskGuid}
                            closeMatter={taskData.close}
                            comments={taskData.comments}
                            assignedTo={assignedTo}
                            priority={priority}
                            taskCategory={category}
                            dueDate={dueDate}
                            estimatedEffort={estimatedEffort}
                            startDate={startDate}
                            completionDate={completionDate}
                            duration={duration}
                            assignedBy={assignedBy} 
                            completionNotes={taskData.completionNotes}
                            owner={owner}
                        />
                    )}  
                </React.Fragment>
            );
        } else if (taskData && currencyData) {
            const assignedTo = taskData.assignedTo && taskData.assignedToName ? {label: taskData.assignedToName, value: taskData.assignedTo} : null;
            const owner = taskData.owner && taskData.ownerName ? {label: taskData.ownerName, value: taskData.owner} : null;
            const priority = taskData.priority && taskData.priorityDescription 
                                ? {label: taskData.priorityDescription, value: taskData.priority} 
                                : state.defaultPriority;
            const category = taskData.category && taskData.categoryDescription 
                                ? {label: taskData.categoryDescription, value: taskData.category} 
                                : null;
            const dueDate = taskData.dueDate;
            const estimatedEffort = taskData.estimatedEffort && taskData.estimatedEffort !== '00:00:00'
                                ? moment(taskData.estimatedEffort, 'HH:mm').format('HH:mm') 
                                : '';
            const startDate = taskData.startDate;
            const completionDate =  taskData.completionDate;
            const duration = taskData.duration && taskData.duration !== '00:00:00'
                                ? moment(taskData.duration, 'HH:mm').format('HH:mm') 
                                : '';
            const assignedBy = taskData.assignedBy && taskData.assignedByName 
                                ? {label: taskData.assignedByName, value: taskData.assignedBy} 
                                : state.loggedInUser;
            const taskNotes = taskData.notes;
            const taskDescription = taskData.description;

            return (
                <React.Fragment>
                    {state.isGeneralOpen && (
                        <GeneralViewDialog 
                            open={state.isGeneralOpen}
                            onClose={onDialogClose}
                            loggedInUser={state.loggedInUser}
                            defaultPriority={state.defaultPriority}

                            // Saved Data
                            taskGuid={props.taskGuid}
                            title={props.taskDescription}
                            assignedTo={assignedTo}
                            priority={priority}
                            taskCategory={category}
                            dueDate={dueDate}
                            estimatedEffort={estimatedEffort}
                            startDate={startDate}
                            completionDate={completionDate}
                            duration={duration}
                            assignedBy={assignedBy} 
                            description={taskDescription}
                            notes={taskNotes}
                            status={props.status}
                            completionNotes={taskData.completionNotes}
                            owner={owner}
                        />
                    )}
            </React.Fragment>
            );
        } else {
            return null;
        }
    };

    const getLoader = () => {
        return (
            <div className={classes.loaderWrapper}>
                <RvLoader />
            </div>
        );
    };

    return (
        <React.Fragment>
            {
                isLoading
                ? getLoader()
                : getMatterTaskActions()            
            }
        </React.Fragment>    
    );
};
