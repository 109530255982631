import React from 'react';
import { makeStyles, Theme, createStyles, Typography, Paper } from '@material-ui/core';
import { formatNumber } from '../../helpers/ChartHelper';
import { MessageDetails } from '../TasksSummaryRepository';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
        padding: '10px'
    },
    message: {
        padding: '10px'
    }
  }),
);

interface PhoneMessageDetailsPanelProps {
    data: MessageDetails;
}

export const PhoneMessageDetailsPanel: React.FC<PhoneMessageDetailsPanelProps> = (props) => {

    const classes = useStyles();
    
    // if (!props.data || (props.data && props.data.messagesNotResponded === 0 && props.data.messagesReceived === 0)) {
    //     return <div />;
    // }

    return (
        <Paper 
            elevation={3}
            className={classes.paper}
        >
            <Typography className={classes.message}>
                {`${formatNumber(props.data.messagesReceived)} messages received in the last 7 days, ${formatNumber(props.data.messagesNotResponded)} unanswered`}    
            </Typography>
        </Paper>
    );
};

export default PhoneMessageDetailsPanel;