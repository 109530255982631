/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { Autocomplete, RenderInputParams } from '@material-ui/lab';
import { TextField, CircularProgress } from '@material-ui/core';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import { retrieveTaskStatusData, TaskStatusQueryParams, TaskData, TaskStatus } from '../TasksSummaryRepository';
import { showNotification } from '../../App';
import { IAutoCompleteItem } from '../../typings/autoComplete';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
        minWidth: '200px',
    },
  })
);

interface TaskStatusSelectorProps {
    disabled?: boolean;
    required?: boolean;
    multiple?: boolean;
    // tslint:disable-next-line: no-any
    value?: IAutoCompleteItem | any; // value only available in single select
    error?: boolean;
    onSelection?: (value: IAutoCompleteItem | IAutoCompleteItem[], name: string) => void;
    className?: string;
    helperText?: string;
    label?: string;
    name?: string;
    disablePortal?: boolean;
    isIncludeFollowUpTasksOnly?: boolean;
    isIncludeCount: boolean;
    isRefresh?: boolean;
}

interface TaskStatusSelectorState {
    isLoading: boolean;
    error?: boolean;
    taskStatus: IAutoCompleteItem[];
}

export const TaskStatusSelector: React.FC<TaskStatusSelectorProps> = props => {

    const classes = useStyles();

    const [state, setState] = useState<TaskStatusSelectorState>({
        isLoading: false,
        error: props.error,
        taskStatus: [],
    });

    useEffect(() => {   
        fetchData();
    // tslint:disable-next-line: align
    }, []);

    const fetchData = () => {

        setState({
            ...state,
            isLoading: true
        });

        var taskStatusQueryParams: TaskStatusQueryParams = {
            taskStatusType: 'User',
            includeCount: null,

            offset: null,
            first: null,
            filter: null,
            taskStatus: null,
            isIncludeCrmTasks: null,
            isMyTasksOnly: null,
            groupBy: null,
            groupByValue: null, // This is the GuidId of group by
            completedFromDate: null,
            completedToDate: null,
            updatedFromDate: null,
            updatedToDate: null,
            priorityList: null,
            statusList: null,
            allocatedToList: null,
            allocatedByList: null,
            created: null,
            due: null,
            teamList: null,
            taskTypes: null,
            isPersonalTask: null,
            isFromDataSource: null,
            matterList: null,
            isIncludeFollowUpTasksOnly: props.isIncludeFollowUpTasksOnly ?? true,
            isIncludeCount: props.isIncludeCount
        };

        retrieveTaskStatusData(
            taskStatusQueryParams,
            true, // this.state.isRefresh, -- Since we are updating and creating, we do not fetch cached data
            // tslint:disable-next-line: no-console
            (data) => onRetrieveTaskStatus(data),
            // tslint:disable-next-line:no-any
            function (reason: any): void {
                showNotification(null, reason, 'error');
            }
        );
    };

    const onRetrieveTaskStatus = (data: TaskData) => {

        let taskStatusList: IAutoCompleteItem[] = [];

        data.task.statuses.forEach((source: TaskStatus) => {
            if (source.description.toLowerCase() !== 'queued') {
                taskStatusList.push({value: source.guidId, label: source.description});
            }
        });
        
        setState({
            ...state,
            taskStatus: taskStatusList,
            isLoading: false
        });
    };

    // tslint:disable-next-line: no-any
    const handleChange = (event: React.ChangeEvent<{}>, value: any) => {
        if (props.onSelection) {
            props.onSelection(value, props.name ? props.name : 'status');
        }
    };

    const getInputProps = (params: RenderInputParams, isLoading?: boolean) => {
        return  ({   
            ...params.InputProps,
            endAdornment: (
                <React.Fragment>
                  {isLoading ? <CircularProgress color="primary" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),     
         });
    };

    return (
        <div className={`${classes.root} ${props.className}`}>
            { props.multiple ? 
                (
                    <Autocomplete
                        value={props.value}
                        multiple={true}
                        loading={state.isLoading}
                        disabled={props.disabled}
                        onChange={handleChange}
                        options={state.taskStatus}
                        disablePortal={props.disablePortal} // to Stop the dropdown select from closing the form
                        // tslint:disable-next-line: jsx-no-lambda
                        getOptionLabel={(option: IAutoCompleteItem) => option.label}     
                        getOptionSelected={(option, value) => value.value === option.value}               
                        // tslint:disable-next-line: jsx-no-lambda
                        renderInput={params => (
                            <TextField 
                                {...params} 
                                label={props.label}
                                variant="standard" 
                                fullWidth={true} 
                                margin="none" 
                                required={props.required}
                                InputProps={getInputProps(params, state.isLoading)}
                                error={state.error}      
                                helperText={props.helperText}                      
                            />
                        )}
                        // tslint:disable-next-line: jsx-no-lambda
                        renderOption={(option, { inputValue }) => {
                            const matches = match(option.label, inputValue);
                            const parts = parse(option.label, matches);
                    
                            return (
                                <div>
                                    {parts.map((part, index) => (
                                        <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                                            {part.text}
                                        </span>
                                    ))}
                                </div>
                            );
                        }}
                    />
                )
                :
                (
                    <Autocomplete
                        value={props.value}
                        loading={state.isLoading}
                        disabled={props.disabled}
                        onChange={handleChange}
                        options={state.taskStatus}
                        disablePortal={props.disablePortal} // to Stop the dropdown select from closing the form
                        // tslint:disable-next-line: jsx-no-lambda
                        getOptionLabel={(option: IAutoCompleteItem) => option.label}        
                        getOptionSelected={(option, value) => value.value === option.value} 
                        // tslint:disable-next-line: jsx-no-lambda
                        renderInput={params => (
                            <TextField 
                                {...params} 
                                label={props.label}
                                variant="standard" 
                                fullWidth={true} 
                                margin="none" 
                                required={props.required}
                                InputProps={getInputProps(params, state.isLoading)}
                                error={state.error}                            
                            />
                        )}
                        // tslint:disable-next-line: jsx-no-lambda
                        renderOption={(option, { inputValue }) => {
                            const matches = match(option.label, inputValue);
                            const parts = parse(option.label, matches);
                    
                            return (
                                <div>
                                {parts.map((part, index) => (
                                    <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                                    {part.text}
                                    </span>
                                ))}
                                </div>
                            );
                        }}
                    />
                )
            }
        </div>
    );
};
