import React from 'react';
import { makeStyles, Theme, createStyles, Paper, Chip } from '@material-ui/core';
// import { AgGridStyle, AgGridTableWrapper } from '../../components/AgGridStyle';
// import { AgGridReact } from '@ag-grid-community/react';
// import { ChipCellRenderer } from '../../matterSummary/MatterSummaryCellRenderer';
import { ContactDetails } from '../TasksSummaryRepository';
import EmailIcon from '@material-ui/icons/Email';
import SmartphoneIcon from '@material-ui/icons/Smartphone';
import PhoneIcon from '@material-ui/icons/Phone';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
        padding: '10px'
    },
    gridWrapper: {
        display: 'flex',
        flex: 1,
        flexFlow: 'column wrap',
        minHeight: '100px'
    },
    chip: {
        margin: theme.spacing() / 4,
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    tableContainer: {
        display: 'flex',
        flexFlow: 'column',
        flex: 1,
        padding: '0 10px'
    },
    tableHeader: {
        display: 'flex',
        alignItems: 'center',
        margin: '0 -10px',
        backgroundColor: '#F5F6FF'
    },
    tableHeaderColumn: {
        flex: 1,
        padding: '10px'
    },
    tableBody: {
        flex: 1,
        display: 'flex',
        flexFlow: 'column',
    },
    tableBodyRow: {
        display: 'flex',
        flex: 1,
        margin: '0 -10px',
        '&:nth-child(odd)': {

        },
        '&:nth-child(even)': {
            backgroundColor: '#F5F6FF'
        }
    },
    tableBodyColumn: {
        flex: 1,
        padding: '10px'
    }
  }),
);

interface PhoneContactDetailsPanelProps {
    data: ContactDetails;
}

export const PhoneContactDetailsPanel: React.FC<PhoneContactDetailsPanelProps> = (props) => {

    const classes = useStyles();

    // const columnDefs = [
    //     { headerName: 'Address', field: 'address' },
    //     { headerName: 'Email', field: 'email' },
    //     { headerName: 'Mobile', field: 'mobile' },
    //     { headerName: 'Phone', field: 'phone' },
    //     { 
    //         headerName: 'Tags', 
    //         field: 'tags',
    //         cellRendererFramework: ChipCellRenderer,
    //         width: 400,
    //     },
    // ];

    // const rowData = [
    //     {
    //         address: props.data?.address,
    //         email: props.data?.email,
    //         mobile: props.data?.mobile,
    //         phone: props.data?.phone,
    //         tags: props.data?.tags,
    //     }
    // ];

    // const gridOptions = {
    //     defaultColDef: {
    //         // suppressResize: true,
    //         menuTabs: ['filterMenuTab', 'generalMenuTab', 'columnsMenuTab'],
    //         resizable: true,
    //         sortable: true,
    //         filter: true
    //     },
    //     rowBuffer: 0,
    //     cacheOverflowSize: 5,
    //     maxConcurrentDatasourceRequests: 1,           
    //     maxBlocksInCache: 100, // How many blocks to cache in the client.
    //     suppressPropertyNamesCheck: true,
    // };
    
    // if (!props.data) {
    //     return <div />;
    // }
    
    // tslint:disable-next-line: no-any
    const getTagsChip = (value: any) => {

        if (value) {
            let chipsArray = [];
            if (!Array.isArray(value)) {
                // tslint:disable-next-line: no-any
                JSON.parse(value).forEach( (item: any) => {
                    chipsArray.push(item);
                });
            } else {
                chipsArray = value;
            }

            const chips: React.ReactNode = (
                <div className={classes.chips}>
                    {
                        // tslint:disable-next-line: no-any
                        chipsArray.map((chipData: any) => {    
                            return (
                                // tslint:disable-next-line: no-unused-expression
                                <Chip 
                                    className={classes.chip} 
                                    key={chipData.value} 
                                    label={chipData.label}
                                />
                            );
                        })
                    }
                </div>
            );
            return chips;
        } else {
            return null;
        }  
    };

    return (
        <Paper
            elevation={3}
            className={classes.paper}
        >
            <div className={classes.tableContainer}>
                <div className={classes.tableHeader}>
                    <div className={classes.tableHeaderColumn}>
                        Address
                    </div>
                    <div className={classes.tableHeaderColumn}>
                        Contact Details
                    </div>
                    <div className={classes.tableHeaderColumn}>
                        Tags
                    </div>
                </div>
                <div className={classes.tableBody}>
                    <div className={classes.tableBodyRow}>
                        <div className={classes.tableBodyColumn}>
                            {props.data?.address}
                        </div>
                        <div className={classes.tableBodyColumn}>
                            <div className={classes.chips}>
                                {props.data && props.data.email && (
                                    <Chip
                                        icon={<EmailIcon />}
                                        clickable={true}
                                        label={props.data.email}
                                        onClick={(e) => {
                                            window.location.href = `mailto:${props!.data!.email}`;
                                            e.preventDefault();
                                        }}
                                        // onDelete={handleDelete}
                                        variant="outlined"
                                        className={classes.chip}
                                    />
                                )}
                                {props.data.mobile && (
                                    <Chip
                                        icon={<SmartphoneIcon />}
                                        clickable={true}
                                        label={props.data.mobile}
                                        onClick={(e) => {
                                            window.location.href = `tel:${props!.data!.mobile}`;
                                            e.preventDefault();
                                        }}
                                        // onDelete={handleDelete}
                                        variant="outlined"
                                        className={classes.chip}
                                    />
                                )}
                                {props.data.phone && (
                                    <Chip
                                        icon={<PhoneIcon />}
                                        clickable={true}
                                        label={props.data.phone}
                                        onClick={(e) => {
                                            window.location.href = `tel:${props!.data!.phone}`;
                                            e.preventDefault();
                                        }}
                                        // onDelete={handleDelete}
                                        variant="outlined"
                                        className={classes.chip}
                                    />
                                )}
                            </div>
                        </div>
                        <div className={classes.tableBodyColumn}>
                            {getTagsChip(props.data?.tags)}
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className={classes.gridWrapper}>
                <AgGridStyle
                    id="SummaryGrid"
                    className="ag-theme-material"
                >
                    <AgGridTableWrapper
                        className={'grid-container'}
                    >
                        <AgGridReact
                            key={'clientGridMatter'}
                            // modules={AllModules}
                            gridOptions={gridOptions}
                            // onGridReady={this.onGridReady}
                            // tslint:disable-next-line:max-line-length
                            rowData={rowData}
                            // rowGroupPanelShow={this.state.isInMemory ? 'always' : 'none'}
                            // rowGroupPanelShow={'always'}
                            // cacheQuickFilter={true}
                            // rowSelection="multiple"
                            // rowMultiSelectWithClick={true}
                            // suppressDragLeaveHidesColumns={true}
                            // onRowSelected={this.onRowSelected}
                            // onRowClicked={this.onRowSelected}
                            animateRows={false}
                            floatingFilter={false}
                            rowHeight={40}
                            headerHeight={40}
                            columnDefs={columnDefs}
                            // getMainMenuItems={this.getMainMenuItems}

                            // onModelUpdated={this.onModelUpdated}
                            // suppressNoRowsOverlay={true}
                            // overlayLoadingTemplate={overlayLoadingTemplate} // non InMemory mode. Custom overlay
                            // onDisplayedColumnsChanged={this.handleDisplayedColumnsChanged}
                            // onColumnResized={this.handleColumnResized}
                        />
                    </AgGridTableWrapper>
                </AgGridStyle>
            </div> */}
        </Paper>
    );
};

export default PhoneContactDetailsPanel;