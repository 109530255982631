import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AssignmentIcon from '@material-ui/icons/Assignment';
import {
    Grid,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Typography,
} from '@material-ui/core';
import { mainTheme } from '../../Theme';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {},
        heading: {
            fontSize: theme.typography.pxToRem(15),
            // fontWeight: theme.typography.fontWeightRegular,
            color: mainTheme.BrandColors.TemplatePrimary
        },
        headerIcon: {
            marginRight: 10,
            color: mainTheme.BrandColors.TemplatePrimary
        },
        fieldContainer: {
            display: 'flex',
            justifyContent: 'space-between',
            margin: '0 -10px',
            flex: 1,
            '& .col': {
                padding: '0 10px',
            },
            '& .value': {
                // fontWeight: 600,
                color: 'grey',
            },
            '& .text': {
                flex: 1,
                color: 'grey',
            },
            '& .status': {
                color: mainTheme.BrandColors.TemplatePrimary
            }
        },
    })
);

interface AssignmentTaskDetailViewFieldsProps {
    priority?: string;
    category?: string;
    due?: string;
    estimatedEffort?: string;
    start?: string;
    completion?: string;
    duration?: string;
    assignedBy?: string;
    status?: string;
    completionNotes?: string | null;
}

export const AssignmentTaskDetailViewFields: React.FC<AssignmentTaskDetailViewFieldsProps> = (props) => {
    const classes = useStyles();

    return (
        <>
            <Accordion
                defaultExpanded={true}
            >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                >
                    <AssignmentIcon className={classes.headerIcon} />
                    <Typography className={classes.heading}>Task Details</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container={true} spacing={3}>
                        <Grid item={true} xs={12}>
                            <Grid container={true} spacing={3}>
                                {!!props.priority && (
                                    <Grid item={true} xs={12} md={6}>
                                        <div className={classes.fieldContainer}>
                                            <div className="col label">
                                                Priority:
                                            </div>
                                            <div className="col value">
                                                {props.priority}
                                            </div>
                                        </div>
                                    </Grid>
                                )}
                                {!!props.category && (
                                    <Grid item={true} xs={12} md={6}>
                                        <div className={classes.fieldContainer}>
                                            <div className="col label">
                                                Category:
                                            </div>
                                            <div className="col value">
                                                {props.category}
                                            </div>
                                        </div>
                                    </Grid>
                                )}
                                {!!props.due && (
                                    <Grid item={true} xs={12} md={6}>
                                        <div className={classes.fieldContainer}>
                                            <div className="col label">
                                                Due:
                                            </div>
                                            <div className="col value">
                                                {props.due}
                                            </div>
                                        </div>
                                    </Grid>
                                )}
                                {!!props.estimatedEffort && (
                                    <Grid item={true} xs={12} md={6}>
                                        <div className={classes.fieldContainer}>
                                            <div className="col label">
                                                Estimated Effort:
                                            </div>
                                            <div className="col value">
                                                {props.estimatedEffort}
                                            </div>
                                        </div>
                                    </Grid>
                                )}
                                {!!props.start && (
                                    <Grid item={true} xs={12} md={6}>
                                        <div className={classes.fieldContainer}>
                                            <div className="col label">
                                                Start:
                                            </div>
                                            <div className="col value">
                                                {props.start}
                                            </div>
                                        </div>
                                    </Grid>
                                )}
                                {!!props.completion && (
                                    <Grid item={true} xs={12} md={6}>
                                        <div className={classes.fieldContainer}>
                                            <div className="col label">
                                                Completion:
                                            </div>
                                            <div className="col value">
                                                {props.completion}
                                            </div>
                                        </div>
                                    </Grid>
                                )}
                                {!!props.duration && (
                                    <Grid item={true} xs={12} md={6}>
                                        <div className={classes.fieldContainer}>
                                            <div className="col label">
                                                Actual Effort:
                                            </div>
                                            <div className="col value">
                                                {props.duration}
                                            </div>
                                        </div>
                                    </Grid>
                                )}
                                {!!props.assignedBy && (
                                    <Grid item={true} xs={12} md={6}>
                                        <div className={classes.fieldContainer}>
                                            <div className="col label">
                                                Assigned By:
                                            </div>
                                            <div className="col value">
                                                {props.assignedBy}
                                            </div>
                                        </div>
                                    </Grid>
                                )}
                                {!!props.status && (
                                    <Grid item={true} xs={12} md={6}>
                                        <div className={classes.fieldContainer}>
                                            <div className="col label">
                                                Status:
                                            </div>
                                            <div className="col status">
                                                {props.status}
                                            </div>
                                        </div>
                                    </Grid>
                                )}
                                {!!props.completionNotes && (
                                    <Grid item={true} xs={12} md={12}>
                                        <div className={classes.fieldContainer}>
                                            <div className="col label">
                                                Completion Notes:
                                            </div>
                                            <div className="col text">
                                                {props.completionNotes}
                                            </div>
                                        </div>
                                    </Grid>
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>
        </>
    );
};
