import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import {
    Button,
    Dialog,
    DialogTitle,
    DialogActions,
    DialogContent,
    Grid,
    Checkbox,
    FormControlLabel,
} from '@material-ui/core';
import { AssignmentTaskDetailViewFields } from '../cards/AssignmentTaskDetailViewFields';
import moment from 'moment';
import { CurrencyDetails } from '../../matterSummary/cellRenderer/MatterTaskAction';
import { IAutoCompleteItem } from '../../typings/autoComplete';
import { DialogTitleExtension } from '../../matterSummary/common/DialogTitleExtension';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {},
        header: {
            textTransform: 'capitalize',
        },
        fieldContainer: {
            display: 'flex',
            justifyContent: 'space-between',
            margin: '0 -10px',
            flex: 1,
            height: '100%',
            alignItems: 'center',
            '& .col': {
                padding: '0 10px',
            },
            '& .value': {
                fontWeight: 600,
                color: 'grey',
            },
            '& .text': {
                flex: 1,
                color: 'grey',
            }
        },
    })
);

interface CloseMatterViewDialogProps {
    open: boolean;
    currencyDetails: CurrencyDetails;
    matterId: number;
    matterGuid: string;
    matterTitle: string;
    client: string;
    onClose?: () => void;
    loggedInUser: IAutoCompleteItem | null;
    defaultPriority: IAutoCompleteItem | null;

    billedFees?: number;

    // Saved values
    taskGuid?: string;
    formAction: 'create' | 'edit';
    closeMatter?: boolean | null;
    comments?: string | null;
    assignedTo?: IAutoCompleteItem | null;
    priority?: IAutoCompleteItem | null;
    taskCategory?: IAutoCompleteItem | null;
    dueDate?: Date | null;
    estimatedEffort?: string | null;
    startDate?: Date | null;
    completionDate?: Date | null;
    duration?: string | null;
    assignedBy?: IAutoCompleteItem | null;
    completionNotes?: string | null;
    owner?: IAutoCompleteItem | null;
}

export const CloseMatterViewDialog: React.FC<CloseMatterViewDialogProps> = (props) => {
    const classes = useStyles();

    const onClose = () => {
        if (props.onClose) {
            props.onClose();
        }
    };

    return (
        <>
            <Dialog
                open={props.open}
                onClose={onClose}
                maxWidth="sm"
                fullWidth={true}
                className={classes.root}
                scroll={'paper'}
            >
                <DialogTitle className={classes.header}>
                    <DialogTitleExtension 
                        title="Close Matter"
                        subTitle={props.client}
                        description={props.matterTitle}
                    />
                </DialogTitle>
                <DialogContent dividers={true}>
                    <Grid container={true} spacing={3}>
                        {!!props.closeMatter && (
                            <Grid item={true} xs={12} md={6}>
                                <FormControlLabel
                                    control={
                                        <Checkbox 
                                            name="isCloseMatter"
                                            checked={props.closeMatter}
                                            // disabled={props.formAction === 'edit'}
                                        />
                                    }
                                    label="Close Matter"
                                    disabled={true}
                                />
                            </Grid>
                        )}
                        <Grid item={true} xs={12} md={12}>
                            <Grid container={true} spacing={3}>
                                {!!props.assignedTo && (
                                    <Grid item={true} xs={12} md={6}>
                                        <div className={classes.fieldContainer}>
                                            <div className="col label">
                                                Assigned To:
                                            </div>
                                            <div className="col value">
                                                {props.assignedTo.label}
                                            </div>
                                        </div>
                                    </Grid>
                                )}
                                {props.owner && (
                                    <Grid item={true} xs={12} md={6}>
                                        <div className={classes.fieldContainer}>
                                            <div className="col label">
                                                Owner:
                                            </div>
                                            <div className="col value">
                                                {props.owner.label}
                                            </div>
                                        </div>
                                    </Grid>
                                )}
                            </Grid>
                        </Grid>
                        {!!props.comments && (
                            <Grid item={true} xs={12} md={12}>
                                <div className={classes.fieldContainer}>
                                    <div className="col label">
                                        Comments:
                                    </div>
                                    <div className="col text">
                                        {props.comments}
                                    </div>
                                </div>
                            </Grid>
                        )}
                        <Grid item={true} xs={12} md={12}>
                            <AssignmentTaskDetailViewFields 
                                priority={props.priority ? props.priority.label : props.defaultPriority!.label}
                                category={props.taskCategory ? props.taskCategory.label : ''}
                                due={props.dueDate ? moment(props.dueDate).format('MMM D, YYYY hh:mm a') : ''}
                                estimatedEffort={props.estimatedEffort ? props.estimatedEffort : ''}
                                start={props.startDate ? moment(props.startDate).format('MMM D, YYYY hh:mm a') : ''}
                                completion={props.completionDate ? moment(props.completionDate).format('MMM D, YYYY hh:mm a') : ''}
                                duration={props.duration ? props.duration : ''}
                                assignedBy={props.assignedBy ? props.assignedBy!.label : props.loggedInUser!.label}
                                completionNotes={props.completionNotes}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button
                        type="button"
                        onClick={onClose}
                        color="primary"
                    >
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};
