/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import {
    Button,
    Dialog,
    DialogTitle,
    DialogActions,
    DialogContent,
    TextField,
    Grid,
} from '@material-ui/core';
import { AnyObject, Field, Form, FormSpy } from 'react-final-form';
import { FormApi } from 'final-form';
import { showNotification } from '../../App';
import { RvLoader } from '../../components/Loader';
import { ChangeDueTask, retrieveTaskDetailsData, TaskDetailsData, TaskDetailsParam } from '../TasksSummaryRepository';
import DialogBox from '../../Dashboard/Component/DialogBox';
import { client } from '../..';
import { KeyboardDateTimePicker } from '@material-ui/pickers/DateTimePicker/DateTimePicker';
import { TimeNumberFormatCustom } from '../../components/TimeNumberFormat';
import moment from 'moment';
import { IAutoCompleteItem } from '../../typings/autoComplete';
import { ReminderSelector } from '../../components/ReminderSelector';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        dialogRoot: {
            '& .downshift-wrapper': {
                flex: 1,
                padding: 0,
            }
        },
        header: {
            textTransform: 'capitalize',
        },
        textField: {
            width: '100%',
        },
        content: {
            flex: 1
        },
        buttonContainer: {
            display: 'flex',
            justifyContent: 'space-between',
            // paddingBottom: '20px'
        },
        button: {
            margin: theme.spacing(1),
            minWidth: '83px',
        },
        hidden: {
            display: 'none',
        },
        loaderWrapper: {
            display: 'flex',
            justifyContent: 'center',
            padding: '20px 0',
        },
        clientSelector: {
            flex: 1
        },
    })
);

interface DueFormDialogProps {
    open: boolean;
    onClose?: () => void;
    title?: string;
    guid: string;
}

interface DueFormDialogState {
    open: boolean;
    isLoading: boolean;
    onSubmitLoading: boolean;
    showDiscard: boolean;
    isChanged: boolean;
}

interface DueForm {
    startDate: Date | null;
    estimatedEffort: string | null;
    dueDate: Date | null;
    reminderOffset: IAutoCompleteItem | null;
}

export const DueFormDialog: React.FC<DueFormDialogProps> = (props) => {
    const classes = useStyles();

    const [state, setState] = useState<DueFormDialogState>({
        open: false,
        isLoading: false,
        onSubmitLoading: false,
        showDiscard: false,
        isChanged: false
    });

    const [dueForm, setDueForm] = useState<DueForm>({
        startDate: null,
        estimatedEffort: null,
        dueDate: null,
        reminderOffset: { label: 'None', value: 'None'}
    });

    useEffect(() => {

        if (props.guid) {
            fetchTaskDetails(props.guid);
        }
        setState((prevState) => {
            return {
                ...prevState,
                open: props.open
            };
        });
    // tslint:disable-next-line: align
    }, [props.open, props.guid]);

    const fetchTaskDetails = (guid: string) => {

        setState((prevState) => {
            return {
                ...prevState,
                isLoading: true
            };
        });

        const taskDetailsParams: TaskDetailsParam = {
            guid: guid
        };

        retrieveTaskDetailsData(
            taskDetailsParams,
            true,
            // tslint:disable-next-line: no-console
            (data: TaskDetailsData) => onTaskDetailsRetrieved(data),
            // tslint:disable-next-line: no-any
            function (reason: any): void {
                showNotification(null, reason, 'error');
            }
        );

    };

    const onTaskDetailsRetrieved = (data: TaskDetailsData) => {
        // tslint:disable-next-line: no-console
        if (data && data.task && data.task.detail) {

            const taskDetails = data.task.detail;

            let reminderOffset: IAutoCompleteItem = { label: 'None', value: 'None'};
            
            if (taskDetails.reminderOffset) {
                reminderOffset = { label: taskDetails.reminderOffset, value: taskDetails.reminderOffset };
            } 

            setDueForm((prevDueForm) => {
                return {
                    ...prevDueForm,
                    startDate: taskDetails.startDate ? moment(taskDetails.startDate).toDate() : null,
                    // tslint:disable-next-line: max-line-length
                    estimatedEffort: taskDetails.estimatedEffort ? taskDetails.estimatedEffort : null,
                    dueDate: taskDetails.dueDate ? moment(taskDetails.dueDate).toDate() : null,
                    reminderOffset: reminderOffset
                };
            });
        }

        setState((prevState) => {
            return {
                ...prevState,
                isLoading: false
            };
        });
    };

    const onSubmit = (form: FormApi<DueForm>, values: AnyObject) => {
        setState((prevState) => {
            return {
                ...prevState,
                onSubmitLoading: true
            };
        });

        client
        .mutate({
            mutation: ChangeDueTask,
            variables: {
                guid: props.guid,
                startDate: values.startDate ? values.startDate : null,
                dueDate: values.dueDate ? values.dueDate : null,
                estimatedEffort: values.estimatedEffort ? values.estimatedEffort : null,
                reminderOffset: values.reminderOffset ? values.reminderOffset.value : null
            },
        })
        // tslint:disable-next-line: no-any
        .then((results: { data: any }) => {
            if (results.data) {
                if (results.data.error === null || results.data.error === undefined) {
                    showNotification(null, 'Successfully submitted', 'info');     
                    closePanel();                   
                } else {
                    showNotification('Failed to update due', results.data.error, 'error');
                }

                setState((prevState) => {
                    return {
                        ...prevState,
                        onSubmitLoading: false
                    };
                });
            }
        })
        // tslint:disable-next-line:no-any
        .catch((reason: any) => {
            showNotification('Failed to update due', reason, 'error');
            setState((prevState) => {
                return {
                    ...prevState,
                    onSubmitLoading: false
                };
            });
        });
    };

    const closePanel = () => {
        // codepanel
        if (props.onClose) {
            return props.onClose();
        }
    };

    const onReset = () => {
        // reset
    };

    // tslint:disable-next-line: no-any
    const onFormValueChanged = (form: FormApi<DueForm>, changeProps: any) => {
        if (!changeProps.pristine) {
            setState((prevState) => {
                return {
                    ...prevState,
                    isChanged: true
                };
            });
        }
    };

    const onClose = () => {
        if (state.isChanged) {
            setState((prevState) => {
                return {
                    ...prevState,
                    showDiscard: true
                };
            });
        } else {
            if (props.onClose) {
                props.onClose();
            }
        }
    };

    const onDiscardChanges = (agree: boolean) => {
        if (agree) {
            setState((prevState) => {
                return {
                    ...prevState,
                    showDiscard: false,
                };
            });

            if (props.onClose) {
                props.onClose();
            }

        } else {
           setState((prevState) => {
               return {
                   ...prevState,
                   showDiscard: false
               };
           });
        }
    };

    // tslint:disable-next-line: no-any
    const required = (value: any) => {
        return value ? undefined : 'Required';
    };

    const actionButton = (
        // tslint:disable-next-line: no-any
        form: FormApi<DueForm>,
        submitting: boolean,
        pristine: boolean,
        // tslint:disable-next-line: no-any
        values: AnyObject
    ) => {
        return (
            <div className={classes.buttonContainer}>
                {/* <RenderCount /> */}
                <div>
                    <Button
                        color="primary"
                        type="submit"
                        className={classes.button}
                        onClick={() => {
                            // code here..
                        }}
                        disabled={submitting || pristine || state.onSubmitLoading}
                    >
                        Update
                    </Button>
                </div>
                <div>
                    <Button
                        id="resetButton"
                        type="button"
                        onClick={onReset}
                        disabled={submitting || pristine}
                        className={classes.hidden}
                    >
                        Reset
                    </Button>
                    <Button
                        color="primary"
                        type="button"
                        className={classes.button}
                        onClick={closePanel}
                    >
                        Cancel
                    </Button>
                </div>
            </div>
        );
    };

    return (
        <>
            <DialogBox
                title="Due"
                // tslint:disable-next-line:max-line-length
                content={`Are you sure you want to close the form?`}
                show={state.showDiscard}
                isAgree={onDiscardChanges}
                disAgreeLabel={'No'}
                agreeLabel={'Yes'}
            />
            
            <Dialog
                open={state.open}
                onClose={onClose}
                maxWidth="sm"
                fullWidth={true}
                className={classes.dialogRoot}
                scroll={'paper'}
            >
                <DialogTitle className={classes.header}>{props.title}</DialogTitle>
                {state.isLoading ? (
                    <DialogContent dividers={true}>
                        <div className={classes.loaderWrapper}>
                            <RvLoader size="small" label="Loading..."/>
                        </div>
                    </DialogContent>
                ) : (
                    <Form
                        onSubmit={(values, form: FormApi<DueForm>) => onSubmit(form, values)}                
                        initialValues={dueForm}
                        subscription={{ submitting: true, pristine: true }}
                        render={({handleSubmit, form, submitting, pristine, values}) => (
                            <form onSubmit={handleSubmit} id="taskForm">
                                <FormSpy 
                                    subscription={{ pristine: true, values: true }}
                                    // tslint:disable-next-line: no-shadowed-variable
                                    onChange={props => {
                                        onFormValueChanged(form, props);
                                    }}
                                />
                                <DialogContent dividers={true}>
                                    <Grid container={true} spacing={3}>
                                        <Grid item={true} xs={12} md={6}>
                                            <Field
                                                name="dueDate"
                                                validate={form.getFieldState('dueDate')?.value !== null ? required : undefined}
                                            >
                                                {({ input, meta }) => (
                                                    <KeyboardDateTimePicker
                                                        {...input}
                                                        className={classes.textField}
                                                        id="dueDate"
                                                        name="dueDate"
                                                        label="Due Date"
                                                        format="DD/MM/YYYY hh:mm a"
                                                        placeholder="dd/mm/yyyy hh:mm a"
                                                        animateYearScrolling={true}
                                                        // tslint:disable-next-line: max-line-length
                                                        autoOk={true}
                                                        allowKeyboardControl={true}
                                                        variant={'inline'}
                                                        error={meta.error && meta.touched}
                                                        inputProps={{autoComplete: 'off'}}
                                                        InputLabelProps={{ shrink: true }}
                                                        helperText={
                                                            meta.error &&
                                                            meta.touched
                                                                ? 'Due date is required'
                                                                : ''
                                                        }
                                                        required={true}
                                                        initialFocusedDate={new Date().setHours(17, 0, 0, 0)}
                                                    />
                                                )}
                                            </Field>
                                        </Grid>
                                        <Grid item={true} xs={12} md={6}>
                                            <Field
                                                name="startDate"
                                                // validate={form.getFieldState('startDate')?.value !== null ? required : undefined}
                                            >
                                                {({ input, meta }) => (
                                                    <KeyboardDateTimePicker
                                                        {...input}
                                                        className={classes.textField}
                                                        id="startDate"
                                                        name="startDate"
                                                        label="Start Date"
                                                        format="DD/MM/YYYY hh:mm a"
                                                        placeholder="dd/mm/yyyy hh:mm a"
                                                        animateYearScrolling={true}
                                                        // tslint:disable-next-line: max-line-length
                                                        autoOk={true}
                                                        allowKeyboardControl={true}
                                                        variant={'inline'}
                                                        error={meta.error && meta.touched}
                                                        inputProps={{autoComplete: 'off'}}
                                                        InputLabelProps={{ shrink: true }}
                                                        helperText={
                                                            meta.error &&
                                                            meta.touched
                                                                ? 'Start date is required'
                                                                : ''
                                                        }
                                                    />
                                                )}
                                            </Field>
                                        </Grid>
                                        <Grid item={true} xs={12} md={6}>
                                            <Field
                                                name="estimatedEffort"
                                                // validate={form.getFieldState('estimatedEffort')?.value !== null ? required : undefined}
                                            >
                                                {({ input, meta }) => (
                                                    // <KeyboardTimePicker
                                                    //     {...input}
                                                    //     name="estimatedEffort"
                                                    //     label="Estimated Effort"
                                                    //     // clearable={true}
                                                    //     ampm={false}
                                                    //     className={classes.textField}
                                                    //     error={meta.error && meta.touched}
                                                    //     helperText={
                                                    //         meta.error &&
                                                    //         meta.touched
                                                    //             ? 'Estimated effort is required'
                                                    //             : ''
                                                    //     }
                                                    //     InputLabelProps={{ shrink: true }}
                                                    // />

                                                    <TextField
                                                        {...input}
                                                        label="Estimated Effort"
                                                        name="estimatedEffort"
                                                        InputProps={{
                                                            // tslint:disable-next-line: no-any
                                                            inputComponent: TimeNumberFormatCustom   as any
                                                        }}
                                                        className={classes.textField}
                                                        error={meta.error && meta.touched}
                                                        helperText={
                                                            meta.error &&
                                                            meta.touched
                                                                ? 'Estiamted Effort is required'
                                                                : ''
                                                        }
                                                        placeholder="00:00"
                                                    />
                                                )}
                                            </Field>
                                        </Grid>
                                        <Grid item={true} xs={12} md={6}>
                                            <Field
                                                name="reminderOffset"
                                                // validate={required}
                                                helperText={'Reminder is required'}
                                            >
                                                {({ input, meta }) => (
                                                    <ReminderSelector
                                                        {...input}
                                                        label="Reminder" 
                                                        className={classes.textField}
                                                        onSelection={(selection: IAutoCompleteItem) => {
                                                            input.onChange(selection);
                                                        }}
                                                        error={meta.error && meta.touched}
                                                        helperText={
                                                            meta.error &&
                                                            meta.touched
                                                                ? meta.error
                                                                : ''
                                                        }
                                                        showIcon={true}
                                                    />
                                                )}
                                            </Field>
                                        </Grid>
                                    </Grid>
                                </DialogContent>
                                <DialogActions>
                                    {actionButton(form, submitting, pristine, values)}
                                </DialogActions>
                            </form>
                        )} 
                    />
                )}
            </Dialog>
        </>
    );
};
