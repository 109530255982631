import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import {
    Popper,
    Grow,
    Paper,
    ClickAwayListener,
    MenuList,
    MenuItem,
    IconButton
} from '@material-ui/core';
import MoreHorizOutlinedIcon from '@material-ui/icons/MoreHorizOutlined'; 
import { UserFeature, UserFeatureEnum } from '../../types/UserFeature';
import RedoIcon from '@material-ui/icons/Redo';
import PersonIcon from '@material-ui/icons/Person';
import EditIcon from '@material-ui/icons/Edit';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import PriorityHighIcon from '@material-ui/icons/PriorityHigh';
import TodayIcon from '@material-ui/icons/Today';
import EventNoteIcon from '@material-ui/icons/EventNote';
import CloseIcon from '@material-ui/icons/Close';
import NestedMenuItem from 'material-ui-nested-menu-item';
import { mainTheme } from '../../Theme';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import CheckIcon from '@material-ui/icons/Check';
import VisibilityIcon from '@material-ui/icons/Visibility';
import CategoryIcon from '@material-ui/icons/Category';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import MessageIcon from '@material-ui/icons/Message';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {},
        icon: {
            marginRight: '10px',
            color: mainTheme.BrandColors.TemplatePrimary
        },
        deleteIcon: {
            marginRight: '10px',
            color: 'rgb(220, 0, 78)',
        }
    })
);

interface ActionMenuProps {
    onOpen?: () => void;
    onAllocate?: () => void;
    onStatus?: () => void;
    onPriority?: () => void;
    onStartDate?: () => void;
    onDueDate?: () => void;
    onDelete?: () => void;
    onStart?: () => void;
    onCompletionDate?: () => void;
    onComplete?: () => void;
    onView?: () => void;
    onCategory?: () => void;
    onOwner?: () => void;
    onMessage?: () => void;
    onDuplicate?: () => void;
    onCreateEnquiry?: () => void;

    // onEnquiryAssign?: () => void;
    // onEnquiryFollowUp?: () => void;

    disabledMenu?: {
        open?: boolean;
        allocate?: boolean;
        status?: boolean;
        priority?: boolean;
        startDate?: boolean;
        dueDate?: boolean;
        delete?: boolean;
        start?: boolean;
        completionDate?: boolean;
        complete?: boolean;
        view?: boolean;
        category?: boolean;
        owner?: boolean;
        message?: boolean;
        duplicate?: boolean;
        createEnquiry?: boolean;

        isEnquiryClosed?: boolean;
        EnquiryTaskType?: string;       
    };
    entityType: number;
    // entityGuidID: string;
    // assignedToEmailAddress?: string;
    // initialEnquiryDate?: Date;
    // enquiryFirstName?: string;
    // enquiryLastName?: string;
    // enquiryStatus?: string;
}

// tslint:disable-next-line: no-any
export const ActionMenu: React.FC<ActionMenuProps> = ( props ) => {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef<HTMLButtonElement>(null);
    // const history = useHistory();
    // const history = useHistory();

    const handleToggle = () => {
        // tslint:disable-next-line: no-shadowed-variable
        setOpen(prevOpen => !prevOpen);
    };

    const archorEvent = (event: React.MouseEvent<EventTarget>) => {
        if (anchorRef.current &&
            anchorRef.current.contains(event.target as HTMLElement)) {
            return;
        }
    };

    const handleClose = (event: React.MouseEvent<EventTarget>) => {
        // archorEvent(event);
        // setOpen(false);

        if (anchorRef.current &&
            anchorRef.current.contains(event.target as HTMLElement)
        ) {
            return;
        }

        setOpen(false);
    };

    const handleOpen = (event: React.MouseEvent<EventTarget>) => {
        archorEvent(event);

        if (props.onOpen) {
            props.onOpen();
        }

        setOpen(false);
    };

    const handleDuplicate = (event: React.MouseEvent<EventTarget>) => {
        archorEvent(event);

        if (props.onDuplicate) {
            props.onDuplicate();
        }

        setOpen(false);
    };

    const handleView = (event: React.MouseEvent<EventTarget>) => {
        archorEvent(event);

        if (props.onView) {
            props.onView();
        }

        setOpen(false);
    };

    const handleAllocate = (event: React.MouseEvent<EventTarget>) => {
        archorEvent(event);

        if (props.onAllocate) {
            props.onAllocate();
        }

        setOpen(false);
    };

    const handleStatus = (event: React.MouseEvent<EventTarget>) => {
        archorEvent(event);

        if (props.onStatus) {
            props.onStatus();
        }

        setOpen(false);
    };

    const handlePriority = (event: React.MouseEvent<EventTarget>) => {
        archorEvent(event);

        if (props.onPriority) {
            props.onPriority();
        }

        setOpen(false);
    };

    const handleStartDate = (event: React.MouseEvent<EventTarget>) => {
        archorEvent(event);

        if (props.onStartDate) {
            props.onStartDate();
        }

        setOpen(false);
    };

    const handleDueDate = (event: React.MouseEvent<EventTarget>) => {
        archorEvent(event);

        if (props.onDueDate) {
            props.onDueDate();
        }

        setOpen(false);
    };

    const handleComplete = (event: React.MouseEvent<EventTarget>) => {
        archorEvent(event);

        if (props.onComplete) {
            props.onComplete();
        }

        setOpen(false);
    };

    const handleStart = (event: React.MouseEvent<EventTarget>) => {
        archorEvent(event);

        if (props.onStart) {
            props.onStart();
        }

        setOpen(false);
    };

    const handleDelete = (event: React.MouseEvent<EventTarget>) => {
        archorEvent(event);

        if (props.onDelete) {
            props.onDelete();
        }

        setOpen(false);
    };

    const handleCompletionDate = (event: React.MouseEvent<EventTarget>) => {
        archorEvent(event);

        if (props.onCompletionDate) {
            props.onCompletionDate();
        }

        setOpen(false);
    };

    const handleCategory = (event: React.MouseEvent<EventTarget>) => {
        archorEvent(event);

        if (props.onCategory) {
            props.onCategory();
        }

        setOpen(false);
    };

    const handleOwner = (event: React.MouseEvent<EventTarget>) => {
        archorEvent(event);

        if (props.onOwner) {
            props.onOwner();
        }

        setOpen(false);
    };

    const handleCreateEnquiry = (event: React.MouseEvent<EventTarget>) => {
        archorEvent(event);

        if (props.onCreateEnquiry) {
            props.onCreateEnquiry();
        }

        setOpen(false);
    };

    const handleMessage = (event: React.MouseEvent<EventTarget>) => {
        archorEvent(event);

        if (props.onMessage) {
            props.onMessage();
        }

        setOpen(false);
    };

    // const handleAssign = (event: React.MouseEvent<EventTarget>) => {
    //     archorEvent(event);

    //     if (props.onEnquiryAssign) {
    //         props.onEnquiryAssign();
    //     }

    //     setOpen(false);
    // };

    // const handleEmailAssignee = (event: React.MouseEvent<EventTarget>) => {
    //     archorEvent(event);
    //     setOpen(false);

    //     var mail = document.createElement('a');
    //     // tslint:disable-next-line:max-line-length
    //     mail.href = `mailto:${props.assignedToEmailAddress}?subject=RE ${props.enquiryFirstName} ${props.enquiryLastName}, 
    //                     new business enquiry received ${moment(props.initialEnquiryDate).format('DD MMM YYYY')}`;
    //     mail.click();
    // };

    // const handleEnquiryView = (event: React.MouseEvent<EventTarget>) => {
    //     archorEvent(event);
    //     setOpen(false);

    //     var href = history.createHref({
    //         pathname: `/enquiriesDetail/${props.entityGuidID}`
    //     });

    //     window.open(href, '_blank');
    // };

    // const handleFollowUp = (event: React.MouseEvent<EventTarget>) => {
    //     archorEvent(event);

    //     if (props.onEnquiryFollowUp) {
    //         props.onEnquiryFollowUp();
    //     }

    //     setOpen(false);
    // };

    // const handleCreateClient = (event: React.MouseEvent<EventTarget>) => {
    //     archorEvent(event);

    //     setOpen(false);

    //     // onCreateClientOpen(props.entityGuidID);
    // };

    function handleListKeyDown(event: React.KeyboardEvent) {
        if (event.key === 'Tab') {
            event.preventDefault();
            setOpen(false);
        }
    }

    // return focus to the button when we transitioned from !open -> open
    const prevOpen = React.useRef(open);
    React.useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current!.focus();
        }

        prevOpen.current = open;
        // tslint:disable-next-line: align
    }, [open]);

    return (
        <div className={classes.root}>
            <IconButton
                color="primary"
                ref={anchorRef}
                aria-controls={open ? 'menu-list-grow' : undefined}
                aria-haspopup="true"
                onClick={handleToggle}
            >
                <MoreHorizOutlinedIcon />
            </IconButton>
            <Popper
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition={true}
                disablePortal={false}
                style={{
                    marginLeft: '50px'                                                       
                }}     
            >
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin:
                                placement === 'bottom'
                                    ? 'center top'
                                    : 'center bottom'
                        }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList
                                    autoFocusItem={open}
                                    id="menu-list-grow"
                                    onKeyDown={handleListKeyDown}
                                >
                                    {props.onCreateEnquiry && UserFeature.HasAccess(UserFeatureEnum.hasCreateLead) && (
                                        <MenuItem 
                                            onClick={event => handleCreateEnquiry(event)}
                                            disabled={props.disabledMenu?.createEnquiry}
                                        >
                                            <AssignmentIndIcon fontSize="small" className={classes.icon} /> Create Enquiry
                                        </MenuItem>
                                    )}

                                    {props.entityType !== 1 && (
                                        <MenuItem 
                                            onClick={event => handleMessage(event)}
                                            disabled={props.disabledMenu?.message}
                                        >
                                            <MessageIcon fontSize="small" className={classes.icon} /> Message
                                        </MenuItem>
                                    )}
                                    
                                    {UserFeature.HasAccess(UserFeatureEnum.hasModifyTask) && props.entityType !== 1 && (
                                        <MenuItem 
                                            onClick={event => handleOpen(event)}
                                            disabled={props.disabledMenu?.open}
                                        >
                                            <RedoIcon fontSize="small" className={classes.icon} /> Modify
                                        </MenuItem>
                                    )}
                                    {UserFeature.HasAccess(UserFeatureEnum.hasModifyTask) && props.entityType !== 1 && props.onDuplicate && (
                                        <MenuItem 
                                            onClick={event => handleDuplicate(event)}
                                            disabled={props.disabledMenu?.duplicate}
                                        >
                                            <FileCopyOutlinedIcon fontSize="small" className={classes.icon} /> Duplicate
                                        </MenuItem>
                                    )}
                                    {UserFeature.HasAccess(UserFeatureEnum.hasViewMatterTask) && (
                                        <MenuItem 
                                            onClick={event => handleView(event)}
                                            disabled={props.disabledMenu?.view}
                                        >
                                            <VisibilityIcon fontSize="small" className={classes.icon} /> View
                                        </MenuItem>
                                    )}
                                    {UserFeature.HasAccess(UserFeatureEnum.hasAllocateTask) && props.entityType !== 1 && (
                                        <MenuItem 
                                            onClick={event => handleAllocate(event)}
                                            disabled={props.disabledMenu?.allocate}
                                        >
                                            <PersonIcon fontSize="small" className={classes.icon} /> Assign
                                        </MenuItem>
                                    )}
                                    
                                    {props.entityType !== 1 && (
                                        <MenuItem 
                                            onClick={event => handleOwner(event)}
                                            disabled={props.disabledMenu?.owner}
                                        >
                                            <AccountBoxIcon fontSize="small" className={classes.icon} /> Owner
                                        </MenuItem>
                                    )}
                                    {props.entityType !== 1 && (
                                        <MenuItem 
                                            onClick={handleStart}
                                            disabled={props.disabledMenu?.start}
                                        >
                                            <PlayArrowIcon fontSize="small" className={classes.icon} /> Start
                                        </MenuItem>
                                    )}
                                    
                                    {props.entityType !== 1 && (
                                        <NestedMenuItem
                                            label={(    
                                                <>
                                                    <EditIcon fontSize="small" className={classes.icon} /> Change
                                                </>
                                            )}
                                            parentMenuOpen={!!open}
                                        >
                                            <MenuItem 
                                                onClick={handleStatus}
                                                disabled={props.disabledMenu?.status}
                                            >
                                                <EqualizerIcon fontSize="small" className={classes.icon} /> Status...
                                            </MenuItem>
                                            <MenuItem 
                                                onClick={handlePriority}
                                                disabled={props.disabledMenu?.priority}
                                            >
                                                <PriorityHighIcon fontSize="small" className={classes.icon} /> Priority...
                                            </MenuItem>
                                            <MenuItem 
                                                onClick={handleStartDate}
                                                disabled={props.disabledMenu?.startDate}
                                            >
                                                <TodayIcon fontSize="small" className={classes.icon} /> Start...
                                            </MenuItem>
                                            <MenuItem 
                                                onClick={handleDueDate}
                                                disabled={props.disabledMenu?.dueDate}
                                            >
                                                <EventNoteIcon fontSize="small" className={classes.icon} /> Due...
                                            </MenuItem>
                                            <MenuItem 
                                                onClick={handleCompletionDate}
                                                disabled={props.disabledMenu?.completionDate}
                                            >
                                                <EventAvailableIcon fontSize="small" className={classes.icon} /> Completion...
                                            </MenuItem>
                                            <MenuItem 
                                                onClick={handleCategory}
                                                disabled={props.disabledMenu?.category}
                                            >
                                                <CategoryIcon fontSize="small" className={classes.icon} /> Category...
                                            </MenuItem>
                                        </NestedMenuItem>
                                    )}
                                    
                                    {props.entityType !== 1 && (
                                        <MenuItem 
                                            onClick={event => handleComplete(event)}
                                            disabled={props.disabledMenu?.complete}
                                        >
                                            <CheckIcon fontSize="small" className={classes.icon} /> Complete
                                        </MenuItem>
                                    )}
                                    
                                    {UserFeature.HasAccess(UserFeatureEnum.hasDeleteTask) && (
                                        <MenuItem 
                                            onClick={event => handleDelete(event)}
                                            disabled={props.disabledMenu?.delete}
                                        >
                                            <CloseIcon fontSize="small" className={classes.deleteIcon} /> Delete
                                        </MenuItem>
                                    )}

                                    {/* Enquiry Related Tasks  */}
                                    {/* {UserFeature.HasAccess(UserFeatureEnum.hasAssignLead) 
                                        && props.entityType === 1
                                        ?                                        
                                            <MenuItem 
                                                onClick={handleAssign} 
                                                disabled={props.disabledMenu?.isEnquiryClosed}
                                            >
                                                Assign
                                            </MenuItem>                                        
                                        : null
                                    }

                                    {UserFeature.HasAccess(UserFeatureEnum.hasEmailLeadAssignee) 
                                        && props.assignedToEmailAddress 
                                        && props.entityType === 1
                                        ?   
                                            <MenuItem 
                                                onClick={handleEmailAssignee} 
                                                disabled={props.disabledMenu?.isEnquiryClosed}
                                            >
                                                Email Assignee
                                            </MenuItem>
                                        : null
                                    }

                                    {UserFeature.HasAccess(UserFeatureEnum.hasFollowUpLead) && props.entityType === 1
                                        && props.enquiryStatus 
                                        && props.enquiryStatus !== 'Unassigned'
                                        // && props.data.assignedTo !== null
                                            ?
                                                <MenuItem 
                                                    onClick={handleFollowUp} 
                                                    disabled={props.disabledMenu?.isEnquiryClosed}
                                                >
                                                    Follow Up
                                                </MenuItem>
                                            : null
                                    }

                                    {UserFeature.HasAccess(UserFeatureEnum.hasCreateClient)  && props.entityType === 1
                                        && props.enquiryStatus
                                        && props.enquiryStatus === 'Pending Client' // Create Client Pending
                                            ?
                                                <MenuItem 
                                                    onClick={handleCreateClient}
                                                >
                                                    Create Client
                                                </MenuItem>
                                            : null
                                    }
                                        
                                    {UserFeature.HasAccess(UserFeatureEnum.hasLeadsDetail) && props.entityType === 1
                                        ? 
                                            <MenuItem onClick={handleEnquiryView}>
                                                History
                                            </MenuItem>
                                        : null
                                    }     */}
                                    
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </div>
    );
};
