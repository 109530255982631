/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import {
    Button,
    Dialog,
    DialogTitle,
    DialogActions,
    DialogContent,
    Grid,
    TextField,
} from '@material-ui/core';
import { AnyObject, Field, FieldInputProps, Form, FormSpy } from 'react-final-form';
import { FormApi } from 'final-form';
import { showNotification } from '../../App';
import { RvLoader } from '../../components/Loader';
import { client } from '../..';
import { IAutoCompleteItem } from '../../typings/autoComplete';
import { ChangeTaskStatus, retrieveTaskDetailsData, TaskDetailsData, TaskDetailsParam } from '../TasksSummaryRepository';
import DialogBox from '../../Dashboard/Component/DialogBox';
import { TaskAssignedBySelector } from '../selectors/TaskAssignedBySelector';
import moment from 'moment';
import { TaskStatusSelector } from '../selectors/TaskStatusSelector';
import { KeyboardDateTimePicker } from '@material-ui/pickers/DateTimePicker/DateTimePicker';
import { TimeNumberFormatCustom } from '../../components/TimeNumberFormat';
import { AllocatedToEntityType } from '../AllocatedToEntityType';
import { TaskAssignedByUserTeamSelector } from '../selectors/TaskAssignedByUserTeamSelector';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        dialogRoot: {
            '& .downshift-wrapper': {
                flex: 1,
                padding: 0,
            }
        },
        header: {
            textTransform: 'capitalize',
        },
        textField: {
            width: '100%',
        },
        content: {
            flex: 1
        },
        buttonContainer: {
            display: 'flex',
            justifyContent: 'space-between',
            // paddingBottom: '20px'
        },
        button: {
            margin: theme.spacing(1),
            minWidth: '83px',
        },
        hidden: {
            display: 'none',
        },
        loaderWrapper: {
            display: 'flex',
            justifyContent: 'center',
            padding: '20px 0',
        },
        clientSelector: {
            flex: 1
        },        
        commendField: {
            width: '100%',
        }
    })
);

interface StatusFormDialogProps {
    open: boolean;
    onClose?: () => void;
    title?: string;
    guid: string;
    initialStatus: IAutoCompleteItem | null;
    destinationStatus: IAutoCompleteItem | null;
}

interface StatusFormDialogState {
    open: boolean;
    isLoading: boolean;
    onSubmitLoading: boolean;
    showDiscard: boolean;
    isChanged: boolean;
    isUserLoading: boolean;
}

interface StatusForm {
    assignTo: IAutoCompleteItem | null;
    assignedBy: IAutoCompleteItem | null;
    startDate: Date | null;
    completionDate: Date | null;
    duration: string | null;
    estimatedEffort: string | null;
    status: IAutoCompleteItem | null;
    title: string | null;
    completionNotes: string | null;
    owner: IAutoCompleteItem | null;
    allocatedToEntityType: number | null;
    dueDate: Date | null;
}

export const StatusFormDialog: React.FC<StatusFormDialogProps> = (props) => {
    const classes = useStyles();

    let draggedToUnallocated = props.destinationStatus?.label === 'Unassigned';
    let draggedToWaiting = props.destinationStatus?.label === 'Not Started';
    let draggedToInProgress = props.destinationStatus?.label === 'In Progress';
    let draggedToComplete = props.destinationStatus?.label === 'Complete';
    
    const [state, setState] = useState<StatusFormDialogState>({
        open: false,
        isLoading: false,
        onSubmitLoading: false,
        showDiscard: false,
        isChanged: false,
        isUserLoading: false,
    });

    const [statusForm, setStatusForm] = useState<StatusForm>({
        assignTo: null,
        assignedBy: null,
        startDate: null,
        completionDate: null,
        duration: null,
        estimatedEffort: null,
        status: props.initialStatus,
        title: null,
        completionNotes: null,
        owner: null,
        allocatedToEntityType: null,
        dueDate: null,
    });

    useEffect(() => {

        if (props.guid) {
            fetchTaskDetails(props.guid);
        }
        setState((prevState) => {
            return {
                ...prevState,
                open: props.open
            };
        });
    // tslint:disable-next-line: align
    }, [props.open, props.guid]);

    const fetchTaskDetails = (guid: string) => {

        setState((prevState) => {
            return {
                ...prevState,
                isLoading: true
            };
        });

        const taskDetailsParams: TaskDetailsParam = {
            guid: guid
        };

        retrieveTaskDetailsData(
            taskDetailsParams,
            true,
            // tslint:disable-next-line: no-console
            (data: TaskDetailsData) => onTaskDetailsRetrieved(data),
            // tslint:disable-next-line: no-any
            function (reason: any): void {
                showNotification(null, reason, 'error');
            }
        );

    };

    const onTaskDetailsRetrieved = (data: TaskDetailsData) => {
        // tslint:disable-next-line: no-console
        if (data && data.task && data.task.detail) {

            const taskDetails = data.task.detail;
            const appUser = data.appUser;

            let completionDate: Date | null = null;
            let startDate: Date | null = null;

            if (draggedToUnallocated) {
                startDate = null;
                completionDate = null;
            } else if (draggedToWaiting) {
                startDate = null;
                completionDate = null;
            } else if (draggedToInProgress) {
                startDate = moment().toDate();
                completionDate = taskDetails.completionDate ? moment(taskDetails.completionDate).toDate() : null;
            } else  if (draggedToComplete) {
                startDate = taskDetails.startDate ? moment(taskDetails.startDate).toDate() : null;
                completionDate = moment().toDate();
            } else {
                startDate = taskDetails.startDate ? moment(taskDetails.startDate).toDate() : null;
                completionDate = taskDetails.completionDate ? moment(taskDetails.completionDate).toDate() : null;  
            }

            setStatusForm((prevStatusForm) => {
                return {
                    ...prevStatusForm,
                    startDate: startDate,
                    completionDate: completionDate, 
                    // tslint:disable-next-line: max-line-length
                    estimatedEffort: taskDetails.estimatedEffort ? taskDetails.estimatedEffort : null,
                    // tslint:disable-next-line: max-line-length
                    assignedBy: taskDetails.assignedBy && taskDetails.assignedByName ? {label: taskDetails.assignedByName, value: taskDetails.assignedBy} : {label: appUser.name, value: appUser.userId},
                    // tslint:disable-next-line: max-line-length
                    assignTo: taskDetails.assignedTo && taskDetails.assignedToName ? {label: taskDetails.assignedToName, value: taskDetails.assignedTo} : null,
                    duration: taskDetails.duration ? taskDetails.duration : null,
                    title: taskDetails.description,
                    completionNotes: taskDetails.completionNotes,
                    owner: taskDetails.owner && taskDetails.ownerName ? {label: taskDetails.ownerName, value: taskDetails.owner} : null,
                    allocatedToEntityType: taskDetails.allocatedToEntityType, 
                    dueDate: taskDetails.dueDate ? moment(taskDetails.dueDate).toDate() : null,
                };
            });
        }

        setState((prevState) => {
            return {
                ...prevState,
                isLoading: false
            };
        });
    };

    const onSubmit = (form: FormApi<StatusForm>, values: AnyObject) => {
        setState((prevState) => {
            return {
                ...prevState,
                onSubmitLoading: true
            };
        });

        client.mutate({
            mutation: ChangeTaskStatus,
            variables: {
                guid: props.guid, 
                startDate: values.startDate ? values.startDate : null,
                completionDate: values.completionDate ? values.completionDate : null,
                dueDate: statusForm.dueDate ? moment(statusForm.dueDate).toDate() : null, // values.dueDate ? values.dueDate : null,
                estimatedEffort: values.estimatedEffort ? values.estimatedEffort : null,
                duration: values.duration ? values.duration : null,
                assignedTo: values.assignTo ? values.assignTo.value : null,
                assignedBy: values.assignedBy ? values.assignedBy.value : null,
                completionNotes: values.completionDate ? values.completionNotes : null,
                changedStatus: values.status ? values.status.label : null,
                owner: values.owner ? values.owner.value : null,
                allocatedToEntityType: values.allocatedToEntityType,
            },
        })
        // tslint:disable-next-line: no-any
        .then((results: { data: any }) => {
            if (results.data) {
                if (results.data.error === null || results.data.error === undefined) {
                    showNotification(null, 'Successfully submitted', 'info');     
                    closePanel();                   
                } else {
                    showNotification('Failed to update priority', results.data.error, 'error');
                }

                setState((prevState) => {
                    return {
                        ...prevState,
                        onSubmitLoading: false
                    };
                });
            }
        })
        // tslint:disable-next-line:no-any
        .catch((reason: any) => {
            showNotification('Failed to update priority', reason, 'error');
            setState((prevState) => {
                return {
                    ...prevState,
                    onSubmitLoading: false
                };
            });
        });
    };

    const closePanel = () => {
        // codepanel
        if (props.onClose) {
            return props.onClose();
        }
    };

    const onReset = () => {
        // reset
    };

    // tslint:disable-next-line: no-any
    const onFormValueChanged = (form: FormApi<StatusForm>, changeProps: any) => {
        if (!changeProps.pristine) {
            setState((prevState) => {
                return {
                    ...prevState,
                    isChanged: true
                };
            });           
        }
    };

    const onClose = () => {
        if (state.isChanged) {
            setState((prevState) => {
                return {
                    ...prevState,
                    showDiscard: true
                };
            });
        } else {
            if (props.onClose) {
                props.onClose();
            }
        }
    };

    const onDiscardChanges = (agree: boolean) => {
        if (agree) {
            setState((prevState) => {
                return {
                    ...prevState,
                    showDiscard: false,
                };
            });

            if (props.onClose) {
                props.onClose();
            }

        } else {
           setState((prevState) => {
               return {
                   ...prevState,
                   showDiscard: false
               };
           });
        }
    };

    // tslint:disable-next-line: no-any
    const required = (value: any) => {
        return value ? undefined : 'Required';
    };

    const actionButton = (
        // tslint:disable-next-line: no-any
        form: FormApi<StatusForm>,
        submitting: boolean,
        pristine: boolean,
        // tslint:disable-next-line: no-any
        values: AnyObject
    ) => {
        return (
            <div className={classes.buttonContainer}>
                {/* <RenderCount /> */}
                <div>
                    <Button
                        color="primary"
                        type="submit"
                        className={classes.button}
                        onClick={() => {
                            // code here..
                        }}
                        disabled={(submitting || pristine || state.onSubmitLoading)}
                    >
                        Update
                    </Button>
                </div>
                <div>
                    <Button
                        id="resetButton"
                        type="button"
                        onClick={onReset}
                        disabled={submitting || pristine}
                        className={classes.hidden}
                    >
                        Reset
                    </Button>
                    <Button
                        color="primary"
                        type="button"
                        className={classes.button}
                        onClick={closePanel}
                    >
                        Cancel
                    </Button>
                </div>
            </div>
        );
    };

    // tslint:disable-next-line: no-any
    const onDropDownSelect = (form: FormApi<StatusForm>, values: AnyObject, input: FieldInputProps<any, HTMLElement>, 
                              // tslint:disable-next-line: no-any
                              selection: IAutoCompleteItem | any, name: string, isUser: boolean) => {
        if (name === 'assignTo') {
            let assignedTo = selection && selection.value && selection.label 
                                ? { value : selection.value, label: selection.label }
                                : null;
            form.batch(() => {
                form.change('assignTo', assignedTo);
                form.change('allocatedToEntityType', isUser 
                    ? AllocatedToEntityType.user 
                    : AllocatedToEntityType.team
                );                
            });
        }
    };

    const getAssignedToRequired = (values: AnyObject) => {
        // Do the validation on Existing form values
        if (values.status) {
            if (values.status.label === 'Not Started' || values.status.label === 'In Progress' || values.status.label === 'Complete') {
                return true;
            }
        }

        // Not requried for any other status
        return false;
    };

    const getStartDateRequired = (values: AnyObject) => {
        // Do the validation on Existing form values
        if (values.status) {
            if (values.status.label === 'In Progress' || values.status.label === 'Complete') {
                return true;
            }
        }

        // Not requried for any other status
        return false;
    };

    const getCompletionDateRequired = (values: AnyObject) => {
        // Do the validation on Existing form values
        if (values.status) {
            if (values.status.label === 'Complete') {
                return true;
            }
        }

        // Not requried for any other status
        return false;
    };

    // tslint:disable-next-line: no-any
    const onStatusChange = (input: FieldInputProps<any, HTMLElement>, selection: IAutoCompleteItem, form: FormApi<StatusForm>) => {
        input.onChange(selection);

        // Get the current Form's state and do the logic on that value.
        const formState = form.getState();

        if (formState && formState.values.status) {
            if (formState.values.status.label === 'Unassigned') {
                form.change('assignTo', null);
                form.change('startDate', null);
                form.change('completionDate', null);
                form.change('duration', moment().startOf('day').toISOString());
            } else if (formState.values.status.label === 'Not Started') {
                form.change('startDate', null);
                form.change('completionDate', null);
                form.change('duration', moment().startOf('day').toISOString());
            } else if (formState.values.status.label === 'In Progress') {
                if (!formState.values.startDate) {
                    form.change('startDate', moment().toDate());
                }
                
                form.change('completionDate', null);
                form.change('duration', moment().startOf('day').toISOString());
            } else if (formState.values.status.label === 'Complete') {
                form.change('completionDate', moment().toDate());
            }
        }
    };

    return (
        <>
            <DialogBox
                title="Status"
                // tslint:disable-next-line:max-line-length
                content={`Are you sure you want to close the form?`}
                show={state.showDiscard}
                isAgree={onDiscardChanges}
                disAgreeLabel={'No'}
                agreeLabel={'Yes'}
            />
            
            <Dialog
                open={state.open}
                onClose={onClose}
                maxWidth="sm"
                fullWidth={true}
                className={classes.dialogRoot}
                scroll={'paper'}
            >
                {state.isLoading || state.isUserLoading ? (
                    <DialogContent dividers={true}>
                        <div className={classes.loaderWrapper}>
                            <RvLoader size="small" label="Loading..."/>
                        </div>
                    </DialogContent>
                ) : (
                    <Form
                        onSubmit={(values, form: FormApi<StatusForm>) => onSubmit(form, values)}                
                        initialValues={statusForm}
                        subscription={{ submitting: true, pristine: true }}
                        validate={(values) => {
                            let errors = {
                                assignTo: '',
                                startDate: '',
                                completionDate: '',
                                owner: ''
                            };

                            if (statusForm.status?.label === 'Unassigned') {
                                if (values.status?.label === 'Not Started' && !values.assignTo) {
                                    errors.assignTo = 'Assigned To is required';
                                } else if (values.status?.label === 'Not Started' && !values.owner) {
                                    errors.owner = 'Owner is required';
                                } else if (values.status?.label === 'In Progress' && !values.assignTo) {
                                    errors.assignTo = 'Assigned To is required';
                                } else if (values.status?.label === 'In Progress' && !values.owner) {
                                    errors.owner = 'Owner is required';
                                } else if (values.status?.label === 'Complete') {
                                    if (!values.assignTo) {
                                        errors.assignTo = 'Assigned To is required';
                                    }

                                    if (!values.owner) {
                                        errors.owner = 'Owner is required';
                                    }

                                    if (!values.startDate) {
                                        errors.startDate = 'Start date is required';
                                    }

                                    if (!values.completionDate) {
                                        errors.completionDate = 'Completion date is required';
                                    }
                                }
                            }

                            if (statusForm.status?.label === 'Not Started') {
                                if (values.status?.label === 'In Progress' && !values.startDate) {
                                    errors.startDate = 'Start date is required';
                                } 

                                if (values.status?.label === 'Complete' && !values.completionDate) {
                                    errors.completionDate = 'Completion date is required';
                                } 
                            }

                            if (errors.assignTo === '' && errors.startDate === '' && errors.completionDate === '' && errors.owner === '') {
                                return undefined;
                            }

                            // tslint:disable-next-line: no-console
                            console.log('Errors', errors);
                            return errors;
                        }}
                        render={({handleSubmit, form, submitting, pristine, values}) => (
                            <form onSubmit={handleSubmit} id="taskForm">
                                
                                <FormSpy 
                                    subscription={{ pristine: true, values: true }}
                                    // tslint:disable-next-line: no-shadowed-variable
                                    onChange={(spyProps) => {
                                        onFormValueChanged(form, spyProps);

                                        if (props.destinationStatus && props.initialStatus !== props.destinationStatus) {
                                            // this status change when from source to destination
                                            form.change('status', props.destinationStatus);
                                        }
                                    }}
                                />

                                <DialogTitle className={classes.header}>{statusForm.title}</DialogTitle>

                                <DialogContent dividers={true}>
                                    <Grid container={true} spacing={3}>
                                        <Grid item={true} xs={12} md={12}>
                                            <Grid container={true} spacing={3}>
                                                <Grid item={true} xs={12} md={6}>
                                                    <Field
                                                        name="status"
                                                        validate={required}
                                                        subscription={{
                                                            touched: true, 
                                                            error: true, 
                                                            value: true,
                                                        }}
                                                    >
                                                        {({ input, meta }) => (
                                                            <TaskStatusSelector 
                                                                {...input}
                                                                name="status"
                                                                label="Status"
                                                                value={input.value ? input.value : undefined}
                                                                required={true}
                                                                disablePortal={false}
                                                                disabled={props.destinationStatus ? true : false}
                                                                // required={true}
                                                                className={`${classes.textField} ${classes.clientSelector}`}
                                                                error={meta.error && meta.touched}
                                                                    // tslint:disable-next-line: jsx-alignment
                                                                onSelection={(selection: IAutoCompleteItem, name: string) => {
                                                                    onStatusChange(input, selection, form);
                                                                }}
                                                                helperText={
                                                                    meta.error &&
                                                                    meta.touched
                                                                        ? 'Status is required'
                                                                        : ''
                                                                }
                                                                isRefresh={false}
                                                                isIncludeCount={false}
                                                            />   
                                                        )}
                                                    </Field>
                                                </Grid>
                                                <Grid item={true} xs={12} md={6}>
                                                    <FormSpy 
                                                        subscription={{values: true, validating: true}}
                                                    >
                                                        {/* tslint:disable-next-line: no-shadowed-variable */}
                                                        {({ values }) => (
                                                            <Field
                                                                name="owner"
                                                                subscription={{
                                                                    touched: true, 
                                                                    error: true, 
                                                                    value: true,
                                                                }}
                                                            >
                                                                {({ input, meta }) => (
                                                                    <TaskAssignedBySelector
                                                                        {...input}
                                                                        name="owner"
                                                                        label="Owner"
                                                                        value={input.value ? input.value : undefined}
                                                                        disablePortal={false}
                                                                        required={getAssignedToRequired(values)}
                                                                        className={`${classes.textField} ${classes.clientSelector}`}
                                                                        error={meta.error && meta.touched}
                                                                            // tslint:disable-next-line: jsx-alignment
                                                                        onSelection={(selection: IAutoCompleteItem, name: string) => 
                                                                            input.onChange(selection)
                                                                        }
                                                                        helperText={
                                                                            meta.error &&
                                                                            meta.touched
                                                                                ? meta.error // 'Assigned to is required'
                                                                                : ''
                                                                        }
                                                                        visible={{
                                                                            role: true
                                                                        }}
                                                                    />
                                                                )}
                                                            </Field>
                                                        )}
                                                    </FormSpy>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item={true} xs={12} md={6}>
                                            <FormSpy subscription={{ values: true, validating: true }}>
                                                {/* tslint:disable-next-line: no-shadowed-variable */}
                                                {({ values }) => (
                                                    <Field
                                                        name="assignTo"
                                                        subscription={{touched: true, error: true, value: true}}
                                                    >
                                                        {({ input, meta }) => (
                                                            <TaskAssignedByUserTeamSelector
                                                                {...input}
                                                                name="assignTo"
                                                                label="Assigned To"
                                                                value={input.value ? input.value : null}
                                                                disablePortal={false}
                                                                required={getAssignedToRequired(values)}
                                                                // required={true}
                                                                className={`${classes.textField} ${classes.clientSelector}`}
                                                                error={meta.error && meta.touched}
                                                                // tslint:disable-next-line: jsx-alignment
                                                                onSelection={(selection: IAutoCompleteItem, name: string, isUser: boolean) => 
                                                                    onDropDownSelect(form, values, input, selection, name, isUser)
                                                                }
                                                                helperText={
                                                                    meta.error &&
                                                                    meta.touched
                                                                        ? meta.error
                                                                        : ''
                                                                }
                                                                visible={{
                                                                    role: true
                                                                }}
                                                            />
                                                        )}
                                                    </Field>
                                                )}
                                            </FormSpy>
                                        </Grid>
                                        <Grid item={true} xs={12} md={6}>
                                            <Field
                                                name="assignedBy"
                                                subscription={{touched: true, error: true, value: true}}
                                            >
                                                {({ input, meta }) => (
                                                    <TaskAssignedBySelector
                                                        {...input}
                                                        name="assignedBy"
                                                        label="Assigned By"
                                                        value={input.value ? input.value : undefined}
                                                        disablePortal={false}
                                                        required={true}
                                                        className={`${classes.textField} ${classes.clientSelector}`}
                                                        error={meta.error && meta.touched}
                                                        // tslint:disable-next-line: jsx-alignment
                                                        onSelection={(selection: IAutoCompleteItem, name: string) => 
                                                            input.onChange(selection)
                                                        }
                                                        helperText={
                                                            meta.error &&
                                                            meta.touched
                                                                ? 'Assigned by is required'
                                                                : ''
                                                        }
                                                        visible={{
                                                            role: true
                                                        }}
                                                    />
                                                )}
                                            </Field>
                                        </Grid>
                                        <Grid item={true} xs={12} md={6}>
                                            <FormSpy 
                                                subscription={{values: true, validating: true}}
                                            >
                                                {/* tslint:disable-next-line: no-shadowed-variable */}
                                                {({ values }) => (
                                                    <Field
                                                        name="startDate"
                                                        // validate={form.getFieldState('startDate')?.value !== null ? required : undefined}
                                                        subscription={{touched: true, error: true, value: true}}   
                                                        value={values.startDate}                                     
                                                    >
                                                        {({ input, meta }) => (
                                                            <KeyboardDateTimePicker
                                                                {...input}
                                                                className={classes.textField}
                                                                id="startDate"
                                                                name="startDate"
                                                                label="Start Date"
                                                                format="DD/MM/YYYY hh:mm a"
                                                                placeholder="dd/mm/yyyy hh:mm a"
                                                                animateYearScrolling={true}
                                                                required={getStartDateRequired(values)}
                                                                // tslint:disable-next-line: max-line-length
                                                                autoOk={true}
                                                                allowKeyboardControl={true}
                                                                variant={'inline'}
                                                                error={meta.error && meta.touched}
                                                                inputProps={{autoComplete: 'off'}}
                                                                InputLabelProps={{ shrink: true }}
                                                                helperText={
                                                                    meta.error &&
                                                                    meta.touched
                                                                        ? meta.error // 'Start date is required'
                                                                        : ''
                                                                }     
                                                                value={values.startDate}                                                          
                                                            />
                                                        )}
                                                    </Field>
                                                )}
                                            </FormSpy>
                                        </Grid>
                                        <Grid item={true} xs={12} md={6}>
                                            <FormSpy 
                                                subscription={{values: true, validating: true}}
                                            >
                                                {/* tslint:disable-next-line: no-shadowed-variable */}
                                                {({ values }) => (
                                                    <Field
                                                        name="completionDate"
                                                        // validate={form.getFieldState('completionDate')?.value !== null ? required : undefined}
                                                        subscription={{touched: true, error: true, value: true}}
                                                        value={values.completionDate}      
                                                    >
                                                        {({ input, meta }) => (
                                                            <KeyboardDateTimePicker
                                                                {...input}
                                                                className={classes.textField}
                                                                id="completionDate"
                                                                name="completionDate"
                                                                label="Completion"
                                                                format="DD/MM/YYYY hh:mm a"
                                                                placeholder="dd/mm/yyyy hh:mm a"
                                                                animateYearScrolling={true}
                                                                required={getCompletionDateRequired(values)}
                                                                // tslint:disable-next-line: max-line-length
                                                                autoOk={true}
                                                                allowKeyboardControl={true}
                                                                variant={'inline'}
                                                                error={meta.error && meta.touched}
                                                                inputProps={{autoComplete: 'off'}}
                                                                InputLabelProps={{ shrink: true }}
                                                                helperText={
                                                                    meta.error &&
                                                                    meta.touched
                                                                        ? meta.error // 'Completion date is required'
                                                                        : ''
                                                                }
                                                                value={values.completionDate}      
                                                            />
                                                        )}
                                                    </Field>
                                                )}
                                            </FormSpy>
                                        </Grid>
                                        <Grid item={true} xs={12} md={6}>
                                            <Field
                                                name="estimatedEffort"
                                                // validate={form.getFieldState('estimatedEffort')?.value !== null ? required : undefined}
                                                subscription={{
                                                    touched: true, 
                                                    error: true, 
                                                    value: true,
                                                }}
                                            >
                                                {({ input, meta }) => (
                                                    <TextField
                                                        {...input}
                                                        label="Estimated Effort"
                                                        name="estimatedEffort"
                                                        InputProps={{
                                                            // tslint:disable-next-line: no-any
                                                            inputComponent: TimeNumberFormatCustom   as any
                                                        }}
                                                        className={classes.textField}
                                                        error={meta.error && meta.touched}
                                                        helperText={
                                                            meta.error &&
                                                            meta.touched
                                                                ? 'Estiamted Effort is required'
                                                                : ''
                                                        }
                                                        placeholder="00:00"
                                                    />
                                                )}
                                            </Field>
                                        </Grid>
                                        <Grid item={true} xs={12} md={6}>
                                            <Field
                                                name="duration"
                                                // validate={form.getFieldState('duration')?.value !== null ? required : undefined}
                                                subscription={{touched: true, error: true, value: true}}
                                            >
                                                {({ input, meta }) => (
                                                    <TextField
                                                        {...input}
                                                        name="duration"
                                                        label="Actual Effort"
                                                        InputProps={{
                                                            // tslint:disable-next-line: no-any
                                                            inputComponent: TimeNumberFormatCustom   as any
                                                        }}
                                                        className={classes.textField}
                                                        error={meta.error && meta.touched}
                                                        helperText={
                                                            meta.error &&
                                                            meta.touched
                                                                ? 'Duration is required'
                                                                : ''
                                                        }
                                                        placeholder="00:00"
                                                    />
                                                )}
                                            </Field>
                                        </Grid>
                                        <Grid item={true} xs={12}>
                                            <FormSpy 
                                                subscription={{values: true, validating: true}}
                                            >
                                                {/* tslint:disable-next-line: no-shadowed-variable */}
                                                {({ values }) => ( 
                                                    values.completionDate ? (
                                                        <Field
                                                            name="completionNotes"
                                                        >
                                                            {({ input, meta }) => (
                                                                <TextField
                                                                    {...input}
                                                                    label="Completion Notes"
                                                                    multiline={true}
                                                                    className={classes.commendField}
                                                                    // disabled={props.formAction === 'edit'}
                                                                />
                                                            )}
                                                        </Field>
                                                    )
                                                    : null                                          
                                                )}
                                            </FormSpy>  
                                        </Grid>         
                                    </Grid>   
                                                                    
                                    {/* <div>
                                        {printJson(values)}
                                    </div> */}
                                </DialogContent>
                                <DialogActions>
                                    {actionButton(form, submitting, pristine, values)}
                                </DialogActions>
                            </form>
                        )} 
                    />
                )}
            </Dialog>
        </>
    );
};

// tslint:disable-next-line: no-any
// function printJson(values: StatusForm) {
//     if (values) {
//         return (
//             <>
//                 <pre>
//                     {JSON.stringify(values, undefined, 2)}
//                 </pre>
//             </>
//         );
//     } else {
//         return (
//             <>
//                 <FormSpy subscription={{ values: true }}>
//                     {/* tslint:disable-next-line: no-shadowed-variable */}
//                     {({ values }) => (
//                         <pre>
//                             {JSON.stringify(values, undefined, 2)}
//                             {/* <RenderCount /> */}
//                         </pre>
//                     )}
//                 </FormSpy>
//             </>
//         );
//     }
// }