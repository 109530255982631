/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
// import { name, random } from 'faker';
import gql from 'graphql-tag';
import { FetchPolicy } from 'apollo-client';
import PersonIcon from '@material-ui/icons/Person';
import PersonOutlinedIcon from '@material-ui/icons/PersonOutlined';
import PeopleOutlineOutlinedIcon from '@material-ui/icons/PeopleOutlineOutlined';
import { TextField, CircularProgress } from '@material-ui/core';
import { IAutoCompleteItem } from '../../typings/autoComplete';
import { showNotification } from '../../App';
import { client } from '../..';
import { Autocomplete, RenderInputParams } from '@material-ui/lab';
// import match from 'autosuggest-highlight/match';
// import parse from 'autosuggest-highlight/parse';
import { FirmData, Team } from '../../lookupConfiguration/models/Firm';
import clsx from 'clsx';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
        minWidth: '200px',
    },
    textField: {
        width: '100%',
    },
    iconSelected: {
        width: 17,
        height: 17,
        marginRight: 5,
        marginLeft: -2,
      },
      color: {
        width: 14,
        height: 14,
        flexShrink: 0,
        borderRadius: 3,
        marginRight: 8,
        marginTop: 2,
      },
      text: {
        flexGrow: 1,
      },
      close: {
        opacity: 0.6,
        width: 18,
        height: 18,
      },
      renderWrapper: {
          padding: '15px 0'
      },
      customRenderWrapper: {
        display: 'flex',
        margin: '0 -10px',
        width: '100%',
        '& > div': {
            padding: '0 10px',
            fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
            fontWeight: 400,
        },
        '& .icon-wrapper': {
            display: 'flex',
            alignItems: 'center',
        },
        '& .other': {
            flex: 1,
            display: 'flex',
            flexFlow: 'column',
            '& .primary': {
                fontSize: '1rem',
                lineHeight: 1.5,
                letterSpacing: '0.00938em',
            },
            '& .secondary': {
                color: 'rgba(0, 0, 0, 0.54)',
                fontSize: '0.875rem',
                lineHeight: 1.43,
                letterSpacing: '0.01071em',
            },
            '& .third': {
                color: 'rgba(0, 0, 0, 0.54)',
                fontSize: '0.675rem',
                lineHeight: 1.43,
                letterSpacing: '0.01071em',
                fontWeight: 600
            }
        }
      }
  })
);

interface TaskAssignedByUserTeamSelectorProps {
    isStaffOnly?: boolean;
    multiple?: boolean;
    isExternal?: boolean;
    label?: string;
    name: string;
    // tslint:disable-next-line: no-any
    value?: IAutoCompleteItem | any; // value only available in single select
    onSelection?: (value: IAutoCompleteItem | IAutoCompleteItem[], name: string, isUser: boolean) => void;
    id?: string;
    required?: boolean;
    helperText?: string;
    error?: boolean;
    className?: string;
    disablePortal?: boolean;
    disabled?: boolean;
    visible?: {
        role: boolean;
    };
    isAuthorized?: boolean;
    excludeTeams?: boolean;
    onBlur?: React.FocusEventHandler<HTMLDivElement>;
}

interface TaskAssignedByUserTeamSelectorState {
    // tslint:disable-next-line: no-any
    items: any;
    isLoading: boolean;
}

export const TaskAssignedByUserTeamSelector: React.FC<TaskAssignedByUserTeamSelectorProps> = props => {
    const classes = useStyles();

    // fetch all records. 
    const loadBlockLimit = 20000;
    
    const [state, setState] = useState<TaskAssignedByUserTeamSelectorState>({
        items: [],
        isLoading: true
    });

    useEffect(() => {
        if (props.excludeTeams) {
            fetchUserData(undefined);
        } else {
            fetchTeamData();
        }
    // tslint:disable-next-line: align
    }, [props.excludeTeams]);

    // tslint:disable-next-line: no-any
    const fetchTeamData = () => {
        retrieveFirmTeamListData(
            false, // fetch from cache
            // tslint:disable-next-line: no-console
            (data) => onRetrieveTeamList(data),
            // tslint:disable-next-line:no-any
            function (reason: any): void {
                showNotification(null, reason, 'error');

                setState((prevState) => {
                    return {
                        ...prevState,
                        isLoading: false,
                    };
                });
            }
        );
    };

    // tslint:disable-next-line: no-any
    const onRetrieveTeamList = (data: FirmData) => {
        const teamsData = data.firm.teams.team;

        // tslint:disable-next-line: no-any
        let itemsList: any = [];
        
        itemsList = teamsData.map( (team: Team) => ({
            label: team.description,
            value: team.guidID,
            isStaff: false,
            email: null,
            roleName: null,
            isUser: false
        }));

        fetchUserData(itemsList);
    };

    // tslint:disable-next-line: no-any
    const fetchUserData = (itemsList: any) => {

        const userQueryParams: UserQueryParams = {
            filter: undefined,
            first: loadBlockLimit,
            offset: 0,
            isStaff: props.isStaffOnly ? props.isStaffOnly : undefined,
            isExternal: props.isExternal ? props.isExternal : false, // by default this should always return internal users
            isAuthorized: props.isAuthorized
        };

        retrieveUserListData(
            userQueryParams,
            false, // fetch from cache
            // tslint:disable-next-line: no-console
            (data) => onRetrieveUserList(data, itemsList),
            // tslint:disable-next-line:no-any
            function (reason: any): void {
                showNotification(null, reason, 'error');

                setState((prevState) => {
                    return {
                        ...prevState,
                        isLoading: false,
                    };
                });
            }
        );
    };

    // tslint:disable-next-line: no-any
    const onRetrieveUserList = (data: UserListData, itemsList: any) => {

        const userData = data.administrator.users.user;

        if (itemsList === undefined || itemsList === null) {
            itemsList = [];
        }          

        userData.forEach((source: User) => {
            itemsList.push({
                label: source.isStaff ? source.staffName : source.friendlyName,
                value: source.userID,                        
                isStaff: source.isStaff,
                email: source.email,
                roleName: source.roleName,
                isUser: true
            });
        });
        
        setState((prevState) => {
            return {
                ...prevState,
                items: itemsList,
                isLoading: false
            };
        });
    };    

    // tslint:disable-next-line: no-any
    const handleChange = (event: object, item: any) => {
        if (props.onSelection) {
            props.onSelection({label: item ? item.label : '', value: item ? item.value : ''}, props.name, item ? item.isUser : true);
        }      
    };

    const getInputProps = (params: RenderInputParams, isLoading?: boolean) => {
        return  ({   
            ...params.InputProps,
            endAdornment: (
                <React.Fragment>
                    {isLoading ? <CircularProgress color="primary" size={20} /> : null}
                    {params.InputProps.endAdornment}
                </React.Fragment>
              ),     
         });
    };

    return (
        <div className={clsx(classes.root, props.className)}>
            <Autocomplete
                loading={state.isLoading}
                disabled={props.disabled}
                autoSelect={true}
                value={props.value}
                onChange={handleChange}
                options={state.items}
                disablePortal={props.disablePortal} // to Stop the dropdown select from closing the form
                // tslint:disable-next-line: jsx-no-lambda
                getOptionLabel={option => option.label}    
                getOptionSelected={(option, value) => value.value === option.value}     
                onBlur={props.onBlur}           
                // tslint:disable-next-line: jsx-no-lambda
                renderInput={params => (
                    <TextField 
                        {...params} 
                        label={props.label} 
                        variant="standard" 
                        fullWidth={true} 
                        margin="none" 
                        required={props.required}
                        InputProps={getInputProps(params, state.isLoading)}
                        error={props.error}         
                        helperText={props.helperText}                   
                    />
                )}
                // tslint:disable-next-line: jsx-no-lambda
                renderOption={(option, { inputValue }) => {
                    // const matches = match(option.label, inputValue);
                    // const parts = parse(option.label, matches);
            
                    return (
                        <div className={classes.customRenderWrapper}>
                            <div className="icon-wrapper">
                                {   option.isUser 
                                        ? option.isStaff ? <PersonIcon fontSize="large"/> : <PersonOutlinedIcon fontSize="large"/>
                                        : <PeopleOutlineOutlinedIcon fontSize="large" />
                                }
                            </div>   
                            <div className="other">
                                <div className="primary">{option.label}</div>
                                {option.isUser && 
                                    <div>
                                        <div className="secondary">{option.email}</div>
                                        {!!props.visible && props.visible!.role && (
                                            <div className="third">{option.roleName}</div>
                                        )}
                                    </div>
                                }
                            </div>                         
                            {/* {parts.map((part, index) => (
                                <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                                    {part.text}
                                </span>
                            ))} */}
                        </div>
                    );
                }}
            />
        </div>
    );
};

export function retrieveUserListData(
    query: UserQueryParams,
    refreshData: boolean,
    onSuccess: (data: UserListData) => void,
    // tslint:disable-next-line:no-any
    onError: (reason: any) => void
): void {
    var fetchPolicy: FetchPolicy = refreshData === true ? 'network-only' : 'cache-first';
    client
        .query({
            query: UserListQuery,
            variables: {
                filter: query.filter,
                first: query.first,
                offset: query.offset,
                isStaff: query.isStaff,
                isExternal: query.isExternal,
                isAuthorized: query.isAuthorized
            },
            fetchPolicy: fetchPolicy,
        })
        // tslint:disable-next-line:no-any
        .then((results: { data: any }) => {
            onSuccess(results.data);
        })
        // tslint:disable-next-line:no-any
        .catch((reason: any) => {
            onError(reason);
        });
}

export interface UserQueryParams {
    offset?: number; 
    first?: number;
    filter?: string;
    isStaff?: boolean;
    isExternal?: boolean;
    isAuthorized?: boolean;
}

export interface UserListData {
    loading?: boolean;
    networkStatus?: number;
    administrator: Administrator;
}

export interface Administrator {
    users: UserList;    
}

export interface UserList {
    recordCount: number;
    user: User[];
}

export interface User {
    userID: string;
    email: string;
    friendlyName: string;
    roleName: boolean;
    isStaff: boolean;
    staffName: string;
    isExternal: boolean;
}

const UserListQuery = gql`
    query userQuery($offset: Int, $first: Int, $filter: String, $isStaff: Boolean, $isExternal: Boolean, $isAuthorized: Boolean){
        administrator{
            users(offset:$offset, first:$first, filter:$filter, isStaff: $isStaff, isExternal: $isExternal, isAuthorized: $isAuthorized){
                recordCount,
                user{
                    userID,
                    email,
                    friendlyName,
                    roleName
                    isStaff,
                    staffName,
                    isExternal
                }
            }
        }
    }
`;

export function retrieveFirmTeamListData(
    refreshData: boolean,
    onSuccess: (data: FirmData) => void,
    // tslint:disable-next-line:no-any
    onError: (reason: any) => void
): void {
    var fetchPolicy: FetchPolicy = refreshData === true ? 'network-only' : 'cache-first';
    client
        .query({
            query: FirmTeamDataQuery,
            variables: {},
            fetchPolicy: fetchPolicy,
        })
        // tslint:disable-next-line:no-any
        .then((results: { data: any }) => {
            onSuccess(results.data);
        })
        // tslint:disable-next-line:no-any
        .catch((reason: any) => {
            onError(reason);
        });
}

const FirmTeamDataQuery = gql`
query firmTeamData{
    firm{
        teams (onlyUsedByTasks: true){
            recordCount,
            team{
                guidID,
                description,
                staffCount
            }
        }
    }
}`;