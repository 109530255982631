/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import {
    FormControl,
    RadioGroup,
    FormControlLabel,
    Radio,
    RadioProps,
    TextField,
    Grid,
    Button,
    // FormLabel,
    Dialog,
    DialogTitle,
    DialogActions,
    DialogContent,
    FormLabel,
    Chip,
    Typography,
    Tabs,
    Tab,
    Divider,
    Checkbox,
    Badge,
} from '@material-ui/core';
import clsx from 'clsx';
import { Form, Field, FormSpy, FieldInputProps } from 'react-final-form';
import { FormApi, AnyObject } from 'final-form';
import { Editor, SyntheticEvent } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { ContentState, convertToRaw, EditorState } from 'draft-js';
import { PrioritySelector } from '../selectors/PrioritySelector';
import { IAutoCompleteItem } from '../../typings/autoComplete';
import { showNotification } from '../../App';
import {
    AppUser,
    AppUserData,
    CreateClientTask,
    CreateGeneralTask,
    CreateMatterTask,
    Detail,
    fetchAppUserData,
    retrieveTaskDetailsData,
    TaskAndPhoneMessageConfig,
    TaskDetailsData,
    TaskDetailsParam,
    // TaskSubscription,
    UpdateClientTask,
    UpdateGeneralTask,
    UpdateMatterTask,
} from '../TasksSummaryRepository';
import { TaskCategorySelector } from '../selectors/TaskCategorySelector';
import { TaskAssignedBySelector } from '../selectors/TaskAssignedBySelector';
import DialogBox from '../../Dashboard/Component/DialogBox';
import { client } from '../..';
// import moment from 'moment';
import draftToHtml from 'draftjs-to-html';
import { ClientAutoCompleteSelector } from '../../components/ClientAutoCompleteSelector';
import htmlToDraft from 'html-to-draftjs';
import { MatterAutoCompleteSelector } from '../../components/MatterAutoCompleteSelector';
import { RvLoader } from '../../components/Loader';
import { mainTheme } from '../../Theme';
import { KeyboardDateTimePicker } from '@material-ui/pickers/DateTimePicker/DateTimePicker';
import { TimeNumberFormatCustom } from '../../components/TimeNumberFormat';
import moment from 'moment';
import { TaskTypeGroupAutoCompleteItem, TaskTypeGroupSelector } from '../../lookupConfiguration/selectors/TaskTypeGroupSelector';
import { DropzoneArea } from 'material-ui-dropzone';
import { DocumentFile } from '../../types/DocumentFile';
import { TaskAssignedByUserTeamSelector } from '../selectors/TaskAssignedByUserTeamSelector';
import { AllocatedToEntityType } from '../AllocatedToEntityType';
import { TaskHistoryActivity, TaskHistoryActivityWidth } from '../common/TaskHistoryActivity';
import { TasksSummaryContext } from '../TasksSummaryContext';
import { MatterData, MatterDetails } from '../../matterSummary/models/Matter';
import { fetchBasicMatterDetailsData } from '../../matterSummary/MatterSummaryRepository';
import { ReminderSelector } from '../../components/ReminderSelector';
import { Message } from '../../ClientAndContactDetails/clientNotes/ClientNotesDetails';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        dialogRoot: {
            '& .downshift-wrapper': {
                flex: 1,
                padding: 0,
            },
        },
        divider: {
            // marginTop: 10,
            width: '100%',
            height: '1px',
        },
        header: {
            textTransform: 'capitalize',
        },
        formControlWrapper: {
            // height: '100%',
            display: 'flex',
            alignItems: 'flex-end',
        },
        textField: {
            width: '100%',
        },
        radioRoot: {
            '&:hover': {
                backgroundColor: 'transparent',
            },
        },
        icon: {
            borderRadius: '50%',
            width: 16,
            height: 16,
            boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
            backgroundColor: '#f5f8fa',
            backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
            '$root.Mui-focusVisible &': {
                outline: '2px auto rgba(19,124,189,.6)',
                outlineOffset: 2,
            },
            'input:hover ~ &': {
                backgroundColor: '#ebf1f5',
            },
            'input:disabled ~ &': {
                boxShadow: 'none',
                background: 'rgba(206,217,224,.5)',
            },
        },
        checkedIcon: {
            backgroundColor: '#137cbd',
            backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
            '&:before': {
                display: 'block',
                width: 16,
                height: 16,
                backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
                content: '""',
            },
            'input:hover ~ &': {
                backgroundColor: '#106ba3',
            },
        },
        radioGroup: {
            flexFlow: 'row',
        },
        clientContainer: {
            display: 'flex',
            alignItems: 'center',
            '& .downshift-wrapper': {
                flex: 1,
            },
        },
        chips: {
            display: 'flex',
            flexWrap: 'wrap',
        },
        chip: {
            marginLeft: '8px',
        },
        clientRowRadio: {},
        clientSelector: {
            flex: 1,
        },
        content: {
            flex: 1,
        },
        matterForm: {},
        clientForm: {},
        formControl: {
            '& .MuiFormGroup-root': {
                padding: '6px 7px',
            },
        },
        buttonContainer: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            // paddingBottom: '20px'
        },
        button: {
            margin: theme.spacing(1),
        },
        heading: {
            textTransform: 'capitalize',
        },
        headerDetails: {
            paddingTop: '20px',
            fontSize: '16px',
        },
        hidden: {
            display: 'none',
        },
        editorClassName: {
            padding: '0 10px',
            minHeight: '100px',
        },
        wrapperClassName: {
            border: '1px solid #949494',
            display: 'flex',
            flexFlow: 'column-reverse',
        },
        toolbarClassName: {
            border: 0,
            // borderBottom: '1px solid #F1F1F1',
            margin: 0,
            padding: 0,
        },
        formLabel: {
            paddingBottom: '10px',
        },
        loaderWrapper: {
            display: 'flex',
            justifyContent: 'center',
            padding: '20px 0',
        },
        status: {
            flex: 1,
            display: 'flex',
            alignItems: 'flex-end',
            height: '100%',
            justifyContent: 'flex-start',
            color: 'grey',
            '& > span': {
                color: mainTheme.BrandColors.TemplatePrimary,
                marginLeft: '10px',
            },
        },
        tabs: {
            marginBottom: 10,
            padding: '10px 0',
        },
        commentField: {
            width: '100%',
        },
        customFieldGrid: {
            paddingTop: '0px !important',
        },
        dropZone: {
            minHeight: 'fit-content !important',
            '& .MuiDropzoneArea-root': {
                minHeight: 'fit-content !important',
            },
        },
        dropZoneFont: {
            '& .MuiDropzoneArea-text': {
                fontSize: '1em',
            },
        },
        marginBottom: {
            marginBottom: '16px',
        },
        dialogPaper: {
            // height: '100%',
            // maxHeight: '920px'
            maxWidth: '700px',
        },
        dialogPaperWithActivity: {
            maxWidth: '1400px',
        },
        activityTab: {
            flex: 1,
            flexFlow: 'column',
            display: 'flex',
            padding: '0 10px'
        },
        taskHistorykContentWrapper: {
            display: 'flex',
            flex: 1,
            flexFlow: 'column',
        },
        historyActivityContainer: {
            flex: 1,
            position: 'relative',
            flexFlow: 'column',
            minHeight: 267,
        },
        historyActivityWrapper: {
            position: 'absolute',
            top: 0,
            right: 0,
            left: 0,
            bottom: 0,
        },
        form: {
            flex: 1,
            display: 'flex',
            flexFlow: 'row wrap',
        },
        dialogContent: {
            flex: 1,
            display: 'flex',
            flexFlow: 'column',
        },
        dialongInsideContentWrapper: {
            flex: 1,
            display: 'flex',
            flexFlow: 'column',
        },
        tabWrapper: {
            flex: 1,
            display: 'flex',
            flexFlow: 'column',
        },
        taskDetailsField: {
            fontSize: '1.25rem',
            fontWeight: 500,
        },
        transformNone: {
            textTransform: 'none',
        },
        clientMaterLabel: {
            fontWeight: 300
        },
        secondaryDescription: {
            flex: 1,
            maxWidth: '100%',
            overflow: 'hidden',
        },
        subTitle: {
            fontSize: 14,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            maxWidth: '97%',
            color: '#000000DE',
        },
        description: {
            fontSize: 12,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            maxWidth: '97%',
            color: '#000000DE',
        },
        taskTypeWrapper: {
            display: 'flex',
            alignItems: 'center',
            gap: '5px'
        },
        taskTypeSelector: {
            flex: 1
        },
        taskTypeNotesWrapper: {
            maxHeight: ' 543.083px', 
            overflowY: 'auto'
        },
        mainContent: {
            width: '700px',
            minHeight: ' 919.594px',
            display: 'flex',
            flexFlow: 'column',
        },
        activityContent: {
            width: '700px',
            display: 'flex',
            flex: 1,
            flexFlow: 'column',
        },
        verticialDivider: {
            // margin: '0 10px',
        },
        activityContentHeader: {
            height: '81.7812px', 
            display: 'flex', 
            padding: '0 10px',
            alignItems: 'center', 
            justifyContent: 'center',
        },
        acitivityContentTitle: {
            fontSize: '17.5px', 
        },
        titleWrapper: {
            display: 'flex',
            gap: '10px',
            justifyContent: 'space-between',
        }
    }),
);

export enum TaskOptionEnum {
    none = 0,
    matter,
    client,
}

export interface TaskInput {
    guid: string | null;
    description: string | null;
    notes: string | null;
    // tslint:disable-next-line: no-any
    files: any;
    assignedTo: string | null;
    allocatedToEntityType: number | null;
    assignedBy: string | null;
    priority: string | null;
    category: string | null;
    dueDate: Date | null;
    estimatedEffort: string | null;
    startDate: Date | null;
    completionDate: Date | null;
    completionNotes: string | null;
    duration: string | null;
    owner: string | null;
    userDefinedTaskType: string | null;
    activityText: string | null;
    reminderOffset: string | null;
    isConfidential: boolean | null;
}

// export interface UploadFile {
//     name: string | null;
//     tempFileName?: string | null;
//     guidID: string | null;
//     status: string | null;
//     // tslint:disable-next-line: no-any
//     file: any;
// }

interface TasksSummaryProps {
    isOpen: boolean;
    onClose?: () => void;
    isCreate: boolean;
    guid?: string | null;
    matterFileNumber?: string;
    matterGuid?: string;
    matterTitle?: string;
    status?: string | null;
    defaultPriority?: IAutoCompleteItem | null;
    reminderDefaultConfig?: TaskAndPhoneMessageConfig;
    isDuplicate?: boolean;
    defaultMatter?: IAutoCompleteItem;
    partyMessage?: Message;
    isFromDataSource?: boolean;
    partyClientGuid?: string;
}

interface TaskSummaryForm {
    handleCreateAndClose: boolean | undefined;
    handleCreateAndNew: boolean | undefined;
    handleUpdate: boolean | undefined;
    handleUpdateAndClose: boolean | undefined;

    description: string | null;
    startDate: Date | null;
    completionDate: Date | null;
    completionNotes: string | null;
    duration: string | null;
    dueDate: Date | null;
    estimatedEffort: string | null;
    matter: IAutoCompleteItem | null;
    client: IAutoCompleteItem | null;
    category: IAutoCompleteItem | null;
    assignedBy: IAutoCompleteItem | null;
    // taskOwner: string | null;
    notes: EditorState;
    files: DocumentFile[];
    assignTo: IAutoCompleteItem | null;
    priority?: IAutoCompleteItem | null;
    owner: IAutoCompleteItem | null;
    allocatedToEntityType: number | null;
    activityText: string | null;
    taskType: TaskTypeGroupAutoCompleteItem | null;
    matterDescription: string | null;
    reminderOffset: IAutoCompleteItem | null;
    isConfidential: boolean | null;
}

interface TaskSummaryFormState {
    option: TaskOptionEnum;
    hideToolbar: boolean;
    isOpen: boolean;
    showDiscard?: boolean;
    isCreate: boolean;
    isChanged: boolean;
    isUserLoading: boolean;
    isLoading: boolean;
    onSubmitLoading: boolean;
    isMatterLoading: boolean;

    // taskTypeDescription: string | null;
    // taskTypeGuid: string | null;
    taskDetails?: Detail;
    isSystemTask: boolean;
    tab: TaskFormTab;

    appUser?: AppUser;
}

export enum TaskFormTab {
    details = 0,
    documents = 1,
    activity = 2,
    taskTypeNotes = 3
}

function StyledRadio(props: RadioProps) {
    const classes = useStyles();

    return (
        <Radio
            className={classes.radioRoot}
            disableRipple={true}
            color="default"
            checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
            icon={<span className={classes.icon} />}
            {...props}
        />
    );
}

export const TasksSummaryFormDialog: React.FC<TasksSummaryProps> = (props) => {
    const classes = useStyles();

    const {
        onClickFormOpen,
        onClickFormClosed,
        isContextRefreshData,
        isContextRefreshTaskForm,
        setIsContextRefreshTaskForm,
        setRefreshDataOnFormClose,
    }: // tslint:disable-next-line: no-any
    any = useContext(TasksSummaryContext);

    const [filesKey, setKey] = useState('');

    const [taskSummaryForm, setTaskSummaryForm] = useState<TaskSummaryForm>({
        handleCreateAndClose: false,
        handleCreateAndNew: false,
        handleUpdate: false,
        handleUpdateAndClose: false,

        description: null,
        startDate: null,
        completionDate: null,
        completionNotes: null,
        dueDate: null,
        duration: null,
        estimatedEffort: null,
        matter: props.matterGuid && props.matterTitle ? { label: props.matterTitle, value: props.matterGuid } : props.defaultMatter ? props.defaultMatter : null,
        client: null,
        category: null,
        assignedBy: null,
        assignTo: null,
        priority: props.defaultPriority,
        // taskOwner: null,
        notes: EditorState.createEmpty(),
        files: [],
        owner: null,
        allocatedToEntityType: null,
        activityText: null,
        taskType: null,
        matterDescription: null,
        reminderOffset: props.reminderDefaultConfig
            ? { label: props.reminderDefaultConfig.reminder, value: props.reminderDefaultConfig.reminder }
            : { label: 'None', value: 'None' },
        isConfidential: false,
    });

    const [state, setState] = useState<TaskSummaryFormState>({
        option: props.defaultMatter ? TaskOptionEnum.matter : TaskOptionEnum.none,
        ...(props.partyMessage && props.partyClientGuid && {
            option: TaskOptionEnum.client
        }),
        hideToolbar: true,
        isOpen: props.isOpen,
        showDiscard: false,
        isCreate: props.isCreate,
        isChanged: false,
        isUserLoading: false,
        isLoading: false,
        onSubmitLoading: false,
        isMatterLoading: false,
        // taskTypeDescription: null,
        // taskTypeGuid: null,
        tab: TaskFormTab.details,
        isSystemTask: true,
        taskDetails: undefined,
        appUser: undefined,
    });

    const [matterData, setMatterData] = useState<MatterDetails>();

    useEffect(() => {
        onClickFormOpen();

        // const taskSubscriptionObj = subscribeToTask();

        // // Cleanup
        // return () => {
        //     taskSubscriptionObj.unsubscribe();
        // };
        // tslint:disable-next-line: align
    }, []);

    useEffect(() => {
        if (props.matterGuid) {
            setState((prevState) => {
                return {
                    ...prevState,
                    option: TaskOptionEnum.matter,
                };
            });
        }
        // tslint:disable-next-line: align
    }, [props.matterGuid]);

    useEffect(() => {
        // tslint:disable-next-line: no-console
        console.log('Task Form - isContextRefreshTaskForm', isContextRefreshTaskForm);
        if (isContextRefreshTaskForm === true && props.guid) {
            setRefreshDataOnFormClose(true);
            // Refresh the Task Details on subscription.
            fetchTaskDetails(props.guid, props.isDuplicate);
        }
        // tslint:disable-next-line: align
    }, [isContextRefreshTaskForm]);

    useEffect(() => {
        if (!state.isCreate && props.guid) {
            fetchTaskDetails(props.guid, props.isDuplicate);
        } else {
            fetchUser();
        }
        // tslint:disable-next-line: align
    }, [props.guid, props.isOpen]);

    const getTaskType = (taskDetails: Detail) => {
        if (!taskDetails.taskTypeInternalDescription && !taskDetails.taskTypeDescription) {
            return null;
        }
        if (taskDetails.isSystemTask === false) {
            return {
                label: taskDetails.taskTypeDescription,
                value: taskDetails.taskTypeInternalDescription,
                notes: taskDetails.taskTypeNotes ?? null,
            } as TaskTypeGroupAutoCompleteItem;
        } else if (props.partyMessage) {
            return {
                label: taskDetails.taskTypeDescription,
                value: taskDetails.taskTypeInternalDescription,
                notes: taskDetails.taskTypeNotes ?? null,
            } as TaskTypeGroupAutoCompleteItem;
        } else {
            return null;
        }
    };

    const fetchTaskDetails = (guid: string, isDuplicate?: boolean) => {
        setState((prevState) => {
            return {
                ...prevState,
                isLoading: true,
            };
        });

        const taskDetailsParams: TaskDetailsParam = {
            guid: guid,
        };

        retrieveTaskDetailsData(
            taskDetailsParams,
            true,
            // tslint:disable-next-line: no-console
            (data: TaskDetailsData) => onTaskDetailsRetrieved(data, isDuplicate),
            // tslint:disable-next-line: no-any
            function (reason: any): void {
                showNotification(null, reason, 'error');
                setIsContextRefreshTaskForm(false);
            },
        );
    };

    const onTaskDetailsRetrieved = (data: TaskDetailsData, isDuplicate?: boolean) => {
        // tslint:disable-next-line: no-console

        if (data && data.task && data.task.detail) {
            const taskDetails = data.task.detail;
            const appUser = data.appUser;

            let tempOption = TaskOptionEnum.none;

            switch (taskDetails.__typename) {
                case 'ClientTask': {
                    tempOption = TaskOptionEnum.client;
                    break;
                }
                case 'MatterTask': {
                    tempOption = TaskOptionEnum.matter;
                    if (!props.isCreate && taskDetails.matterId) {
                        fetchMatterDetails(taskDetails.matterId);
                    }
                    break;
                }
                default: {
                    tempOption = TaskOptionEnum.none;
                    break;
                }
            }

            setState((prevState) => {
                return {
                    ...prevState,
                    option: tempOption,
                    taskType: taskDetails.taskType,
                    taskTypeGuid: taskDetails.isSystemTask === false ? taskDetails.taskTypeInternalDescription : null,
                    taskTypeDescription: taskDetails.isSystemTask === false ? taskDetails.taskTypeDescription : null,
                    taskDetails: taskDetails,
                    isSystemTask: taskDetails.isSystemTask,
                    appUser: appUser,
                };
            });

            let editorState = EditorState.createEmpty();

            if (taskDetails.notes !== null && !props.isDuplicate) {
                const contentBlock = htmlToDraft(taskDetails.notes);
                if (contentBlock) {
                    const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
                    editorState = EditorState.createWithContent(contentState);
                }
            }

            setKey('' + taskDetails.guid);

            let reminderOffset: IAutoCompleteItem | null = taskSummaryForm.reminderOffset;

            if (taskDetails.reminderOffset) {
                reminderOffset = { label: taskDetails.reminderOffset, value: taskDetails.reminderOffset };
            }

            // else if (props.enquiryDefaultConfig && props.enquiryDefaultConfig.reminder) {
            //     reminderOffset = { label: props.enquiryDefaultConfig.reminder, value: props.enquiryDefaultConfig.reminder };
            // }

            setTaskSummaryForm((prevTaskSummaryForm) => {
                return {
                    ...prevTaskSummaryForm,
                    description: taskDetails.description,
                    startDate: taskDetails.startDate ? moment(taskDetails.startDate).toDate() : null,
                    completionDate: taskDetails.completionDate ? moment(taskDetails.completionDate).toDate() : null,
                    completionNotes: taskDetails.completionNotes,
                    dueDate: taskDetails.dueDate ? moment(taskDetails.dueDate).toDate() : null,
                    duration: taskDetails.duration ? taskDetails.duration : '00:00', // moment(taskDetails.duration, 'HH:mm').toString()
                    // tslint:disable-next-line: max-line-length
                    estimatedEffort: taskDetails.estimatedEffort ? taskDetails.estimatedEffort : '00:00', // moment(taskDetails.estimatedEffort, 'HH:mm').toString()
                    // tslint:disable-next-line: max-line-length
                    matter: taskDetails.matterGuid && taskDetails.matterDescription ? { label: taskDetails.matterDescription, value: taskDetails.matterGuid } : null,
                    // tslint:disable-next-line: max-line-length
                    client: taskDetails.clientGuid && taskDetails.clientDescription ? { label: taskDetails.clientDescription, value: taskDetails.clientGuid } : null,
                    // tslint:disable-next-line: max-line-length
                    category: taskDetails.category && taskDetails.categoryDescription ? { label: taskDetails.categoryDescription, value: taskDetails.category } : null,
                    // tslint:disable-next-line: max-line-length
                    assignedBy:
                        taskDetails.assignedBy && taskDetails.assignedByName
                            ? { label: taskDetails.assignedByName, value: taskDetails.assignedBy }
                            : { label: appUser.name, value: appUser.userId },
                    // tslint:disable-next-line: max-line-length
                    assignTo: taskDetails.assignedTo && taskDetails.assignedToName ? { label: taskDetails.assignedToName, value: taskDetails.assignedTo } : null,
                    // tslint:disable-next-line: max-line-length
                    priority: taskDetails.priority && taskDetails.priorityDescription ? { label: taskDetails.priorityDescription, value: taskDetails.priority } : null,
                    notes: editorState,
                    owner: taskDetails.owner && taskDetails.ownerName ? { label: taskDetails.ownerName, value: taskDetails.owner } : null,
                    files: taskDetails.files ? taskDetails.files : [],
                    allocatedToEntityType: taskDetails.allocatedToEntityType,
                    // tslint:disable-next-line: max-line-length
                    taskType: getTaskType(taskDetails),
                    matterDescription: taskDetails.matterDescription ? taskDetails.matterDescription : null,
                    reminderOffset: reminderOffset,
                    isConfidential: taskDetails.isConfidential,
                };
            });
        }

        setIsContextRefreshTaskForm(false);

        setState((prevState) => {
            return {
                ...prevState,
                isLoading: false,
            };
        });
    };

    const fetchMatterDetails = (matterId: number) => {
        setState((prevState) => {
            return {
                ...prevState,
                isMatterLoading: true,
            };
        });

        fetchBasicMatterDetailsData(
            true, // This should always be a new request.
            matterId,
            (data: MatterData) => {
                handleMatterDataFetch(data);
            },
            (reason) => {
                showNotification('Failed to Fetch App User Settings', reason, 'error');
            },
        );
    };

    const handleMatterDataFetch = (data: MatterData) => {
        if (data && !data.loading && data.matter) {
            if (data.matter) {
                setMatterData(data.matter);
                setState((prevState) => {
                    return {
                        ...prevState,
                        isMatterLoading: false,
                    };
                });
            }
        }
    };

    const fetchUser = () => {
        setState((prevState) => {
            return {
                ...prevState,
                isUserLoading: true,
            };
        });

        fetchAppUserData(
            false,
            // tslint:disable-next-line: no-console
            (data: AppUserData) => onUserRetrieved(data),
            // tslint:disable-next-line: no-any
            function (reason: any): void {
                showNotification(null, reason, 'error');
            },
        );
    };

    const onUserRetrieved = (data: AppUserData) => {

        if (props.partyMessage) {
            // Only for Follow Up Tasks, set the Assign To to logged in User.
            setTaskSummaryForm({
                ...taskSummaryForm,
                assignTo: {
                    label: data.appUser.name,
                    value: data.appUser.userId,
                },
                assignedBy: {
                    label: data.appUser.name,
                    value: data.appUser.userId,
                }
            });
        } else {
            setTaskSummaryForm({
                ...taskSummaryForm,
                assignedBy: {
                    label: data.appUser.name,
                    value: data.appUser.userId,
                },
            });
        }

        setState((prevState) => {
            return {
                ...prevState,
                isUserLoading: false,
                appUser: data.appUser,
            };
        });
    };

    const handleOption = (event: React.ChangeEvent<HTMLInputElement>) => {
        setState((prevState) => {
            return {
                ...prevState,
                // tslint:disable-next-line: radix
                option: parseInt((event.target as HTMLInputElement).value),
            };
        });
    };

    // tslint:disable-next-line: no-any
    const required = (value: any) => {
        return value ? undefined : 'Required';
    };

    // tslint:disable-next-line: no-any
    const onSubmit = (form: FormApi<TaskSummaryForm>, values: TaskSummaryForm) => {
        setState((prevState) => {
            return {
                ...prevState,
                onSubmitLoading: true,
            };
        });

        const html = draftToHtml(convertToRaw(values.notes.getCurrentContent()));

        // Check if we have new Files
        // const newFiles = values.files.filter((documentFile: DocumentFile) => {
        //     return documentFile.status === 1;
        // });

        let ownerGuid = values.owner ? values.owner.value : state.appUser ? state.appUser!.userId : '';

        const taskInput: TaskInput = {
            guid: props.guid && !props.isDuplicate ? props.guid : null,
            description: values.description ? values.description : null,
            notes: html,
            files: GetFiles(values.files),
            assignedTo: values.assignTo ? values.assignTo.value : null,
            assignedBy: values.assignedBy ? values.assignedBy.value : null,
            priority: values.priority ? values.priority.label : null,
            category: values.category ? values.category.label : null,
            dueDate: values.dueDate ? values.dueDate : null,
            estimatedEffort: values.estimatedEffort ? values.estimatedEffort : null, // moment(values.estimatedEffort).format('HH:mm')
            startDate: values.startDate ? values.startDate : null,
            completionDate: values.completionDate ? values.completionDate : null,
            duration: values.duration ? values.duration : null, // moment(values.duration).format('HH:mm')
            completionNotes: values.completionDate ? values.completionNotes : null, // Only pass completion notes if we have value in completion date
            owner: ownerGuid,
            userDefinedTaskType: props.partyMessage ? 'TaskFollowUp' : values.taskType ? values.taskType.label : null,
            allocatedToEntityType: values.allocatedToEntityType,
            activityText: values.activityText,
            reminderOffset: values.reminderOffset ? values.reminderOffset.value : null,
            isConfidential: values.isConfidential,
        };

        const taskMatter = values.matter ? values.matter : null;
        const taskClient = values.client ? values.client : null;

        setState((prevState) => {
            return {
                ...prevState,
                isChanged: false,
            };
        });

        if (values.handleCreateAndClose) {
            // tslint:disable-next-line: no-console
            // console.log('handleCreateAndClose', form, values);

            createTask(taskInput, taskMatter, taskClient, true, form, false, values);
        } else if (values.handleCreateAndNew) {
            // tslint:disable-next-line: no-console
            // console.log('handleCreateAndNew', form, values);

            createTask(taskInput, taskMatter, taskClient, false, form, true, values);
        } else {
            // tslint:disable-next-line: no-console
            // console.log('handleUpdate', form, values);

            updateTask(taskInput, taskMatter, taskClient, values.handleUpdateAndClose ? values.handleUpdateAndClose : false);
        }
    };

    const GetFiles = (files: DocumentFile[]) => {
        return files.map((document: DocumentFile) => {
            return {
                file: document.file,
                name: document.name,
                guidID: document.guidID,
                status: document.status,
            };
        });
    };

    const formReset = (form: FormApi<TaskSummaryForm>, values: TaskSummaryForm) => {
        form.setConfig('keepDirtyOnReinitialize', false);
        const tempValues: TaskSummaryForm = {
            ...values,
            files: [],
            description: null,
            // notes: EditorState.createEmpty(),
        };
        form.reset(tempValues);
        // form.change('notes', EditorState.createEmpty());
        form.setConfig('keepDirtyOnReinitialize', true);
    };

    const createTask = (
        input: TaskInput,
        taskMatter: IAutoCompleteItem | null,
        taskClient: IAutoCompleteItem | null,
        isForceClosed: boolean,
        form: FormApi<TaskSummaryForm>,
        isCreatAndNew: boolean,
        values: TaskSummaryForm,
    ) => {
        if (state.option === TaskOptionEnum.none) {
            createGeneralTask(input, isForceClosed, form, isCreatAndNew, values);
        } else if (state.option === TaskOptionEnum.matter) {
            createMatterTask(input, taskMatter, isForceClosed, form, isCreatAndNew, values);
        } else {
            createClientTask(input, taskClient, isForceClosed, form, isCreatAndNew, values);
        }
    };

    const updateTask = (input: TaskInput, taskMatter: IAutoCompleteItem | null, taskClient: IAutoCompleteItem | null, isForceClosed: boolean) => {
        if (state.option === TaskOptionEnum.none) {
            updateGeneralTask(input, isForceClosed);
        } else if (state.option === TaskOptionEnum.matter) {
            updateMatterTask(input, taskMatter, isForceClosed);
        } else {
            updateClientTask(input, taskClient, isForceClosed);
        }
    };

    const createGeneralTask = (task: TaskInput, isForceClosed: boolean, form: FormApi<TaskSummaryForm>, isCreatAndNew: boolean, values: TaskSummaryForm) => {
        client
            .mutate({
                mutation: CreateGeneralTask,
                variables: {
                    input: task,
                },
            })
            // tslint:disable-next-line: no-any
            .then((results: { data: any }) => {
                if (results.data) {
                    if (results.data.error === null || results.data.error === undefined) {
                        showNotification(null, 'Successfully submitted', 'info');

                        if (isCreatAndNew) {
                            formReset(form, values);
                        } else {
                            if (isForceClosed && props.onClose) {
                                onClickFormClosed(false);
                                props.onClose();
                            }
                        }
                    } else {
                        showNotification('Failed to save general task', results.data.error, 'error');
                    }
                }
                setState((prevState) => {
                    return {
                        ...prevState,
                        onSubmitLoading: false,
                    };
                });
            })
            // tslint:disable-next-line:no-any
            .catch((reason: any) => {
                showNotification('Failed to save general task', reason, 'error');
                setState((prevState) => {
                    return {
                        ...prevState,
                        onSubmitLoading: false,
                    };
                });
            });
    };

    const updateGeneralTask = (task: TaskInput, isForceClosed: boolean) => {
        client
            .mutate({
                mutation: UpdateGeneralTask,
                variables: {
                    input: task,
                },
            })
            // tslint:disable-next-line: no-any
            .then((results: { data: any }) => {
                if (results.data) {
                    if (results.data.error === null || results.data.error === undefined) {
                        showNotification(null, 'Successfully submitted', 'info');
                        if (isForceClosed && props.onClose) {
                            onClickFormClosed(true);
                            props.onClose();
                        }
                    } else {
                        showNotification('Failed to update general task', results.data.error, 'error');
                    }
                }
                setState((prevState) => {
                    return {
                        ...prevState,
                        onSubmitLoading: false,
                    };
                });
            })
            // tslint:disable-next-line:no-any
            .catch((reason: any) => {
                showNotification('Failed to update general task', reason, 'error');
                setState((prevState) => {
                    return {
                        ...prevState,
                        onSubmitLoading: false,
                    };
                });
            });
    };

    // tslint:disable-next-line: max-line-length
    const createClientTask = (
        task: TaskInput,
        taskClient: IAutoCompleteItem | null,
        isForceClosed: boolean,
        form: FormApi<TaskSummaryForm>,
        isCreatAndNew: boolean,
        values: TaskSummaryForm,
    ) => {
        client
            .mutate({
                mutation: CreateClientTask,
                variables: {
                    input: task,
                    clientGuid: props.partyMessage && props.partyClientGuid ? props.partyClientGuid : taskClient && taskClient.value.length > 0 ? taskClient.value : null,
                    ...(props.partyMessage && props.isFromDataSource && {
                        parentNameTextId: props.partyMessage.id,
                    }),
                    ...(props.partyMessage && !props.isFromDataSource && {
                        parentNameCrmTextId: !props.isFromDataSource ? props.partyMessage.id : null,
                    })
                },
            })
            // tslint:disable-next-line: no-any
            .then((results: { data: any }) => {
                if (results.data) {
                    if (results.data.error === null || results.data.error === undefined) {
                        showNotification(null, 'Successfully submitted', 'info');
                        if (isCreatAndNew) {
                            formReset(form, values);
                        } else {
                            if (isForceClosed && props.onClose) {
                                onClickFormClosed(true);
                                props.onClose();
                            }
                        }
                    } else {
                        showNotification('Failed to save client task', results.data.error, 'error');
                    }
                }
                setState((prevState) => {
                    return {
                        ...prevState,
                        onSubmitLoading: false,
                    };
                });
            })
            // tslint:disable-next-line:no-any
            .catch((reason: any) => {
                showNotification('Failed to save client task', reason, 'error');
                setState((prevState) => {
                    return {
                        ...prevState,
                        onSubmitLoading: false,
                    };
                });
            });
    };

    const updateClientTask = (task: TaskInput, taskClient: IAutoCompleteItem | null, isForceClosed: boolean) => {
        client
            .mutate({
                mutation: UpdateClientTask,
                variables: {
                    input: task,
                    clientGuid: taskClient && taskClient.value.length > 0 ? taskClient.value : null,
                    ...(state.taskDetails && state.taskDetails.taskTypeInternalDescription === 'TaskFollowUp' && {
                        parentNameTextId: state.taskDetails.parentNameTextId,
                        parentNameCrmTextId: state.taskDetails.parentNameCrmTextId
                    })
                },
            })
            // tslint:disable-next-line: no-any
            .then((results: { data: any }) => {
                if (results.data) {
                    if (results.data.error === null || results.data.error === undefined) {
                        showNotification(null, 'Successfully submitted', 'info');
                        if (isForceClosed && props.onClose) {
                            onClickFormClosed(true);
                            props.onClose();
                        }
                    } else {
                        showNotification('Failed to update client task', results.data.error, 'error');
                    }
                }
                setState((prevState) => {
                    return {
                        ...prevState,
                        onSubmitLoading: false,
                    };
                });
            })
            // tslint:disable-next-line:no-any
            .catch((reason: any) => {
                showNotification('Failed to update client task', reason, 'error');
                setState((prevState) => {
                    return {
                        ...prevState,
                        onSubmitLoading: false,
                    };
                });
            });
    };

    // tslint:disable-next-line: max-line-length
    const createMatterTask = (
        task: TaskInput,
        taskMatter: IAutoCompleteItem | null,
        isForceClosed: boolean,
        form: FormApi<TaskSummaryForm>,
        isCreatAndNew: boolean,
        values: TaskSummaryForm,
    ) => {
        client
            .mutate({
                mutation: CreateMatterTask,
                variables: {
                    input: task,
                    matterGuid: taskMatter && taskMatter.value.length > 0 ? taskMatter.value : null,
                },
            })
            // tslint:disable-next-line: no-any
            .then((results: { data: any }) => {
                if (results.data) {
                    if (results.data.error === null || results.data.error === undefined) {
                        showNotification(null, 'Successfully submitted', 'info');

                        if (isCreatAndNew) {
                            formReset(form, values);
                        } else {
                            if (isForceClosed && props.onClose) {
                                onClickFormClosed(true);
                                props.onClose();
                            }
                        }
                    } else {
                        showNotification('Failed to save matter task', results.data.error, 'error');
                    }
                }
                setState((prevState) => {
                    return {
                        ...prevState,
                        onSubmitLoading: false,
                    };
                });
            })
            // tslint:disable-next-line:no-any
            .catch((reason: any) => {
                showNotification('Failed to save matter task', reason, 'error');
                setState((prevState) => {
                    return {
                        ...prevState,
                        onSubmitLoading: false,
                    };
                });
            });
    };

    const updateMatterTask = (task: TaskInput, taskMatter: IAutoCompleteItem | null, isForceClosed: boolean) => {
        client
            .mutate({
                mutation: UpdateMatterTask,
                variables: {
                    input: task,
                    matterGuid: taskMatter && taskMatter.value.length > 0 ? taskMatter.value : null,
                },
            })
            // tslint:disable-next-line: no-any
            .then((results: { data: any }) => {
                if (results.data) {
                    if (results.data.error === null || results.data.error === undefined) {
                        showNotification(null, 'Successfully submitted', 'info');
                        if (isForceClosed && props.onClose) {
                            onClickFormClosed(true);
                            props.onClose();
                        }
                    } else {
                        showNotification('Failed to update matter task', results.data.error, 'error');
                    }
                }
                setState((prevState) => {
                    return {
                        ...prevState,
                        onSubmitLoading: false,
                    };
                });
            })
            // tslint:disable-next-line:no-any
            .catch((reason: any) => {
                showNotification('Failed to update matter task', reason, 'error');
                setState((prevState) => {
                    return {
                        ...prevState,
                        onSubmitLoading: false,
                    };
                });
            });
    };

    // tslint:disable-next-line: no-any
    const closePanel = (form: FormApi<TaskSummaryForm>, values: any, pristine: boolean) => {
        if (!pristine) {
            // code here...
            setState((prevState) => {
                return {
                    ...prevState,
                    showDiscard: true,
                };
            });
        } else {
            if (props.onClose) {
                onClickFormClosed(isContextRefreshData);
                props.onClose();
            }
        }
    };

    const onReset = (form: FormApi<TaskSummaryForm>, values: TaskSummaryForm) => {
        formReset(form, values);
    };

    const onFocusEditor = (event: SyntheticEvent) => {
        setState((prevState) => {
            return {
                ...prevState,
                hideToolbar: false,
            };
        });
    };

    const onBlurEditor = (event: SyntheticEvent) => {
        setState((prevState) => {
            return {
                ...prevState,
                hideToolbar: true,
            };
        });
    };

    const onClose = () => {
        if (state.isChanged) {
            setState((prevState) => {
                return {
                    ...prevState,
                    showDiscard: true,
                };
            });
        } else {
            if (props.onClose) {
                props.onClose();
            }
        }
    };

    const onDiscardChanges = (agree: boolean) => {
        if (agree) {
            setState((prevState) => {
                return {
                    ...prevState,
                    showDiscard: false,
                };
            });

            if (props.onClose) {
                props.onClose();
            }
        } else {
            setState((prevState) => {
                return {
                    ...prevState,
                    showDiscard: false,
                };
            });
        }
    };

    // tslint:disable-next-line: no-any no-shadowed-variable
    const onFormValueChanged = (form: FormApi<TaskSummaryForm>, changeProps: any) => {
        if (!changeProps.pristine) {
            setState((prevState) => {
                return {
                    ...prevState,
                    isChanged: true,
                };
            });
        }
    };

    const getClientAndMatter = () => {
        if (state.option === TaskOptionEnum.client && taskSummaryForm.client) {
            return (
                <Grid item={true} xs={12} md={12}>
                    <div className={classes.titleWrapper}>
                        <h4 className={`${classes.clientMaterLabel} client`}>{`${taskSummaryForm.client!.label}`}</h4>
                        {state.taskDetails?.isFromDataSource && (
                            <Chip
                                // icon={<FaceIcon />}
                                label="LEAP"
                                color="primary"
                            />
                        )}
                    </div>
                </Grid>
            );
        } else if (state.option === TaskOptionEnum.matter) {

            const description = [matterData?.fileNumber, matterData?.title].filter(Boolean).join(' - ');

            return (
                <Grid item={true} xs={12} md={12}>
                    <div className={classes.titleWrapper}>
                        <div className={classes.secondaryDescription}>
                            {matterData?.client ? (
                                <div className={classes.subTitle}>{matterData?.client}</div>
                            ) : null}
                            {description.length > 0 ? (
                                <div className={classes.description}>{description}</div>                        
                            ) : null}
                        </div>
                        {state.taskDetails?.isFromDataSource && (
                            <Chip
                                // icon={<FaceIcon />}
                                label="LEAP"
                                color="primary"
                            />
                        )}
                    </div>
                </Grid>
            );
        }
        return undefined;
    };

    const showEntitySelection = () => {
        if (state.isCreate) {
            if (props.matterGuid) {
                return false; // We coming from matter, then don't. Matter should be pre-selected
            } else if (props.partyMessage) {
                return false;
            } else if (state.taskDetails && state.taskDetails.taskTypeInternalDescription === 'TaskFollowUp') {
                return false;
            } else {
                return true; // by default show
            }
        } else {
            return false;
        }
    };

    const getTaskTypeHeader = () => {
        return (
            state.taskDetails && state.taskDetails.taskTypeInternalDescription === 'TaskFollowUp' && state.taskDetails.isFromDataSource === false && (
                <Grid item={true} xs={12} md={12}>
                    <Typography color="primary">Task Type: {state.taskDetails.taskTypeDescription}</Typography>
                </Grid>
            )
        );
    };

    const getTaskTypeControl = (form: FormApi<TaskSummaryForm>) => {
        if ( 
                (props.partyMessage || 
                (state.taskDetails && state.taskDetails.taskTypeInternalDescription !== 'TaskFollowUp') ||
                (state.taskDetails && state.taskDetails.isFromDataSource === false)
            )) {
            // If this is a Follow Up task created from the Client & Contact, OR 
            // Editing a follow up Task OR the task is from the data source (e.g. Leap) then don't display the selector
            return <div/>;
        } else {
            return (
                <Grid item={true} xs={12} md={12}>
                    <Field
                        name="taskType"
                        // validate={form.getFieldState('description')?.value !== null ? required : undefined}
                    >
                        {({ input, meta }) => (
                            <div className={classes.taskTypeWrapper}>
                                <TaskTypeGroupSelector
                                    {...input}
                                    label="Task Type"
                                    name="taskType"
                                    // tslint:disable-next-line: no-any
                                    onSelection={(selection: TaskTypeGroupAutoCompleteItem | undefined | any, name: string) => {
                                        input.onChange(selection);
                                        form.change('estimatedEffort', selection ? selection.estimatedEffort : null);
                                        if (!selection.notes) {
                                            setState((prevState) => {
                                                return {
                                                    ...prevState,
                                                    tab: TaskFormTab.details
                                                };
                                            });
                                        } 
                                    }}
                                    className={classes.taskTypeSelector}
                                />
                            </div>
                        )}
                    </Field>
                </Grid>
            );
        }
    };

    const actionButton = (
        // tslint:disable-next-line: no-any
        form: FormApi<any>,
        submitting: boolean,
        pristine: boolean,
        // tslint:disable-next-line: no-any
        values: any,
    ) => {
        return (
            <div className={classes.buttonContainer}>
                {/* <RenderCount /> */}
                {!state.isCreate && !props.isDuplicate ? (
                    <div>
                        <Button
                            color="primary"
                            type="submit"
                            className={classes.button}
                            onClick={() => {
                                form.change('handleCreateAndClose', false);
                                form.change('handleCreateAndNew', false);
                                form.change('handleUpdate', true);
                                form.change('handleUpdateAndClose', false);
                            }}
                            disabled={submitting || /*pristine ||*/ state.onSubmitLoading || !state.isChanged || state.taskDetails?.isFromDataSource}
                        >
                            Update
                        </Button>
                        <Button
                            color="primary"
                            type="submit"
                            className={classes.button}
                            onClick={() => {
                                form.change('handleCreateAndClose', false);
                                form.change('handleCreateAndNew', false);
                                form.change('handleUpdate', false);
                                form.change('handleUpdateAndClose', true);
                            }}
                            disabled={submitting || /*pristine ||*/ state.onSubmitLoading || !state.isChanged || state.taskDetails?.isFromDataSource}
                        >
                            Save and Close
                        </Button>
                    </div>
                ) : (
                    <div>
                        <Button
                            color="primary"
                            type="submit"
                            className={classes.button}
                            onClick={() => {
                                form.change('handleCreateAndClose', true);
                                form.change('handleCreateAndNew', false);
                                form.change('handleUpdate', false);
                                form.change('handleUpdateAndClose', false);
                            }}
                            disabled={submitting || /*pristine ||*/ state.onSubmitLoading || !state.isChanged || state.taskDetails?.isFromDataSource}
                        >
                            Save and Close
                        </Button>
                        <Button
                            color="primary"
                            type="submit"
                            className={classes.button}
                            onClick={() => {
                                form.change('handleCreateAndNew', true);
                                form.change('handleCreateAndClose', false);
                                form.change('handleUpdate', false);
                                form.change('handleUpdateAndClose', false);
                            }}
                            disabled={submitting || /*pristine ||*/ state.onSubmitLoading || !state.isChanged || state.taskDetails?.isFromDataSource}
                        >
                            Save and New
                        </Button>
                    </div>
                )}
                <div>
                    <Button color="primary" type="button" className={classes.button} onClick={() => closePanel(form, values, pristine)}>
                        Cancel
                    </Button>
                    <Button id="resetButton" type="button" onClick={() => onReset(form, values)} disabled={submitting || pristine} className={classes.hidden}>
                        Reset
                    </Button>
                </div>
            </div>
        );
    };

    // const heading = (option: TaskOptionEnum, isCreate: boolean) => {

    //     if (option === TaskOptionEnum.none) {
    //         if (isCreate) {
    //             return 'Create Task';
    //         }
    //         return 'Edit Task';
    //     } else {
    //         if (isCreate) {
    //             return `Create ${TaskOptionEnum[option]} Task`;
    //         }
    //         return `Edit ${TaskOptionEnum[option]} Task`;
    //     }
    // };

    const filteredField = (option: TaskOptionEnum, form: FormApi<TaskSummaryForm>) => {
        if (option === TaskOptionEnum.matter) {
            return (
                <Grid item={true} xs={12} md={7}>
                    <Field name="matter" validate={required}>
                        {({ input, meta }) => (
                            <MatterAutoCompleteSelector
                                {...input}
                                label="Matter"
                                name="matter"
                                required={true}
                                onSelection={(selection: IAutoCompleteItem, name: string) => input.onChange(selection)}
                                value={input.value ? input.value : null}
                                textLimit={{
                                    description: 40,
                                    title: 40,
                                }}
                                error={meta.error && meta.touched}
                                helperText={meta.error && meta.touched ? 'required' : ''}
                            />
                        )}
                    </Field>
                </Grid>
            );
        } else if (option === TaskOptionEnum.client) {
            return (
                <Grid item={true} xs={12} md={7}>
                    <Field name="client" validate={required}>
                        {({ input, meta }) => (
                            <ClientAutoCompleteSelector
                                {...input}
                                label="Client"
                                name="client"
                                required={true}
                                onSelection={(selection: IAutoCompleteItem, name: string) => input.onChange(selection)}
                                value={input.value ? input.value : null}
                                textLimit={{
                                    email: 40,
                                    label: 40,
                                }}
                                error={meta.error && meta.touched}
                                helperText={meta.error && meta.touched ? 'required' : ''}
                            />
                        )}
                    </Field>
                </Grid>
            );
        }

        return <div />;
    };

    // tslint:disable-next-line: no-any
    const onDropDownSelect = (
        form: FormApi<TaskSummaryForm>,
        values: AnyObject,
        // tslint:disable-next-line: no-any
        input: FieldInputProps<any, HTMLElement>,
        // tslint:disable-next-line: no-any
        selection: IAutoCompleteItem | any,
        name: string,
        isUser: boolean,
    ) => {
        if (name === 'assignTo') {
            let assignedTo = selection && selection.value && selection.label ? { value: selection.value, label: selection.label } : null;
            form.batch(() => {
                form.change('assignTo', assignedTo);
                form.change('allocatedToEntityType', isUser ? AllocatedToEntityType.user : AllocatedToEntityType.team);
            });
        }
    };

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const getFileItems = (files?: DocumentFile[]) => {
        if (files) {
            const filteredFiles = files.filter((file: DocumentFile) => {
                return file.status !== 2 && file.url;
            });

            if (filteredFiles) {
                const chip = (
                    <div>
                        {filteredFiles.length > 0 ? <Typography>{'Click on the following to open the uploaded files'}</Typography> : null}
                        <div className={classes.chips}>
                            {filteredFiles.map((file: DocumentFile, index: number) => {
                                return <Chip key={index} className={classes.chip} label={file.name} onClick={() => onFileClicked(file)} />;
                            })}
                        </div>
                    </div>
                );

                return chip;
            } else {
                return <div />;
            }
        } else {
            return <div />;
        }
    };

    const onFileClicked = (file: DocumentFile) => {
        if (file && file.url) {
            var win = window.open(file.url, '_blank');
            if (win) {
                win.focus();
            }
        }
    };

    const handleTabChange = (event: React.ChangeEvent<{}>, newValue: TaskFormTab) => {
        setState((prevState) => {
            return {
                ...prevState,
                tab: newValue,
            };
        });
    };

    const detailsTab = (form: FormApi<TaskSummaryForm>, pristine: boolean, values: AnyObject) => {
        return (
            <Grid container={true} spacing={2} style={{ display: state.tab === TaskFormTab.details ? 'block' : 'none' }}>
                <Grid item={true} xs={12} md={12} lg={12} sm={12}>
                    <Grid container={true} spacing={3}>
                        <Grid item={true} xs={12} md={12}>
                            <Field
                                name="notes"
                                // validate={form.getFieldState('notes')?.value !== null ? required : undefined}
                            >
                                {({ input, meta }) => (
                                    <>
                                        <FormLabel component="legend" className={classes.formLabel}>
                                            Notes
                                        </FormLabel>
                                        <Editor
                                            editorState={input.value}
                                            // tslint:disable-next-line: no-console
                                            onFocus={onFocusEditor}
                                            onBlur={onBlurEditor}
                                            toolbarHidden={state.hideToolbar}
                                            toolbarClassName={`${classes.toolbarClassName} toolbarClassName`}
                                            wrapperClassName={`${classes.wrapperClassName} wrapperClassName`}
                                            editorClassName={`${classes.editorClassName} editorClassName`}
                                            onEditorStateChange={input.onChange}
                                        />
                                    </>
                                )}
                            </Field>
                        </Grid>
                        <Grid item={true} xs={12} md={6}>
                            <FormSpy subscription={{ values: true, pristine: true }}>
                                {/* tslint:disable-next-line: no-shadowed-variable */}
                                {({ values }) => (
                                    <Field
                                        name="assignTo"
                                        // validate={required}
                                        // validate={form.getFieldState('assignTo')?.value !== null ? required : undefined}
                                    >
                                        {({ input, meta }) => (
                                            <TaskAssignedByUserTeamSelector
                                                {...input}
                                                label="Assigned To"
                                                value={input.value ? input.value : null}
                                                disablePortal={false}
                                                // required={true}
                                                className={`${classes.textField} ${classes.clientSelector}`}
                                                error={meta.error && meta.touched}
                                                // tslint:disable-next-line: jsx-alignment
                                                onSelection={(selection: IAutoCompleteItem, name: string, isUser: boolean) =>
                                                    onDropDownSelect(form, values, input, selection, name, isUser)
                                                }
                                                helperText={meta.error && meta.touched ? 'Assigned to is required' : ''}
                                                visible={{
                                                    role: true,
                                                }}
                                                isAuthorized={true}
                                            />
                                        )}
                                    </Field>
                                )}
                            </FormSpy>
                        </Grid>

                        <Grid item={true} xs={12} md={6}>
                            <Field
                                name="assignedBy"
                                // validate={form.getFieldState('assignedBy')?.value !== null ? required : undefined}
                            >
                                {({ input, meta }) => (
                                    <TaskAssignedBySelector
                                        {...input}
                                        name="assignedBy"
                                        label="Assigned By"
                                        value={input.value ? input.value : null}
                                        disablePortal={false}
                                        required={true}
                                        className={`${classes.textField} ${classes.clientSelector}`}
                                        error={meta.error && meta.touched}
                                        // tslint:disable-next-line: jsx-alignment
                                        onSelection={(selection: IAutoCompleteItem, name: string) => input.onChange(selection)}
                                        helperText={meta.error && meta.touched ? 'Assigned by is required' : ''}
                                        visible={{
                                            role: true,
                                        }}
                                        isAuthorized={true}
                                    />
                                )}
                            </Field>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item={true} xs={12} md={12} lg={12} sm={12}>
                    <Grid container={true} spacing={3}>
                        <Grid item={true} xs={12} md={6}>
                            <Field
                                name="dueDate"
                                // validate={form.getFieldState('dueDate')?.value !== null ? required : undefined}
                            >
                                {({ input, meta }) => (
                                    <KeyboardDateTimePicker
                                        {...input}
                                        value={input.value ? input.value : null}
                                        className={classes.textField}
                                        label="Due"
                                        format="DD/MM/YYYY hh:mm a"
                                        placeholder="dd/mm/yyyy hh:mm a"
                                        animateYearScrolling={true}
                                        // tslint:disable-next-line: max-line-length
                                        autoOk={true}
                                        allowKeyboardControl={true}
                                        variant={'inline'}
                                        error={meta.error && meta.touched}
                                        inputProps={{ autoComplete: 'off' }}
                                        InputLabelProps={{ shrink: true }}
                                        helperText={meta.error && meta.touched ? 'Due date is required' : ''}
                                        // set new date to current with default hours to 5pm
                                        initialFocusedDate={new Date().setHours(17, 0, 0, 0)}
                                    />
                                )}
                            </Field>
                        </Grid>
                        <Grid item={true} xs={12} md={6}>
                            <Field
                                name="reminderOffset"
                                // validate={required}
                                helperText={'Reminder is required'}
                            >
                                {({ input, meta }) => (
                                    <ReminderSelector
                                        {...input}
                                        label="Reminder"
                                        className={classes.textField}
                                        onSelection={(selection: IAutoCompleteItem) => {
                                            input.onChange(selection);
                                        }}
                                        error={meta.error && meta.touched}
                                        helperText={meta.error && meta.touched ? meta.error : ''}
                                        showIcon={true}
                                    />
                                )}
                            </Field>
                        </Grid>
                        <Grid item={true} xs={12} md={6}>
                            <Field
                                name="priority"
                                validate={required}
                                required={true}
                                helperText={'Priority is required'}
                                // validate={form.getFieldState('priority')?.value !== undefined ? required : undefined}
                            >
                                {({ input, meta }) => (
                                    <PrioritySelector
                                        {...input}
                                        value={input.value ? input.value : null}
                                        required={true}
                                        className={`${classes.textField} ${classes.clientSelector}`}
                                        error={meta.error && meta.touched}
                                        // tslint:disable-next-line: jsx-alignment
                                        onSelection={(selection: IAutoCompleteItem, name: string) => input.onChange(selection)}
                                        helperText={meta.error && meta.touched ? 'Priority is required' : ''}
                                        disablePortal={false}
                                    />
                                )}
                            </Field>
                        </Grid>
                        <Grid item={true} xs={12} md={6}>
                            <Field
                                name="category"
                                // validate={form.getFieldState('category')?.value !== null ? required : undefined}
                            >
                                {({ input, meta }) => (
                                    <TaskCategorySelector
                                        {...input}
                                        value={input.value ? input.value : null}
                                        label="Category"
                                        required={false}
                                        className={`${classes.textField} ${classes.clientSelector}`}
                                        error={meta.error && meta.touched}
                                        // tslint:disable-next-line: jsx-alignment
                                        onSelection={(selection: IAutoCompleteItem, name: string) => input.onChange(selection)}
                                        helperText={meta.error && meta.touched ? 'Category is required' : ''}
                                        disablePortal={false}
                                    />
                                )}
                            </Field>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item={true} xs={12} md={12} lg={12} sm={12}>
                    <Grid container={true} spacing={3}>
                        <Grid item={true} xs={12} md={6}>
                            <FormSpy subscription={{ values: true, validating: true }}>
                                {/* tslint:disable-next-line: no-shadowed-variable */}
                                {({ values }) => (
                                    <Field
                                        name="startDate"
                                        // validate={form.getFieldState('startDate')?.value !== null ? required : undefined}
                                    >
                                        {({ input, meta }) => (
                                            <KeyboardDateTimePicker
                                                {...input}
                                                value={input.value ? input.value : null}
                                                className={classes.textField}
                                                id="startDate"
                                                name="startDate"
                                                label="Start"
                                                format="DD/MM/YYYY hh:mm a"
                                                placeholder="dd/mm/yyyy hh:mm a"
                                                animateYearScrolling={true}
                                                // tslint:disable-next-line: max-line-length
                                                autoOk={true}
                                                allowKeyboardControl={true}
                                                variant={'inline'}
                                                disabled={values.allocatedToEntityType !== AllocatedToEntityType.user}
                                                error={meta.error && meta.touched}
                                                inputProps={{ autoComplete: 'off' }}
                                                InputLabelProps={{ shrink: true }}
                                                helperText={meta.error && meta.touched ? 'Start date is required' : ''}
                                            />
                                        )}
                                    </Field>
                                )}
                            </FormSpy>
                        </Grid>
                        <Grid item={true} xs={12} md={6}>
                            <FormSpy subscription={{ values: true, validating: true }}>
                                {/* tslint:disable-next-line: no-shadowed-variable */}
                                {({ values }) => (
                                    <Field
                                        name="completionDate"
                                        // validate={form.getFieldState('completionDate')?.value !== null ? required : undefined}
                                    >
                                        {({ input, meta }) => (
                                            <KeyboardDateTimePicker
                                                {...input}
                                                value={input.value ? input.value : null}
                                                className={classes.textField}
                                                label="Completion"
                                                format="DD/MM/YYYY hh:mm a"
                                                placeholder="dd/mm/yyyy hh:mm a"
                                                animateYearScrolling={true}
                                                // tslint:disable-next-line: max-line-length
                                                autoOk={true}
                                                allowKeyboardControl={true}
                                                variant={'inline'}
                                                disabled={values.allocatedToEntityType !== AllocatedToEntityType.user}
                                                error={meta.error && meta.touched}
                                                inputProps={{ autoComplete: 'off' }}
                                                InputLabelProps={{ shrink: true }}
                                                helperText={meta.error && meta.touched ? 'Completion date is required' : ''}
                                            />
                                        )}
                                    </Field>
                                )}
                            </FormSpy>
                        </Grid>
                        <Grid item={true} xs={12} md={6}>
                            <Field
                                name="estimatedEffort"
                                // validate={form.getFieldState('estimatedEffort')?.value !== null ? required : undefined}
                            >
                                {({ input, meta }) => (
                                    <TextField
                                        {...input}
                                        label="Estimated Effort"
                                        name="estimatedEffort"
                                        InputProps={{
                                            // tslint:disable-next-line: no-any
                                            inputComponent: TimeNumberFormatCustom as any,
                                        }}
                                        className={classes.textField}
                                        error={meta.error && meta.touched}
                                        helperText={meta.error && meta.touched ? 'Estiamted Effort is required' : ''}
                                        placeholder="00:00"
                                    />
                                )}
                            </Field>
                        </Grid>
                        <Grid item={true} xs={12} md={6}>
                            <FormSpy subscription={{ values: true, validating: true }}>
                                {/* tslint:disable-next-line: no-shadowed-variable */}
                                {({ values }) => (
                                    <Field
                                        name="duration"
                                        // validate={form.getFieldState('duration')?.value !== null ? required : undefined}
                                    >
                                        {({ input, meta }) => (
                                            <TextField
                                                {...input}
                                                name="duration"
                                                label="Actual Effort"
                                                InputProps={{
                                                    // tslint:disable-next-line: no-any
                                                    inputComponent: TimeNumberFormatCustom as any,
                                                }}
                                                className={classes.textField}
                                                disabled={values.allocatedToEntityType !== AllocatedToEntityType.user}
                                                error={meta.error && meta.touched}
                                                helperText={meta.error && meta.touched ? 'Duration is required' : ''}
                                                placeholder="00:00"
                                            />
                                        )}
                                    </Field>
                                )}
                            </FormSpy>
                        </Grid>
                        <Grid item={true} xs={12} md={6}>
                            <Field
                                name="owner"
                                // validate={form.getFieldState('assignTo')?.value !== null ? required : undefined}
                            >
                                {({ input, meta }) => (
                                    <TaskAssignedBySelector
                                        {...input}
                                        label="Owner"
                                        value={input.value ? input.value : null}
                                        disablePortal={false}
                                        // required={true}
                                        className={`${classes.textField} ${classes.clientSelector}`}
                                        error={meta.error && meta.touched}
                                        // tslint:disable-next-line: jsx-alignment
                                        onSelection={(selection: IAutoCompleteItem, name: string) => input.onChange(selection)}
                                        helperText={meta.error && meta.touched ? 'Owner to is required' : ''}
                                        visible={{
                                            role: true,
                                        }}
                                        isAuthorized={true}
                                    />
                                )}
                            </Field>
                        </Grid>
                        {props.guid && !props.isDuplicate && (
                            <Grid item={true} xs={12} md={6}>
                                <div className={classes.status}>
                                    Status:
                                    <span>{props.status ? props.status : state.taskDetails && state.taskDetails.taskStatus ? state.taskDetails.taskStatus : ''}</span>
                                </div>
                            </Grid>
                        )}
                        <FormSpy subscription={{ values: true, validating: true }}>
                            {/* tslint:disable-next-line: no-shadowed-variable */}
                            {({ values }) =>
                                values.completionDate ? (
                                    <Grid item={true} xs={12}>
                                        <Field name="completionNotes">
                                            {({ input, meta }) => (
                                                <TextField
                                                    {...input}
                                                    label="Completion Notes"
                                                    multiline={true}
                                                    className={classes.commentField}
                                                    // disabled={props.formAction === 'edit'}
                                                />
                                            )}
                                        </Field>
                                    </Grid>
                                ) : null}
                        </FormSpy>
                        <Grid item={true} xs={12} md={6}>
                            <Field name="isConfidential" subscription={{ touched: true, error: true, value: true }}>
                                {({ input, meta }) => (
                                    <FormControlLabel control={<Checkbox {...input} checked={input.value} onChange={input.onChange} color="primary" />} label="Confidential" />
                                )}
                            </Field>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        );
    };

    const documentsTab = (form: FormApi<TaskSummaryForm>, pristine: boolean, values: AnyObject) => {
        return (
            <Grid container={true} spacing={2} style={{ display: state.tab === TaskFormTab.documents ? 'block' : 'none' }}>
                <Grid item={true} xs={12} className={classes.customFieldGrid}>
                    <div>
                        <FormSpy subscription={{ values: true }}>
                            {/* tslint:disable-next-line: no-shadowed-variable */}
                            {({ values }) => getFileItems(values.files)}
                        </FormSpy>
                    </div>
                </Grid>
                <Grid item={true} xs={12} className={classes.customFieldGrid + ' ' + classes.dropZone}>
                    <Field
                        name="files"
                        // subscription={{touched: true, error: true, value: true}}
                    >
                        {({ input, meta }) => (
                            <DropzoneArea
                                {...input}
                                inputProps={{
                                    className: classes.dropZone,
                                }}
                                key={filesKey}
                                maxFileSize={15000000}
                                // acceptedFiles={['.msg', '.jpg', '.jpeg', '.png', '.docx', '.zip']}
                                showFileNames={true}
                                useChipsForPreview={true}
                                filesLimit={10}
                                dropzoneText="To add a file, drag and drop or click in this box.
                                Click the 'X' on a file to delete.
                                Click the filename to replace it.
                                View a file using the links above. 
                                These links will become available once the task is saved."
                                dropzoneClass={classes.dropZoneFont}
                                // tslint:disable-next-line: no-shadowed-variable
                                onChange={(files: File[]) => {
                                    let existingFiles: DocumentFile[] = [...input.value];
                                    const newFiles = files.filter((file: File) => {
                                        return !existingFiles.some((item: DocumentFile) => {
                                            return item.name === file.name && item.status !== 2;
                                        });
                                    });
                                    if (newFiles && newFiles.length > 0) {
                                        newFiles.forEach((file: File) => {
                                            const documentFile: DocumentFile = {
                                                guidID: null,
                                                file: file,
                                                status: 1,
                                                name: file.name,
                                                url: '',
                                            };
                                            existingFiles.push(documentFile);
                                        });
                                        input.onChange(existingFiles);
                                    }
                                }}
                                onDelete={(file: File) => {
                                    let existingFiles: DocumentFile[] = [...input.value];
                                    // eslint-disable-next-line array-callback-return
                                    existingFiles.map((item: DocumentFile) => {
                                        if (file.name === item.name) {
                                            item.file = file;
                                            item.status = 2; // deleted
                                        }
                                    });
                                    // tslint:disable-next-line: max-line-length
                                    const filteredFiles = existingFiles.filter((item: DocumentFile) => {
                                        return item.status !== 2 || item.guidID !== null;
                                    });
                                    input.onChange(filteredFiles);
                                }}
                                // tslint:disable-next-line
                                onDrop={(files: File[], event: any) => {
                                    // let existingFiles: DocumentFile[] = [...input.value];
                                    // tslint:disable-next-line: max-line-length
                                    // const existingFile = existingFiles.find((item: DocumentFile) => item.name === file)
                                }}
                                initialFiles={input.value.map((file: { url: string }) => file.url)}
                            />
                        )}
                    </Field>
                </Grid>
            </Grid>
        );
    };

    const activityTab = (form: FormApi<TaskSummaryForm>, pristine: boolean, values: AnyObject, submitting: boolean) => {
        return (
            <div 
                className={classes.activityTab} 
                // style={{ display: state.tab === TaskFormTab.activity ? 'flex' : 'none' }}
            >
                <>
                    {state.taskDetails && (
                        <div className={classes.taskHistorykContentWrapper}>
                            <div className={classes.historyActivityContainer}>
                                <TaskHistoryActivity
                                    className={`${classes.historyActivityWrapper} historyActivityWrapper`}
                                    taskDetail={state.taskDetails}
                                    width={TaskHistoryActivityWidth.FULL_WIDTH}
                                    autoScroll={true}
                                />
                            </div>
                        </div>
                    )}
                    {/* <Divider className={`${classes.divider} ${classes.marginBottom}`} /> */}

                    <div style={{ marginBottom: '10px', display: 'flex', gap: '10px' }}>
                        <FormSpy subscription={{ values: true, validating: true }}>
                            {/* tslint:disable-next-line: no-shadowed-variable */}
                            {({ values }) => (
                                <Field name="activityText">
                                    {({ input, meta }) => (
                                        <TextField
                                            {...input}
                                            label="Log Activity"
                                            name="activityText"
                                            required={false}
                                            multiline={true}
                                            // rows={4}
                                            className={classes.textField}
                                            disabled={values.allocatedToEntityType !== AllocatedToEntityType.user}
                                            error={meta.error && meta.touched}
                                            helperText={meta.error && meta.touched ? 'Activity Text is required' : ''}
                                            variant="outlined"
                                        />
                                    )}
                                </Field>
                            )}
                        </FormSpy>
                        <Button
                            color="primary"
                            type="submit"
                            className={classes.button}
                            onClick={() => {
                                form.change('handleCreateAndClose', false);
                                form.change('handleCreateAndNew', false);
                                form.change('handleUpdate', true);
                                form.change('handleUpdateAndClose', false);
                            }}
                            disabled={submitting || /*pristine ||*/ state.onSubmitLoading || !state.isChanged}
                        >
                            Update
                        </Button>
                    </div>
                </>
            </div>
        );
    };

    const taskTypeNotesTab = (form: FormApi<TaskSummaryForm>, pristine: boolean) => {
        return (
            <FormSpy subscription={{ values: true, pristine: true }}>
                {/* tslint:disable-next-line: no-shadowed-variable */}
                {({ values }) => {

                    if (values.taskType && values.taskType.notes) {
                        const contentBlock = htmlToDraft(values.taskType.notes);

                        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
                        
                        const editorState = EditorState.createWithContent(contentState);

                        return  (
                            <Grid container={true} spacing={2} style={{ display: state.tab === TaskFormTab.taskTypeNotes ? 'block' : 'none' }}>
                                <div className={classes.taskTypeNotesWrapper}>
                                    <Editor 
                                        editorState={editorState}
                                        readOnly={true}
                                        toolbarHidden={true}
                                    />
                                </div>
                            </Grid>
                        );
                    }
                    
                    return  null;
                }}
            </FormSpy>
        );
    };

    return (
        <>
            <DialogBox
                title="Task"
                // tslint:disable-next-line:max-line-length
                content={`Are you sure you want to close the form?`}
                show={state.showDiscard}
                isAgree={onDiscardChanges}
                disAgreeLabel={'No'}
                agreeLabel={'Yes'}
            />
            <Dialog
                open={state.isOpen}
                // onClose={onClose}
                onClose={(event, reason) => {
                    if (reason !== 'backdropClick') {
                        onClose();
                    }
                }}
                // maxWidth="md"
                fullWidth={true}
                className={classes.dialogRoot}
                scroll={'paper'}
                classes={{
                    paper: props.isCreate ? classes.dialogPaper : classes.dialogPaperWithActivity,
                }}
            >
                {state.isMatterLoading || state.isUserLoading || (state.isLoading && !state.isCreate) ? (
                    <DialogContent dividers={true}>
                        <div className={classes.loaderWrapper}>
                            <RvLoader size="small" label="Loading..." />
                        </div>
                    </DialogContent>
                ) : (
                    <Form
                        onSubmit={(values, form: FormApi<TaskSummaryForm>) => onSubmit(form, values)}
                        initialValues={taskSummaryForm}
                        initialValuesEqual={() => true}
                        keepDirtyOnReinitialize={true}
                        subscription={{ submitting: true, pristine: true, values: true}}
                        render={({ handleSubmit, form, submitting, pristine, values }) => (
                            <form onSubmit={(e) => handleSubmit(e)} id="taskForm" className={classes.form}>
                                
                                <FormSpy
                                    subscription={{ pristine: true, values: true }}
                                    // tslint:disable-next-line: no-shadowed-variable
                                    onChange={(props) => {
                                        onFormValueChanged(form, props);
                                    }}
                                />

                                <div className={classes.mainContent}>
                                    <DialogTitle className={classes.header}>
                                        <>
                                            <Grid container={true} spacing={3}>
                                                {/* <Grid item={true} xs={6} md={4}>
                                                    {heading(state.option, state.isCreate)} 
                                                </Grid> */}
                                                <Grid item={true} xs={12} md={12} justify="flex-end">
                                                    <Field
                                                        name="description"
                                                        // validate={form.getFieldState('description')?.value !== null ? required : undefined}
                                                    >
                                                        {({ input, meta }) => (
                                                            <TextField
                                                                {...input}
                                                                label="Enter details to create task"
                                                                name="description"
                                                                required={true}
                                                                // multiline={true}
                                                                className={`${classes.textField} ${classes.transformNone}`}
                                                                error={meta.error && meta.touched}
                                                                InputProps={{
                                                                    className: classes.taskDetailsField,
                                                                }}
                                                                helperText={meta.error && meta.touched ? 'Enter details to create task is required' : ''}
                                                            />
                                                        )}
                                                    </Field>
                                                </Grid>
                                            </Grid>
                                        </>
                                    </DialogTitle>
                                    <DialogContent dividers={true} className={classes.dialogContent}>
                                        <div className={classes.dialongInsideContentWrapper}>
                                            
                                            <Grid container={true} spacing={3}>
                                                <Grid item={true} xs={12} md={12}>
                                                    <Grid container={true} spacing={3}>
                                                        {showEntitySelection() ? (                                                        
                                                            <>
                                                                <Grid item={true} xs={12} md={5}>
                                                                    <div className={classes.formControlWrapper}>
                                                                        <FormControl component="fieldset" className={classes.clientRowRadio}>
                                                                            <RadioGroup
                                                                                defaultValue={TaskOptionEnum.none}
                                                                                name="type"
                                                                                className={classes.radioGroup}
                                                                                value={state.option}
                                                                                onChange={handleOption}
                                                                            >
                                                                                <FormControlLabel value={TaskOptionEnum.none} control={<StyledRadio />} label="None" />
                                                                                <FormControlLabel value={TaskOptionEnum.matter} control={<StyledRadio />} label="Matter" />
                                                                                <FormControlLabel value={TaskOptionEnum.client} control={<StyledRadio />} label="Client" />
                                                                            </RadioGroup>
                                                                        </FormControl>
                                                                    </div>
                                                                </Grid>
                                                                {filteredField(state.option, form)}
                                                            </>
                                                        ) : (
                                                            <>{getClientAndMatter()}</>
                                                        )}
                                                    </Grid>
                                                </Grid>
                                               
                                                {getTaskTypeHeader()}
                                                {getTaskTypeControl(form)}
                                                
                                                {/* {state.taskDetails && state.taskDetails.taskTypeInternalDescription === 'TaskFollowUp' && (
                                                    <Grid item={true} xs={12} md={12}>
                                                        <Typography color="primary">Task Type: {state.taskDetails.taskTypeDescription}</Typography>
                                                    </Grid>
                                                )}
                                                {!props.partyMessage && (state.taskDetails && state.taskDetails.taskTypeInternalDescription !== 'TaskFollowUp') && (
                                                    <Grid item={true} xs={12} md={12}>
                                                        <Field
                                                            name="taskType"
                                                            // validate={form.getFieldState('description')?.value !== null ? required : undefined}
                                                        >
                                                            {({ input, meta }) => (
                                                                <div className={classes.taskTypeWrapper}>
                                                                    <TaskTypeGroupSelector
                                                                        {...input}
                                                                        label="Task Type"
                                                                        name="taskType"
                                                                        // tslint:disable-next-line: no-any
                                                                        onSelection={(selection: TaskTypeGroupAutoCompleteItem | undefined | any, name: string) => {
                                                                            input.onChange(selection);
                                                                            form.change('estimatedEffort', selection ? selection.estimatedEffort : null);
                                                                            if (!selection.notes) {
                                                                                setState((prevState) => {
                                                                                    return {
                                                                                        ...prevState,
                                                                                        tab: TaskFormTab.details
                                                                                    };
                                                                                });
                                                                            } 
                                                                        }}
                                                                        className={classes.taskTypeSelector}
                                                                    />
                                                                </div>
                                                            )}
                                                        </Field>
                                                    </Grid>
                                                )}
                                                 */}
                                            </Grid>
                                            
                                            <FormSpy subscription={{ values: true, pristine: true }}>
                                                {/* tslint:disable-next-line: no-shadowed-variable */}
                                                {({ values }) => (
                                                    <Tabs value={state.tab} onChange={handleTabChange} indicatorColor="primary" textColor="primary" className={classes.tabs}>
                                                        <Tab label="Details" value={TaskFormTab.details} />
                                                        <Tab
                                                            label={
                                                                <Badge color="secondary" variant="dot" invisible={values.files.length === 0}>
                                                                    Documents
                                                                </Badge>}
                                                            value={TaskFormTab.documents}
                                                        />
                                                        {/* <Tab label="Activity" value={TaskFormTab.activity} /> */}
                                                        {values.taskType && values.taskType.notes && (
                                                            <Tab label="Task Notes" value={TaskFormTab.taskTypeNotes} />
                                                        )}
                                                    </Tabs>
                                                )}
                                            </FormSpy>
                                            <div className={classes.tabWrapper}>
                                                {detailsTab(form, pristine, values)}
                                                {documentsTab(form, pristine, values)}
                                                {taskTypeNotesTab(form, pristine)}
                                            </div>
                                        </div>
                                        {/* <div>
                                            {printJson(values)}
                                        </div> */}
                                    </DialogContent>
                                    <FormSpy subscription={{ values: true, pristine: true }}>
                                        {/* tslint:disable-next-line: no-shadowed-variable */}
                                        {({ values }) => <DialogActions>{actionButton(form, submitting, pristine, values)}</DialogActions>}
                                    </FormSpy>
                                </div>
                                {!props.isCreate ? (
                                    <>
                                        <Divider 
                                            orientation="vertical" 
                                            flexItem={true} 
                                            className={classes.verticialDivider}
                                        />
                                        <div className={classes.activityContent}>
                                            <div className={classes.activityContentHeader}>
                                                <div className={classes.acitivityContentTitle}>Activity</div>
                                            </div>
                                            <Divider className={classes.divider} flexItem={true} />
                                            {activityTab(form, pristine, values, submitting)}
                                        </div>
                                    </>
                                ) : null}
                            </form>
                        )}
                    />
                )}
            </Dialog>
        </>
    );
};

// tslint:disable-next-line: no-any
// function printJson(values: TaskSummaryForm) {
//     if (values) {
//         return (
//             <>
//                 <pre>
//                     {JSON.stringify(values, undefined, 2)}
//                 </pre>
//             </>
//         );
//     } else {
//         return (
//             <>
//                 <FormSpy subscription={{ values: true }}>
//                     {/* tslint:disable-next-line: no-shadowed-variable */}
//                     {({ values }) => (
//                         <pre>
//                             {JSON.stringify(values, undefined, 2)}
//                             {/* <RenderCount /> */}
//                         </pre>
//                     )}
//                 </FormSpy>
//             </>
//         );
//     }
// }
