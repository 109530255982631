import gql from 'graphql-tag';
import { FetchPolicy } from 'apollo-client';
import { client } from '..';
import { UserListData } from '../components/UserAutocompleteSelector';
import { DocumentFile } from '../types/DocumentFile';

export enum TaskStatusEnum {
    All = 0,
    Open = 1,
    Closed = 2,
    None = 3,
}

export interface TasksSummaryQueryParams {
    offset: number | null;
    first: number | null;
    filter: string | null;
    sortColumn?: string | null;
    sortDirection?: string | null;
    entityGuidID?: string | null;
    taskStatus: TaskStatusEnum | null;
    isIncludeCrmTasks: boolean | null;
    isMyTasksOnly: boolean | null;
    isPersonalTask: boolean | null;
    isFromDataSource: boolean | null;
    groupBy: string | null;
    groupByValue: string | null;
    completedFromDate: Date | null;
    completedToDate: Date | null;
    updatedFromDate: Date | null;
    updatedToDate: Date | null;
    priorityList: string | null;
    statusList: string | null;
    allocatedToList: string | null;
    allocatedByList: string | null;
    created: string | null;
    due: string | null;
    teamList: string | null;
    taskTypes: string | null;
    clientList: string | null;
    matterList: string | null;
    isIncludeFollowUpTasksOnly: boolean | null;
    isIncludeHasTasks: boolean | null | undefined;
}

export interface TaskSummaryListData {
    loading?: boolean;
    networkStatus?: number;
    task: TaskData;
}
export interface TaskData {
    summaryList: TaskSummaryList;
}
export interface TaskSummaryList {
    recordCount: number;
    actualRowCountForSearch: number;
    tasks: TaskSummary[];
}

export interface TaskSummary {
    startDate: Date | null;
    assignedByGuidID: null | string;
    assignedByName: string | null;
    assignedDate: Date | null;
    assignedToGuidID: null | string;
    assignedToName: string | null;
    allocatedToEntityType: number | null;
    completedDate: Date | null;
    createdDate: Date | null;
    dueDate: Date | null;
    entityGuidID: string | null;
    entityType: number;
    ownerGuidID: null | string;
    ownerName: string | null;
    properties: string | null;
    taskDescription: string | null;
    matterDescription: string | null;
    taskGuidID: string;
    taskStatusDescription: string | null;
    taskStatusGuidID: null | string;
    priorityGuidID: null | string;
    priorityDescription: null | string;
    priorityLevel: null | string;
    updatedDate: Date | null;
    taskType: string | null;
    taskTypeGuidID: string | null;
    taskTypeInternalDescription: string | null;
    taskTypeDescription: string | null;
    taskTypeNotes: string | null;
    isSystemTask: boolean | null;
    completionNotesGuidID: string | null;
    completionNotes: string | null;
    taskCategoryGuidID: string | null;
    taskCategoryDescription: string | null;
    matter: string | null;
    client: string | null;
    estimatedEffort: string | null;
    duration: string | null;
    notes: string | null;
    isKeyOpportunity: boolean;
    fromNameId: number | null;
    fromName: string | null;
    fromNameGuid: string | null;
    organisation: string | null;
    organisationGuid: string | null;
    isPhoneCall: boolean;
    isReturnedCall: boolean;
    isWillCallAgain: boolean;
    phoneNumber: string | null;
    isConfidential: boolean | null;
    matterId: number | null;
    nameId: number | null;
    isFromNameFromDataSource: boolean;
    isFromDataSource: boolean;
}

export function retrieveTasksSummaryData(
    query: TasksSummaryQueryParams,
    refreshData: boolean,
    onSuccess: (data: TaskSummaryListData) => void,
    // tslint:disable-next-line:no-any
    onError: (reason: any) => void,
): void {
    var fetchPolicy: FetchPolicy = refreshData === true ? 'network-only' : 'cache-first';
    client
        .query({
            query: TaskSummaryListData,
            variables: {
                offset: query.offset,
                first: query.first,
                filter: query.filter,
                sortColumn: query.sortColumn,
                sortDirection: query.sortDirection,
                entityGuidID: query.entityGuidID,
                groupBy: query.groupBy,
                groupByValue: query.groupByValue,
                taskStatus: query.taskStatus,
                isIncludeCrmTasks: query.isIncludeCrmTasks,
                isMyTasksOnly: query.isMyTasksOnly,
                completedFromDate: query.completedFromDate,
                completedToDate: query.completedToDate,
                updatedFromDate: query.updatedFromDate,
                updatedToDate: query.updatedToDate,
                priorityList: query.priorityList,
                statusList: query.statusList,
                allocatedToList: query.allocatedToList,
                allocatedByList: query.allocatedByList,
                created: query.created,
                due: query.due,
                teamList: query.teamList,
                taskTypes: query.taskTypes,
                clientList: query.clientList,
                matterList: query.matterList,
                isPersonalTask: query.isPersonalTask,
                isFromDataSource: query.isFromDataSource,
                isIncludeFollowUpTasksOnly: query.isIncludeFollowUpTasksOnly
            },
            fetchPolicy: fetchPolicy,
        })
        // tslint:disable-next-line:no-any
        .then((results: { data: any }) => {
            onSuccess(results.data);
        })
        // tslint:disable-next-line:no-any
        .catch((reason: any) => {
            onError(reason);
        });
}

const TaskSummaryListData = gql`
    query TaskSummaryData(
        $offset: Int
        $first: Int
        $filter: String
        $sortColumn: String
        $sortDirection: String
        $entityGuidID: String
        $taskStatus: Int
        $isIncludeCrmTasks: Boolean
        $isMyTasksOnly: Boolean
        $groupBy: String
        $groupByValue: String
        $completedFromDate: DateTime
        $completedToDate: DateTime
        $updatedFromDate: DateTime
        $updatedToDate: DateTime
        $priorityList: String
        $statusList: String
        $allocatedToList: String
        $allocatedByList: String
        $created: String
        $due: String
        $teamList: String
        $taskTypes: String
        $clientList: String
        $matterList: String
        $isPersonalTask: Boolean
        $isFromDataSource: Boolean
        $isIncludeFollowUpTasksOnly: Boolean
    ) {
        task {
            summaryList(
                offset: $offset
                first: $first
                filter: $filter
                sortColumn: $sortColumn
                sortDirection: $sortDirection
                entityGuidID: $entityGuidID
                taskStatus: $taskStatus
                isIncludeCrmTasks: $isIncludeCrmTasks
                isMyTasksOnly: $isMyTasksOnly
                groupBy: $groupBy
                groupByValue: $groupByValue
                completedFromDate: $completedFromDate
                completedToDate: $completedToDate
                updatedFromDate: $updatedFromDate
                updatedToDate: $updatedToDate
                priorityList: $priorityList
                statusList: $statusList
                allocatedToList: $allocatedToList
                allocatedByList: $allocatedByList
                created: $created
                due: $due
                teamList: $teamList
                taskTypes: $taskTypes
                clientList: $clientList
                matterList: $matterList
              	isPersonalTask: $isPersonalTask,
                isFromDataSource: $isFromDataSource,
              	isIncludeFollowUpTasksOnly: $isIncludeFollowUpTasksOnly
            ) {
                recordCount
                actualRowCountForSearch
                tasks {
                    startDate
                    assignedByGuidID
                    assignedByName
                    assignedDate
                    assignedToGuidID
                    assignedToName
                    allocatedToEntityType
                    completedDate
                    createdDate
                    dueDate
                    entityGuidID
                    entityType
                    ownerGuidID
                    ownerName
                    properties
                    taskDescription
                    matterDescription
                    taskGuidID
                    taskStatusDescription
                    taskStatusGuidID
                    isSystemTask
                    updatedDate
                    priorityGuidID
                    priorityDescription
                    priorityLevel
                    taskType
                    taskTypeGuidID
                    taskTypeInternalDescription
                    taskTypeDescription
                    taskTypeNotes
                    completionNotesGuidID
                    completionNotes
                    taskCategoryGuidID
                    taskCategoryDescription
                    matter
                    client
                    estimatedEffort
                    duration
                    notes
                    isKeyOpportunity
                    fromName
                    fromNameGuid
                    organisation
                    organisationGuid
                    isPhoneCall
                    isReturnedCall
                    isWillCallAgain
                    phoneNumber
                    isConfidential
                    matterId
                    nameId
                    isFromNameFromDataSource
                    isFromDataSource
                    responseTime
                    elapsedDays
                }
            }
        }
    }
`;

export interface AppUserData {
    loading?: boolean;
    networkStatus?: number;
    appUser: AppUser;
}

export interface AppUser {
    isAdministrator: boolean;
    hasFeeEarnerDashboardAccess: boolean;
    userId: string;
    name: string;
}

export function fetchAppUserData(
    refreshData: boolean,
    onSuccess: (data?: AppUserData) => void,
    // tslint:disable-next-line:no-any
    onError: (reason: any) => void,
): void {
    var fetchPolicy: FetchPolicy = refreshData === true ? 'network-only' : 'cache-first';

    client
        .query({
            query: UserData,
            variables: {},
            fetchPolicy: fetchPolicy,
        })
        // tslint:disable-next-line:no-any
        .then((results: { data: any }) => {
            onSuccess(results.data);
        })
        // tslint:disable-next-line:no-any
        .catch((reason: any) => {
            onError(reason);
        });
}

const UserData = gql`
    query AppUser {
        appUser {
            isAdministrator
            name
            userId
            hasFeeEarnerDashboardAccess
        }
    }
`;

export interface TaskStatusQueryParams {
    taskStatusType: string | null;
    includeCount: boolean | null;

    offset: number | null;
    first: number | null;
    filter: string | null;
    entityGuidID?: string | null;
    taskStatus: TaskStatusEnum | null;
    isIncludeCrmTasks: boolean | null;
    isMyTasksOnly: boolean | null;
    groupBy: string | null;
    groupByValue: string | null;
    completedFromDate: Date | null;
    completedToDate: Date | null;
    updatedFromDate: Date | null;
    updatedToDate: Date | null;
    priorityList: string | null;
    statusList: string | null;
    allocatedToList: string | null;
    allocatedByList: string | null;
    created: string | null;
    due: string | null;
    teamList: string | null;
    taskTypes: string | null;
    isPersonalTask: boolean | null;
    isFromDataSource: boolean | null;
    matterList: string | null;
    isIncludeFollowUpTasksOnly: boolean | null;
    isIncludeCount: boolean | null;
}

export interface TaskData {
    loading?: boolean;
    networkStatus?: number;
    task: Task;
}

export interface Task {
    statuses: TaskStatus[];
    dueDates: DueDate[];
}

export interface TaskStatus {
    guidId: string;
    description: string;
    sequence: number;
    type?: number;
    count: number;
}

export interface DueDate {
    id: number;
    description: string;
    count: number;
}

export function retrieveTaskStatusData(
    query: TaskStatusQueryParams,
    refreshData: boolean,
    onSuccess: (data: TaskData) => void,
    // tslint:disable-next-line:no-any
    onError: (reason: any) => void,
): void {
    var fetchPolicy: FetchPolicy = refreshData === true ? 'network-only' : 'cache-first';
    client
        .query({
            query: TaskStatusQuery,
            variables: {
                taskStatusType: query.taskStatusType,
                includeCount: query.includeCount,

                offset: query.offset,
                first: query.first,
                filter: query.filter,
                entityGuidID: query.entityGuidID,
                groupBy: query.groupBy,
                groupByValue: query.groupByValue,
                taskStatus: query.taskStatus,
                isIncludeCrmTasks: query.isIncludeCrmTasks,
                isMyTasksOnly: query.isMyTasksOnly,
                completedFromDate: query.completedFromDate,
                completedToDate: query.completedToDate,
                updatedFromDate: query.updatedFromDate,
                updatedToDate: query.updatedToDate,
                priorityList: query.priorityList,
                statusList: query.statusList,
                allocatedToList: query.allocatedToList,
                allocatedByList: query.allocatedByList,
                created: query.created,
                due: query.due,
                teamList: query.teamList,
                taskTypes: query.taskTypes,
                isPersonalTask: query.isPersonalTask,
                isFromDataSource: query.isFromDataSource,
                matterList: query.matterList,
                isIncludeFollowUpTasksOnly: query.isIncludeFollowUpTasksOnly
            },
            fetchPolicy: fetchPolicy,
        })
        // tslint:disable-next-line:no-any
        .then((results: { data: any }) => {
            onSuccess(results.data);
        })
        // tslint:disable-next-line:no-any
        .catch((reason: any) => {
            onError(reason);
        });
}

export interface PriorityData {
    loading?: boolean;
    networkStatus?: number;
    lookup: PriorityLookup;
}

export interface PriorityLookup {
    priority: Priority[];
}

export interface Priority {
    guidId: string;
    description: string;
    level: number;
    sequence: number;
}

export function retrievePriorityData(
    refreshData: boolean,
    onSuccess: (data: PriorityData) => void,
    // tslint:disable-next-line:no-any
    onError: (reason: any) => void,
): void {
    var fetchPolicy: FetchPolicy = refreshData === true ? 'network-only' : 'cache-first';
    client
        .query({
            query: PriorityQuery,
            variables: {},
            fetchPolicy: fetchPolicy,
        })
        // tslint:disable-next-line:no-any
        .then((results: { data: any }) => {
            onSuccess(results.data);
        })
        // tslint:disable-next-line:no-any
        .catch((reason: any) => {
            onError(reason);
        });
}

export interface TaskCategoryData {
    loading?: boolean;
    networkStatus?: number;
    task: TaskCategoryLookup;
}

export interface TaskCategoryLookup {
    categories: TaskCategory[];
}

export interface TaskCategory {
    id?: number;
    guidId: string;
    description: string;
    hasTasks?: boolean;
}

export function retrieveTaskCategoryData(
    query: TasksSummaryQueryParams,
    refreshData: boolean,
    onSuccess: (data: TaskCategoryData) => void,
    // tslint:disable-next-line:no-any
    onError: (reason: any) => void,
): void {
    var fetchPolicy: FetchPolicy = refreshData === true ? 'network-only' : 'cache-first';
    client
        .query({
            query: TaskCategoryQuery,
            variables: {
                offset: query.offset,
                first: query.first,
                filter: query.filter,
                entityGuidID: query.entityGuidID,
                groupBy: query.groupBy,
                groupByValue: query.groupByValue,
                taskStatus: query.taskStatus,
                isIncludeCrmTasks: query.isIncludeCrmTasks,
                isMyTasksOnly: query.isMyTasksOnly,
                isFromDataSource: query.isFromDataSource,
                completedFromDate: query.completedFromDate,
                completedToDate: query.completedToDate,
                updatedFromDate: query.updatedFromDate,
                updatedToDate: query.updatedToDate,
                priorityList: query.priorityList,
                statusList: query.statusList,
                allocatedToList: query.allocatedToList,
                allocatedByList: query.allocatedByList,
                teamList: query.teamList,
                taskTypes: query.taskTypes,
                isIncludeFollowUpTasksOnly: query.isIncludeFollowUpTasksOnly
            },
            fetchPolicy: fetchPolicy,
        })
        // tslint:disable-next-line:no-any
        .then((results: { data: any }) => {
            onSuccess(results.data);
        })
        // tslint:disable-next-line:no-any
        .catch((reason: any) => {
            onError(reason);
        });
}

const TaskCategoryQuery = gql`
    query lookupTaskCategory(
        $offset: Int
        $first: Int
        $filter: String
        $entityGuidID: String
        $taskStatus: Int
        $isIncludeCrmTasks: Boolean
        $isMyTasksOnly: Boolean
        $groupBy: String
        $groupByValue: String
        $completedFromDate: DateTime
        $completedToDate: DateTime
        $updatedFromDate: DateTime
        $updatedToDate: DateTime
        $priorityList: String
        $statusList: String
        $allocatedToList: String
        $allocatedByList: String
        $teamList: String
        $taskTypes: String
        $isIncludeFollowUpTasksOnly: Boolean
    ) {
        task {
            categories(
                offset: $offset
                first: $first
                filter: $filter
                entityGuidID: $entityGuidID
                taskStatus: $taskStatus
                isIncludeCrmTasks: $isIncludeCrmTasks
                isMyTasksOnly: $isMyTasksOnly
                groupBy: $groupBy
                groupByValue: $groupByValue
                completedFromDate: $completedFromDate
                completedToDate: $completedToDate
                updatedFromDate: $updatedFromDate
                updatedToDate: $updatedToDate
                priorityList: $priorityList
                statusList: $statusList
                allocatedToList: $allocatedToList
                allocatedByList: $allocatedByList
                teamList: $teamList
                taskTypes: $taskTypes
              	isIncludeFollowUpTasksOnly: $isIncludeFollowUpTasksOnly
            ) {
                guidId
                description
                hasTasks
            }
        }
    }
`;

export interface TaskDueDateQueryParams {
    isIncludeCount: boolean | null;

    offset: number | null;
    first: number | null;
    filter: string | null;
    entityGuidID?: string | null;
    taskStatus: TaskStatusEnum | null;
    isIncludeCrmTasks: boolean | null;
    isMyTasksOnly: boolean | null;
    groupBy: string | null;
    groupByValue: string | null;
    completedFromDate: Date | null;
    completedToDate: Date | null;
    updatedFromDate: Date | null;
    updatedToDate: Date | null;
    priorityList: string | null;
    statusList: string | null;
    allocatedToList: string | null;
    allocatedByList: string | null;
    created: string | null;
    due: string | null;
    teamList: string | null;
    taskTypes: string | null;
    isIncludeFollowUpTasksOnly: boolean | null;
    isFromDataSource: boolean | null;
}

export function retrieveTaskDueDateData(
    query: TaskDueDateQueryParams,
    refreshData: boolean,
    onSuccess: (data: TaskData) => void,
    // tslint:disable-next-line:no-any
    onError: (reason: any) => void,
): void {
    var fetchPolicy: FetchPolicy = refreshData === true ? 'network-only' : 'cache-first';
    client
        .query({
            query: TaskDueDatesQuery,
            variables: {
                isIncludeCount: query.isIncludeCount,
                offset: query.offset,
                first: query.first,
                filter: query.filter,
                entityGuidID: query.entityGuidID,
                groupBy: query.groupBy,
                groupByValue: query.groupByValue,
                taskStatus: query.taskStatus,
                isIncludeCrmTasks: query.isIncludeCrmTasks,
                isMyTasksOnly: query.isMyTasksOnly,
                completedFromDate: query.completedFromDate,
                completedToDate: query.completedToDate,
                updatedFromDate: query.updatedFromDate,
                updatedToDate: query.updatedToDate,
                priorityList: query.priorityList,
                statusList: query.statusList,
                allocatedToList: query.allocatedToList,
                allocatedByList: query.allocatedByList,
                created: query.created,
                due: query.due,
                teamList: query.teamList,
                taskTypes: query.taskTypes,
                isIncludeFollowUpTasksOnly: query.isIncludeFollowUpTasksOnly,
                isFromDataSource: query.isFromDataSource
            },
            fetchPolicy: fetchPolicy,
        })
        // tslint:disable-next-line:no-any
        .then((results: { data: any }) => {
            onSuccess(results.data);
        })
        // tslint:disable-next-line:no-any
        .catch((reason: any) => {
            onError(reason);
        });
}

const TaskDueDatesQuery = gql`
    query taskDueDates(
        $isIncludeCount: Boolean
        $offset: Int
        $first: Int
        $filter: String
        $entityGuidID: String
        $taskStatus: Int
        $isIncludeCrmTasks: Boolean
        $isMyTasksOnly: Boolean
        $groupBy: String
        $groupByValue: String
        $completedFromDate: DateTime
        $completedToDate: DateTime
        $updatedFromDate: DateTime
        $updatedToDate: DateTime
        $priorityList: String
        $statusList: String
        $allocatedToList: String
        $allocatedByList: String
        $created: String
        $due: String
        $teamList: String
        $taskTypes: String
        $isIncludeFollowUpTasksOnly: Boolean
        $isFromDataSource: Boolean
    ) {
        task {
            dueDates(
                isIncludeCount: $isIncludeCount
                offset: $offset
                first: $first
                filter: $filter
                entityGuidID: $entityGuidID
                taskStatus: $taskStatus
                isIncludeCrmTasks: $isIncludeCrmTasks
                isMyTasksOnly: $isMyTasksOnly
                groupBy: $groupBy
                groupByValue: $groupByValue
                completedFromDate: $completedFromDate
                completedToDate: $completedToDate
                updatedFromDate: $updatedFromDate
                updatedToDate: $updatedToDate
                priorityList: $priorityList
                statusList: $statusList
                allocatedToList: $allocatedToList
                allocatedByList: $allocatedByList
                created: $created
                due: $due
                teamList: $teamList
                taskTypes: $taskTypes
              	isIncludeFollowUpTasksOnly: $isIncludeFollowUpTasksOnly
                isFromDataSource: $isFromDataSource
            ) {
                id
                description
                count
            }
        }
    }
`;

export interface FirmData {
    loading?: boolean;
    networkStatus?: number;
    firm: Firm;
}

export interface Firm {
    teams: Teams;
}

export interface Teams {
    team: Team[];
}

export interface Team {
    id: number;
    guidID: string;
    description: string;
    staffCount: number;
    taskCount: number;
}

export interface TaskTeamQueryParams {
    isIncludeTaskCount: boolean | null;

    offset: number | null;
    first: number | null;
    filter: string | null;
    entityGuidID?: string | null;
    taskStatus: TaskStatusEnum | null;
    isIncludeCrmTasks: boolean | null;
    isMyTasksOnly: boolean | null;
    groupBy: string | null;
    groupByValue: string | null;
    completedFromDate: Date | null;
    completedToDate: Date | null;
    updatedFromDate: Date | null;
    updatedToDate: Date | null;
    priorityList: string | null;
    statusList: string | null;
    allocatedToList: string | null;
    allocatedByList: string | null;
    created: string | null;
    due: string | null;
    teamList: string | null;
    taskTypes: string | null;
    onlyUsedByTasks: boolean | null;
    onlyUsedByEnquiries: boolean | null;
    onlyUsedByFinancials: boolean | null;
    isFromDataSource: boolean | null;
}

export function retrieveTaskTeamData(
    query: TaskTeamQueryParams,
    refreshData: boolean,
    onSuccess: (data: FirmData) => void,
    // tslint:disable-next-line:no-any
    onError: (reason: any) => void,
): void {
    var fetchPolicy: FetchPolicy = refreshData === true ? 'network-only' : 'cache-first';
    client
        .query({
            query: TeamTaskQuery,
            variables: {
                isIncludeTaskCount: query.isIncludeTaskCount,
                offset: query.offset,
                first: query.first,
                filter: query.filter,
                entityGuidID: query.entityGuidID,
                groupBy: query.groupBy,
                groupByValue: query.groupByValue,
                taskStatus: query.taskStatus,
                isIncludeCrmTasks: query.isIncludeCrmTasks,
                isMyTasksOnly: query.isMyTasksOnly,
                completedFromDate: query.completedFromDate,
                completedToDate: query.completedToDate,
                updatedFromDate: query.updatedFromDate,
                updatedToDate: query.updatedToDate,
                priorityList: query.priorityList,
                statusList: query.statusList,
                allocatedToList: query.allocatedToList,
                allocatedByList: query.allocatedByList,
                created: query.created,
                due: query.due,
                teamList: query.teamList,
                taskTypes: query.taskTypes,
                isFromDataSource: query.isFromDataSource
            },
            fetchPolicy: fetchPolicy,
        })
        // tslint:disable-next-line:no-any
        .then((results: { data: any }) => {
            onSuccess(results.data);
        })
        // tslint:disable-next-line:no-any
        .catch((reason: any) => {
            onError(reason);
        });
}

const TeamTaskQuery = gql`
    query firmTeamTask(
        $isIncludeTaskCount: Boolean
        $offset: Int
        $first: Int
        $filter: String
        $entityGuidID: String
        $taskStatus: Int
        $isIncludeCrmTasks: Boolean
        $isMyTasksOnly: Boolean
        $groupBy: String
        $groupByValue: String
        $completedFromDate: DateTime
        $completedToDate: DateTime
        $updatedFromDate: DateTime
        $updatedToDate: DateTime
        $priorityList: String
        $statusList: String
        $allocatedToList: String
        $allocatedByList: String
        $created: String
        $due: String
        $teamList: String
        $taskTypes: String
        $onlyUsedByTasks: Boolean
        $onlyUsedByEnquiries: Boolean
        $onlyUsedByFinancials: Boolean
        $isFromDataSource: Boolean
    ) {
        firm {
            teams(
                isIncludeTaskCount: $isIncludeTaskCount
                offset: $offset
                first: $first
                filter: $filter
                entityGuidId: $entityGuidID
                taskStatus: $taskStatus
                isIncludeCrmTasks: $isIncludeCrmTasks
                isMyTasksOnly: $isMyTasksOnly
                groupBy: $groupBy
                groupByValue: $groupByValue
                completedFromDate: $completedFromDate
                completedToDate: $completedToDate
                updatedFromDate: $updatedFromDate
                updatedToDate: $updatedToDate
                priorityList: $priorityList
                statusList: $statusList
                allocatedToList: $allocatedToList
                allocatedByList: $allocatedByList
                created: $created
                due: $due
                teamList: $teamList
                taskTypes: $taskTypes
                onlyUsedByTasks: $onlyUsedByTasks
                onlyUsedByEnquiries: $onlyUsedByEnquiries
                onlyUsedByFinancials: $onlyUsedByFinancials
                isFromDataSource: $isFromDataSource
            ) {
                team {
                    id
                    guidID
                    description
                    taskCount
                    usedByTasks
                    usedByFinancials
                    usedByEnquiries
                }
            }
        }
    }
`;

const TaskStatusQuery = gql`
    query taskStatus(
        $taskStatusType: TaskStatusEnum
        $includeCount: Boolean
        $offset: Int
        $first: Int
        $filter: String
        $entityGuidID: String
        $taskStatus: Int
        $isIncludeCrmTasks: Boolean
        $isMyTasksOnly: Boolean
        $groupBy: String
        $groupByValue: String
        $completedFromDate: DateTime
        $completedToDate: DateTime
        $updatedFromDate: DateTime
        $updatedToDate: DateTime
        $priorityList: String
        $statusList: String
        $allocatedToList: String
        $allocatedByList: String
        $created: String
        $due: String
        $teamList: String
        $taskTypes: String
        $isPersonalTask: Boolean,
        $isFromDataSource: Boolean,
        $matterList: String
        $isIncludeFollowUpTasksOnly: Boolean
    ) {
        task {
            statuses(
                taskStatusType: $taskStatusType
                includeCount: $includeCount
                offset: $offset
                first: $first
                filter: $filter
                entityGuidID: $entityGuidID
                taskStatus: $taskStatus
                isIncludeCrmTasks: $isIncludeCrmTasks
                isMyTasksOnly: $isMyTasksOnly
                groupBy: $groupBy
                groupByValue: $groupByValue
                completedFromDate: $completedFromDate
                completedToDate: $completedToDate
                updatedFromDate: $updatedFromDate
                updatedToDate: $updatedToDate
                priorityList: $priorityList
                statusList: $statusList
                allocatedToList: $allocatedToList
                allocatedByList: $allocatedByList
                created: $created
                due: $due
                teamList: $teamList
                taskTypes: $taskTypes
                isPersonalTask: $isPersonalTask,
                isFromDataSource: $isFromDataSource,
              	matterList: $matterList
              	isIncludeFollowUpTasksOnly: $isIncludeFollowUpTasksOnly
            ) {
                guidId
                description
                sequence
                type
                count
            }
        }
    }
`;

export const PriorityQuery = gql`
    query lookupPriority {
        lookup {
            priority {
                guidId
                description
                sequence
                level
            }
        }
    }
`;

export const ChangeStatus = gql`
    mutation changeTaskStatus($guidId: String, $statusGuidId: String) {
        changeTaskStatus(guidId: $guidId, statusGuidId: $statusGuidId)
    }
`;

export const ChangePriority = gql`
    mutation changeTaskPriority($guidId: String, $priorityGuidId: String) {
        changeTaskPriority(guidId: $guidId, priorityGuidId: $priorityGuidId)
    }
`;

export const ChangeAllocation = gql`
    mutation changeTaskAllocation($guidId: String, $allocatedUserGuidId: String) {
        changeTaskAllocation(guidId: $guidId, allocatedUserGuidId: $allocatedUserGuidId)
    }
`;

export type TaskSubscriptionData = {
    taskNotification: TaskNotification;
};

export type TaskNotification = {
    id:          number;
    message:     string;
    status:      boolean;
    description: null;
};

export const TaskSubscription = gql`
    subscription taskSubscription {
        taskNotification {
            id
            message
            status
            description
        }
    }
`;

export const CreateGeneralTask = gql`
    mutation CreateGeneralTask($input: TaskInput) {
        createGeneralTask(input: $input) {
            status
            error
        }
    }
`;

export const CreateClientTask = gql`
    mutation CreateClientTask($clientGuid: String, $input: TaskInput, $parentNameTextId: Int, $parentNameCrmTextId: Int) {
        createClientTask(input: $input, clientGuid: $clientGuid, parentNameTextId: $parentNameTextId, parentNameCrmTextId: $parentNameCrmTextId) {
            status
            error
        }
    }
`;

export const CreateMatterTask = gql`
    mutation CreateMatterTask($matterGuid: String, $input: TaskInput) {
        createMatterTask(input: $input, matterGuid: $matterGuid) {
            status
            error
        }
    }
`;

export const UpdateGeneralTask = gql`
    mutation UpdateGeneralTask($input: TaskInput) {
        updateGeneralTask(input: $input) {
            status
            error
        }
    }
`;

export const UpdateClientTask = gql`
    mutation UpdateClientTask($clientGuid: String, $input: TaskInput, $parentNameTextId: Int, $parentNameCrmTextId: Int) {
        updateClientTask(input: $input, clientGuid: $clientGuid, parentNameTextId: $parentNameTextId, parentNameCrmTextId: $parentNameCrmTextId) {
            status
            error
        }
    }
`;

export const UpdateMatterTask = gql`
    mutation UpdateMatterTask($matterGuid: String, $input: TaskInput) {
        updateMatterTask(input: $input, matterGuid: $matterGuid) {
            status
            error
        }
    }
`;

export const DeleteTask = gql`
    mutation DeleteTask($guid: String) {
        deleteTask(guid: $guid)
    }
`;

export const StartTask = gql`
    mutation StartTask($guid: String, $startDate: DateTime, $dueDate: DateTime, $estimatedEffort: String, $reminderOffset: String) {
        startTask(guidId: $guid, startDate: $startDate, dueDate: $dueDate, estimatedEffort: $estimatedEffort, reminderOffset: $reminderOffset)
    }
`;

export const CompleteTask = gql`
    mutation CompleteTask($guid: String, $completionDate: DateTime, $startDate: DateTime, $dueDate: DateTime, $duration: String, $completionNotes: String, 
        $parentNameTextId: Int, $parentNameCrmTextId: Int) {
        completeTask(guidId: $guid, completionDate: $completionDate, startDate: $startDate, dueDate: $dueDate, duration: $duration, completionNotes: $completionNotes, 
            parentNameTextId: $parentNameTextId, parentNameCrmTextId: $parentNameCrmTextId)
    }
`;

export const ChangeTaskPriority = gql`
    mutation ChangeTaskPriority($guid: String, $priority: String) {
        changeTaskPriority(guidId: $guid, priority: $priority)
    }
`;

export const ChangeTaskCategory = gql`
    mutation ChangeTaskCategory($guid: String, $category: String) {
        changeTaskCategory(guidId: $guid, category: $category)
    }
`;

export const ChangeTaskAllocation = gql`
    mutation ChangeTaskAllocation($guidId: String, $assignedTo: String, $assignedBy: String, $allocatedToEntityType: Int) {
        changeTaskAllocation(guidId: $guidId, assignedTo: $assignedTo, assignedBy: $assignedBy, allocatedToEntityType: $allocatedToEntityType)
    }
`;

export const ChangeTaskOwner = gql`
    mutation ChangeTaskOwner($guid: String, $owner: String) {
        changeTaskOwner(guidId: $guid, owner: $owner)
    }
`;

export const ChangeTaskStatus = gql`
    mutation ChangeTaskStatus(
        $guid: String
        $startDate: DateTime
        $completionDate: DateTime
        $dueDate: DateTime
        $estimatedEffort: String
        $duration: String
        $assignedTo: String
        $assignedBy: String
        $completionNotes: String
        $changedStatus: String
        $owner: String
        $allocatedToEntityType: Int
    ) {
        changeTaskStatus(
            guidId: $guid
            completionDate: $completionDate
            startDate: $startDate
            dueDate: $dueDate
            estimatedEffort: $estimatedEffort
            duration: $duration
            assignedTo: $assignedTo
            assignedBy: $assignedBy
            completionNotes: $completionNotes
            changedStatus: $changedStatus
            owner: $owner
            allocatedToEntityType: $allocatedToEntityType
        )
    }
`;
export const ChangeDueTask = gql`
    mutation DueTask($guid: String, $startDate: DateTime, $dueDate: DateTime, $estimatedEffort: String, $reminderOffset: String) {
        dueTask(guidId: $guid, startDate: $startDate, dueDate: $dueDate, estimatedEffort: $estimatedEffort, reminderOffset: $reminderOffset)
    }
`;

export interface TaskDetailsParam {
    guid: string;
}
export interface TaskDetailsData {
    loading?: boolean;
    networkStatus?: number;
    task: Task;
    appUser: AppUser;
}

export interface Task {
    detail: Detail;
}

export interface Detail {
    __typename: string;
    guid: string;
    clientGuid: string | null;
    clientDescription: string | null;
    parentNameTextId: number | null;
    parentNameCrmTextId: number | null;
    assignedBy: string | null;
    assignedByName: string | null;
    assignedTo: string | null;
    assignedToName: string | null;
    allocatedToEntityType: number | null;
    owner: string | null;
    files: DocumentFile[];
    emailContent: string;
    ownerName: string | null;
    category: string | null;
    categoryDescription: string | null;
    createdDate: Date | null;
    createdByName: string | null;
    startDate: Date | null;
    completionDate: Date | null;
    description: string | null;
    notes: string | null;
    dueDate: Date | null;
    estimatedEffort: string | null;
    duration: string | null;
    priority: string | null;
    priorityDescription: string | null;
    taskStatus: string | null;
    taskType: string | null;
    taskTypeGuidID: string | null;
    taskTypeInternalDescription: string | null;
    taskTypeDescription: string | null;
    taskTypeNotes: string | null;
    isSystemTask: boolean;
    isFromNameFromDataSource: boolean;
    isFromDataSource: boolean;
    isOrganisationFromDataSource: boolean;
    completionNotesGuidID: string | null;
    completionNotes: string | null;
    activityTexts: ActivityText[];
    allocationHistoryList: AllocationHistory[];
    taskActivity: TaskActivity[];
    reminderOffset: string | null;
    isConfidential: boolean | null;
    messageTitle: string | null;

    matterId: number | null;
    matterGuid: string | null;
    matterDescription: string | null;
    writeOffFeesAmount: number | null;
    writeOffDisbursementsAmount: number | null;
    writeOffDebtorsAmount: number | null;
    billFeesAmount: number | null;
    billDisbursementsAmount: number | null;
    applyTrustAmount: number | null;
    invoiceToApply: string | null;
    trustTransferAmount: number | null;
    closeMatterAfterWriteOff: boolean | null;
    comments: string | null;
    archive: boolean | null;
    close: boolean | null;

    fromNameId: number | null;
    fromName: string | null;
    fromNameGuid: string | null;
    phoneNumber: string | null;
    organisation: string | null;
    organisationGuid: string | null;
    isPhoneCall: boolean;
    isReturnedCall: boolean;
    isWillCallAgain: boolean;
    ccUserList: CcUserList[] | null;
}

export interface CcUserList {
    userID:       string;
    email:        string;
    friendlyName: string;
    roleName:     string;
    isStaff:      boolean;
    staffName:    null | string;
    isExternal:   boolean;
}

export interface ActivityText {
    activityDate: Date;
    createdByGuid: string | null;
    createdByName: string | null;
    text: string | null;
    message: string | null;
    messagedTo: string | null;
}

export interface AllocationHistory {
    guidID: string | null;
    createdDate: Date;
    allocatedByUserID: string | null;
    allocatedByName: string | null;
    allocatedUserID: string | null;
    allocatedUserName: string | null;
    allocatedTeamID: string | null;
    allocatedTeamName: string | null;
}

export interface TaskActivity {
    guidID: string | null;
    activityDate: Date | null;
    activityTypeID: string | null;
    activityTypeDescription: string | null;
    activityByUserId: string | null;
    activityByUser: string | null;
}

export function retrieveTaskDetailsData(
    query: TaskDetailsParam,
    refreshData: boolean,
    onSuccess: (data: TaskDetailsData) => void,
    // tslint:disable-next-line:no-any
    onError: (reason: any) => void,
): void {
    var fetchPolicy: FetchPolicy = refreshData === true ? 'network-only' : 'cache-first';
    client
        .query({
            query: TASK_DETAILS_QUERY,
            variables: {
                guid: query.guid,
            },
            fetchPolicy: fetchPolicy,
        })
        // tslint:disable-next-line:no-any
        .then((results: { data: any }) => {
            onSuccess(results.data);
        })
        // tslint:disable-next-line:no-any
        .catch((reason: any) => {
            onError(reason);
        });
}

export const TASK_DETAILS_QUERY = gql`
fragment taskDetails on TaskDetailTypeInterface {
    guid
    assignedBy
    assignedByName
    assignedTo
    assignedToName
    allocatedToEntityType
    owner
    ownerName
    category
    categoryDescription
    createdDate
    createdByName
    startDate
    completionDate
    description
    notes
    dueDate
    estimatedEffort
    duration
    priority
    priorityDescription
    taskTypeGuid
    taskType
    taskTypeDescription
    taskTypeNotes
    taskTypeInternalDescription
    completionNotesGuidID
    completionNotes
    taskStatus
    isSystemTask
    isFromDataSource
    messageTitle
    activityTexts {
        id
        activityDate
        createdByGuid
        createdByName
        text
        message
        messagedTo
    }
    allocationHistoryList {
        id
        guidID
        createdDate
        allocatedByUserID
        allocatedByName
        allocatedUserID
        allocatedUserName
        allocatedTeamID
        allocatedTeamName
    }
    taskActivity {
        guidID
        activityDate
        activityTypeDescription
        activityTypeID
        activityByUser
    }
    files {
        id
        guidID
        entityType
        name
        url
    }
    ccUserList {
      userID
      email
      friendlyName
      roleName
      isStaff
      staffName
      isExternal
    }
    emailContent
    reminderOffset
    isConfidential
}

query TaskDetails($guid: String!) {
    appUser {
        isAdministrator
        name
        userId
    }
    task {
        detail(guid: $guid) {
            ... on FollowUpTask {
                ...taskDetails
            }
            ... on GeneralTask {
                ...taskDetails
            }
            ... on ClientTask {
                ...taskDetails
                clientGuid
                clientDescription
                parentNameTextId
                parentNameCrmTextId
            }
            ... on MatterTask {
                ...taskDetails
                matterId
                matterGuid
                matterDescription
                writeOffFeesAmount
                writeOffDisbursementsAmount
                writeOffDebtorsAmount
                billFeesAmount
                billDisbursementsAmount
                applyTrustAmount
                invoiceToApply
                trustTransferAmount
                closeMatterAfterWriteOff
                comments
                archive
                close
            }
            ... on PhoneTask {
                ...taskDetails
                matterId
                clientGuid
                clientDescription
                matterId
                matterGuid
                matterDescription
                fromNameId
                fromName
                fromNameGuid
                phoneNumber
                organisation
                organisationGuid
                isPhoneCall
                isReturnedCall
                isWillCallAgain
                guid
                assignedBy
                assignedByName
                assignedTo
                assignedToName
                category
                categoryDescription
                startDate
                completionDate
                description
                notes
                dueDate
                estimatedEffort
                duration
                priority
                priorityDescription
                taskType
                completionNotesGuidID
                completionNotes
                taskStatus
                taskTypeInternalDescription
                isSystemTask
                isFromNameFromDataSource
              	isOrganisationFromDataSource      
                messageTitle          
            }
        }
    }
}  
`;

export interface TaskTypeData {
    task: Task;
}
export interface Task {
    taskTypes: TaskType[];
}
export interface TaskType {
    id: number;
    guidId: string;
    description: string;
    internalDescription: string;
    hasTasks: boolean;
}

export function retrieveTaskTypeData(
    query: TasksSummaryQueryParams,
    refreshData: boolean,
    onSuccess: (data: TaskTypeData) => void,
    // tslint:disable-next-line:no-any
    onError: (reason: any) => void,
): void {
    var fetchPolicy: FetchPolicy = refreshData === true ? 'network-only' : 'cache-first';
    client
        .query({
            query: taskTypeQuery,
            variables: {
                offset: query.offset,
                first: query.first,
                filter: query.filter,
                entityGuidID: query.entityGuidID,
                groupBy: query.groupBy,
                groupByValue: query.groupByValue,
                taskStatus: query.taskStatus,
                isIncludeCrmTasks: query.isIncludeCrmTasks,
                isMyTasksOnly: query.isMyTasksOnly,
                isPersonalTask: query.isPersonalTask,
                isFromDataSource: query.isFromDataSource,
                completedFromDate: query.completedFromDate,
                completedToDate: query.completedToDate,
                updatedFromDate: query.updatedFromDate,
                updatedToDate: query.updatedToDate,
                priorityList: query.priorityList,
                statusList: query.statusList,
                allocatedToList: query.allocatedToList,
                allocatedByList: query.allocatedByList,
                teamList: query.teamList,
                taskTypes: query.taskTypes,
                isIncludeFollowUpTasksOnly: query.isIncludeFollowUpTasksOnly
            },
            fetchPolicy: fetchPolicy,
        })
        // tslint:disable-next-line:no-any
        .then((results: { data: any }) => {
            onSuccess(results.data);
        })
        // tslint:disable-next-line:no-any
        .catch((reason: any) => {
            onError(reason);
        });
}

const taskTypeQuery = gql`
    query taskType(
        $offset: Int
        $first: Int
        $filter: String
        $entityGuidID: String
        $taskStatus: Int
        $isIncludeCrmTasks: Boolean
        $isMyTasksOnly: Boolean
        $isPersonalTask: Boolean
        $isFromDataSource: Boolean
        $groupBy: String
        $groupByValue: String
        $completedFromDate: DateTime
        $completedToDate: DateTime
        $updatedFromDate: DateTime
        $updatedToDate: DateTime
        $priorityList: String
        $statusList: String
        $allocatedToList: String
        $allocatedByList: String
        $teamList: String
        $taskTypes: String
        $isIncludeFollowUpTasksOnly: Boolean
    ) {
        task {
            taskTypes(
                offset: $offset
                first: $first
                filter: $filter
                entityGuidID: $entityGuidID
                taskStatus: $taskStatus
                isIncludeCrmTasks: $isIncludeCrmTasks
                isMyTasksOnly: $isMyTasksOnly
                isPersonalTask: $isPersonalTask
                isFromDataSource: $isFromDataSource
                groupBy: $groupBy
                groupByValue: $groupByValue
                completedFromDate: $completedFromDate
                completedToDate: $completedToDate
                updatedFromDate: $updatedFromDate
                updatedToDate: $updatedToDate
                priorityList: $priorityList
                statusList: $statusList
                allocatedToList: $allocatedToList
                allocatedByList: $allocatedByList
                teamList: $teamList
                taskTypes: $taskTypes,
              	isIncludeFollowUpTasksOnly: $isIncludeFollowUpTasksOnly
            ) {
                id
                guidId
                description
                hasTasks
            }
        }
    }
`;

export interface UserQueryParams {
    offset?: number;
    first?: number;
    filter?: string;
    isStaff?: boolean;
    isExternal?: boolean;

    includeTask?: boolean;
    entityGuidID?: string | null;
    taskStatus: TaskStatusEnum | null;
    isIncludeCrmTasks: boolean | null;
    isMyTasksOnly: boolean | null;
    groupBy: string | null;
    groupByValue: string | null;
    completedFromDate: Date | null;
    completedToDate: Date | null;
    updatedFromDate: Date | null;
    updatedToDate: Date | null;
    priorityList: string | null;
    statusList: string | null;
    allocatedToList: string | null;
    allocatedByList: string | null;
    created: string | null;
    due: string | null;
    teamList: string | null;
    taskTypes: string | null;
    includeTaskAssignedBy?: boolean;
    isFromDataSource: boolean | null;
    isIncludeFollowUpTasksOnly: boolean | null;
}

export function retrieveUserListData(
    query: UserQueryParams,
    refreshData: boolean,
    onSuccess: (data: UserListData) => void,
    // tslint:disable-next-line:no-any
    onError: (reason: any) => void,
): void {
    var fetchPolicy: FetchPolicy = refreshData === true ? 'network-only' : 'cache-first';
    client
        .query({
            query: UserListQuery,
            variables: {
                filter: query.filter,
                first: query.first,
                offset: query.offset,
                isStaff: query.isStaff,
                isExternal: query.isExternal,

                includeTask: query.includeTask,
                entityGuidID: query.entityGuidID,
                groupBy: query.groupBy,
                groupByValue: query.groupByValue,
                taskStatus: query.taskStatus,
                isIncludeCrmTasks: query.isIncludeCrmTasks,
                isMyTasksOnly: query.isMyTasksOnly,
                completedFromDate: query.completedFromDate,
                completedToDate: query.completedToDate,
                updatedFromDate: query.updatedFromDate,
                updatedToDate: query.updatedToDate,
                priorityList: query.priorityList,
                statusList: query.statusList,
                allocatedToList: query.allocatedToList,
                allocatedByList: query.allocatedByList,
                created: query.created,
                due: query.due,
                teamList: query.teamList,
                taskTypes: query.taskTypes,
                includeTaskAssignedBy: query.includeTaskAssignedBy,
                isFromDataSource: query.isFromDataSource,
                isIncludeFollowUpTasksOnly: query.isIncludeFollowUpTasksOnly
            },
            fetchPolicy: fetchPolicy,
        })
        // tslint:disable-next-line:no-any
        .then((results: { data: any }) => {
            onSuccess(results.data);
        })
        // tslint:disable-next-line:no-any
        .catch((reason: any) => {
            onError(reason);
        });
}

const UserListQuery = gql`
    query userQuery(
        $offset: Int
        $first: Int
        $filter: String
        $isStaff: Boolean
        $isExternal: Boolean
        $includeTask: Boolean
        $entityGuidID: String
        $taskStatus: Int
        $isIncludeCrmTasks: Boolean
        $isMyTasksOnly: Boolean
        $groupBy: String
        $groupByValue: String
        $completedFromDate: DateTime
        $completedToDate: DateTime
        $updatedFromDate: DateTime
        $updatedToDate: DateTime
        $priorityList: String
        $statusList: String
        $allocatedToList: String
        $allocatedByList: String
        $created: String
        $due: String
        $teamList: String
        $taskTypes: String
        $includeTaskAssignedBy: Boolean
        $isFromDataSource: Boolean,
        $isIncludeFollowUpTasksOnly: Boolean
    ) {
        administrator {
            users(
                offset: $offset
                first: $first
                filter: $filter
                isStaff: $isStaff
                isExternal: $isExternal
                includeTask: $includeTask
                entityGuidID: $entityGuidID
                taskStatus: $taskStatus
                isIncludeCrmTasks: $isIncludeCrmTasks
                isMyTasksOnly: $isMyTasksOnly
                groupBy: $groupBy
                groupByValue: $groupByValue
                completedFromDate: $completedFromDate
                completedToDate: $completedToDate
                updatedFromDate: $updatedFromDate
                updatedToDate: $updatedToDate
                priorityList: $priorityList
                statusList: $statusList
                allocatedToList: $allocatedToList
                allocatedByList: $allocatedByList
                created: $created
                due: $due
                teamList: $teamList
                taskTypes: $taskTypes
                includeTaskAssignedBy: $includeTaskAssignedBy
                isFromDataSource: $isFromDataSource
                isIncludeFollowUpTasksOnly: $isIncludeFollowUpTasksOnly
            ) {
                recordCount
                user {
                    userID
                    email
                    friendlyName
                    roleName
                    isStaff
                    staffName
                    isExternal
                    hasTasks
                }
            }
        }
    }
`;

export const CreatePhoneTaskMutation = gql`
    mutation CreatePhoneTask(
        $input: TaskInput
        $clientGuid: String
        $matterGuid: String
        $fromName: String
        $fromNameGuid: String
        $phoneNumber: String
        $organisation: String
        $organisationGuid: String
        $isPhoneCall: Boolean
        $isReturnedCall: Boolean
        $isWillCallAgain: Boolean
        $messageTitle: String
        $isFromNameFromDataSource: Boolean
        $isOrganisationFromDataSource: Boolean
    ) {
        createPhoneTask(
            input: $input
            clientGuid: $clientGuid
            matterGuid: $matterGuid
            fromName: $fromName
            fromNameGuid: $fromNameGuid
            phoneNumber: $phoneNumber
            organisation: $organisation
            organisationGuid: $organisationGuid
            isPhoneCall: $isPhoneCall
            isReturnedCall: $isReturnedCall
            isWillCallAgain: $isWillCallAgain
            messageTitle: $messageTitle,
          	isFromNameFromDataSource: $isFromNameFromDataSource,
          	isOrganisationFromDataSource: $isOrganisationFromDataSource
        ) {
            status
            error
        }
    }
`;

export const UpdatePhoneTaskMutation = gql`
    mutation UpdatePhoneTask(
        $input: TaskInput
        $clientGuid: String
        $matterGuid: String
        $fromName: String
        $fromNameGuid: String
        $phoneNumber: String
        $organisation: String
        $organisationGuid: String
        $isPhoneCall: Boolean
        $isReturnedCall: Boolean
        $isWillCallAgain: Boolean
        $messageTitle: String
        $isFromNameFromDataSource: Boolean
        $isOrganisationFromDataSource: Boolean
    ) {
        updatePhoneTask(
            input: $input
            clientGuid: $clientGuid
            matterGuid: $matterGuid
            fromName: $fromName
            fromNameGuid: $fromNameGuid
            phoneNumber: $phoneNumber
            organisation: $organisation
            organisationGuid: $organisationGuid
            isPhoneCall: $isPhoneCall
            isReturnedCall: $isReturnedCall
            isWillCallAgain: $isWillCallAgain
            messageTitle: $messageTitle
          	isFromNameFromDataSource: $isFromNameFromDataSource
          	isOrganisationFromDataSource: $isOrganisationFromDataSource
        ) {
            status
            error
        }
    }
`;

export interface TimesheetScopeQueryData {
    timesheetScope: TimesheetScope;
    loading?: boolean;
    networkStatus?: number;
}

export interface TimesheetScope {
    id: number;
    showFeeEarnerFilter: boolean;
    viewAll: boolean;
    viewMyDepartment: boolean;
    viewMyOffice: boolean;
    viewMyTeam: boolean;
    updateTimesheet: boolean;
}

export function retrieveTimesheetScopeQueryData(
    refreshData: boolean,
    onSuccess: (data: TimesheetScopeQueryData) => void,
    // tslint:disable-next-line:no-any
    onError: (reason: any) => void,
): void {
    var fetchPolicy: FetchPolicy = refreshData === true ? 'network-only' : 'cache-first';
    client
        .query({
            query: timesheetScopeQuery,
            variables: {},
            fetchPolicy: fetchPolicy,
        })
        // tslint:disable-next-line:no-any
        .then((results: { data: any }) => {
            onSuccess(results.data);
        })
        // tslint:disable-next-line:no-any
        .catch((reason: any) => {
            onError(reason);
        });
}

const timesheetScopeQuery = gql`
    query timesheetScope {
        timesheetScope {
            id
            showFeeEarnerFilter
            viewAll
            viewMyDepartment
            viewMyOffice
            viewMyTeam
            updateTimesheet
        }
    }
`;

export interface ExtendPhoneTaskDetailsParams {
    nameGuid: string;
    isFromDataSource: boolean;
}

export interface ExtendPhoneTaskDetailsData {
    party: Party;
}

export interface Party {
    phoneTaskDetails: PhoneTaskDetails;
}

export interface PhoneTaskDetails {
    matterDetails: MatterDetails;
    contactDetails: ContactDetails;
    messageDetails: MessageDetails;
}

export interface ContactDetails {
    tags: string;
    address: string;
    mobile: null;
    email: string;
    phone: string;
}

export interface MatterDetails {
    totalMatters: number;
    lastUpdate: Date;
    closedMatters: number;
    matterSummary: MatterSummary[];
}

export interface MatterSummary {
    fileNumber: string;
    matterType: string;
    matterTitle: string;
    personActing: string;
}

export interface MessageDetails {
    messagesNotResponded: number;
    messagesReceived: number;
}

export const EXTEND_PHONE_TASK_DETAILS = gql`
    query extendPhoneTaskDetails($nameGuid: String, $isFromDataSource: Boolean) {
        party {
            phoneTaskDetails(nameGuid: $nameGuid, isFromDataSource: $isFromDataSource) {
                matterDetails {
                    totalMatters
                    lastUpdate
                    closedMatters
                    matterSummary {
                        fileNumber
                        matterType
                        matterTitle
                        personActing
                    }
                }
                contactDetails {
                    tags
                    address
                    mobile
                    email
                    phone
                }
                messageDetails {
                    messagesNotResponded
                    messagesReceived
                }
            }
        }
    }
`;

export interface TaskAndPhoneMessageData {
    settings: Settings;
}

export interface Settings {
    systems: Systems;
}

export interface Systems {
    taskDefaults: Defaults;
    phoneMessageDefaults: Defaults;
}

export interface Defaults {
    config: TaskAndPhoneMessageConfig;
}
export interface TaskAndPhoneMessageConfig {
    isSendReminderOnWeekend: boolean;
    isSendReminderOnWeekendForOverDueReminders: boolean;
    reminder: string;
    overdueReminder: string;
}

export function retrieveTaskAndPhoneMessageDefaultConfigData(
    refreshData: boolean,
    onSuccess: (data: TaskAndPhoneMessageData) => void,
    // tslint:disable-next-line:no-any
    onError: (reason: any) => void,
): void {
    var fetchPolicy: FetchPolicy = refreshData === true ? 'network-only' : 'cache-first';

    client
        .query({
            query: TASK_AND_PHONE_MESSAGE_DEFAULT_QUERY,
            variables: {},
            fetchPolicy: fetchPolicy,
        })
        // tslint:disable-next-line:no-any
        .then((results: { data: any }) => {
            onSuccess(results.data);
        })
        // tslint:disable-next-line:no-any
        .catch((reason: any) => {
            onError(reason);
        });
}

export const TASK_AND_PHONE_MESSAGE_DEFAULT_QUERY = gql`
    query TaskDefaultsQuery {
        settings {
            systems {
                taskDefaults {
                    config {
                        isSendReminderOnWeekend
                        isSendReminderOnWeekendForOverDueReminders
                        reminder
                        overdueReminder
                    }
                }
                phoneMessageDefaults {
                    config {
                        isSendReminderOnWeekend
                        isSendReminderOnWeekendForOverDueReminders
                        reminder
                        overdueReminder
                    }
                }
            }
        }
    }
`;
