/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { makeStyles, Theme, createStyles, Grid, IconButton, FormControl, InputLabel, Select, MenuItem, Badge } from '@material-ui/core';
import { MatterTerminology } from '../MatterTerminology';
import { RvSearch } from '../components/Search';
import { mainTheme } from '../Theme';
// import { RvCheckbox, CheckboxState } from '../components/Checkbox';
// import { RvLabel } from '../components/Label/Label';
import { RvTooltipFabric } from '../components/Tooltip';
import { RvButtonFabric, RvButtonType } from '../components/Button';
import { UserFeature, UserFeatureEnum } from '../types/UserFeature';
import FilterListIcon from '@material-ui/icons/FilterList';
import { TasksSummaryAdvanceFilter } from './TasksSummaryAdvanceFilter';
import { CheckboxState } from '../components/Checkbox';
import { SummaryViewConfigObj } from '../viewConfig/SummaryViewConfigurations';
import { TaskStatusEnum } from './TasksSummaryRepository';
import { Checkbox } from 'semantic-ui-react';
import _ from 'lodash';
import { RvLabel } from '../components/Label/Label';
import { StatusCard } from './cards/StatusCard';
import { TasksMenu } from '../components/AppHeader/AppMenu';
import { TaskSummaryAdvancedFilter } from './TasksSummaryPage';
import { IAutoCompleteItem } from '../typings/autoComplete';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
            flex: 1,
            display: 'flex',
            alignItems: 'center',
            // padding: '30px 0',
        },
        searchContentWrapper: {
            display: 'flex',
            margin: '0 -10px',
            alignItems: 'center',
            '& > div': {
                padding: '0 10px',
            }
        },
        searchWrapper: {
            padding: '0 10px',
            '& .input': {
                height: '48px',
                width: '100%', // '336px',
                border: '1px solid ' + mainTheme.NeutralColors.BackgroundGrey1,
                borderRadius: '4px',
                boxShadow:
                    '0 0 4px 0 ' + mainTheme.NeutralColors.BackgroundGrey2,
                margin: 0,
            },
        },
        checkboxWrapper: {
            display: 'flex',
            flexFlow: 'row wrap',
            justifyContent: 'space-evenly',
            padding: '0 10px',
            gap: '20px',
        },
        checkboxContainer: {
            display: 'flex',
            flexFlow: 'column wrap',
            gap: '5px',
            // flex: 1
        },
        showingWrapper: {
            display: 'flex',
            alignItems: 'center',
            height: '100%',
            [theme.breakpoints.down('sm')]: {
                justifyContent: 'center',
            },
            [theme.breakpoints.up('md')]: {
                justifyContent: 'flex-start',
            },
        },
        menuWrapper: {
            height: '100%',
            alignItems: 'center',
            display: 'flex',
            margin: '0 -10px',
            [theme.breakpoints.down('md')]: {
                justifyContent: 'center',
            },
            [theme.breakpoints.up('lg')]: {
                justifyContent: 'flex-end',
            },
            '& > div': {
                padding: '0 10px',
            },
            '& .crud-container': {
                display: 'flex',
                margin: '0 -10px',
                '& > div': {
                    padding: '0 10px',
                },
                [`& .${`button-fabric`}`]: {
                    [`& .${`ms-Button-icon`}`]: {
                        color: '#70D389',
                        fontSize: '20px',
                    },
                    [`& .${`is-disabled`}`]: {
                        [`& .${`ms-Button-icon`}`]: {
                            color: '#ECF9EF',
                        },
                    },
                },
            },
            [`& .${`ms-Button`}`]: {
                '&:hover': {
                    backgroundColor: 'transparent',
                },
            },
            [`& .${`is-disabled`}`]: {
                backgroundColor: 'transparent',
                [`& g`]: {
                    stroke: 'none',
                    [`& path`]: {
                        stroke: 'none',
                    },
                },
            },
            [`& .${`custom-split-2`}`]: {
                '& .share i': {
                    color: '#70d389 !important',
                    fontSize: '29px',
                    display: 'none',
                },
                '& .share i:first-child': {
                    display: 'block',
                },
                [`& .${`ms-Button`}`]: {
                    minWidth: 0,
                    padding: 0,
                    backgroundColor: 'transparent',
                    border: 0,
                    [`& .${`ms-Button-flexContainer`}`]: {
                        [`& ${`i`}`]: {
                            color: 'green',
                        },
                    },
                },
            },
            [`& .${`custom-split`}`]: {
                [`& .${`ms-Button`}`]: {
                    minWidth: 0,
                    padding: 0,
                    backgroundColor: 'transparent',
                    border: 0,
                },
                [`& .${`ms-Button-menuIcon`}`]: {
                    display: 'none',
                },
            },
        },
        notes: {
            backgroundColor: 'rgba(243, 243, 158, 0.29)',
            padding: '10px',
        },
        download: {
            cursor: 'pointer'
        },
        viewType: {
            display: 'flex',
            border: '1px solid #D9DFF7',
            borderRadius: '4px',
            boxShadow: '0 0 4px 0 rgba(81,115,255,0.33)',
            '& .is-checked': {
                backgroundColor: '#F5F6FF',
            }
        },
        advancedFilter: {
            display: 'flex',
            alignItems: 'center',
            margin: '0 -10px',
            '& > div': {
                padding: '0 10px',
            }
        },
        advancedFilterButton: {
            color: '#70D389',
        },
        advanceFilterContainer: {
            backgroundColor: 'rgb(248, 248, 248)',
            padding: '10px',
            borderRadius: '5px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
        },
        formControl: {
            minWidth: 64
        },
    })
);

export enum ViewTypeEnum {
    LIST = 0,
    BOARD,
    COMPRESS_BOARD
}

export enum GroupByType {
    status = 0,
    allocatedBy,
    allocatedTo,
    priority,
    taskType,
    category,
    dueDates,
    team
}

interface ControlPanelProps {
    isLoading: boolean;
    loadedCount: number;
    totalCount: number;

    onSearch?: (searchString: string) => void;
    // onInclude?: (taskStatus: TaskStatusEnum | null) => void;
    // tslint:disable-next-line: max-line-length
    onSearchFilterChanged?: (searchString: string, taskStatus: TaskStatusEnum, groupBy: GroupByType, 
                             isMyTasksOnly: boolean, isIncludeCrmTasks: boolean, isPersonalTask: boolean, isFromDataSource: boolean) => void;

    onToggleConfigs?: (show: boolean) => void;

    isRowGroupPanelShow?: (show: boolean) => void;
    isShowRowGroupPanel: boolean;
    isToolPanelShow?: (show: boolean) => void;
    onRefreshData?: () => void;
    onExport?: () => void;
    onPrint?: () => void;
    onAdd?: (type: 'phone' | 'adhoc') => void;
    onEdit?: () => void;
    onDelete?: () => void;
    onAddMatterFinancial?: () => void;

    // tslint:disable-next-line: no-any
    views?: Array<SummaryViewConfigObj>;
    isDisableSave?: boolean;
    onViewSelected?: (configId: number) => void;
    onSaveCurrentView?: () => void;

    isDeleteDisabled?: boolean;
    isEditDisabled?: boolean;
    isResetDisabled?: boolean;

    isInMemory: boolean /* If the grid data is in memory */;
    onDownloadChanged?: (
        isDownload: boolean
    ) => void /* Trigger to start task download */;
    onViewType?: (viewType: ViewTypeEnum) => void;
    // onBoardroupBy?: (groupBy: GroupByType) => void;
    advancedFilter: TaskSummaryAdvancedFilter;
    onSearchAdvanced?: (
        due: IAutoCompleteItem | undefined,
        created: IAutoCompleteItem | undefined,
        priority: IAutoCompleteItem[] | undefined,
        status: IAutoCompleteItem[] | undefined,
        allocatedTo: IAutoCompleteItem[] | undefined,
        allocatedBy: IAutoCompleteItem[] | undefined,
        updatedFrom: Date | undefined,
        updatedTo: Date | undefined,
        completedFrom: Date | undefined,
        completedTo: Date | undefined,
        team: IAutoCompleteItem[] | undefined,
        taskTypes: IAutoCompleteItem[] | undefined,
        matterList: IAutoCompleteItem[] | undefined,
        clientList: IAutoCompleteItem[] | undefined,
    ) => void;
    isRefreshData: boolean;
    isFormOpen: boolean;
    viewType?: ViewTypeEnum;
    isInclude: TaskStatusEnum | null;
    isIncludeCrmTasks: boolean;
    isMyTasksOnly: boolean;
    groupBy: GroupByType;
    isPersonalTask: boolean;
    isFromDataSource: boolean;
    showCheckbox?: {
        isIncludeOpen: boolean;
        isIncludeClosed: boolean;
        isMyTasksOnly: boolean;
        isIncludeCrmTasks: boolean;
        isPersonalTask: boolean;
        isFromDataSource: boolean;
    };
    hideControls?: {
        adhocTask: boolean;
        phoneTask: boolean;
        matterFinancialTask: boolean;
        edit: boolean;
        delete: boolean;
        savedView: boolean;
    };
    isIncludeFollowUpTasksOnly: boolean | null;
    sourceSystem: string | null;
}

interface ControlPanelStates {
    isInMemory?: boolean;
    isDownloading?: boolean;
    isPaused?: boolean;
    isLoading?: boolean;
    isRowGroupPanelShow?: boolean;
    isViewShow?: boolean;
    isToolPanelShow?: boolean;
    isDeleteDisabled?: boolean;
    isEditDisabled?: boolean;
    viewType?: ViewTypeEnum;
    showAdvancedFilter: boolean;
    groupBy: GroupByType;

    search: string;
    isIncludeOpen: boolean;
    isIncludeClosed: boolean;
    isMyTasksOnly: boolean;
    isIncludeCrmTasks: boolean;
    hasFilter: boolean;
    isPersonalTask: boolean;
    isFromDataSource: boolean;
}

// tslint:disable-next-line: no-anyW
export const TasksSummaryControlPanel: React.FunctionComponent<ControlPanelProps> = (props) => {
    const classes = useStyles();

    const delayedSearch = _.debounce(filter => onSearch(filter), 500);

    const [value, setValue] = useState<ControlPanelStates>({
        isInMemory: false,
        isDownloading: false,
        isPaused: false,        
        isLoading: false,
        isToolPanelShow: false,
        isDeleteDisabled: props.isDeleteDisabled,
        isEditDisabled: props.isEditDisabled,
        isRowGroupPanelShow: props.isShowRowGroupPanel,
        isViewShow: false,
        viewType: props.viewType,
        showAdvancedFilter: false,
        groupBy: props.groupBy,
        search: '',
        isIncludeOpen: true,
        isIncludeClosed: false,
        isMyTasksOnly: props.isMyTasksOnly,
        isIncludeCrmTasks: props.isIncludeCrmTasks,
        hasFilter: false,
        isPersonalTask: props.isPersonalTask,
        isFromDataSource: props.isFromDataSource
    });

    const [advancedFilter, setAdvancedFilter] = React.useState<TaskSummaryAdvancedFilter>({
        ...props.advancedFilter,
        // due: undefined,
        // created: undefined,
        // priority: undefined,
        // status: undefined,
        // allocatedTo: undefined,
        // allocatedBy: undefined,
        // updatedFrom: undefined,
        // updatedTo: undefined,
        // completedFrom: undefined,
        // completedTo: undefined,
        // team: undefined,
        // taskTypes: undefined,
        // clientList: undefined,
        // matterList: undefined
    });

    // tslint:disable-next-line: no-any
    let downloadTaskLink: any;
    var viewCount = 0;
    var viewMenuItems = [];

    // tslint:disable-next-line: no-console
    useEffect(() => {
        setValue({
                ...value,
                isEditDisabled: props.isEditDisabled,
                isRowGroupPanelShow: props.isShowRowGroupPanel,
                isInMemory: props.isInMemory,
                isLoading: props.isLoading
        });
    // tslint:disable-next-line: align
    },  [
            props.isEditDisabled, 
            props.isShowRowGroupPanel, 
            props.isLoading,
            props.isInMemory, 
            downloadTaskLink,
        ]
    );

    useEffect(() => {

        let taskStatus: TaskStatusEnum = TaskStatusEnum.Open;

        if (value.isIncludeOpen && value.isIncludeClosed) {
            taskStatus = TaskStatusEnum.All;
        } else if (value.isIncludeOpen && !value.isIncludeClosed) {
            taskStatus = TaskStatusEnum.Open;
        } else if (!value.isIncludeOpen && value.isIncludeClosed) {
            taskStatus = TaskStatusEnum.Closed;
        } else {
            taskStatus = TaskStatusEnum.None;
        }

        if (props.onSearchFilterChanged) {
            props.onSearchFilterChanged(value.search, taskStatus, value.groupBy, value.isMyTasksOnly, 
                                        value.isIncludeCrmTasks, value.isPersonalTask, value.isFromDataSource);
        }
    // tslint:disable-next-line: align
    }, [value.isIncludeOpen, value.isIncludeClosed, value.groupBy, value.isMyTasksOnly, value.isIncludeCrmTasks, value.isPersonalTask, value.isFromDataSource]);

    const onSearchAdvancedChanged = (
        due: IAutoCompleteItem | undefined,
        created: IAutoCompleteItem | undefined,
        priority: IAutoCompleteItem[] | undefined,
        status: IAutoCompleteItem[] | undefined,
        allocatedTo: IAutoCompleteItem[] | undefined,
        allocatedBy: IAutoCompleteItem[] | undefined,
        updatedFrom: Date | undefined,
        updatedTo: Date | undefined,
        completedFrom: Date | undefined,
        completedTo: Date | undefined,
        team: IAutoCompleteItem[] | undefined,
        taskTypes: IAutoCompleteItem[] | undefined,
        matterList: IAutoCompleteItem[] | undefined,
        clientList: IAutoCompleteItem[] | undefined,
    ) => {
        setAdvancedFilter((prevAdvancedFilter: TaskSummaryAdvancedFilter) => {
            return {
                ...prevAdvancedFilter,
                due: due,
                completedFrom: completedFrom,
                completedTo: completedTo,
                updatedFrom: updatedFrom,
                updatedTo: updatedTo,
                created: created,
                priority: priority,
                status: status,
                allocatedTo: allocatedTo,
                allocatedBy: allocatedBy,
                team: team,
                taskTypes: taskTypes
            };
        });

        if (props.onSearchAdvanced) {
            props.onSearchAdvanced(
                due,
                created,
                priority,
                status,
                allocatedTo,
                allocatedBy,
                updatedFrom,
                updatedTo,
                completedFrom,
                completedTo,
                team,
                taskTypes,
                matterList,
                clientList
            );
        }
    };

    const onCloseAdvancedFilter = () => {
        setValue({
            ...value,
            showAdvancedFilter: !value.showAdvancedFilter
        });
    };

    const onSearch = (filter: string) => {
        setValue((prevValue) => {
            return {
                ...prevValue,
                search: filter
            };
        });

        if (props.onSearch) {
            props.onSearch(filter);
        }
    };

    const onMyPersonalTasks = (event: React.FormEvent<HTMLInputElement>, state: CheckboxState) => {

        setValue((prevValue) => {
            return {
                ...prevValue,
                isPersonalTask: state.checked,
                isMyTasksOnly: false
            };
        });

    };

    const onIncludeOpen = (event: React.FormEvent<HTMLInputElement>, state: CheckboxState) => {
        setValue((prevValue) => {
            return {
                ...prevValue,
                isIncludeOpen: state.checked
            };
        });
    };

    const onIncludeClosed = (event: React.FormEvent<HTMLInputElement>, state: CheckboxState) => {
        setValue((prevValue) => {
            return {
                ...prevValue,
                isIncludeClosed: state.checked
            };
        });
    };

    const onIncludeCrmTasks = (event: React.FormEvent<HTMLInputElement>, state: CheckboxState) => {
        setValue((prevValue) => {
            return {
                ...prevValue,
                isIncludeCrmTasks: state.checked
            };
        });
    };

    const onIncludeTasksFromDataSource = (event: React.FormEvent<HTMLInputElement>, state: CheckboxState) => {
        setValue((prevValue) => {
            return {
                ...prevValue,
                isFromDataSource: state.checked
            };
        });
    };

    const onIsMyTasksOnly = (event: React.FormEvent<HTMLInputElement>, state: CheckboxState) => {
        setValue((prevValue) => {
            return {
                ...prevValue,
                isMyTasksOnly: state.checked,
                isPersonalTask: false
            };
        });
    };

    const onRefreshData = () => {
        setValue({
            ...value,
            isDownloading: false,
            isPaused: false,
        });

        if (props.onRefreshData) {
            props.onRefreshData();
        }
    };

    const onToggleRowGrouping = () => {
        if (props.isRowGroupPanelShow) {
            props.isRowGroupPanelShow(!value.isRowGroupPanelShow);
        }

        setValue({
            ...value,
            isRowGroupPanelShow: !value.isRowGroupPanelShow,
        });
    };

    const onShowToolPanel = () => {
        if (props.isToolPanelShow) {
            props.isToolPanelShow(!value.isToolPanelShow);
        }

        setValue({ 
            ...value,
            isToolPanelShow: !value.isToolPanelShow 
        });
    };

    const onExport = () => {
        if (props.onExport) {
            props.onExport();
        }
    };

    const onPrint = () => {
        if (props.onPrint) {
            props.onPrint();
        }
    };

    const onAdd = (type: 'phone' | 'adhoc') => () => {
        if (props.onAdd) {
            props.onAdd(type);
        }
    };

    const onEdit = () => {
        if (props.onEdit) {
            props.onEdit();
        }
    };

    const onDelete = () => {
        if (props.onDelete) {
            props.onDelete();
        }
    };

    const onToggleConfigs = () => {
        if (props.onToggleConfigs) {
            props.onToggleConfigs(!value.isViewShow);
        }

        setValue({
            ...value,
            isViewShow: !value.isViewShow,
        });
    };

    const onViewSelected = (viewKey: string, configId: number) => {
        if (props.onViewSelected) {
            props.onViewSelected(configId);
        }
    };

    const onSaveView = () => {
        if (props.onSaveCurrentView) {
            props.onSaveCurrentView();
        }
    };

    const handleTaskDownload = () => {
        if (value.isLoading) {
            return;
        }

        if (value.isDownloading === false) {
            setValue({
                ...value,
                isPaused: false,
                isDownloading: true,
            });

            if (props.onDownloadChanged) {
                props.onDownloadChanged(true);
            }
        } else {
            setValue({
                ...value,
                isPaused: true,
                isDownloading: false,
            });

            if (props.onDownloadChanged) {
                props.onDownloadChanged(false);
            }
        }
    };

    const onClickViewType = (viewType: ViewTypeEnum) => {
        setValue({
            ...value,
            viewType: viewType
        });
        if (props.onViewType) {
            props.onViewType(viewType);
        }
    };

    const handleGroupBy = (event: React.ChangeEvent<{ name?: string; value: GroupByType }>) => {

        setValue((prevValue) => {
            return {
                ...prevValue,
                groupBy: event.target.value,
            };
        });

    };

    const toggleTaskAndPhone = () => {
        if (
            // UserFeature.HasAccess(UserFeatureEnum.hasMatterTasks) ||
            // UserFeature.HasAccess(UserFeatureEnum.hasArchiveMatter) ||
            // UserFeature.HasAccess(UserFeatureEnum.hasBillMatter) ||
            // UserFeature.HasAccess(UserFeatureEnum.hasCloseMatter) ||
            // UserFeature.HasAccess(UserFeatureEnum.hasDeleteTask) ||
            // UserFeature.HasAccess(UserFeatureEnum.hasMatterEmailPersonActing) ||
            // UserFeature.HasAccess(UserFeatureEnum.hasMatterEmailPersonResponsible) ||
            // UserFeature.HasAccess(UserFeatureEnum.hasFollowUpMatterTask) ||
            // UserFeature.HasAccess(UserFeatureEnum.hasWriteOffDebtors) ||
            // UserFeature.HasAccess(UserFeatureEnum.hasWriteOffWIP) ||
            // UserFeature.HasAccess(UserFeatureEnum.hasTransferMatterTrust) ||
            // UserFeature.HasAccess(UserFeatureEnum.hasTaskDetail) ||
            UserFeature.HasAccess(UserFeatureEnum.hasAdHocTask)
        ) {
            return TasksMenu.TASKS;
        } else if (
            UserFeature.HasAccess(UserFeatureEnum.hasPhoneTask)
        ) {
            return TasksMenu.PHONE_CALLS;
        } else {
            return TasksMenu.HIDDEN;
        }
    };

    const onHasFilter = (_value: boolean) => {
        setValue((prevValue) => {
            return {
                ...prevValue,
                hasFilter: _value
            };
        });
    };

    const isPersonalTaskLabel = (hasAccessToAdHocTask: boolean) => {
        if (props.isIncludeFollowUpTasksOnly) {
            return 'My Personal Tasks';
        } else {
            return hasAccessToAdHocTask && props.isIncludeFollowUpTasksOnly ? 'My Personal Tasks' : 'My Personal Calls';
        }        
    };

    if (props.views) {
        viewCount = props.views.length;
    }

    if (props.views) {
        props.views.forEach((config: SummaryViewConfigObj) => {
            viewMenuItems.push({
                key: config.configId,
                name: config.name,
                onClick: () => {
                    onViewSelected(config.name, config.configId);
                },
            });
        });
    }

    viewMenuItems.push({
        key: 'saveLayout',
        name: 'Save Current Layout',
        onClick: () => {
            onSaveView();
        },
        disabled: viewCount === 0 ? true : props.isDisableSave,
    });

    if (value.isInMemory === false && value.isDownloading === false && value.isPaused === false) {
        downloadTaskLink = (
            // eslint-disable-next-line jsx-a11y/anchor-is-valid
            <a onClick={handleTaskDownload} className={classes.download}>Click Here</a>
        );
    } else if ( value.isInMemory === false && value.isDownloading && value.isPaused === false) {
        downloadTaskLink = (
            // eslint-disable-next-line jsx-a11y/anchor-is-valid
            <a onClick={handleTaskDownload} className={classes.download}>Pause Download</a>
        );
    } else if (value.isInMemory === false && value.isDownloading === false && value.isPaused === true) {
        downloadTaskLink = (
            // eslint-disable-next-line jsx-a11y/anchor-is-valid
            <a onClick={handleTaskDownload} className={classes.download}>Resume Download</a>
        );
    } else if (value.isInMemory) {
        // Setting it back to be used again. When Include complete is clicked
        downloadTaskLink = (
            // eslint-disable-next-line jsx-a11y/anchor-is-valid
            <a onClick={handleTaskDownload} className={classes.download}>Click Here</a>
        );
    } 

    return (
        <div className={classes.root}>
            <Grid container={true} spacing={3}>
                <Grid item={true} xs={12} md={12} lg={5}>
                    <Grid container={true} spacing={3}>
                        <Grid item={true} xs={12} md={12} lg={12}>
                            <div className={classes.searchContentWrapper}>
                                <div className={classes.searchWrapper}>
                                    <RvSearch
                                        hint={MatterTerminology.SearchTasks}
                                        search={value.search ? value.search : ''}
                                        searchAction={delayedSearch}
                                        showClear={true}
                                        // disabled={value.isLoading}
                                    />
                                </div>
                                <div className={classes.checkboxWrapper}>
                                    <div className={classes.checkboxContainer}>
                                        {props.showCheckbox?.isPersonalTask ? (
                                            <Checkbox
                                                label={isPersonalTaskLabel(UserFeature.HasAccess(UserFeatureEnum.hasAdHocTask))}
                                                key={'isPersonalTask'}
                                                checked={value.isPersonalTask}
                                                disabled={value.isLoading}
                                                onChange={onMyPersonalTasks}
                                            />
                                        ) : undefined}
                                        
                                        {props.showCheckbox?.isMyTasksOnly ? (
                                            <Checkbox
                                                // tslint:disable-next-line: max-line-length
                                                label={toggleTaskAndPhone() === TasksMenu.TASKS ? 'My Tasks Only' : toggleTaskAndPhone() === TasksMenu.PHONE_CALLS ? ' My Phone Calls Only' : 'My Tasks Only'}
                                                key={'isMyTasksOnly'}
                                                checked={value.isMyTasksOnly}
                                                disabled={value.isLoading}
                                                onChange={onIsMyTasksOnly}
                                            />
                                        ) : undefined}

                                        {props.showCheckbox?.isIncludeOpen ? (
                                            <Checkbox
                                                label={'Include Open'}
                                                key={'isIncludeOpen'}
                                                checked={value.isIncludeOpen}
                                                disabled={value.isLoading}
                                                onChange={onIncludeOpen}
                                            />
                                        ) : undefined}

                                        {props.showCheckbox?.isIncludeClosed ? (
                                            <Checkbox
                                                label={'Include Completed'}
                                                key={'isIncludeClosed'}
                                                checked={value.isIncludeClosed}
                                                disabled={value.isLoading}
                                                onChange={onIncludeClosed}
                                            />
                                        ) : undefined}
                                    </div>
                                    <div className={classes.checkboxContainer}>
                                        {!props.isIncludeFollowUpTasksOnly && props.showCheckbox?.isIncludeCrmTasks && UserFeature.HasAccess(UserFeatureEnum.hasLeadsDetail)
                                            ?
                                            <Checkbox
                                                label={'Include CRM Tasks'}
                                                key={'isIncludeCrmTasks'}
                                                checked={value.isIncludeCrmTasks}
                                                disabled={value.isLoading}
                                                onChange={onIncludeCrmTasks}
                                            />
                                            : null
                                        }
                                        {props.showCheckbox?.isFromDataSource && UserFeature.HasAccess(UserFeatureEnum.hasLeapTask) && props.sourceSystem === 'Leap' ? (
                                            <Checkbox
                                                label={'Include LEAP Tasks'}
                                                key={'isFromDataSource'}
                                                checked={value.isFromDataSource}
                                                disabled={value.isLoading}
                                                onChange={onIncludeTasksFromDataSource}
                                            />
                                        ) : undefined}
                                    </div>
                                </div>
                                <div className={classes.advancedFilter}>
                                    <div>
                                        <IconButton
                                            aria-label="Advanced Filter"
                                            className={classes.advancedFilterButton}
                                            onClick={onCloseAdvancedFilter}
                                        >
                                            <Badge 
                                                color="secondary" 
                                                variant="dot"
                                                invisible={!value.hasFilter}
                                            >
                                                <FilterListIcon 
                                                    fontSize="default"
                                                    style={{
                                                        color: value.showAdvancedFilter ? '#5173FF' : '#70D389'
                                                    }}
                                                />
                                            </Badge>
                                        </IconButton>
                                    </div>
                                    {
                                        value.viewType !== ViewTypeEnum.LIST && (
                                            <div>
                                                <FormControl className={classes.formControl}>
                                                    <InputLabel htmlFor="groupBy">Group By</InputLabel>
                                                    <Select
                                                        value={value.groupBy}
                                                        onChange={handleGroupBy}
                                                    >                                                        
                                                        <MenuItem value={GroupByType.allocatedBy}>Assigned By</MenuItem>
                                                        <MenuItem value={GroupByType.allocatedTo}>Assigned To</MenuItem>
                                                        <MenuItem value={GroupByType.category}>Category</MenuItem>
                                                        <MenuItem value={GroupByType.dueDates}>Due Date</MenuItem>
                                                        <MenuItem value={GroupByType.priority}>Priority</MenuItem>
                                                        <MenuItem value={GroupByType.status}>Status</MenuItem>
                                                        <MenuItem value={GroupByType.team}>Team</MenuItem>
                                                        <MenuItem value={GroupByType.taskType}>Type</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </div>
                                        )
                                    }
                                </div>
                                {value.viewType === ViewTypeEnum.LIST ? 
                                    (
                                        props.loadedCount === 0 
                                        ? 
                                            (
                                                <div className={classes.showingWrapper}>
                                                    <RvLabel
                                                        // tslint:disable-next-line:max-line-length
                                                        label={`Showing 0 ${MatterTerminology.Tasks}`}
                                                    />
                                                </div>
                                            )
                                        :
                                            (
                                                <div className={classes.showingWrapper}>
                                                    <RvLabel
                                                        // tslint:disable-next-line:max-line-length
                                                        label={`Showing ${props.loadedCount} of ${props.totalCount} ${MatterTerminology.TasksFound}`}
                                                    />
                                                </div>
                                            )
                                    )
                                    :
                                    null
                                }

                                {/* {value.isListView && (
                                    <div className={classes.showingWrapper}>
                                        <RvLabel
                                            // tslint:disable-next-line:max-line-length
                                            label={`Showing ${
                                                props.loadedTaskCount
                                            } of ${props.totalTaskCount} Tasks found`}
                                        />
                                    </div>
                                )} */}
                                
                            </div>
                        </Grid>
                        {/* {
                            value.isListView && (
                                <Grid item={true} xs={12} md={6}>
                                    <div className={classes.showingWrapper}>
                                        <RvLabel
                                            // tslint:disable-next-line:max-line-length
                                            label={`Showing ${
                                                props.loadedTaskCount
                                            } of ${props.totalTaskCount} Tasks`}
                                        />
                                    </div>
                                </Grid>
                            )
                        } */}
                        
                    </Grid>
                </Grid>
                <Grid item={true} xs={12} md={12} lg={3}>
                    <StatusCard
                        isRefreshData={props.isRefreshData}
                        isFormOpen={props.isFormOpen}
                        advancedFilter={advancedFilter}
                        isInclude={props.isInclude}
                        isIncludeCrmTasks={props.isIncludeCrmTasks}
                        isMyTasksOnly={props.isMyTasksOnly}
                        isPersonalTask={props.isPersonalTask}
                        isFromDataSource={props.isFromDataSource}
                        isIncludeFollowUpTasksOnly={props.isIncludeFollowUpTasksOnly}
                    />
                </Grid>
                <Grid item={true} xs={12} md={12} lg={4}>
                    <div className={classes.menuWrapper}>
                        <div className="crud-container">
                            {/* {UserFeature.HasAccess(UserFeatureEnum.hasCreateTask)
                                ?
                                    <RvTooltipFabric hint="Add">
                                        <RvButtonFabric
                                            rvKey="add"
                                            rvbuttonType={RvButtonType.icon}
                                            rvClassName="button-fabric "
                                            rvIcon={'Add'}
                                            disabled={value.isLoading}
                                            onClick={onAdd}
                                        />
                                    </RvTooltipFabric>
                                : null
                            } */}
                            {UserFeature.HasAccess(UserFeatureEnum.hasCreateTask) && UserFeature.HasAccess(UserFeatureEnum.hasAdHocTask) && !props.hideControls?.adhocTask
                                ?
                                    <RvTooltipFabric hint="Add AdHoc Task">
                                        <RvButtonFabric
                                            rvKey="AddToShoppingList"
                                            rvbuttonType={RvButtonType.icon}
                                            rvClassName="button-fabric "
                                            rvIcon={'AddToShoppingList'}
                                            disabled={value.isLoading}
                                            onClick={onAdd('adhoc')}
                                        />
                                    </RvTooltipFabric>
                                : null
                            }
                            {UserFeature.HasAccess(UserFeatureEnum.hasPhoneTask) && !props.hideControls?.phoneTask
                                ?
                                <RvTooltipFabric hint="Add Phone Message">
                                    <RvButtonFabric
                                        rvKey="AddPhone"
                                        rvbuttonType={RvButtonType.icon}
                                        rvClassName="button-fabric "
                                        rvIcon={'AddPhone'}
                                        disabled={value.isLoading}
                                        onClick={onAdd('phone')}
                                    />
                                </RvTooltipFabric>
                                : null
                            }

                            {(UserFeature.HasAccess(UserFeatureEnum.hasArchiveMatter)
                            || UserFeature.HasAccess(UserFeatureEnum.hasBillMatter)
                            || UserFeature.HasAccess(UserFeatureEnum.hasCloseMatter)
                            || UserFeature.HasAccess(UserFeatureEnum.hasDeleteMatterTask)
                            || UserFeature.HasAccess(UserFeatureEnum.hasWriteOffDebtors)
                            || UserFeature.HasAccess(UserFeatureEnum.hasWriteOffWIP)
                            || UserFeature.HasAccess(UserFeatureEnum.hasTransferMatterTrust)) && !props.hideControls?.matterFinancialTask 
                            ? (
                                <RvTooltipFabric hint="Matter financial task">
                                    <RvButtonFabric
                                        rvKey="matterFinancialTask"
                                        rvbuttonType={RvButtonType.icon}
                                        rvClassName="button-fabric "
                                        rvIcon={'Calculator'}
                                        disabled={value.isLoading}
                                        onClick={props.onAddMatterFinancial}
                                    />
                                </RvTooltipFabric>
                            ) : null}

                            {UserFeature.HasAccess(UserFeatureEnum.hasModifyTask) && value.viewType === ViewTypeEnum.LIST && !props.hideControls?.edit
                                ?
                                    <RvTooltipFabric hint="Edit">
                                        <RvButtonFabric
                                            rvKey="edit"
                                            rvbuttonType={RvButtonType.icon}
                                            rvClassName="button-fabric"
                                            rvIcon={'Edit'}
                                            disabled={value.isLoading || value.isEditDisabled}
                                            onClick={onEdit}
                                        />
                                    </RvTooltipFabric>
                                : null                                
                            }
                            {UserFeature.HasAccess(UserFeatureEnum.hasDeleteTask) && value.viewType === ViewTypeEnum.LIST && !props.hideControls?.delete
                                ?
                                    <RvTooltipFabric hint="Delete">
                                        <RvButtonFabric
                                            rvKey="delete"
                                            rvbuttonType={RvButtonType.icon}
                                            rvClassName="button-fabric"
                                            rvIcon={'Delete'}
                                            disabled={value.isLoading || value.isEditDisabled}
                                            onClick={onDelete}
                                        />
                                    </RvTooltipFabric>
                                : null                                
                            }
                            
                        </div>
                        {
                            value.viewType === ViewTypeEnum.LIST && (
                                <RvTooltipFabric
                                    hint={
                                        value.isInMemory
                                            ? 'Row Grouping'
                                            : 'Row Grouping - Available when all tasks have been loaded'
                                    }
                                >
                                    <RvButtonFabric
                                        rvKey="group"
                                        rvbuttonType={RvButtonType.icon}
                                        rvClassName="button-fabric"
                                        rvIcon={
                                            value.isRowGroupPanelShow
                                                ? 'row-groups-hover-svg'
                                                : 'row-groups-svg'
                                        }
                                        onClick={onToggleRowGrouping}
                                        disabled={value.isLoading}
                                    />
                                </RvTooltipFabric>
                            )
                        }
                        
                        <RvTooltipFabric hint="Refresh">
                            <RvButtonFabric
                                rvKey="refresh"
                                rvbuttonType={RvButtonType.icon}
                                rvIcon="refresh-svg"
                                rvClassName="button-fabric"
                                onClick={onRefreshData}
                                disabled={value.isLoading}
                            />
                        </RvTooltipFabric>
                        {
                            value.viewType === ViewTypeEnum.LIST && (
                                <>
                                    <RvTooltipFabric hint="Share">
                                        <RvButtonFabric
                                            rvKey="share"
                                            rvbuttonType={RvButtonType.split}
                                            disabled={value.isLoading}
                                            rvIcon="share-svg"
                                            split={true}
                                            rvClassName="button-fabric custom-split"
                                            rvMenuProps={{
                                                items: [
                                                    {
                                                        key: 'print',
                                                        name: 'Print List...',
                                                        onClick: () => {onPrint(); },
                                                    },
                                                    {
                                                        key: 'export',
                                                        name: 'Export List...',
                                                        onClick: () => {onExport(); },
                                                    },
                                                ],
                                            }}
                                        />
                                    </RvTooltipFabric>
                                    <RvTooltipFabric hint="Columns">
                                        <RvButtonFabric
                                            rvKey="filter"
                                            rvbuttonType={RvButtonType.icon}
                                            rvClassName="button-fabric"
                                            rvIcon={
                                                value.isToolPanelShow
                                                    ? 'columns-hover-svg'
                                                    : 'columns-svg'
                                            }
                                            onClick={onShowToolPanel}
                                            disabled={value.isLoading}
                                        />
                                    </RvTooltipFabric>
                                    {!props.hideControls?.savedView ? (
                                        <RvTooltipFabric hint="Saved Views">
                                            <RvButtonFabric
                                                rvKey="views"
                                                rvbuttonType={RvButtonType.split}
                                                split={true}
                                                primary={false}
                                                onClick={onToggleConfigs}
                                                disabled={value.isLoading}
                                                rvIcon={
                                                    value.isViewShow
                                                        ? 'views-hover-svg'
                                                        : 'views-svg'
                                                }
                                                rvClassName="button-fabric custom-split-2"
                                                rvMenuProps={{
                                                    items: viewMenuItems,
                                                }}
                                            />
                                        </RvTooltipFabric>
                                    ) : null}
                                </>
                            )
                        }
                        <div>
                            <div 
                                className={classes.viewType}
                                style={{
                                    opacity: value.isLoading ? 0.2 : 1 
                                }}
                            >
                                <RvTooltipFabric hint="List View">
                                    <RvButtonFabric 
                                        rvKey="list"
                                        rvbuttonType={RvButtonType.icon}
                                        disabled={value.isLoading}
                                        // checked={this.state.viewStyle === ViewDisplayStyle.List ? true : false}
                                        rvIcon={value.viewType === ViewTypeEnum.LIST ? 'list-on-svg' : 'list-off-svg'}
                                        rvClassName="display-type list"
                                        // tslint:disable-next-line: no-console
                                        onClick={() => onClickViewType(ViewTypeEnum.LIST)}
                                    />
                                </RvTooltipFabric>
                                <RvTooltipFabric hint="Task Board">
                                    <RvButtonFabric 
                                        rvKey="grid"
                                        rvbuttonType={RvButtonType.icon}
                                        disabled={value.isLoading}
                                        // checked={this.state.viewStyle === ViewDisplayStyle.Card ? true : false}
                                        rvIcon={value.viewType === ViewTypeEnum.BOARD ? 'grid-on-svg' : 'grid-off-svg'}
                                        rvClassName="display-type grid"
                                        // tslint:disable-next-line: no-console
                                        onClick={() => onClickViewType(ViewTypeEnum.BOARD)}
                                    />
                                </RvTooltipFabric>
                                <RvTooltipFabric hint="Compress Board">
                                    <RvButtonFabric 
                                        rvKey="grid"
                                        rvbuttonType={RvButtonType.icon}
                                        disabled={value.isLoading}
                                        // checked={this.state.viewStyle === ViewDisplayStyle.Card ? true : false}
                                        rvIcon={value.viewType === ViewTypeEnum.COMPRESS_BOARD ? 'compact-on-svg' : 'compact-off-svg'}
                                        rvClassName="display-type grid"
                                        // tslint:disable-next-line: no-console
                                        onClick={() => onClickViewType(ViewTypeEnum.COMPRESS_BOARD)}
                                    />
                                </RvTooltipFabric>
                            </div>
                        </div>
                    </div>
                </Grid>
                {value.viewType === ViewTypeEnum.LIST && value.isLoading === false && props.loadedCount > 0 ? (
                    value.isInMemory === false && 
                    (value.search === '' || value.search === undefined) ? (
                        <Grid item={true} xs={12} md={12}>
                            <p className={classes.notes}>
                                {' '}
                                The summary returned a large number of tasks.
                                Narrow the filter or {downloadTaskLink} to
                                download remaining tasks. Note that grouping,
                                sorting and filtering won’t be activated until
                                all tasks are downloaded
                            </p>
                        </Grid>
                    ) : null
                ) : null}

                <Grid 
                    item={true} 
                    xs={12} 
                    md={12}
                    style={{
                        display: value.showAdvancedFilter ? 'block' : 'none'
                    }}
                >
                    <div className={classes.advanceFilterContainer}>
                        <TasksSummaryAdvanceFilter 
                            onSearch={onSearchAdvancedChanged}
                            advancedFilter={advancedFilter}
                            onClose={onCloseAdvancedFilter}
                            onHasFilter={onHasFilter}
                        />
                    </div>
                </Grid>
            </Grid>
        </div>
    );
};