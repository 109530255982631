/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { makeStyles, Theme, createStyles, withStyles } from '@material-ui/core/styles';
import { DraggableProvided, DraggableStateSnapshot } from 'react-beautiful-dnd';
import { Avatar, IconButton, Tooltip } from '@material-ui/core';
import { SemanticCOLORS } from 'semantic-ui-react';
import moment from 'moment';
import { PrioritySelector } from '../selectors/PrioritySelector';
import { IAutoCompleteItem } from '../../typings/autoComplete';
import useOnClickOutside from 'use-onclickoutside';
import { TaskStatusSelector } from '../selectors/TaskStatusSelector';
import { client } from '../..';
import { showNotification } from '../../App';
import { GroupByType } from '../TasksSummaryControlPanel';
import { UserAutocompleteSelector } from '../../components/UserAutocompleteSelector';
import { ActionMenu } from '../menu/ActionMenu';
import { 
    ChangeStatus,
    ChangeTaskAllocation,
    ChangeTaskPriority,
    DeleteTask,
    StartTask 
} from '../TasksSummaryRepository';
import DialogBox from '../../Dashboard/Component/DialogBox';
import { TasksSummaryFormDialog } from '../dialog/TasksSummaryFormDialog';
import { StartFormDialog } from '../dialog/StartFormDialog';
import { PriorityFormDialog } from '../dialog/PriorityFormDialog';
import { CompleteFormDialog } from '../dialog/CompleteFormDialog';
import { DueFormDialog } from '../dialog/DueFormDialog';
import { AllocateFormDialog } from '../dialog/AllocateFormDialog';
import { StatusFormDialog } from '../dialog/StatusFormDialog';
import { UserFeature, UserFeatureEnum } from '../../types/UserFeature';
import { EnquiryTaskAction } from '../../enquirySummary/EnquiryTaskAction';
import { MatterTaskRenderer } from '../cellRenderer/MatterTaskRenderer';
import { MatterTaskViewRender } from '../cellRenderer/MatterTaskViewRender';
import PersonIcon from '@material-ui/icons/Person';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import CheckIcon from '@material-ui/icons/Check';
import { mainTheme } from '../../Theme';
import { CategoryFormDialog } from '../dialog/CategoryFormDialog';
import { MatterTerminology } from '../../MatterTerminology';
import { PhoneFormDialog } from '../dialog/PhoneFormDialog';
import PhoneIcon from '@material-ui/icons/Phone';
import { OwnerFormDialog } from '../dialog/OwnerFormDialog';
import { AllocatedToEntityType } from '../AllocatedToEntityType';
import { EnquiryFormDrawer } from '../../enquirySummary/drawers/EnquiryFormDrawer';
// import { fetchEnquiryDetailsData } from '../../enquirySummary/EnquirySummaryRepository';
// import { Enquiry, EnquiryData } from '../../enquirySummary/models/Enquiry';
import { MessageFormDialog } from '../dialog/MessageFormDialog';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import OpenInNew from '@material-ui/icons/OpenInNewOutlined';
import htmlToDraft from 'html-to-draftjs';
import { ContentState, EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import { LightTooltip } from '../../helpers/commonHelper';
import { AddUserMatterAccessActivity } from '../../matterSummary/MatterSummaryPageX';
import { nameAccessActivityMutation } from '../../ClientAndContactDetails/ClientAndContactDetailsRepositories';
import { EnquiryDefaultsConfig } from '../../hooks/useEnquiryConfigSettingsQuery';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            userSelect: 'none',
            padding: 8,
            margin: '0 0 8px 0',
            color: '#000',
            display: 'flex',
            position: 'relative',
            backgroundColor: '#fff'
        },
        option: {
            // position: 'absolute',
            // top: 0,
            // right: 0,
            display: 'flex',
            flex: 1,
            justifyContent: 'flex-end',
            alignItems: 'center'
        },
        contentContainer: {
            // padding: '0 10px',
            display: 'flex',
            flexFlow: 'column',
            flex: 1,
        },
        content: {
            paddingBottom: '10px',
            flex: 1,
            // paddingRight: '30px',
        },
        otherDetails: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            fontSize: '12px',
            '& > div': {
                flex: 1,
            },
            '& .session': {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
            },
            '& .user': {
                backgroundColor: 'yellow',
                padding: '3px 5px',
                textTransform: 'capitalize',
                borderRadius: '3px',
            }
        },
        userContainer: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            fontSize: '12px',
            padding: '10px 0',
            cursor: 'pointer',
            '& .user': {
                display: 'flex',
                alignItems: 'center',
                padding: '0 5px',
                margin: '0 -5px',
                '& > div': {
                    padding: '0 5px',
                }
            },
            '& .name': {
                fontWeight: 600,
            },
            '& .category': {
                padding: '0 5px',
                fontWeight: 600,
            }
        },
        avatar: {
            width: '20px',
            height: '20px',
            fontSize: '10px',
        },
        priority: {
            cursor: 'pointer'
        },
        status: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            cursor: 'pointer',
        },
        optionContainer: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            margin: '-8px -8px 0px -8px',
        },
        phoneTaskCardContent: {
            '& .user': {
                display: 'flex',
                alignItems: 'center',
                padding: '0 5px',
                margin: '0 -5px',
                '& > div': {
                    padding: '0 5px',
                }
            },
            '& .card-heading': {
                fontSize: 16,
                fontWeight: 600,
                marginBottom: 5,
            },
            '& .card-fromAndOrganisation': {
                marginBottom: 5,
                '& span': {
                    fontWeight: 600,
                }
            },
            '& .card-phoneNumber': {
                display: 'flex',
                alignItems: 'center',
                '& svg': {
                    marginRight: 5,
                }
            },
            '& .card-message': {
                marginBottom: 5,
            },
            '& .card-callType': {
                marginBottom: 5,
            },
            '& .card-bottom': {
                display: 'flex',
                justifyContent: 'space-between',
            },
            '& .card-bottom-left': {
            },
            '& .card-to': {
                fontWeight: 600,
                display: 'flex',
                paddingBottom: 5,
            },
            '& .card-bottom-right': {
                textAlign: 'right'
            },
            '& .card-callNumberWrapper': {
                display: 'flex',
                justifyContent: 'space-between',
                marginBottom: 5,
            },
            '& .card-status': {
                fontSize: 12
            }
        },
        matterDescription: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start'
        },
        matterDescriptionContent: {
            display: 'flex',
        },
        clientDescription: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex'
        },
        clientDescriptionContent: {
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
        },
        openNewWindowIcon: {
            marginLeft: '5px',
            cursor: 'pointer'
        },
        taskContentDescription: {
            fontWeight: 600
        },
        confidential: {
            flex: 2,
        },
        infoIcon: {
            cursor: 'pointer'
        }
    })
);

export const Colors: SemanticCOLORS[] = ['red', 'orange', 'olive', 'green', 'teal', 'blue',
    'purple', 'pink', 'brown', 'grey'];

export function hashString(input: string): number {
    var hash = 0, i, chr;
    if (input.length === 0) {
        return hash;
    }
    for (i = 0; i < input.length; i++) {
        chr = input.charCodeAt(i);
        // tslint:disable-next-line:no-bitwise
        hash = ((hash << 5) - hash) + chr - 1;
        // tslint:disable-next-line:no-bitwise
        hash |= 0; // Convert to 32bit integer
    }
    return hash;
}

interface BoardCardDraggableProps {
    key: string;
    assignedTo: IAutoCompleteItem;
    content: string | null;
    dueDate: Date | null;
    category?: string | null;
    priority: IAutoCompleteItem;
    status: IAutoCompleteItem;
    draggableProvided: DraggableProvided;
    draggableSnapshot: DraggableStateSnapshot;
    // tslint:disable-next-line: no-any
    item?: any;
    groupBy: GroupByType;
    entityType: number;
    isDraggable: boolean;
    loggedInuser: IAutoCompleteItem | null;
    defaultPriority: IAutoCompleteItem | null;
    homeCountry: string;
    pOBoxType: string | null;
    matterId: number | null;
    nameId: number | null;
    enquiryDefaultConfig?: EnquiryDefaultsConfig;
    isIncludeFollowUpTasksOnly: boolean | null;
}

interface TaskFormState {
    isDuplicate: boolean;
    isTaskFormOpen: boolean;
    isStartDateOpen: boolean;
    isPriorityFormOpen: boolean;
    isCompletionFormOpen: boolean;
    isDueFormOpen: boolean;
    isAllocatedFormOpen: boolean;
    isStatusFormOpen: boolean;
    isTaskViewOpen: boolean;
    isCategoryFormOpen: boolean;
    isOwnerFormOpen: boolean;
    isPhoneFormOpen: boolean;
    isEnquiryFormOpen: boolean;
    isMessageFormOpen: boolean;
}

interface BoardCardDraggableState extends TaskFormState {
    priority: IAutoCompleteItem;
    status: IAutoCompleteItem;
    assignedTo: IAutoCompleteItem;
    groupBy: GroupByType;
    isDeleteConfirm: boolean;
    matterId: number | null;
    nameId: number | null;
}

export const BoardCardDraggable: React.FC<BoardCardDraggableProps> = props => {
    
    const classes = useStyles();

    // const [enquiryData, setEnquiryData] = useState<Enquiry>();

    const [state, setState] = useState<BoardCardDraggableState>({
        priority: props.priority,
        status: props.status,
        assignedTo: props.assignedTo,
        groupBy: props.groupBy,
        isDeleteConfirm: false,
        isTaskFormOpen: false,
        isStartDateOpen: false,
        isPriorityFormOpen: false,
        isCompletionFormOpen: false,
        isDueFormOpen: false,
        isAllocatedFormOpen: false,
        isStatusFormOpen: false,
        isTaskViewOpen: false,
        isCategoryFormOpen: false,
        isOwnerFormOpen: false,
        isPhoneFormOpen: false,
        isEnquiryFormOpen: false,
        isMessageFormOpen: false,
        isDuplicate: false,
        matterId: props.matterId,
        nameId: props.nameId
    });
  
    useEffect(() => {
      setState((prevState) => {
        return {
          ...prevState,
          groupBy: props.groupBy
        };
      });

    // tslint:disable-next-line: align
    }, [props.groupBy]);

    const [isPrioritySelected, setIsPrioritySelected] = useState(false);
    const [isStatusSelected, setIsStatusSelected] = useState(false);
    const [isAssignedToSelected, setIsAssignedToSelected] = useState(false);

    const refPriority = React.useRef(null);

    const refStatus = React.useRef(null);
    const refAssignedTo = React.useRef(null);

    // click outside hide the selector
    useOnClickOutside(refPriority, () => setIsPrioritySelected(false));

    // click outside hide the selectoras
    useOnClickOutside(refStatus, () => setIsStatusSelected(false));

    // click outside hide the selector
    useOnClickOutside(refAssignedTo, () => setIsAssignedToSelected(false));

    // useEffect(() => {
    //     if (state.isEnquiryFormOpen) {
    //         fetchEnquiryDetails();
    //     }        
    // // tslint:disable-next-line: align
    // }, [state.isEnquiryFormOpen]);

    // const fetchEnquiryDetails = () => {
    //     fetchEnquiryDetailsData(true, // This should always be a new request.
    //                             props.item.entityGuidID,
    //                             (data: EnquiryData) => {
    //                                 handleEnquiryDataFetch(data);
    //                             }, 
    //                             (reason) => {
    //                                 showNotification('Failed to Fetch App User Settings', reason, 'error'); 
    //                             });
    // };

    // const handleEnquiryDataFetch = (data: EnquiryData) => {
    //     if (data && !data.loading && data.enquiry) {
    //         if (data.enquiry) {
    //             setEnquiryData(data.enquiry);
    //         }
    //     }
    // };

    const avatar = () => {
        let bColor: SemanticCOLORS = Colors[(state.assignedTo.label === '' || state.assignedTo.label === null) ? 
        Colors[Colors.length] : (hashString(state.assignedTo.label) % Colors.length) - 1];
        // tslint:disable-next-line: no-any
        let stringSplit = state.assignedTo.label.split(' ').map((n: any) => n[0]).join('');

        let stringInitials = stringSplit.slice(0, 2);

        return (
            <Avatar 
                className={classes.avatar}
                style={{ backgroundColor: bColor ? bColor : 'grey', color: '#fff' }}
            >
                {stringInitials}
            </Avatar>
        );
    };

    const dueDate = () => {
        if (props.dueDate) {
            return `Due ${moment(props.dueDate, 'YYYY-MM-DD HH:mm:ss').endOf('second').fromNow()}`;  
        } else {
            return '';
        }    
    };

    const formattedDate = (date: Date | null, format: string) => {
        if (date) {
            return moment(date).format(format);
        }
        return undefined;
    };

    const isOverDue = () => {
        if (props.dueDate) {
            return moment().diff(props.dueDate, 'second');
        } else {
            return 0;
        }      
    };

    const handleClickPriority = () => {
        setIsPrioritySelected(true);
    };

    const handleClickStatus = () => {
        setIsStatusSelected(true);
    };

    const handleClickAssignedTo = () => {
        setIsAssignedToSelected(true);
    };

    const handleChangePriority = (data: IAutoCompleteItem, name: string) => {
        setState((prevState) => {
          return {
            ...prevState,
            [name]: data
          };
        });

        client
        .mutate({
            mutation: ChangeTaskPriority,
            variables: {
                guid: props.item.taskStatusGuidID,
                priority: data.value
            },
        })
        // tslint:disable-next-line: no-any
        .then((results: { data: any }) => {
            if (results.data) {
                if (results.data.error !== undefined) {
                    showNotification( 'Failed to Change Priority', results.data.error, 'error');
                }
            }
        })
        // tslint:disable-next-line:no-any
        .catch((reason: any) => {
            showNotification('Failed to Change Priority', reason, 'error');
        });
    };

    const handleChangeStatus = (data: IAutoCompleteItem, name: string) => {

        setState((prevState) => {
          return {
            ...prevState,
            [name]: data
          };
        });

        client
        .mutate({
            mutation: ChangeStatus,
            variables: {
                guidId: props.item.taskStatusGuidID,
                statusGuidId: data.value
            },
        })
        // tslint:disable-next-line: no-any
        .then((results: { data: any }) => {
            if (results.data) {
                if (results.data.error !== undefined) {
                    showNotification( 'Failed to Change Status', results.data.error, 'error');
                }
            }
        })
        // tslint:disable-next-line:no-any
        .catch((reason: any) => {
            showNotification('Failed to Change Status', reason, 'error');
        });
    };

    const handleChangeAssignedTo = (data: IAutoCompleteItem, name: string) => {
        // tslint:disable-next-line: no-console
        // console.log('assigned To', data, name, props.item.taskStatusGuidID);
        if (!data) {
            return;
        }
        
        setState((prevState) => {
            return {
              ...prevState,
              [name]: data
            };
        });

        client
        .mutate({
            mutation: ChangeTaskAllocation,
            variables: {
                guidId: props.item.taskGuidID,
                assignedTo: data.value,
                assignedBy: UserFeature.UserId,
                allocatedToEntityType: AllocatedToEntityType.user
            },
        })
        // tslint:disable-next-line: no-any
        .then((results: { data: any }) => {
            if (results.data) {
                if (results.data.error !== undefined) {
                    showNotification( 'Failed to Change Assigned To', results.data.error, 'error');
                }
            }
        })
        // tslint:disable-next-line:no-any
        .catch((reason: any) => {
            showNotification('Failed to Change Assigned To', reason, 'error');
        });
    };

    const onOpen = (isAdhoc: boolean, isDuplicate: boolean) => () => {

        setState((prevState) => {
            return {
                ...prevState,
                isTaskFormOpen: isAdhoc && props.entityType !== 1 ? true : false,
                isPhoneFormOpen: !isAdhoc && props.entityType !== 1 ? true : false,
                isEnquiryFormOpen: props.entityType === 1 ? true : false,
                isDuplicate: isDuplicate
            };
        });
    };

    const onView = () => {
        setState((prevState) => {
            return {
                ...prevState,
                isTaskViewOpen: true
            };
        });
    };

    const onStart = () => {
        client
        .mutate({
            mutation: StartTask,
            variables: { 
                guid: props.item.taskGuidID, 
                startDate: new Date(), 
                // dueDate: null, 
                // estimatedEffort: null
            },
        })
        // tslint:disable-next-line: no-any
        .then((results: { data: any }) => {
            if (results.data) {
                if (results.data.error === null || results.data.error === undefined) {
                    showNotification(null, 'Successfully started', 'info');
                } else {
                    showNotification('Failed to start', results.data.error, 'error');
                }
            }
        })
        // tslint:disable-next-line:no-any
        .catch((reason: any) => {
            showNotification('Failed to start', reason, 'error');
        });
    };

    const onDelete = () => {
        setState((prevState) => {
            return {
              ...prevState,
              isDeleteConfirm: true
            };
        });
    };

    const onDeleteConfirm = (agree: boolean) => {
        if (agree) {
            // tslint:disable-next-line: no-console
            // console.log('delete task');

            deleteTask(props.item.taskGuidID);
        }

        setState((prevState) => {
            return {
                ...prevState,
                isDeleteConfirm: false
            };
        });
    };

    const deleteTask = (guid: string) => {
        client
        .mutate({
            mutation: DeleteTask,
            variables: { 
                guid
            },
        })
        // tslint:disable-next-line: no-any
        .then((results: { data: any }) => {
            if (results.data) {
                if (results.data.error === null || results.data.error === undefined) {
                    showNotification(null, 'Successfully deleted', 'info');
                } else {
                    showNotification('Failed to delete task', results.data.error, 'error');
                }
            }
        })
        // tslint:disable-next-line:no-any
        .catch((reason: any) => {
            showNotification('Failed to delete task', reason, 'error');
        });
  };

    const onDialogForm = (form: keyof TaskFormState, open: boolean) => () => {
        setState((prevState) => {
            return {
                ...prevState,
                [form]: open
            };
        });
    };

    const TooltipUI = withStyles((theme: Theme) => ({
        tooltip: {
          backgroundColor: mainTheme.BrandColors.TemplatePrimary,
          color: '#ffffff',
          maxWidth: 220,
          fontSize: 14,
          border: '1px solid #dadde9',
        },
    }))(Tooltip);
  
    const quickActions = () => {
        if (props.status && props.status!.label.toLowerCase() === 'unallocated') {
            return (
              <TooltipUI title="Assign">
                  <IconButton
                      color="primary"
                      aria-haspopup="true"
                      onClick={onDialogForm('isAllocatedFormOpen', true)}
                  >
                      <PersonIcon fontSize="small" />
                  </IconButton>
              </TooltipUI>
            );
        } else if (props.status && props.status!.label.toLowerCase() === 'not started' && props.item.taskType !== 'PhoneTask' &&
                   props.item.allocatedToEntityType === AllocatedToEntityType.user ) {
            return (
                <TooltipUI title="Start">
                    <IconButton
                        color="primary"
                        aria-haspopup="true"
                        onClick={onStart}
                    >
                        <PlayArrowIcon fontSize="small" />
                    </IconButton>
                </TooltipUI>
            );
        } else if (props.item.completedDate === null || props.item.allocatedToEntityType === AllocatedToEntityType.user) {
            return (
                <TooltipUI title="Complete">
                    <IconButton
                        color="primary"
                        aria-haspopup="true"
                        onClick={onDialogForm('isCompletionFormOpen', true)}
                    >
                        <CheckIcon fontSize="small" />
                    </IconButton>
                </TooltipUI>
            );
        } else if (props.status && props.status!.label.toLowerCase() === 'unassigned' 
                    && props.item.allocatedToEntityType === AllocatedToEntityType.user) {
            return (
                <>
                    <TooltipUI title="Assign">
                        <IconButton
                            color="primary"
                            aria-haspopup="true"
                            onClick={onDialogForm('isAllocatedFormOpen', true)}
                        >
                            <PersonIcon fontSize="small" />
                        </IconButton>
                    </TooltipUI>
                    <TooltipUI title="Start">
                        <IconButton
                            color="primary"
                            aria-haspopup="true"
                            onClick={onStart}
                        >
                            <PlayArrowIcon fontSize="small" />
                        </IconButton>
                    </TooltipUI>
                </>
            );
        }
        return <div />;
    };

    const isTaskTypeMatter = props.item.taskType === 'ArchiveMatterRequested' ||
        props.item.taskType === 'BillMatterRequested' ||
        props.item.taskType === 'WriteOffMatterWIPRequested' ||
        props.item.taskType === 'WriteOffMatterDebtorsRequested' ||
        props.item.taskType === 'TransferMatterTrustToInvoiceRequested' ||
        props.item.taskType === 'CloseMatterRequested' || 
        props.item.taskType === 'General' ||
        props.item.isSystemTask === false;

    const enquiryText = props.item.isKeyOpportunity ? MatterTerminology.KeyOpportunity : MatterTerminology.Enquiry;

    const taskTypeDescription = props.item.isFromDataSource === true 
        ? 'LEAP' 
        : props.item.taskTypeDescription.replace('Enquiry', enquiryText);

    const callType = Object.entries({
        isReturnedCall: props.item.isReturnedCall,
        isPhoneCall: props.item.isPhoneCall,
        isWillCallAgain: props.item.isWillCallAgain,
    })
    .map(([key, value]) => {
        if (value) {
            if (key === 'isPhoneCall') {
                return 'Please Call';
            } else if (key === 'isReturnedCall') {
                return 'Returned Call';
            } else {
                return 'Will Call Again';
            }
        }
        return undefined;
    })
    // tslint:disable-next-line: no-any
    .filter((val: any) => val !== undefined)
    .join(' - ');

    const isDuplicateEnabled = props.item.taskType !== 'BillMatterRequested' &&
                                props.item.taskType !== 'WriteOffMatterWIPRequested' &&
                                props.item.taskType !== 'TransferMatterTrustToInvoiceRequested' &&
                                props.item.taskType !== 'WriteOffMatterDebtorsRequested' &&
                                props.item.taskType !== 'CloseMatterRequested' &&
                                props.item.taskType !== 'ArchiveMatterRequested' &&
                                props.item.taskType !== 'PhoneTask';

    const getMatterDescription = () => {

        if (
            (props.item.taskType !== 'BillMatterRequested' &&
            props.item.taskType !== 'WriteOffMatterWIPRequested' &&
            props.item.taskType !== 'TransferMatterTrustToInvoiceRequested' &&
            props.item.taskType !== 'WriteOffMatterDebtorsRequested' &&
            props.item.taskType !== 'CloseMatterRequested' &&
            props.item.taskType !== 'ArchiveMatterRequested') && props.item.matterDescription
        ) {
            return (
                <div className={classes.matterDescription}>
                    <div className={classes.matterDescriptionContent}>
                        {`${props.item.matterDescription}`}
                        <TooltipUI title="Show matter details">
                            <OpenInNew 
                                fontSize="small"
                                color="primary"
                                onClick={onShowMatterDetails()}
                                className={classes.openNewWindowIcon}
                            />
                        </TooltipUI>
                    </div>
                </div>
            );
        }

        if (
            (props.item.client !== undefined || props.item.client !== null) && props.item.client 
        ) {
            return (
                <div className={classes.clientDescription}>
                    <div className={classes.clientDescriptionContent}>
                        {`${props.item.client}`}
                        <TooltipUI title="Show client details">
                            <OpenInNew 
                                fontSize="small"
                                color="primary"
                                onClick={onShowClientAndContactDetails()}
                                className={classes.openNewWindowIcon}
                            />
                        </TooltipUI>
                    </div>
                </div>
            );
        }

        return undefined;
    };

    const onShowMatterDetails = () => () => {
        client
        .mutate({
            mutation: AddUserMatterAccessActivity,
            variables: {
                matterId: props.matterId
            },
        })
        .then(
            (results: {
                data: {
                    addUserMatterAccessActivity: boolean;
                };
            }) => {
                if (results.data.addUserMatterAccessActivity === false) {
                    // tslint:disable-next-line:no-console
                    console.log('AddUserMatterAccessActivity mutation failed');
                }
            },
        );

        window.open('/matterDetail/' + props.matterId);
    };

    const onShowClientAndContactDetails = () => () => {
        client
        .mutate({
            mutation: nameAccessActivityMutation,
            variables: {
                matterId: props.nameId,
                isFromDataSource: true
            },
        })
        .then(
            (results: {
                data: {
                    nameAccessActivityMutation: boolean;
                };
            }) => {
                if (results.data.nameAccessActivityMutation === false) {
                    // tslint:disable-next-line:no-console
                    console.log('nameAccessActivityMutation mutation failed');
                }
            },
        );
        window.open(`/clientAndContactDetail/${props.nameId}?isFromDataSource=true`);
    };

    const getTooltipTitle = (description: string): NonNullable<React.ReactNode> => {

        const contentBlock = htmlToDraft(description);

        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
        
        const editorState = EditorState.createWithContent(contentState);

        return (
            <>
                <Editor 
                    editorState={editorState}
                    readOnly={true}
                    toolbarHidden={true}
                />
            </>
        );
    };

    const defaultCard = () => {
        return (
            <div className={classes.contentContainer}>
                <div className={classes.optionContainer}>
                    {/* {state.groupBy !== GroupByType.taskType && ( */}
                    <div className="board-tasktype">
                        <div 
                            className="board-type-content"
                            onClick={onOpen(props.item.taskType !== 'PhoneTask', false)}
                        >
                            {taskTypeDescription}
                        </div>
                    </div>
                    {/* )} */}
                    {props.entityType !== 1 && (
                        <div className={classes.option}>
                            {!!props.item.isConfidential && (
                                <TooltipUI title="Confidential">
                                    <LockOutlinedIcon 
                                        fontSize="small" 
                                        color="primary"
                                        className={classes.confidential}
                                    />
                                </TooltipUI>
                            )}
                            {props.item.taskTypeNotes && (
                                <LightTooltip title={getTooltipTitle(props.item.taskTypeNotes)}>
                                    <InfoIcon color="primary" fontSize="small" className={classes.infoIcon}/>
                                </LightTooltip>
                            )}
                            {quickActions()}
                            <ActionMenu 
                                onOpen={onOpen(props.item.taskType !== 'PhoneTask', false)}
                                onAllocate={onDialogForm('isAllocatedFormOpen', true)}
                                onStatus={onDialogForm('isStatusFormOpen', true)}
                                onPriority={onDialogForm('isPriorityFormOpen', true)}
                                onStartDate={onDialogForm('isStartDateOpen', true)}
                                onDueDate={onDialogForm('isDueFormOpen', true)}
                                onDelete={onDelete}
                                onStart={onStart}
                                onCompletionDate={onDialogForm('isCompletionFormOpen', true)}
                                onComplete={onDialogForm('isCompletionFormOpen', true)}
                                onView={onView}
                                onCategory={onDialogForm('isCategoryFormOpen', true)}
                                onOwner={onDialogForm('isOwnerFormOpen', true)}
                                onMessage={onDialogForm('isMessageFormOpen', true)}
                                onDuplicate={isDuplicateEnabled ? onOpen(props.item.taskType !== 'PhoneTask', true) : undefined}
                                // onCreateEnquiry={onDialogForm('isEnquiryFormOpen', true)}
                                disabledMenu={{
                                    // if we have value for start date or if it is not assigned to a user don't allow to start
                                    // tslint:disable-next-line: max-line-length
                                    start: props.item.startDate !== null || props.item.allocatedToEntityType !== AllocatedToEntityType.user, // Not started yet and allocated to a user.
                                    complete: props.item.completedDate !== null || props.item.allocatedToEntityType !== AllocatedToEntityType.user,
                                    status: props.item.allocatedToEntityType !== AllocatedToEntityType.user,
                                    startDate: props.item.allocatedToEntityType !== AllocatedToEntityType.user,
                                    completionDate: props.item.allocatedToEntityType !== AllocatedToEntityType.user,
                                    view: !isTaskTypeMatter,
                                    duplicate: props.item.taskType === 'PhoneTask',
                                    // createEnquiry: !UserFeature.HasAccess(UserFeatureEnum.hasCreateLead)
                                }}
                                entityType={props.item.entityType}
                            />
                        </div>
                    )}  

                    {props.entityType === 1 && (
                        <div className={classes.option}>
                            <EnquiryTaskAction 
                              enquiryGuid={props.item.entityGuidID}
                            />
                        </div>
                    )}
                </div>
                <div className={classes.content}>
                    <div className={classes.taskContentDescription}>
                        {props.content}
                    </div>
                        {getMatterDescription()}
                </div>
                <div className={classes.userContainer}>
                    { 
                        state.groupBy !== GroupByType.allocatedTo 
                        ? (
                            <div ref={refAssignedTo} onClick={handleClickAssignedTo}>
                                {
                                    isAssignedToSelected ? 
                                    (
                                        <UserAutocompleteSelector 
                                            onSelection={handleChangeAssignedTo}
                                            value={state.assignedTo}
                                            label="Assigned To"
                                            name="assignedTo"
                                            isExternal={false}
                                            visible={{
                                                role: true
                                            }}
                                        />
                                    )
                                    :
                                    (
                                    <>
                                        {state.assignedTo.label && (
                                        <div className="user">
                                            {avatar()}
                                            <div className="name">{state.assignedTo.label}</div>
                                        </div>
                                        )}
                                    </>
                                    )
                                }
                            </div>
                        ) 
                        : 
                        (
                            <div ref={refPriority} onClick={handleClickPriority}>
                            {
                                isPrioritySelected ? 
                                (
                                <PrioritySelector 
                                    onSelection={handleChangePriority}
                                    value={state.priority}
                                />
                                ) 
                                : 
                                (
                                <div>{state.priority.label}</div>
                                )
                            }
                            </div>
                        )
                    }
                    {
                        props.category && props.groupBy !== GroupByType.category && (
                            <div className="category">
                                {props.category}
                            </div>
                        )
                    }
                </div>
                <div className={classes.otherDetails}>
                    <div>
                        { 
                            state.groupBy !== GroupByType.priority && state.groupBy !== GroupByType.allocatedTo 
                            &&
                            (
                                <div className={classes.priority} ref={refPriority} onClick={handleClickPriority}>
                                {
                                    isPrioritySelected ? 
                                    (
                                        <PrioritySelector 
                                            onSelection={handleChangePriority}
                                            value={state.priority}
                                        />
                                    ) 
                                    : 
                                    (
                                        <div>
                                            {state.priority.label}
                                        </div>
                                    )
                                }
                                </div>
                            )    
                        }
                        {state.groupBy !== GroupByType.status && 
                            (
                                <div className={classes.priority} ref={refStatus} onClick={handleClickStatus}>
                                {
                                    isStatusSelected ? 
                                    (
                                        (
                                            <TaskStatusSelector 
                                                onSelection={handleChangeStatus}
                                                value={state.status}
                                                label="Status"
                                                name="status"
                                                isIncludeFollowUpTasksOnly={props.isIncludeFollowUpTasksOnly ?? undefined}
                                                isIncludeCount={false}
                                                isRefresh={false}
                                            />
                                        ) 
                                    )
                                    :
                                    (
                                        <div><b>Status: </b>{state.status.label}</div>
                                    )
                                }
                                </div>
                            )     
                        }
                    </div>
                    {props.item.completedDate === null && state.status.label.toLowerCase() !== 'complete' && (
                        <div 
                            className="session"
                            style={{ color: isOverDue() > 0 ? 'red' : '#000'}}
                        >
                            {dueDate()}
                        </div>
                    )}
                    {props.item.completedDate && (
                        <div 
                            className="session"
                            // style={{ color: isOverDue() > 0 ? 'red' : '#000'}}
                        >
                            {formattedDate(props.item.createdDate, 'DD MMM YYYY')}
                        </div>
                    )}
                </div>
            </div>
        );
    };

    const phoneTaskCard = () => {
        return (
            <div className={classes.contentContainer}>
                <div className={classes.optionContainer}>
                    {/* {state.groupBy !== GroupByType.taskType && ( */}
                    <div className="board-tasktype">
                        <div 
                            className="board-type-content"
                            onClick={onOpen(props.item.taskType !== 'PhoneTask', false)}
                        >
                            {taskTypeDescription}
                        </div>
                    </div>
                    {/* )} */}
                    {props.entityType !== 1 && (
                        <div className={classes.option}>
                            {props.item.taskTypeNotes && (
                                <LightTooltip title={getTooltipTitle(props.item.taskTypeNotes)}>
                                    <InfoIcon color="primary" fontSize="small" />
                                </LightTooltip>
                            )}
                            {quickActions()}
                            <ActionMenu 
                                onOpen={onOpen(props.item.taskType !== 'PhoneTask', false)}
                                onAllocate={onDialogForm('isAllocatedFormOpen', true)}
                                onStatus={onDialogForm('isStatusFormOpen', true)}
                                onPriority={onDialogForm('isPriorityFormOpen', true)}
                                onStartDate={onDialogForm('isStartDateOpen', true)}
                                onDueDate={onDialogForm('isDueFormOpen', true)}
                                onDelete={onDelete}
                                onStart={onStart}
                                onCompletionDate={onDialogForm('isCompletionFormOpen', true)}
                                onComplete={onDialogForm('isCompletionFormOpen', true)}
                                onView={onView}
                                onCategory={onDialogForm('isCategoryFormOpen', true)}
                                onOwner={onDialogForm('isOwnerFormOpen', true)}
                                onMessage={onDialogForm('isMessageFormOpen', true)}
                                onCreateEnquiry={onDialogForm('isEnquiryFormOpen', true)}
                                // onDuplicate={onOpen(props.item.taskType !== 'PhoneTask', true)}
                                disabledMenu={{
                                    // if we have value for start date or if it is not assigned to a user don't allow to start
                                    start: props.item.startDate !== null || props.item.allocatedToEntityType !== AllocatedToEntityType.user,
                                    complete: props.item.completedDate !== null || props.item.allocatedToEntityType !== AllocatedToEntityType.user,
                                    status: props.item.allocatedToEntityType !== AllocatedToEntityType.user,
                                    startDate: props.item.allocatedToEntityType !== AllocatedToEntityType.user,
                                    completionDate: props.item.allocatedToEntityType !== AllocatedToEntityType.user,
                                    view: !isTaskTypeMatter,
                                    createEnquiry: !UserFeature.HasAccess(UserFeatureEnum.hasCreateLead)
                                    // duplicate: props.item.taskType === 'PhoneTask'
                                }}
                                entityType={props.item.entityType}
                            />
                        </div>
                    )}  

                    {props.entityType === 1 && (
                        <div className={classes.option}>
                            <EnquiryTaskAction 
                                enquiryGuid={props.item.entityGuidID}
                            />
                        </div>
                    )}
                </div>
                <div className={`${classes.content} ${classes.phoneTaskCardContent}`}>
                    <div className="card-fromAndOrganisation">
                        <span>{props.item.fromName}</span> {props.item.organisation ? (<>from <span>{props.item.organisation}</span></>) : undefined} 
                    </div>
                    {!!props.item.matterDescription && (
                        <div className={classes.matterDescription}>
                            <div className={classes.matterDescriptionContent}>
                                {`${props.item.matterDescription}`}
                                <TooltipUI title="Show matter details">
                                    <OpenInNew 
                                        fontSize="small"
                                        color="primary"
                                        onClick={onShowMatterDetails()}
                                        className={classes.openNewWindowIcon}
                                    />
                                </TooltipUI>
                            </div>
                        </div>
                    )}
                    <div className="card-callNumberWrapper">
                        {props.item.phoneNumber && (
                            <div className="card-phoneNumber">
                                <PhoneIcon fontSize="small" />
                                {props.item.phoneNumber}
                            </div>
                        )}
                    </div>
                    <div className="card-callType">
                        {callType}
                    </div>
                    {!!props.item.notes && (
                        <div className="card-message" dangerouslySetInnerHTML={{ __html: props.item.notes}} />
                    )}
                    <div className="card-bottom">
                        <div className="card-bottom-left">
                            <div 
                                className="card-to" 
                                ref={refAssignedTo} 
                                // onClick={handleClickAssignedTo}
                            >
                                {
                                    isAssignedToSelected ? 
                                    (
                                        <UserAutocompleteSelector 
                                            onSelection={handleChangeAssignedTo}
                                            value={state.assignedTo}
                                            label="Assigned To"
                                            name="assignedTo"
                                            visible={{
                                                role: true
                                            }}
                                        />
                                    )
                                    :
                                    (
                                        <>
                                        {state.assignedTo.label && (
                                            <div className="user">
                                                {avatar()}
                                                <div className="name">{state.assignedTo.label}</div>
                                            </div>
                                        )}
                                        </>
                                    )
                                }
                            </div>
                            {state.groupBy !== GroupByType.status && 
                                (
                                    <div className={`${classes.priority} card-status`} ref={refStatus} onClick={handleClickStatus}>
                                    {
                                        isStatusSelected ? 
                                        (
                                            (
                                                <TaskStatusSelector 
                                                    onSelection={handleChangeStatus}
                                                    value={state.status}
                                                    label="Status"
                                                    name="status"
                                                    isIncludeFollowUpTasksOnly={props.isIncludeFollowUpTasksOnly ?? undefined}
                                                    isIncludeCount={false}
                                                    isRefresh={false}
                                                />
                                            ) 
                                        )
                                        :
                                        (
                                            <div><b>Status: </b>{state.status.label}</div>
                                        )
                                    }
                                    </div>
                                )     
                            }
                        </div>
                        <div className="card-bottom-right">
                            {props.item.priorityLevel !== 0 && (
                                <div 
                                    className="card-priority" 
                                    ref={refPriority} 
                                    style={{color: props.item.priorityLevel === 10 ? 'red' : 'inherit'}}
                                    // onClick={handleClickPriority}
                                >
                                    {
                                    isPrioritySelected ? 
                                    (
                                        <PrioritySelector 
                                            onSelection={handleChangePriority}
                                            value={state.priority}
                                        />
                                    ) 
                                    : 
                                    (
                                        <div>{state.priority.label}</div>
                                    )
                                    }
                                </div>
                            )}
                            {state.status.label.toLowerCase() !== 'complete' && (
                                <div 
                                    className="card-due"
                                    style={{ color: isOverDue() > 0 ? 'red' : '#000'}}
                                >
                                    {dueDate()}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    return (
        <div 
            // key={props.key}
            className={classes.root}
            ref={props.draggableProvided.innerRef}
            {...props.draggableProvided.draggableProps}
            {...props.draggableProvided.dragHandleProps}
            style={{
                // backgroundColor: props.isDraggable ? '#fff' : '#d9dce6', gainsboro
                // boxShadow: !props.isDraggable ? '0 8px 9px -8px #5173FF' : '0 0 black',
                border: !props.isDraggable ? '2px solid #5173FF' : 0,
                ...props.draggableProvided.draggableProps.style
            }}
        >
            {state.isDeleteConfirm && (
                <DialogBox
                    title="Delete Task"
                    // tslint:disable-next-line:max-line-length
                    content={`Are you sure to delete Task: ${props.item ? props.item.taskDescription : ''}?`}
                    show={state.isDeleteConfirm}
                    isAgree={onDeleteConfirm}
                    disAgreeLabel={'No'}
                    agreeLabel={'Yes'}
                />
            )}
            {/* All IsSystemTask = 0 are user defined and should be opened in Task Form Dialog */}
            {state.isTaskFormOpen && (props.item.taskType === 'General' || props.item.taskType === 'TaskFollowUp' || props.item.isSystemTask === false ) && (
                <TasksSummaryFormDialog  
                    isOpen={state.isTaskFormOpen}
                    onClose={onDialogForm('isTaskFormOpen', false)}
                    guid={props.item.taskGuidID}
                    isCreate={false}
                    defaultPriority={props.defaultPriority}
                    isDuplicate={state.isDuplicate}
                />
            )}

            {state.isPhoneFormOpen && props.item.taskType === 'PhoneTask' && (
                <PhoneFormDialog  
                    isOpen={state.isPhoneFormOpen}
                    onClose={onDialogForm('isPhoneFormOpen', false)}
                    guid={props.item.taskGuidID}
                    isCreate={false}
                    defaultPriority={props.defaultPriority}
                />
            )}

            {state.isStartDateOpen && (
                <StartFormDialog
                    title={props.item.taskDescription}
                    open={state.isStartDateOpen}
                    guid={props.item.taskGuidID}
                    onClose={onDialogForm('isStartDateOpen', false)}
                />
            )}

            {state.isPriorityFormOpen && (
                <PriorityFormDialog 
                    title={props.item.taskDescription}
                    open={state.isPriorityFormOpen}
                    guid={props.item.taskGuidID}
                    onClose={onDialogForm('isPriorityFormOpen', false)}
                    defaultPriority={props.defaultPriority}
                />
            )}

            {state.isCompletionFormOpen && (
                <CompleteFormDialog 
                    title={props.item.taskDescription}
                    open={state.isCompletionFormOpen}
                    guid={props.item.taskGuidID}
                    onClose={onDialogForm('isCompletionFormOpen', false)}
                />
            )}

            {state.isDueFormOpen && (
                <DueFormDialog 
                    title={props.item.taskDescription}
                    open={state.isDueFormOpen}
                    guid={props.item.taskGuidID}
                    onClose={onDialogForm('isDueFormOpen', false)}
                />
            )}

            {state.isAllocatedFormOpen && (
                <AllocateFormDialog
                    title={props.item.taskDescription}
                    open={state.isAllocatedFormOpen}
                    guid={props.item.taskGuidID}
                    onClose={onDialogForm('isAllocatedFormOpen', false)}
                    loggedInUser={props.loggedInuser}
                />
            )}

            {state.isStatusFormOpen && (
                <StatusFormDialog 
                    title={props.item.taskDescription}
                    open={state.isStatusFormOpen}
                    guid={props.item.taskGuidID}
                    initialStatus={
                      {
                        label: props.item.taskStatusDescription,
                        value: props.item.taskStatusGuidID
                      }
                    }
                    destinationStatus={null}
                    onClose={onDialogForm('isStatusFormOpen', false)}
                />
            )}

            {state.isCategoryFormOpen && (
                <CategoryFormDialog 
                    title={props.item.taskDescription}
                    open={state.isCategoryFormOpen}
                    guid={props.item.taskGuidID}
                    onClose={onDialogForm('isCategoryFormOpen', false)}
                    defaultCategory={null}
                />
            )}

            {state.isOwnerFormOpen && (
                <OwnerFormDialog
                    title={props.item.taskDescription}
                    open={state.isOwnerFormOpen}
                    guid={props.item.taskGuidID}
                    onClose={onDialogForm('isOwnerFormOpen', false)}
                />
            )}

            {state.isMessageFormOpen && (
                <MessageFormDialog
                    open={state.isMessageFormOpen}
                    guid={props.item.taskGuidID}
                    onClose={onDialogForm('isMessageFormOpen', false)}
                    data={props.item}
                />
            )}

            {state.isTaskFormOpen && isTaskTypeMatter && props.item.isSystemTask === true && props.item.taskType !== 'General' && (
                <MatterTaskRenderer
                    taskType={props.item.taskType}
                    onDialogClose={onDialogForm('isTaskFormOpen', false)}
                    taskGuid={props.item.taskGuidID}
                />
            )}

            {state.isTaskViewOpen && isTaskTypeMatter && (
                <MatterTaskViewRender
                    taskType={props.item.taskType}
                    onDialogClose={onDialogForm('isTaskViewOpen', false)}
                    taskGuid={props.item.taskGuidID}
                    taskDescription={props.item.taskDescription}
                    isSystemTask={props.item.isSystemTask}
                />
            )}

            {state.isEnquiryFormOpen && props.loggedInuser && (
                <>
                    <EnquiryFormDrawer                         
                        guid={props.item.entityGuidID}
                        onDialogClose={onDialogForm('isEnquiryFormOpen', false)}
                        formAction={'edit'}
                        assignedBy={props.loggedInuser.value} 
                        assignedByName={props.loggedInuser.label} 
                        isEnquiryFormOpen={state.isEnquiryFormOpen}  
                        isKeyOpportunity={props.item.isKeyOpportunity}
                        homeCountry={props.homeCountry}
                        pOBoxType={props.pOBoxType}
                        taskSummary={props.item}
                        enquiryDefaultConfig={props.enquiryDefaultConfig}
                    />
                </>
            )}  

            {props.item.taskType !== 'PhoneTask' ? defaultCard() : phoneTaskCard()}
        </div>
    );
};