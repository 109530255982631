/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Card, makeStyles, Theme, createStyles } from '@material-ui/core';
import { retrieveTaskStatusData, TaskStatus, TaskData, TaskStatusEnum, TaskStatusQueryParams } from '../TasksSummaryRepository';
import { showNotification } from '../../App';
import { TaskSummaryAdvancedFilter } from '../TasksSummaryPage';
import { IAutoCompleteItem } from '../../typings/autoComplete';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            margin: '0 -5px',
            justifyContent: 'center',
            '& .card': {
                margin: '0 5px',
                padding: 5,
                textAlign: 'center',
                minWidth: '73px',
                '& .label': {
                    color: '#5173FF',
                    fontSize: '12px',
                    paddingBottom: '10px',
                },
                '& .counts': {
                    fontWeight: 600,
                    fontSize: '1.5rem'
                }
            }
        },
    })
);

interface StatusCardProps {
    isRefreshData: boolean;
    isFormOpen: boolean;
    advancedFilter: TaskSummaryAdvancedFilter;
    isInclude: TaskStatusEnum | null;
    isIncludeCrmTasks: boolean | null;
    isMyTasksOnly: boolean | null;
    isPersonalTask: boolean | null;
    isFromDataSource: boolean | null;
    isIncludeFollowUpTasksOnly: boolean | null;
}

interface StatusCardState {
    statuses: TaskStatus[];
}

export const StatusCard: React.FunctionComponent<StatusCardProps> = (props) => {

    const classes = useStyles();

    const [state, setState] = useState<StatusCardState>({
        statuses: []
    });
    
    useEffect(() => {   
        // tslint:disable-next-line: no-console
        // console.log('StatusCard Refreshing');
        if (!props.isFormOpen) {
            fetchData();
        }
        
    // tslint:disable-next-line: align
    }, [props]);
    
    const fetchData = () => {

        const taskStatusQueryParams: TaskStatusQueryParams = {
            taskStatusType: 'USER',
            includeCount: true,

            offset: null,
            first: null,
            filter: null,
            taskStatus: props.isInclude,
            isIncludeCrmTasks: props.isIncludeCrmTasks,
            isMyTasksOnly: props.isMyTasksOnly,
            groupBy: null,
            groupByValue: null, // This is the GuidId of group by
            completedFromDate: props.advancedFilter.completedFrom ? props.advancedFilter.completedFrom : null,
            completedToDate: props.advancedFilter.completedTo ? props.advancedFilter.completedTo : null,
            updatedFromDate: props.advancedFilter.updatedFrom ? props.advancedFilter.updatedFrom : null,
            updatedToDate: props.advancedFilter.updatedTo ? props.advancedFilter.updatedTo : null,
            priorityList: convertArrayToStringFromat(props.advancedFilter.priority),
            statusList: convertArrayToStringFromat(props.advancedFilter.status),
            allocatedToList: convertArrayToStringFromat(props.advancedFilter.allocatedTo),
            allocatedByList: convertArrayToStringFromat(props.advancedFilter.allocatedBy),
            created: props.advancedFilter.created ? props.advancedFilter.created.value : null,
            due: props.advancedFilter.due ? props.advancedFilter.due.value : null,
            teamList: convertArrayToStringFromat(props.advancedFilter.team),
            taskTypes: convertArrayToStringFromat(props.advancedFilter.taskTypes),
            isPersonalTask: props.isPersonalTask,
            isFromDataSource: props.isFromDataSource,
            matterList: convertArrayToStringFromat(props.advancedFilter.matterList),
            isIncludeFollowUpTasksOnly: props.isIncludeFollowUpTasksOnly,
            isIncludeCount: true
        };

        retrieveTaskStatusData(
            taskStatusQueryParams,
            true, // this.state.isRefresh, -- Since we are updating and creating, we do not fetch cached data
            // tslint:disable-next-line: no-console
            (data) => onRetrieveStatus(data),
            // tslint:disable-next-line:no-any
            function (reason: any): void {
                showNotification(null, reason, 'error');
            }
        );

    };

    const convertArrayToStringFromat = (_values: IAutoCompleteItem[] | undefined) => {
        return _values && _values.length > 0 ? _values.map((value: IAutoCompleteItem) => value.value).join() : null;
    };

    const onRetrieveStatus = (data: TaskData) => {
        setState((prevState) => {
            return {
                ...prevState,
                statuses: data.task.statuses.filter((x: TaskStatus) => {
                    return x.description.toLowerCase() !== 'complete';
                })
            };
        });
    };

    return (
        <div className={classes.root}>
            {state.statuses.map((status: TaskStatus) => {
                return (
                    <Card className="card" key={status.description}>
                        <div className="label">{status.description}</div>
                        <div className="counts">{status.count}</div>
                    </Card>
                );
            })}
        </div>
    );
};